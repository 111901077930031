@import "../../../../styles/theme.scss";

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.label {
  font-size: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  color: $cGreen;
}
