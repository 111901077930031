@import "../../../../styles/theme.scss";

$borderColor: lighten($cGray2, 5%);

.root {
  display: flex;
  gap: 32px;
  padding-left: 32px;
  border-left: 1px solid $borderColor;
  padding-bottom: 24px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: -2px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px solid $borderColor;
    left: -16px;
    position: absolute;
    background: $cWhite;

    @media screen and (max-width: 860px) {
      display: none;
    }
  }

  @media screen and (max-width: 860px) {
    padding-left: 0;
    border-left: none;
    flex-direction: column;
    gap: 8px;
    border-bottom: 1px solid $borderColor;
    padding-bottom: 8px;
  }
}

.info {
  max-width: 200px;

  h3 {
    font-size: 16px;
    color: $cPrimary;
    text-transform: uppercase;
  }

  div {
    margin-top: 4px;
    color: $cGray0Dark;
    font-size: 13px;
  }

  @media screen and (max-width: 860px) {
    max-width: none;
  }
}

.note {
  border-radius: 4px;
  padding: 10px 15px;
  font-size: 13px;
  flex: 1;
  background-color: lighten($cGray4, 1%);
  color: $cGray0Dark;
  border: 1px solid lighten($cGray2, 12%);
}

.actions {
  button {
    border: none;
    background: none;
    outline: none;
    font-size: 13px;
    color: $cPrimary;
    padding: 4px 8px;

    &:hover {
      text-decoration: underline;
    }
  }
}
