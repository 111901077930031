@import "../../../../styles/theme.scss";

.root {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  background-color: lighten($cPrimaryDim, 3%);
  border: 1.5px dashed $cPrimaryDim;
  color: $cPrimary;
  border-radius: 4px;
  transform: translate(-50%, -50%);
  max-width: 220px;
  min-width: 220px;
  text-align: center;
  box-shadow: 0 4px 20px 20px rgba(220, 238, 253, 0.3);
}
