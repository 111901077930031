.root {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.content {
  padding: 15px 0 0 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.tableWrap {
  overflow-x: scroll;
  padding: 0 20px 20px 0;
}
