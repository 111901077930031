@import "../../../../../../styles/theme.scss";

.inlineBlock {
  display: inline-block;
}

.validationTips {
  color: $cGray1;
  font-size: 13px;
  padding-left: 17px;
  padding-top: 8px;
  margin-bottom: 0;
  user-select: none;
  cursor: default;
}