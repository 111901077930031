.wrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
  font-family: "Lato", sans-serif;
}

.fitzpatrickBlock {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .skinDescp {
    position: absolute;
    top: 5px;
    right: 15px;
  }

  .testInfoBloc {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}

.title {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
}