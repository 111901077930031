@import "../../styles/theme.scss";

.root {
  position: relative;
  input {
    background-color: $cPrimaryDim;
    padding: 0 30px 0 10px;
    width: 100%;
    border-radius: 4px;
    color: $cPrimary;
    font-size: 14px;
    height: 40px;
  }
  img {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
  }
}

.error {
  background-color: $cRedDim !important;
  color: $cRed !important;
}

.disabled {
  background-color: $cGray4 !important;
  color: $cGray0 !important;
}
