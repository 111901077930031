.root {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.reportLayout {
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-size: 14px;
  color: #667680;
}

.headerContainer {
  display: flex;
  gap: 16px;
  justify-content: space-between;
}

.headerFiltersContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.inputGroup {
  display: flex;
  flex-direction: column;
}

.addFileButton {
  margin-left: auto;
  margin-top: auto;
}

.select {
  width: 150px;
}

.inputLabel {
  font-size: 14px;
  margin-bottom: 0;
}

.inputSize {
  height: 38px;
}

.actionData {
  display: flex;
  align-items: center;
  gap: 8px;

  .actionButtons {
    background: none;
    border: none;
    color: #227acd;
  }
}
