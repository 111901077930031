.inner {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  @media screen and (max-width: 545px) {
    flex-direction: column;
  }
}

.errorLogs {
  margin-top: 16px;
}
