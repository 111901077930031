.newInputFileldOuter{
   .tag-auto-select{
    padding-left:10px;
  }
  .selected-flag{
    width: 35px !important;
    border:none!important;
    margin-top:0px;
  }
  .field_error {
      border: 1px solid #e74c3c !important;
  }
  .intl-tel-input{display:inline-block;}
}
.profile-signature{
    .change-sig{
    position:absolute;
    right:12px;
    top:-6px;
  }
  .signature-box{height:156px;}
}
.sign-link{
  background:#cccccc;
  color:#666666;
  width:23px;
  height:23px;
  @include border-radius(3px);
  display: block;
    text-align: center;
    padding: 1px;
}
.sign-box-outer{
  padding-right:0px;
  margin-top:-47px;

  .setting-custom-switch{margin-top:5px;}
  .img-src img{height: 153px !important;}
}
.sign-switch-outer{
  background: #eeeeee;
  clear: both;
  float: left;
  width: 100%;
  padding: 3px 13px 10px;

  .setting-custom-switch{width:100%;color:#444444;}
}
.twofas-light-icons a{width:48%;}
.termsServices a{display:inline-block;;margin-right:20px;margin-bottom:20px;}
