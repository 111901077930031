.root {
  padding: 0 20px;
}

.errors {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 15px;
}
