.right {
  display: flex;
  align-items: center;
  gap: 16px;
}

.select {
  min-width: 230px;
}

.clinic {
  display: flex;
  align-items: center;
  gap: 8px;
}
