.root {
  @media screen and (max-width: 1396px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
.left {
  display: flex;
  align-items: center;
  gap: 8px;
  .filtersDirection {
    width: 170px;
  }
  .filtersAssignment {
    width: 185px;
  }
  @media screen and (max-width: 430px) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    .filtersDirection {
      width: 100%;
    }
    .filtersAssignment {
      width: 100%;
    }
  }
}

.right {
  display: flex;
  align-items: center;
  gap: 8px;

  @media screen and (max-width: 430px) {
    flex-direction: column;
    align-items: flex-start;
  }

  button {
    white-space: nowrap;
  }
}
