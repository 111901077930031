body {
  background: $main-bg !important;
}

.popup-modal-header {
  width: 270px;
}

@media (min-width: 600px) {
  .popup-modal-header {
    width: 305px;
  }
}

@media (min-width: 768px) {
  .popup-modal-header {
    width: 740px;
  }
}

header {
  height: 56px;
  border: none;
  width: 100%;
  float: left;
  position: fixed;
  top: 0px;
  background: $white-color;
  display: block;
  margin-bottom: 17px;
  z-index: 11;
  font-size: 13px;
  font-family: $opensan;
  @include box-shadow(0, 1px, 2px, 0, rgba(0, 0, 0, 0.15));

  .navbar-header {
    .navbar-toggle {
      position: relative;
      float: right;
      margin-right: 0px;
      padding: 9px 10px;
      margin-bottom: 13px;
      background-color: transparent;
      background-image: none;
      @include border-radius(4px);
      border: 1px solid $blue;
      display: none;

      span {
        background: $blue;
      }

      .sr-only {
        position: absolute;
        width: 1px;
        height: 1px;
        margin: -1px;
        padding: 0;
        overflow: hidden;
        border: 0;
      }

      .icon-bar {
        display: block;
        width: 22px;
        height: 2px;
        @include border-radius(1px);
      }
    }

    .navbar-brand {
      padding: 9px 0px;
      float: none;
      height: auto;
      display: inline-block;
      line-height: 1;
      font-size: 19px;
      width: 120px;
      margin-right: 0px;

      > img {
        height: 27px;
        display: block;
        max-width: inherit;
        margin: 0px;
      }
    }

    .navbar-brand-wrap {
      margin-top: 6px;
      display: flex !important;
      align-items: center;
      gap: 16px;
    }
  }

  ul.nav {
    float: right;
    padding: 0px 0px;
    margin: 0px;
    margin-left: 8px;

    > li {
      position: relative;
      display: block;
      margin: 0px;

      > a {
        padding: 18px 8px 10px 8px;
        font-size: 13px;
        color: #667680;
        border: none;
        font-weight: normal;
        letter-spacing: -1px;
        line-height: 21px;
        height: 56px;
        margin: 0px;

        label {
          font-weight: normal;
          cursor: pointer;
        }

        &:hover,
        &:focus {
          border-bottom: 2px solid $blue;
          background: none;
        }
      }

      .right-menus-a {
        height: 56px !important;
      }
    }

    > li.active a {
      color: #0472b3;
      border-bottom: 2px solid $blue;
    }

    .users-logs.show-desktop {
      background: url(/../images/History.svg) no-repeat center;
      border-bottom: none;
      height: 25px;
      width: 40px;
    }
  }

  ul.right-menus {
    .notification span {
      min-width: 17px;
      height: 17px;
      position: absolute;
      right: 4px;
      top: 8px;
      font-size: 12px;
      color: #fff;
      background: #e52817;
      border-radius: 20px;
      line-height: 17px;
      font-weight: 400;
      text-align: center;
      padding: 0px 2px;
    }

    li {
      & > div {
        padding: 17px 11px 18px;
      }

      a {
        padding: 17px 11px 18px;
        font-size: 13px;
        color: #667680;
        border: none;
        font-weight: normal;
        letter-spacing: -1px;
        line-height: 21px;
        height: 56px;

        &:hover {
          border-bottom: none;
        }
      }

      .users-logs.show-desktop {
        background: url(/../images/History.svg) no-repeat center;
        border-bottom: none;
        height: 25px;
        width: 30px;
      }

      .show-mobile {
        display: none !important;
      }

      .noti-icon.show-desktop {
        background: url(/../images/Notification.svg) no-repeat center;
        border-bottom: none;
        width: 30px;
      }

      .noti-popup {
        position: absolute;
        width: 300px;
        height: auto;
        border: 1px solid #ddd;
        @include border-radius(5px);
        @include box-shadow(0, 0px, 14px, 0, #eee);

        min-height: 100px;
        background: #fff;
        z-index: 20;
        right: 0px;
        top: 54px;

        .noti-title {
          font-size: 13px;
          color: #444;
          font-weight: 500;
          text-align: left;
          padding: 8px 12px;
          border-bottom: 2px solid $blue;
        }

        .noti-content {
          max-height: 311px;
          overflow-x: hidden;
          overflow-y: auto;
          background: #f2f2f2;

          > div {
            display: block;
            font-size: 13px;
            color: #444;
            text-align: left;
            padding: 8px 12px;
            border-bottom: 1px solid #ddd;

            &:last-child {
              border: none;
            }
          }

          .read {
            background: #fff;
          }
        }

        .noti-footer {
          display: block;
          padding: 8px;
          border-top: 1px solid #ddd;
          text-align: center;

          a {
            color: $blue;
          }
        }
      }
    }

    li.arow-link-outer {
      .arow-link {
        @include border-radius(3px);
        height: auto;
        line-height: 10px;
        margin-top: 12px;
        padding: 4px 9px;
        width: auto;
        border-bottom: none;
        padding-right: 0px;
        display: inline-block;
        color: #667680;

        #headerUserImage {
          float: left;
          margin-top: -9px;
          width: 40px;
          height: 40px;
          display: inline-block;
          @include border-radius(50%);
          overflow: hidden;
          vertical-align: top;
          background: url(/../images/user.png) #eee;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
        }

        .name-txt {
          display: inline-block;
          margin-top: -2px;
          margin-left: 5px;
          border-right: 1px solid #ececec;
          padding: 8px 10px 5px;
        }
      }
    }

    .setting.show-desktop {
      background: url(/../images/Settings.svg) no-repeat center;
      border-bottom: none;
      height: 25px;
      width: 40px;

      &:hover,
      &.select {
        background: url(/../images/setting-hover.svg) no-repeat center;
        background-size: 18px 18px;
      }
    }

    li.active {
      .setting.show-desktop {
        background: url(/../images/setting-hover.svg) no-repeat center;
        background-size: 18px 18px;
      }

      .noti-icon.show-desktop {
        background: url(/../images/NotificationHover.svg) no-repeat center;
        background-size: 18px 18px;
      }

      .users-logs.show-desktop {
        background: url(/../images/HistoryHover.svg) no-repeat center;
        background-size: 18px 18px;
      }
    }

    .setting.right-menus-a {
      height: 24px !important;
      border-right: 1px solid #ececec;
      margin-top: 15px;
      padding: 9px 8px !important;
    }

    .logout.show-desktop {
      background: url(/../images/logout.png) no-repeat right center;
      border-bottom: none;
      height: 25px;
      width: 30px;
      padding-right: 0px;
    }
  }

  .container-fluid {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.upgradeBtn,
.upgradeBtn:hover {
  height: 33px !important;
  color: #ffffff !important;
  font-size: 13px !important;
  letter-spacing: 0px !important;
  padding: 4px 10px 4px !important;
  margin-top: 11px !important;
  background-color: $blue;
  background-image: linear-gradient(-90deg, #047ec3, #21a7f2) !important;
  line-height: 25px !important;
  min-width: inherit;

  svg {
    margin-right: 3px;
  }
}

.show-mobile-upgrade {
  display: none;
}

.inboxLock {
  display: inline-block;
  vertical-align: top;
  margin-right: 3px;
  margin-left: 0px;

  svg {
    font-size: 11px;
  }
}

.inboxCount {
  min-width: 17px;
  height: 17px;
  position: absolute;
  right: -8px;
  top: 8px;
  font-size: 12px;
  color: #fff;
  background: #e52817;
  border-radius: 20px;
  line-height: 17px;
  font-weight: 400;
  text-align: center;
  padding: 0px 2px;
}

.UpgradeClinic-modal-wrapper {
  font-family: lato, sans-serif;
}

.selectCountry {
  float: left;
  font-size: 13px;
  color: #047ec3;
  background: #ffffff;
  position: relative;
  padding: 0px 4px;
  top: 1px;
  min-height: 18px;
  z-index: 1;
  outline: none;
  box-sizing: border-box;
  font-family: lato, sans-serif;
}

.newInputFileldOuterModel {
  width: 100%;
  position: relative;
  margin-bottom: 10px;
  margin-top: 15px;

  .newInputLabelModel {
    font-size: 12px;
    color: $blue;
    background: #ffffff;
    margin: 0px 11px -8px;

    padding: 0px 4px;
    top: -9px;

    .fieldRequired {
      color: $blue;
    }
  }

  .newInputFieldModel {
    width: 100%;

    color: #404040;
    border: 1px solid #cad2d6;
    @include border-radius(3px);
    height: 33px;
    padding: 0px 15px;
  }

  .newSelectFieldModel {
    width: 100%;

    color: #404040;
    border: 1px solid #cad2d6;
    @include border-radius(3px);
    background-image: url(/../images/select-arrow2.png);
    background-repeat: no-repeat;
    background-position: right 8px center;
    background-size: 10px auto;
    background-color: #ffffff;
    height: 33px;
    padding: 0px 15px;
  }

  .newtextareaFieldModel {
    width: 100%;
    float: left;
    color: #404040;
    border: 1px solid #cad2d6;
    @include border-radius(3px);
    height: 150px;
    padding: 10px 15px;
  }
}

.newInputFileldOuter {
  float: left;
  width: 100%;
  position: relative;
  margin-bottom: 10px;
  margin-top: 15px;

  .newInputLabel {
    float: left;
    font-size: 12px;
    color: $blue;
    background: #ffffff;
    margin: 0px 11px -8px;
    position: absolute;
    padding: 0px 4px;
    top: -9px;

    .fieldRequired {
      color: $blue;
    }
  }

  .newInputField {
    width: 100%;
    float: left;
    color: #404040;
    border: 1px solid #cad2d6;
    @include border-radius(3px);
    height: 33px;
    padding: 0px 15px;
  }

  .newSelectField {
    width: 100%;
    float: left;
    color: #404040;
    border: 1px solid #cad2d6;
    @include border-radius(3px);
    background-image: url(/../images/select-arrow2.png);
    background-repeat: no-repeat;
    background-position: right 8px center;
    background-size: 10px auto;
    background-color: #ffffff;
    height: 33px;
    padding: 0px 15px;
  }

  .newtextareaField {
    width: 100%;
    float: left;
    color: #404040;
    border: 1px solid #cad2d6;
    @include border-radius(3px);
    height: 150px;
    padding: 10px 15px;
  }
}

.UpgradeClinic-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.clockOut-modal-wrapper {
  text-align: center;
  font-family: lato, sans-serif;

  .clockTime {
    font-size: 26px;
    display: block;
    color: #404040;
    font-weight: bold;
    margin-top: 15px;
  }

  .clockDate {
    font-size: 20px;
    display: block;
    color: #047ec3;
    margin-top: 8px;
  }

  .clockName {
    font-size: 21px;
    display: block;
    color: #404040;
    margin-top: 30px;
  }

  .clockDetail {
    font-size: 14px;
    display: block;
    color: #404040;
    margin-top: 6px;

    span {
      display: inline-block;
      color: $blue;
      font-weight: bold;
    }
  }

  .ClockOuter {
    margin-top: 30px;
    background: #eaf2f8;
    display: flex;
    align-items: center;
    @include border-radius(5px);
    flex-direction: row;

    .clockSection {
      padding: 20px 30px;

      span {
        width: 100%;
        display: block;
        color: $blue;
        font-size: 22px;
      }

      p {
        width: 100%;
        display: block;
        margin: 0px;
        font-size: 13px;
        color: #667680;
        text-transform: uppercase;
      }

      img {
        height: 67px;
      }
    }
  }

  .clockOutBtn {
    margin: 26px 0px 0px;
    padding: 4px 25px;
    height: 40px;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 30px;
    color: #ffffff;
  }

  .clockContent {
    overflow: inherit;
    max-height: inherit;
    padding: 0px 35px;
    line-height: 21px;
  }

  .small-cross {
    padding-top: 0px;
  }
}

.navbar-collapse#navbar {
  padding: 0px;
}

.clockIn {
  display: inline-block;
  vertical-align: top;
  margin-top: -4px;
  margin-left: 0px;
  margin-right: 0px;
}

.ClockLoggedInBtn {
  border: 1px solid #047ec3 !important;
  color: #047ec3 !important;
  font-size: 13px !important;
  height: auto !important;
  line-height: 22px !important;
  padding: 4px 8px 5px !important;
  display: inline-block;
  vertical-align: top;
  @include border-radius(3px);

  &:hover {
    border: 1px solid #047ec3;
    background: $blue;
    color: #ffffff !important;
  }
}

.mobileClockIn {
  display: none;
}

.headerUpdatedImageOuter {
  display: inline-block;
  margin-left: 12px;
  margin-top: 7px;
  float: left;
  position: relative;
  padding: 0px !important;

  .headerUpdatedImage {
    float: left;
    width: 40px;
    height: 40px;
    display: inline-block;
    @include border-radius(50%);
    overflow: hidden;
    vertical-align: top;
    background: url(/../images/user.png) #eee;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    cursor: pointer;

    .headerUserName {
      position: absolute;
      top: 51px;
      right: 0px;
      background: #000000;
      color: #ffffff;
      font-size: 13px;
      padding: 8px 13px;
      white-space: nowrap;
      @include border-radius(5px);
      text-align: center;
      // opacity: 0;
      display: none;
      transition: 1s;
      z-index: 9;

      &:after {
        position: absolute;
        top: -10px;
        right: 10px;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #000000;
        content: "";
        height: 10px;
        width: 10px;
      }

      p {
        color: #ffffff;
        margin-bottom: 0px;
      }

      span {
        color: #999999;
        font-size: 12px;
      }
    }

    &:hover .headerUserName {
      // opacity:1;
      display: block;
    }
  }

  .myAccountPopup {
    position: absolute;
    top: 51px;
    right: 0px;
    background: #ffffff;
    color: #ffffff;
    font-size: 13px;
    @include border-radius(5px);
    width: 300px;
    height: auto;
    border: 1px solid #ddd;
    @include box-shadow(0, 0px, 14px, 0, #eee);
    z-index: 10;
    padding: 5px 0px 7px;
    letter-spacing: 0px;
    max-height: calc(100vh - 100px);
    overflow-y: auto;

    a {
      padding: 5px 10px 5px 50px;
      display: block;
      color: #404040;
      font-size: 14px;
      font-weight: 600;
      height: auto;
      letter-spacing: 0px;
      background-size: auto 15px !important;
      background-repeat: no-repeat !important;
      background-position: 22px 8px !important;

      &:hover {
        color: $blue;
        background-color: #fafafa;
      }

      &.selected {
        color: $blue;
      }
    }

    .popupAccount {
      background: url(/../images/user-icon.png);
    }

    .popupLogout {
      background: url(/../images/logout.png);
    }

    .clinicdropdownLink {
      width: 100%;
      background-color: #ffffff;
      padding-top: 3px;

      li {
        width: 100%;
      }
    }

    &:after {
      position: absolute;
      top: -10px;
      right: 10px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #ffffff;
      content: "";
      height: 10px;
      width: 10px;
    }
  }
}

.ClinicSection {
  background-color: #ffffff;
  margin-bottom: 0px;
  word-break: break-word;
  border-bottom: 1px solid #dddddd;
  padding-bottom: 7px;
  padding-right: 10px;
}

.ClinicSection a {
  position: relative;
  padding: 12px 18px 12px 50px !important;
  display: block !important;
}

.ClinicSection .branchLogo {
  position: absolute;
  left: 12px;
  top: 7px;
  width: 30px;
  height: 30px;
  background: #dddddd;
  @include border-radius(5px);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.ClinicSection a.selected {
  background-image: url(/../images/new-check.png) !important;
  color: $blue;
  background-repeat: no-repeat !important;
  background-position: right 11px !important;
}

.worksapceDropDrown {
  padding: 12px 14px;
  display: none;

  button {
    width: 100%;
    text-align: left;
    position: relative;
    font-size: 13px;

    .caret {
      position: absolute;
      right: 8px;
      top: 17px;
    }
  }

  .btn-default:active:focus {
    color: #666666 !important;
    background-color: #ffffff !important;
    border-color: #dddddd !important;
    outline: none;
  }

  ul.dropdown-menu {
    top: 52px !important;
    left: 15px !important;
    transform: initial !important;
    right: 14px !important;
    padding: 8px 0px;

    li {
      padding-right: 6px;
    }

    li a.active {
      background-image: url(/../images/new-check.png) !important;
      color: $blue;
      background-repeat: no-repeat !important;
      background-position: right 7px !important;
      background-color: inherit;
    }

    li a {
      padding: 5px 20px 5px 42px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;

      .accountLogo {
        position: absolute;
        left: 10px;
        top: 6px;
        width: 25px;
        height: 25px;
        background: #dddddd;
        @include border-radius(3px);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        margin-top: -5px;
      }
    }
  }
}
