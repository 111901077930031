@import "../../../../styles/theme.scss";

.label {
  color: $cPrimary;
  font-size: 15px;
  font-weight: 600;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
