@import "../../../../../../../styles/theme.scss";

.root {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.onFile {
}
