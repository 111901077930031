.tableHead {
  font-size: 13px;
}

.bigColWidth {
  width: 180px;
}

.biggerColWidth {
  width: 230px;
}

.row {
  font-size: 13px;
}

.col {
  word-break: break-word;
}

.loading:after {
  content: " .";
  font-size: 23px;
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: #667680;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 #667680, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 #667680, 0.5em 0 0 #667680;
  }
}
