@import "./base.scss";

.root {
  background-color: $cWhite;
  padding: 18px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: $tabletBreakpoint) {
    padding: 15px 20px;
  }

  @media screen and (max-width: $mobileBreakpoint) {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
}

.info {
  display: flex;
  align-items: center;
  gap: 63px;

  @media screen and (max-width: $tabletBreakpoint) {
    gap: 32px;
  }

  @media screen and (max-width: $mobileBreakpoint) {
    gap: 16px;
  }
}

.image {
  border-radius: 3px;
  min-height: 57px;
  min-width: 57px;
  background-color: $cGray3;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 45px;
    margin-right: 2px;
  }
}

.nameWrap {
  display: flex;
  align-items: center;
  gap: 35px;
  flex-wrap: wrap;

  @media screen and (max-width: $tabletBreakpoint) {
    gap: 10px;
  }
}

.name {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.launchBtn {
  margin-right: 44px;

  @media screen and (max-width: $tabletBreakpoint) {
    margin-right: 0;
  }

  @media screen and (max-width: $mobileBreakpoint) {
    width: 100%;
  }
}

.status {
  padding: 3px 9px;
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  border-radius: 4px;
}

.statusHold {
  background-color: $cYellowDim;
  color: $cYellowDark;
}

.statusActive {
  background-color: $cGreenDim;
  color: $cGreen;
}

.statusInactive {
  background-color: $cGray4;
  color: $cGray0;
}

.statusCancelled {
  background-color: $cRedDim;
  color: $cRed;
}
