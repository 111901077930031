.profile-detail-left {
  position: relative;
  padding-left: 210px;
}

.setting-custom-switch {
  margin-top: 22px;
}

#img-src {
  text-align: center;
}
#img-src img {
  height: 206px;
}

.signature-subtitle {
  margin: 10px 0px !important;
}

.img-src .input-outer {
  border: 1px solid #ddd;
  display: table;
  border-radius: 3px;
  height: 38px;
  background: #ffffff;
  position: relative;
  width: 100%;
  float: left;
}

#signature_image {
  max-width: 100%;
}

.sig-div, .change-sig {
  float: left;
  margin-top: 18px;
}

.change-pwd-outer {
  float: right;
  padding-left: 25px;
}
.signature-box {
    width: 100%;
    float: left;
    background-color:#ffffff;
    border: 1px solid #dddddd;
    height: 150px;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    margin-bottom: 20px;
    height: 200px;
    overflow: hidden;
    margin: 0px;

    > div{margin:auto;}
    .canvas-container{background:#f5f5f5;}
}
.img-src img{height:198px!important;}
#sig-div {
    text-align: center;
}
.setting-input-box-invalid{
border-bottom: 1px solid #e74c3c !important;
}
.payroll-formula {
    float: left;
    width: 100%;
    padding-left: 25px;
    margin: -7px 0px 16px 0px;
}
.payroll-label {
    color: #404040;
}
#payroll_error{color: red;padding-left: 25px;}
#payroll_selection_div{
  .payroll-label{padding-bottom:8px;padding-top:0px;}
  input{margin-top:2px;}
}
.colored-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f13638;
    -webkit-transition: .4s;
    transition: .4s;
    border-color:#f13638;
}
input:checked + .colored-slider {
    background-color: #8cbd3f;
    border-color:#8cbd3f;
}

.header-select-btn {
    font-size: 14px !important;
    font-weight: 400;
    color: #fff !important;
    background: #2682c5;
    display: inline-block;
    padding: 10px 20px;
    border-radius: 3px;
    border: 1px solid #2682c5;
    margin-left: 5px;
    height: 32px;
    line-height: 31px !important;
    padding: 0px 20px !important;
    cursor: pointer;
    float: right;
    text-decoration: none;
    font-family: arial;
}

.content-terms .popup-three-btns{
  padding: 10px;
}

.content-ar-terms-of-use, .content-hipaa-terms-of-use {
    max-width: 1200px;
    width: 100%;
    margin: auto;
    min-height: 700px;
    display: table;
    height: 10087px;
    background-size: 100% auto!important;
}
.content-ar-terms-of-use {
    background: url(/../../../images/content-ar-terms-of-use.jpeg) no-repeat center;
height: 38800px;
}
.content-hipaa-terms-of-use {
    background: url(/../../../images/content-hipaa-terms-of-use.jpg) no-repeat center top;
    height:3000px;
}

.xs-m-b-25{
  margin-bottom: 25px;
}
@media (max-width: 768px) {
  .xs-m-b-25{
    margin-bottom: inherit;
  }
}



/*# sourceMappingURL=setting-profile.css.map */
