@import "../../SalesCheckoutInvoice.variables.scss";

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}

.text {
  display: flex;
  align-items: center;
  gap: 30px;

  .title {
    font-size: 16px;
    font-weight: 400;
  }

  @media screen and (max-width: $mobileBreakpoint) {
    gap: 20px;
  }
}

.btn {
  @media screen and (max-width: $mobileBreakpoint) {
    width: 100%;
  }
}
