@import "../../../../../../styles/theme.scss";

.reasons {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
}

.reasonAdornment {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
}

.footer {
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid $cGray2;

  .footerButtons {
    padding-top: 16px;
    display: flex;
    gap: 16px;
  }
}