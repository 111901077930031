.portalSection {
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
  font-family: "Lato", sans-serif;
}

.portalContainer {
  display: flex;
  padding-top: 15px;
  flex-direction: column;
  gap: 6px;
}

.portalTable {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 2fr;
  align-items: center;
}

.infoItem {
  text-transform: uppercase;
  font-weight: 700;
}

.portalContent {
  display: table-cell;
  vertical-align: top;
  font-size: 13px;
  color: #5a5a5a;
  word-break: break-all;

  .line-btn {
    font-size: 13px;
    padding: 4px 10px;
  }
}

.portalLabel {
  width: 100px;
  display: table-cell;
  vertical-align: top;
  font-size: 13px;
  color: #5a5a5a;
}

.switch {
  margin-top: 6px;
  float: none;
}

.accessBtn {
  width: 100%;
  margin-left: 0;
}
