.root {
  border: none;
  background: none;
  outline: none;
  display: flex;
  align-items: center;

  img {
    width: 20px;
  }
}
