@import "../../styles/theme.scss";

.root {
  position: relative;
  input {
    border-radius: 8px;
    border: 1px solid $cGray1;
    height: 50px;
    width: 100%;
    padding: 0 35px 0 15px;
    background-color: $cWhite;
    color: $cBlack;
    font-size: 16px;
  }
  img {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translate(0, -50%);
  }
}

.error {
  border-color: $cRed !important;
  background-color: $cRedDim2 !important;
  color: $cRed !important;
  &::placeholder {
    color: $cRed;
  }
}

.disabled {
  background-color: $cGray4 !important;
  pointer-events: none;
  cursor: default;
  user-select: none;
  color: $cGray0 !important;
  &::placeholder {
    color: $cGray0;
  }
}
