@import "../../../../../../styles/theme.scss";

.text {
  border: none;
  background: none;
  color: $cPrimary;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;

  &:hover {
    color: $cPrimaryDark !important;
    text-decoration: underline;
  }

  img {
    width: 14px;
  }
}

.contained {
  border: none;
  padding: 4px 17px 4px;
  height: 32px;
  font-size: 14px;
  color: $cWhite;
  background-color: $cPrimary !important;
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  &:hover {
    color: $cWhite !important;
  }

  img {
    width: 15px;
  }
}

.disabled {
  opacity: 0.6;
  cursor: default;

  &:hover {
    text-decoration: none;
  }
}

.error {
  &.text {
    color: $cRed;

    &:hover {
      color: $cRedDark !important;
    }
  }

  &.contained {
    background-color: $cRed !important;

    &:hover {
      background-color: $cRedDark !important;
    }
  }
}