.root {
  width: 440px;
  margin: 0 auto;

  @media screen and (max-width: 500px) {
    width: 100%;
  }
}

.header {
  font-weight: 300;
  margin-bottom: 48px;
  font-size: 36px;
  text-align: center;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
