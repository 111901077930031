@import "../../styles/theme.scss";

.wide-popup-wrapper.time-line.displayBlock {
  display: block !important;
}

.save-sticky-note-button {
  border: 1px solid #047ec3;
  cursor: pointer;
  padding: 4px 17px 4px;
  height: 32px;
  font-size: 14px;
  color: #047ec3;
  border-radius: 3px;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  margin-left: 16px;
  text-decoration: none !important;
  font-family: "Lato", sans-serif;
  line-height: 24px;
  background-color: white;
}

.note-cancel-button {
  margin-left: 16px !important;
}

.sticky-notes-modal {
  position: absolute !important;
  right: 38px !important;
  max-width: 500px;
  overflow: hidden;

  max-height: 90%;

  > div {
    overflow: auto;
  }

  .sticky-notes-modal-head {
    background: #dceefe;
    overflow: visible;
    padding: 12px 0px 12px 24px;

    h3 {
      color: $cPrimary !important;
      font-weight: 600;
      line-height: 24.51px;
      font-size: 18px;
    }
  }

  .sticky-note-item:last-child {
    border-radius: 0px 0px 36px 36px;
  }

  .row {
    display: flex;
    margin: 0px !important;
  }

  div {
    padding: 0px;
  }

  .time-line {
    padding: 0px;
  }

  .note-timeline {
    border: none;
    margin-top: 0px;
  }

  #note-content {
    flex-grow: 1;
  }

  .sticky-note-item {
    padding: 20px 30px;
    margin: 0px !important;

    .note-label {
      display: flex;
      align-items: center;
      position: relative;

      label {
        margin: auto;
        margin-right: 4px;
        margin-top: 16px;
        min-width: 34px;
      }

      .label-text {
        flex-grow: 1;
        font-size: 15px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;

        .read-more {
          font-size: 16px;
          color: #1172ce;
          cursor: pointer;
        }

        ::first-letter {
          text-transform: capitalize;
        }
      }

      .settings-icon {
        cursor: pointer;
        color: #9b9b9b;

        .dropdown-toggle {
          padding: 0px 6px;
        }
      }
    }

    .note-body {
      padding: 20px 20px 20px 0px;

      .body-text {
        white-space: pre-wrap;
        flex-grow: 1;
        font-size: 13px;
        font-weight: 400;
        line-height: 17.7px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;

        ::first-letter {
          text-transform: capitalize;
        }
      }

      button {
        font-size: 13px;
      }
    }

    .note-edit-history {
      margin-top: 10px;
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;
      color: $cGray0;
    }
  }

  .sticky-note-item:nth-child(even) {
    background-color: #f5f5f5;
  }

  .note-actions {
    display: flex;
    width: 60%;
    gap: 16px;
    margin: 16px 20px 8px 0px;

    sup {
      font-size: 16px;
    }

    .note-action {
      color: #1172ce;
      cursor: pointer;

      .dropdown {
        .dropdown-toggle {
          border: 1px solid #1172ce;
          padding: 7.43px 20px !important;
          font-size: 13px !important;
          border-radius: 5px;
          display: flex;
          align-items: center;
          gap: 15px;
          font-weight: 500;
        }
      }
    }

    button {
      margin: 0px !important;
      padding: 5px 15px !important;
      font-size: 13px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
    }
  }

  .dropdown-item {
    color: #2c3e50;
    padding: 5px 20px;
  }

  .dropdown-item:hover {
    background-color: #dbeefd;
  }
}

.sticky-note-editor {
  .setting-textarea-box {
    color: inherit !important;
    overflow: auto !important;
    min-height: 112px;
    padding-top: 10px;
  }

  .stick-note-title {
    width: 100%;
    border-bottom: 1px solid black;
    box-sizing: border-box;
    padding: 5px 0px;
  }
}

.read-full-note {
  font-size: 18px;
  margin-bottom: 8px;
  overflow-y: auto;
  max-height: 50vh;
  word-break: break-word;
  white-space: pre-wrap;
}

.delete-note-confirmation-display {
  margin-top: 12px;
  font-weight: 600;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.note-history-modal-content {
  overflow: scroll;
}

.acknowledge-history-modal {
  max-width: 500px;

  table {
    width: 100%;
  }

  th,
  td {
    padding: 16px 10px;
  }

  td.date-cell {
    min-width: 180px !important;
  }

  tr:nth-child(even) {
    background-color: #f5f5f5;
  }
}

.client-notes-container.time-line {
  width: 90% !important;
  max-width: 1280px !important;
}

.sticky-note-image {
  width: 35px;
  height: 35px;
  display: inline-block;
  border-radius: 30px;
  left: -4px;
  position: absolute;
  background: #fff;
}

.sticky-note-add-title-modal-footer {
  margin: auto !important;
}

.setting-input-box-invalid {
  border-bottom: 1px solid #e74c3c !important;
}
.headerSticky {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .acknowledgeBtn {
    margin-right: 25px;
  }
}
