@import "../../SalesCheckoutInvoice.variables.scss";

.root {
  display: flex;
  flex-direction: column;
  padding: 26px 30px;
  gap: 16px;

  @media screen and (max-width: $mobileBreakpoint) {
    padding: 20px;
  }
}

.label {
  font-size: 20px;
  font-weight: 700;
}
