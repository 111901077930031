.root {
  overflow-x: scroll;
}

.colBig {
  min-width: 200px;
  max-width: 230px;
  width: 230px;
}

.colSmall {
  min-width: 120px;
  max-width: 150px;
  width: 150px;
}

.shopifyNameWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}
