.actions {
  display: flex;
  justify-content: flex-end;
  gap: 4px;
}

.col {
  max-width: 200px;
  min-width: 150px;
}
