@import "../../styles/theme.scss";

.root {
  padding: 5px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: $cPrimary;
  font-size: 14px;
  font-weight: 400;
  width: fit-content;

  &:hover {
    background-color: $cPrimaryDim;
  }

  img {
    min-width: 13px;
    width: 13px;
  }
}
