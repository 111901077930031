@import "../../styles/theme.scss";

.root {
  border-radius: 4px;
  height: 40px;
  border: none;
  padding: 0 10px;
  color: $cPrimary;
  font-size: 14px;
  width: 100%;
  background-color: $cPrimaryDim;
  background-image: linear-gradient(45deg, transparent 50%, $cPrimary 50%),
    linear-gradient(135deg, $cPrimary 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 4px),
    calc(100% - 15px) calc(1em + 4px);
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  padding-right: 32px;

  &.vLegacy {
    height: 32px;
    width: fit-content;
    border-radius: 3px;
    background-color: #f2f6f7;
    background-position: calc(100% - 15px) calc(1em + 1px),
      calc(100% - 10px) calc(1em + 1px);
    background-image: linear-gradient(45deg, transparent 50%, #667780 50%),
      linear-gradient(135deg, #667780 50%, transparent 50%);
    color: #667780;
  }
}

.error {
  background-color: $cRedDim !important;
  color: $cRed !important;
  background-image: linear-gradient(45deg, transparent 50%, $cRed 50%),
    linear-gradient(135deg, $cRed 50%, transparent 50%);

  &::placeholder {
    color: $cRed !important;
  }
}

.disabled {
  background-color: $cGray4 !important;
  pointer-events: none;
  cursor: default;
  user-select: none;
  color: $cGray0 !important;
  background-image: linear-gradient(45deg, transparent 50%, $cGray0 50%),
    linear-gradient(135deg, $cGray0 50%, transparent 50%);

  &::placeholder {
    color: $cGray2 !important;
  }
}
