@import "../../styles/theme.scss";

.root {
  padding: 20px;
  border-radius: 8px;
}

.rootFitContent {
  width: fit-content;
}

.warn {
  background-color: $cOrangeDim;
  color: $cOrangeDark;
}

.success {
  background-color: $cGreenDim;
  color: $cGreenDark;
}

.error {
  background-color: $cRedDim3;
  color: $cRed;
}

.sSmall {
  padding: 10px;
  font-size: 13px;
  border-radius: 4px;
}

.center {
  text-align: center;
}
