@import "../../../../../../styles/theme";

.resetModalWrapper {
  font-family: "Lato", sans-serif;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.passwordInput {
  position: relative;
}

.clientTreatCal {
  position: absolute;
  right: 0px;
  top: 4px;
  color: $cPrimary;
  cursor: pointer;
  z-index: 2;
  border: none;
}

.passHidden {
  width: 45px;
  height: 45px;
  background: url(/../images/eye-icon.png) no-repeat center;
}

.passShow {
  width: 45px;
  height: 45px;
  background: url(/../images/eye-slash-icon.png) no-repeat center;
}

.passwordNote {
  margin-top: 15px;
}
