.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 45px;
  border-radius: 0;
}

.header {
  text-align: center;
  font-weight: bold;
  margin-bottom: 8px;
}

.inner {
  width: 450px;

  @media screen and (max-width: 650px) {
    width: 100%;
  }

  ul {
    margin-left: 25px;
  }
}
