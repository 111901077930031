@import "../../../../../styles/theme.scss";

$breakpointTablet: 800px;
$breakpointMobile: 490px;

.root {
  display: grid;
  grid-template-columns: 225px 1fr;
  gap: 20px;
  padding: 20px 20px 0 20px;
  @media screen and (max-width: $breakpointTablet) {
    display: block;
  }
  @media screen and (max-width: $breakpointMobile) {
    padding: 15px 15px 0 15px;
  }
}

.files {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  height: 100%;
  @media screen and (max-width: $breakpointTablet) {
    &.filesHide {
      display: none;
    }
  }

  .filesInner {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .filesWrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-height: 544px;
    height: 544px;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding-bottom: 20px;
    &::-webkit-scrollbar {
      display: none;
    }
    @media screen and (max-width: $breakpointTablet) {
      height: auto;
      max-height: unset;
      padding-bottom: 15px;
    }
  }

  .filesTitle {
    display: none;
    margin-right: 10px;
    @media screen and (max-width: $breakpointTablet) {
      display: block;
    }
  }

  .filesSubtitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }

  .filesFooter {
    display: none;
    @media screen and (max-width: $breakpointTablet) {
      display: block;
      padding-bottom: 16px;
    }
  }
}

.contentWrap {
  border-left: 1px solid $cGray3;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  @media screen and (max-width: $breakpointTablet) {
    border: none;
    padding-left: 0;
    &.contentHide {
      display: none;
    }
  }

  .header {
    display: flex;
    align-items: center;
    padding-right: 10px;

    .headerBack {
      border: none;
      background: none;
      padding: 10px;
      border-radius: 50%;
      display: none;
      margin-right: 16px;
      transition: all 0.15s ease;
      &:hover {
        background-color: $cPrimaryDim;
      }
      @media screen and (max-width: $breakpointTablet) {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      @media screen and (max-width: $breakpointMobile) {
        margin-right: 5px;
      }
    }

    .headerDropzone {
      margin-left: 16px;
    }
  }

  .content {
    flex: 1;
    padding: 20px 0;
    @media screen and (max-width: $breakpointMobile) {
      padding: 15px 0;
    }
  }
}

.title {
  font-size: 23px;
  font-weight: 600;
  margin-bottom: 3px;
  @media screen and (max-width: $breakpointMobile) {
    font-size: 20px;
  }
}

.contentWrapInner {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.modalRoot {
  @media screen and (max-width: $breakpointTablet) {
    min-height: 90%;
  }
}

.footer {
  padding-bottom: 20px;
  @media screen and (max-width: $breakpointTablet) {
    padding-bottom: 15px;
  }
}
