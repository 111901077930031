.patient-referral-report-graph {
  display: flex;
  flex: 1 1 0px;
  height: 500px;
  align-items: center;
  justify-content: center;
  margin-left: -150px;
}

.angleArrow {
  width: 10px;
  height: 15px;
  margin-left: 5px;
  color: #0472b3;
}

.sourcesNameGraph {
  fill: black !important;
}

#patientReferralReportContainer .highcharts-text-outline {
  display: none;
}

.patient-referral-report-container {
  width: 600px;
}

.break-word {
  word-wrap: break-word;
  overflow-wrap: break-word;
}
