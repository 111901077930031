@import "../../styles/mixins.scss";

.root {
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 860px) {
    gap: 0;
  }
}

.notes {
  flex: 1;
  display: flex;
  max-height: 50vh;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: visible;
  @include hide-scroll-bar();

  @media screen and (max-width: 860px) {
    gap: 16px;
  }
}
