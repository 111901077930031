@import "../../styles/theme.scss";

.root {
  -webkit-animation: loader-1-1 4.8s linear infinite;
  animation: loader-1-1 4.8s linear infinite;

  span {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    -webkit-animation: loader-1-2 1.2s linear infinite;
    animation: loader-1-2 1.2s linear infinite;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      border-style: solid;
      border-radius: 50%;
      -webkit-animation: loader-1-3 1.2s cubic-bezier(0.77, 0, 0.175, 1)
        infinite;
      animation: loader-1-3 1.2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
    }
  }
}

/* Size */

.sTiny {
  height: 14px;
  width: 14px;
  min-width: 14px;
  min-height: 14px;

  span {
    height: 14px;
    width: 14px;
    clip: rect(0, 14px, 14px, 7px);

    &::after {
      height: 14px;
      width: 14px;
      clip: rect(0, 14px, 14px, 7px);
      border-width: 1px;
    }
  }
}

.sSmall {
  height: 20px;
  width: 20px;
  min-width: 20px;
  min-height: 20px;

  span {
    height: 20px;
    width: 20px;
    clip: rect(0, 20px, 20px, 10px);

    &::after {
      height: 20px;
      width: 20px;
      clip: rect(0, 20px, 20px, 10px);
      border-width: 2px;
    }
  }
}

.sMedium {
  height: 30px;
  width: 30px;
  min-width: 30px;
  min-height: 30px;

  span {
    height: 30px;
    width: 30px;
    clip: rect(0, 30px, 30px, 15px);

    &::after {
      height: 30px;
      width: 30px;
      clip: rect(0, 30px, 30px, 15px);
      border-width: 2px;
    }
  }
}

.sBig {
  height: 40px;
  width: 40px;
  min-width: 40px;
  min-height: 40px;

  span {
    height: 40px;
    width: 40px;
    clip: rect(0, 40px, 40px, 20px);

    &::after {
      height: 40px;
      width: 40px;
      clip: rect(0, 40px, 40px, 20px);
      border-width: 3px;
    }
  }
}

/* Color */

.cPrimary span::after {
  border-color: $cPrimary;
}

.cGray1 span::after {
  border-color: $cGray1;
}

.cWhite span::after {
  border-color: $cWhite;
}

.cRed span::after {
  border-color: $cRed;
}

.cOrange span::after {
  border-color: $cOrange;
}

.cGreen span::after {
  border-color: $cGreen;
}

/* Keyframes */

@-webkit-keyframes loader-1-1 {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes loader-1-1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader-1-2 {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(220deg);
  }
}

@keyframes loader-1-2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(220deg);
  }
}

@-webkit-keyframes loader-1-3 {
  0% {
    -webkit-transform: rotate(-140deg);
  }
  50% {
    -webkit-transform: rotate(-160deg);
  }
  100% {
    -webkit-transform: rotate(140deg);
  }
}

@keyframes loader-1-3 {
  0% {
    transform: rotate(-140deg);
  }
  50% {
    transform: rotate(-160deg);
  }
  100% {
    transform: rotate(140deg);
  }
}
