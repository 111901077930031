@import "../../../../styles/theme.scss";

.root {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.footer {
  padding-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.passCodeTooltip {
  display: flex;
  align-items: center;
  color: $cBlue;
  border: none;
  background: none;
  outline: none;
}
