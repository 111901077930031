@import "../../../../../../styles/theme.scss";

.root {
  width: 500px;
}

.changeCalculatorButton {
  color: $cPrimary;
  border: none;
  background: transparent;
  font-size: 15px;
  margin-right: 10px;

  &:hover {
    text-decoration: underline;
  }
}

.change {
  .amount {
    font-weight: bold;
    color: $cPrimary;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.footer {
  display: flex;
  align-items: flex-end;
}

.infoBox {
  display: flex;
  align-items: center;
  gap: 8px;

  .infoIcon {
    display: flex;
    padding-bottom: 2px;
  }
}
