@import "../../styles/theme.scss";

.root {
  border: 1px solid $cGray2;
  border-radius: 4px;
  width: 122px;
  height: 137px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.imageBox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #fafafa;
  border-radius: 4px;
  position: relative;
  color: $cGray2;

  img {
    max-width: 100%;
    max-height: 100px;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }

  .remove {
    position: absolute;
    top: 4px;
    right: 4px;
    border: none;
    background: $cWhite;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    color: $cRed;

    svg {
      height: 12px;
    }
  }
}

.upload {
  padding: 8px 0px;
  font-size: 13px;
  margin: 0;
  border-top: 1px solid $cGray2;
  font-weight: 400;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: center;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  &:hover {
    background-color: $cPrimaryDim3;
  }

  input {
    visibility: hidden;
    position: absolute;
    left: -1000px;
  }
}
