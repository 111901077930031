.selects {
  width: 600px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 16px;

  & > * {
    flex: 1;
  }

  @media screen and (max-width: 740px) {
    width: 100%;
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
}
