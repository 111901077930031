.browser-support {
  display: table;
  margin: auto;
  width: 550px;
  text-align: center;
  margin-top: 100px;

  .browser-ar-logo {
    height: 45px;
    margin: 0px 0px 20px;
  }

  .browser-support-wrapper {
    background: #ffffff;
    @include box-shadow(0, 1px, 2px, 0, rgba(0, 0, 0, 0.15));
    @include border-radius(3px);
    width: 100%;
    min-height: 100px;
    padding: 30px;
    float: left;

    .browse-title {
      font-size: 16px;
      font-weight: bold;
      color: #667680;
      margin-bottom: 15px;
    }
    p {
      font-size: 14px;
      font-weight: normal;
      margin: 0px;
      color: #667680;
    }
    ul {
      width: 100%;
      float: left;
      text-align: center;
      display: table;
      margin: 20px 0px;

      li {
        display: table-cell;
        height: 100px;
        position: relative;
        cursor: pointer;
        padding-top: 15px;

        a {
          color: #667680;
        }

        img {
          height: 50px;
        }
        span {
          width: 100%;
          position: absolute;
          top: 70px;
          left: 0px;
          font-size: 12px;
        }

        &:hover {
          background: #fafafa;
        }
      }
    }
  }
}
