@import "../../../../../styles/theme.scss";
@import "../../../sass/variables.scss";

.clientEntity {
  @media screen and (max-width: $desktopMaxBreakpoint) {
    width: $entityRowMaxWidth !important;
  }

  @media screen and (max-width: $tabletMaxBreakpoint) {
    width: 100% !important;
  }
}

.formGrid {
  display: grid;
  grid-template-columns: repeat(3, 380px);
  gap: 10px;

  @media screen and (max-width: $desktopMaxBreakpoint) {
    display: flex;
    flex-direction: column;
  }
}

.formControls {
  margin-top: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
}

.formBtn {
  padding: 0 35px;
}

.alert {
  margin-top: 8px;
  width: $entityRowContentMaxWidth;

  @media screen and (max-width: $tabletMaxBreakpoint) {
    width: 100%;
  }
}
