.tableWrapper {
  overflow-x: auto;
}

.col {
  width: 120px;
  max-width: 120px;
  min-width: 120px;
  word-break: break-all;
}

.fulfillmentStatus {
  word-break: break-word;
}

.checkboxCol {
  width: 40px;
}

.emailCol {
  word-break: break-all;
  width: 200px;
  max-width: 200px;
  min-width: 200px;
}

.actionCol {
  display: flex;
  justify-content: flex-end;
}
