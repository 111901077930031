@import "../../../../styles/theme.scss";

.root {
  border: none;
  background: none;
  outline: none;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 2px;
  font-size: 12px;
  color: $cGray0;

  &:hover {
    color: $cPrimary;
  }
}
