@import "../../styles/variables.scss";
@import "../../styles/theme.scss";

.root {
  position: relative;
  width: fit-content;
}

.menu {
  position: absolute;
  z-index: $zIndexMax;
  border-radius: 4px;
  background: $cWhite;
  overflow: auto;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  max-height: 200px;
}

.option {
  border: none;
  background: none;
  padding: 5px 8px;
  color: $cGray0Dark;
  width: 100%;
  text-align: left;
  font-size: 14px;
  white-space: nowrap;

  &:hover {
    background-color: $cPrimaryDim;
    color: $cPrimary;
  }
}

// Position

.posBottomRight {
  top: calc(100% + 8px);
  right: 0;
}

.posBottomLeft {
  top: calc(100% + 8px);
  left: 0;
}

.posTopRight {
  bottom: calc(100% + 8px);
  right: 0;
}

.posTopLeft {
  bottom: calc(100% + 8px);
  left: 0;
}
