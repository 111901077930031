@mixin border-radius($radius) {
  -moz-border-radius: $radius;
  -webkit-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin transition($what: all, $time: 0.2s, $how: ease-in-out) {
  -webkit-transition: $what $time $how;
  -moz-transition: $what $time $how;
  -ms-transition: $what $time $how;
  -o-transition: $what $time $how;
  transition: $what $time $how;
}

@mixin transform($property) {
  -webkit-transform: $property;
  -ms-transform: $property;
  transform: $property;
}

@mixin ellipsis() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin opacity($params) {
  filter: alpha(opacity=$params);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$params)";
  opacity: $params;
}

@mixin translateX($x) {
  -webkit-transform: translateX($x);
  -ms-transform: translateX($x);
  transform: translateX($x);
}

@mixin box-shadow(
  $x-axis: 0,
  $y-axis: 0px,
  $blur: 0px,
  $radius: 0px,
  $color: $default
) {
  box-shadow: $x-axis $y-axis $blur $radius $color;
  -moz-box-shadow: $x-axis $y-axis $blur $radius $color;
  -webkit-box-shadow: $x-axis $y-axis $blur $radius $color;
  -ms-box-shadow: $x-axis $y-axis $blur $radius $color;
}
@mixin box-shadow-inherit {
  box-shadow: inherit;
  -moz-box-shadow: inherit;
  -webkit-box-shadow: inherit;
  -ms-box-shadow: inherit;
}
