.root {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.top {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 32px;
}
