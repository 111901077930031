@import "../../../../styles/theme.scss";

.openModalBtn {
  border: none;
  background: none;
  color: $cPrimary;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer {
  display: flex;
  gap: 16px;
}
