@import "../../styles/theme.scss";

.header {
  display: flex;
  align-items: center;
  gap: 8px;
  color: $cRed;
}

.footer {
  display: flex;
  justify-content: flex-end;
}
