.setting-textarea-box {
  overflow: auto !important;
}

.procedure-notes-container.time-line {
  width: 90% !important;
  max-width: 1280px !important;
}

.procedure-notes-list {
  display: block !important;
  padding: 8px !important;
  font-size: 13px !important;
  color: #444444 !important;
  text-decoration: none !important;
  cursor: pointer !important;

  &:hover {
    background: #f5f5f5;
    color: #188bf6;
  }
}
