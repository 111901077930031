.medical-history {
  .setting-wrapper {
    @media (max-width: 991px) {
      padding: 0 14px 40px;
    }
  }

  .close-form,
  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 16px;
    height: 16px;
  }

  .close-form {
    top: 8px;
    right: 8px;
    background: url(/../images/close.png) no-repeat center;
  }

  .juvly-container {
    padding: 55px 40px;

    @media (max-width: 991px) {
      padding: 55px 14px 40px;
    }
  }

  .medical-history-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .new-white-btn {
      @media (max-width: 767px) {
        margin-bottom: 2px;
      }
    }

    .new-white-btn,
    .new-blue-btn {
      @media (max-width: 767px) {
        width: 100%;
      }
    }
  }

  .history-section {
    position: relative;
    display: inline-block;
    width: 100%;
    background: rgba(102, 118, 128, 0.05);
    border-radius: 2px;
    padding: 20px;
    margin-bottom: 10px;

    .history-section-title {
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 14px;
      letter-spacing: -0.30000001192092896px;
      color: #667680;

      @media (max-width: 480px) {
        width: 170px;
      }
    }

    .history-section-head {
      display: flex;
      align-items: center;

      @media (max-width: 767px) {
        cursor: pointer;

        &:after {
          content: "";
          position: absolute;
          right: 20px;
          width: 12px;
          height: 12px;
          background-image: url("/../images/arrow-blue.svg");
          background-repeat: no-repeat;
          background-position: center;
          transform: rotate(90deg);
        }
      }
    }

    .history-section-checkbox {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0;
      color: #000000;
      margin: 0 0 0 20px;
      cursor: pointer;

      .history-section-checkbox-input {
        margin: 0 8px 0 0;
        width: 14px;
        height: 14px;

        &:after {
          content: "";
          background-image: url("/../images/new-check.png");
          background-size: cover;
          width: 12px;
          height: 12px;
          top: 0 !important;
          left: 0;
        }
      }
    }

    .basic-form-checkbox {
      &:disabled {
        &:after {
          background: #cad2d6;
        }

        + .basic-form-text {
          cursor: not-allowed;
        }
      }
    }

    .history-section-body {
      margin-top: 20px;

      .newInputFileldOuter {
        padding-left: 0;
      }
    }

    .history-section-surgery-row {
      float: left;
    }

    .history-section-row-expand,
    .history-section-row-add-new,
    .history-section-add-new {
      position: relative;
      display: inline-block;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 14px;
      letter-spacing: -0.30000001192092896px;
      color: #2682c5;
      margin: 0 0 20px 20px;
      cursor: pointer;

      &:before {
        content: "+ ";
        left: 0;
      }
    }

    .history-section-row-add-new {
      margin: 5px 0 25px 17px;
    }

    .history-section-row-expand {
      text-transform: capitalize;
      float: right;

      &:before {
        content: "";
      }
    }

    .history-section-delete {
      position: relative;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0;
      color: #0472b2;
      margin: 23px 0;
      padding-left: 20px;
      cursor: pointer;
      height: 17px;
      min-width: 17px;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        background-image: url("/../images/delete-icon.png");
        background-size: contain;
        background-position: center;
        width: 12px;
        height: 15px;
        top: 1px;
      }

      &.history-section-delete-empty {
        &:before {
          display: none;
        }
      }

      @media (max-width: 767px) {
        padding: 0;
      }
    }

    .history-section-delete-text {
      @media (max-width: 767px) {
        display: none;
      }
    }

    .history-section-radio-title {
      width: auto;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 14px;
      letter-spacing: -0.30000001192092896px;
      text-align: left;
      color: #667680;
      margin: 0 0 5px 0;
      text-transform: capitalize;
    }

    .history-section-radios-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-left: 10px;
      min-height: 25px;

      @media (max-width: 991px) {
        min-height: 0;
      }

      @media (max-width: 767px) {
        clear: both;
      }

      .basic-checkbox-outer-wrapper {
        display: flex;
      }

      .basic-checkbox-outer {
        margin: 0;
        display: flex;

        &:last-child {
          margin-right: 0;

          .basic-form-text {
            margin-right: 0;
          }
        }
      }

      .basic-form-text {
        cursor: pointer;
        color: #404040;
      }
    }
  }

  .history-section_collapse {
    @media (max-width: 991px) {
      &.history-section {
        padding: 0;
      }

      .history-section-head {
        padding: 20px;

        &:after {
          transform: rotate(0deg);
        }
      }

      .history-section-body {
        display: none;
      }
    }
  }

  .newInputFileldOuter {
    .newInputLabel {
      background: #f7f8f9;
      padding: 0 2px;
      text-transform: capitalize;
    }

    .newInputField {
      margin-bottom: 10px;
    }

    .input-autocomplete {
      position: relative;

      &:before {
        content: "";
        position: absolute;
        background-image: url("/../images/appointment-hover.png");
        background-size: cover;
        width: 18px;
        height: 18px;
        z-index: 2;
        right: 10px;
        top: 9px;
        pointer-events: none;
      }
    }

    .input-helper {
      color: #e74c3c;
      padding: 0;
      margin: 0 0 0 13px;
      font-size: 12px;
      font-weight: 400;
      position: absolute;
      left: 0;
      bottom: -8px;
      white-space: nowrap;
      text-transform: capitalize;
    }

    .danger-text {
      color: #e74c3c;
    }
  }

  .autocomplete-select {
    margin-bottom: 10px;

    .css-bg1rzq-control {
      min-height: 36px;
      height: 36px;
      border: 1px solid #cad2d6;
    }

    .css-kj6f9i-menu {
      z-index: 100;
    }

    .css-16pqwjk-indicatorContainer,
    .css-bgvzuu-indicatorSeparator {
      display: none;
    }

    &.field_error {
      border-radius: 4px;
    }
  }

  .input-wrapper-5,
  .input-wrapper-3 {
    display: inline-flex;
    padding: 0 5px;
    width: 33.3%;

    @media (max-width: 991px) {
      width: 100%;
    }

    &:first-child {
      padding-left: 0;

      @media (max-width: 991px) {
        padding: 0 5px;
      }
    }

    &:last-child {
      padding-right: 0;

      @media (max-width: 991px) {
        padding: 0 5px;
      }
    }
  }

  .input-wrapper-5 {
    width: 20%;

    @media (max-width: 991px) {
      width: 100%;
    }
  }

  .input-fields-row-wrapper {
    clear: both;

    @media (max-width: 991px) {
      clear: both;
      display: flex;
      align-items: center;
    }
  }

  .input-fields-row {
    @media (max-width: 991px) {
      position: relative;
      width: 100%;

      &.input-fields-2-row {
        &:after {
          height: 90px;
        }
      }

      &.input-fields-3-row {
        &:after {
          height: 160px;
        }
      }

      &.input-fields-5-row {
        &:after {
          height: 310px;
        }
      }

      &:after {
        content: "";
        position: absolute;
        display: block;
        width: 1px;
        background-color: rgba(0, 0, 0, 0.11);
        right: 0;
        top: 26px;
      }
    }
  }

  .row-flex-wrap {
    display: flex;
    flex-wrap: wrap;
  }

  .section-drug-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 767px) {
      .newInputFileldOuter {
        flex: 1 0 130px;
      }
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
}
