@import "../../../.././../../styles/theme.scss";

.root {
  color: $cGray1;

  ul {
    padding-top: 8px;
    padding-left: 20px;
    font-size: 13px;
    list-style-type: none;

    li {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
}

.error {
  color: $cRed;
  display: flex;
  align-items: center;
}

.success {
  color: $cGreen;
  display: flex;
  align-items: center;
}
