ul.merge-steps {
  list-style: none;
  display: block;
  text-align: right;
  font-size: 13px;
  border-bottom: 1px solid #dddddd;
  margin: 0px 15px 0px;

  li {
    display: inline-block;

    a {
      display: inline-block;
      padding: 0px 20px 16px;

      .step-no {
        width: 20px;
        height: 20px;
        border: 1px solid #666666;
        @include border-radius(40px);
        color: #404040;
        text-align: center;
        display: inline-block;
        margin-right: 7px;
        line-height: 16px;
      }
      .step-name {
        color: #666666;
        font-weight: normal;
        cursor: pointer;
      }
    }

    &.merge-sel {
      a {
        border-bottom: 1px solid $blue;

        .step-no {
          border: 1px solid $blue;
          background: $blue;
          color: #ffffff;
        }
      }
    }
  }
}
.next-step {
  line-height: 28px;
  padding: 0px 20px !important;
}
.duplicate-patient-name {
  float: left;
  padding: 0px 15px;
}
.merge-setion {
  background: #ffffff;
  float: left;
  width: 100%;
  @include border-radius(5px);
  @include box-shadow(0, 1px, 2px, 0, rgba(0, 0, 0, 0.15));

  position: relative;
}
.section-title {
  border-bottom: 1px solid #dddddd;
  display: block;
  padding: 15px;
}
.section-title-name {
  color: #444444;
  font-size: 12px;
  text-transform: uppercase;
}
.section-title-p {
  color: #999999;
  font-size: 13px;
  display: inline-block;
  padding-left: 15px;
  margin-left: 15px;
  border-left: 1px solid #ddd;
  margin-bottom: 0px;
}
.merge-outer {
  margin-top: 15px;
}
.merge-content {
  padding: 20px 15px;
  float: left;
  width: 100%;
}
.merge-form-label {
  font-size: 11px;
  color: #667680;
  display: block;
  text-transform: uppercase;
  font-weight: normal;
  margin-bottom: 12px;
}
.profile-images {
  display: block;
}
.merge-pic {
  display: inline-block;
  width: 55px;
  height: 55px;
  @include border-radius(90px);
  border: 2px solid #ffffff;
  margin-right: 40px;
  background-image: url("/../../images/user.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}
.merge-pic.selected-pic {
  @include box-shadow(0, 0, 0, 1px, $blue);
}
.merge-form-row {
  margin-bottom: 15px;
  padding: 0px 15px;
}
.merge-field-outer {
  border-bottom: 1px solid #dddddd;
  display: block;
  min-height: 47px;
  padding-bottom: 5px;
  position: relative;
  padding-left: 47px;
}
.merge-plus {
  width: 38px;
  height: 35px;
  text-align: center;
  font-size: 30px;
  color: $blue;
  @include border-radius(3px);
  border: 1px dashed #ddd;
  line-height: 30px;
  display: inline-block;
  vertical-align: top;
  margin-right: 8px;
  margin-bottom: 5px;
  position: absolute;
  left: 0px;
  top: 0px;
}
.merge-plus:hover {
  background: $blue;
  border: 1px solid $blue;
  color: #ffffff;
  cursor: pointer;
}
.default-input {
  border: 1px solid #ddd;
  display: inline-block;
  padding: 8px 15px;
  @include border-radius(4px);
  color: #999;
  vertical-align: top;
  margin-right: 2%;
  background: #ffffff;
  margin-bottom: 5px;
  width: 47.3%;
  cursor: pointer;
  position: relative;
  min-height: 37px;
}
.custom-input {
  border: 1px solid #c2e0f2;
  display: inline-block;
  padding: 8px 15px;
  @include border-radius(4px);
  color: #444444;
  background: #c2e0f2;
  vertical-align: top;
  margin-right: 2%;
  margin-bottom: 5px;
  width: 47.3%;
  cursor: pointer;
  position: relative;
  min-height: 37px;
}
.removable-input {
  border: 1px solid #c2ccf2;
  display: inline-block;
  padding: 8px 15px;
  @include border-radius(4px);
  color: #444444;
  background: #c2ccf2;
  vertical-align: top;
  margin-right: 2%;
  position: relative;
  padding-right: 30px;
  margin-bottom: 5px;
  width: 47.3%;
  cursor: pointer;
  min-height: 37px;
}
.custom-input a {
  position: absolute;
  right: 8px;
  top: 11px;
  background: url("/../../images/cross-input.png") no-repeat center;
  width: 14px;
  height: 14px;
}
.default-input a {
  position: absolute;
  right: 8px;
  top: 11px;
  background: url("/../../images/cross-input.png") no-repeat center;
  width: 14px;
  height: 14px;
}
.header-blue {
  background: #c2e0f2;
}
.new-popup-form-section .form-group {
  margin-bottom: 0px;
}
.merge-pro-data-img {
  width: 115px;
  height: 115px;
  display: inline-block;
  background: #f5f5f5;
  @include border-radius(5px);

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.duplicate-patient-name {
  float: left;
  padding: 0px 15px;
}
.or-left {
  padding-left: 0px;
}
.or-right {
  padding-right: 0px;
}
.patient-photo {
  width: 40px;
  height: 40px;
  display: inline-block;
  overflow: hidden;
  @include border-radius(50px);
  margin: -6px 0px -10px;
}
.add-field {
  float: none;
  clear: both;
  display: inline-block;
  position: absolute;
  right: 15px;
  bottom: 0px;
  z-index: 10;
}
.add-field button {
  font-size: 24px;
  background: $blue;
  border: none;
  color: #fff;
  height: 27px;
  line-height: 2px;
  width: 27px;
  padding: 0px 0px 2px;
  @include border-radius(30px);
}
.add-field-list {
  height: 380px;
  overflow: auto;
  position: absolute;
  right: 200px;
  padding: 15px;
  margin-left: -130px !important;
}
.add-field-list li {
  padding: 3px 0px;
}
.add-field-list input {
  display: inline-block;
  line-height: normal;
  margin: 0;
  vertical-align: top;
}
.add-field-list label {
  display: inline-block;
  font-size: 13px;
  font-weight: normal;
  vertical-align: top;
  margin: 0px;
}
.select_all {
  margin: 0px !important;
  height: 17px;
  display: inline-block;
  vertical-align: top;
  margin-right: 8px !important;
}
.pro-procedure-image img {
  max-width: 100%;
}
.pro-procedure-checkbox {
  margin-right: 8px !important;
  display: inline-block;
  position: absolute;
  top: 74px;
  left: 0px;
  margin-top: -10px !important;
}
.pro-procedure-image {
  display: inline-block;
  width: 300px;
  margin-right: 15px;
  float: left;
  max-width: 100%;
}
.pro-procedure-row {
  position: relative;
  border-top: 1px dotted #ddd;
  padding: 10px 0px 0px 32px;
  float: left;
  width: 100%;
}
.pro-proc-title {
  color: #404040;
  display: block;
  font-size: 16px;
  margin-bottom: 5px;
}
.pro-proc-time {
  display: block;
  font-weight: 600;
  margin-bottom: 5px;
}
.pro-proc-text {
  display: block;
  font-size: 13px;
}
.merged-procedure {
  padding-left: 0px;
}
.section-tab {
  width: 49%;
  display: inline-block;
  text-align: center;
  padding: 15px 0px;
  text-transform: none;
}
.section-tab.sel-merge-tab {
  border-bottom: 1px solid $blue;
  color: $blue;
}
.section-tab-outer {
  padding: 0px 15px;
  height: 49px;
}
.merge-setion div.pro-procedure-row:last-child {
  border: none;
}

.popup-field-box .chosen-choices {
  border: none !important;
  background: none !important;

  li {
    background: none;

    &:first-child {
      border: none;
    }
  }
}
.patient-treat-icon {
  position: absolute;
  top: 15px;
  right: 9px;
  padding: 0px !important;
  border: none;
  margin: 0px;
  height: auto;
  color: $blue;
}
.header-cancel-btn {
  position: absolute;
  top: 11px;
  left: 10px;
}
.full-input-width {
  width: 99%;
  margin-right: 0px;
}
.email-body-content,
.phone-body-content {
  overflow: hidden;
  display: block;
  padding-left: 14px;
}
input[type="checkbox"]:checked::after,
.checkbox input[type="checkbox"]:checked::after,
.checkbox-inline input[type="checkbox"]:checked::after {
  top: initial;
  bottom: 0px;
}
.phone-input,
.email-input {
  display: inline-block;
  vertical-align: top;
  margin-top: 3px;
  margin-right: 4px !important;
  margin-left: -7px !important;
  cursor: pointer;
  position: absolute;
  top: 7px;
  left: 15px;
}
.popup-input-field-name {
  font-size: 10px;
  color: #667680;
  display: block;
  text-transform: uppercase;
  margin-bottom: 5px;
  float: left;
  width: 100%;
  font-family: $lato;
  font-weight: normal;
}
.popup-field-box {
  border: none;
  border-bottom: 1px dotted #bfc9ce;
  min-height: 26px;
  font-size: 14px;
  padding-left: 0px;
  margin-bottom: 22px;
  color: #404040;
  width: 100%;
  padding-bottom: 5px;
  min-height: 45px;
  display: block;
}
.check-outer {
  position: absolute;
  right: 10px;
  top: 10px;
}
.med-history-row {
  float: left;
  width: 100%;
  padding: 5px 15px;
}
.med-history {
  margin-bottom: 20px;
  float: left;
  width: 100%;
}
.med-history > div:first-child .pro-procedure-row {
  border: none;
}
.audit-btn {
  padding: 4px 20px 4px 15px !important;
  svg {
    right: 5px;
    top: 8px;
  }
}
