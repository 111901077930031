@import "../../../styles/theme.scss";

.root {
  margin: 8px 0 16px 0;
  flex: 1;
}

.tab {
  font-size: 14px;
  padding: 8px;
}

.chartTableHead {
  border-bottom: 5px solid #d0d5dd !important;
  background-color: #ffffff !important;
  font-size: 17px;
  color: #5c5050;
}

thead .chartTableCol {
  font-family: Lato;
  font-size: 15px;
  font-weight: 700;
  color: #5c5050;
}

tbody .chartTableCol td {
  text-align: center;
}

tbody .chartTdWidth td {
  width: 150px;
}
