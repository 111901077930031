@import "../../styles/theme.scss";

.root {
  display: flex;
  flex-direction: column;
  gap: 8px;

  img {
    width: auto;
  }
}

.signature {
  border-radius: 8px;
  display: flex;
  justify-content: center;
  border: 1px solid $cGray1;
  background-color: $cPrimaryDim;

  .signBlock {
    background-color: $cWhite;
  }
}

.buttonsWrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;

  .buttonsInner {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
  }
}

.error {
  background-color: $cRedDim;
  border-color: $cRed;
}

.disabled {
  opacity: 0.6;
}
