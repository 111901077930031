.tabsList {
    display: flex;
    list-style: none;
    gap: 16px;
    margin-left: 15px;
    margin-right: 15px;

    .rightElement {
        margin-left: auto;
    }
}

.procedureFilters {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    margin: 15px;

    .minHeightButtons {
        min-height: 30px;
        height: 30px;
    }

    .datePicker {
        min-width: 215px;
    }
}

.tabListSelects {
    min-height: 30px;
    height: 30px;
    min-width: 150px;
    gap: 16px;

    div {
        min-height: 30px;
        height: 30px;

        div {
            display: flex;
            align-items: center;
            font-size: 13px;

            img {
                max-width: 15px;
            }
        }
    }
}

@media (max-width: 1024px) {
    .procedureFilters {
        float: left;
    }
}
