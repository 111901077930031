@import "../../../../styles/theme.scss";

.root {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  min-height: 40px;

  @media screen and (max-width: 590px) {
    flex-direction: column;
    gap: 16px;
    min-height: auto;
  }
}
