@import "../../styles/theme.scss";

.root {
  display: flex;
  align-items: center;

  span {
    color: $cGray0Dark;
  }
}

.rootTiny {
  gap: 8px;

  span {
    font-size: 14px;
  }
}

.rootSmall {
  gap: 8px;

  span {
    font-size: 16px;
  }
}

.rootMedium {
  gap: 10px;

  span {
    font-size: 18px;
  }
}

.rootBig {
  gap: 16px;

  span {
    font-size: 20px;
  }
}

.rootCenter {
  justify-content: center;
}
