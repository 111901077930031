@import "../../../../../styles/theme.scss";

.root {
  display: flex;
  align-items: center;
  color: $cPrimary;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  gap: 10px;
  margin-left: 10px;
}
