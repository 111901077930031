@import "../../../../styles/theme.scss";

.title {
  padding: 0 0 20px 0 !important;
}

.alert {
  margin-top: 20px;

  a {
    color: $cGreenDark;
    text-decoration: underline;
  }
}
