@import "../../../../../styles/theme.scss";

.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 15px;
  border-radius: 4px;
  background-color: #f5f9ff;
  border: 1px solid $cPrimaryDim2;

  h3 {
    font-weight: bold;
    font-size: 16px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .params {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
  }
}

.footer {
  display: flex;
  align-items: center;
  gap: 16px;
  padding-top: 16px;

  button {
    border: none;
    background: none;
    outline: none;
    padding: 0;
    display: flex;
    align-items: center;
    gap: 2px;
    font-size: 12px;
    color: $cGray0;

    &:hover {
      color: $cPrimary;
    }
  }
}

.type {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $cPrimary;
  color: $cPrimary;
  border-radius: 50px;
  padding: 0 8px;
  font-size: 11px;
  width: fit-content;
}

@mixin fillIndicator() {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 13px;

  svg {
    width: 17px;
  }
}

.notFilled {
  @include fillIndicator();
  color: $cRed;
}

.filled {
  @include fillIndicator();
  color: $cGreen;
}

.empty {
  @include fillIndicator();
  color: $cGray0;

  svg {
    width: 15px;
  }
}
