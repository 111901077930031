.inputFieldRow {
  display: flex;
  gap: 16px;

  .membershipInput {
    min-width: 50%;
    width: 50%;
  }

  .inputFieldActions {
    display: flex;
    gap: 8px;

    .colorsContainer {
      position: relative;

      .colorsList {
        background: white;
        position: absolute;
        right: 8px;
        top: 40px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        justify-content: center;
        gap: 4px;
        width: 160px;
        border: 1px solid #dddddd;
        border-radius: 5px;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
        padding: 4px;
      }
    }
  }

  .colorSquare {
    display: inline-block;
    width: 34px;
    height: 34px;
    border: none;
    border-radius: 5px;
    background: #000000;
  }
}
