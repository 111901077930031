@import "../../../../../../styles//theme.scss";

.root {
  overflow-x: scroll;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.field {
  color: $cGray1;
  font-weight: 500;
  font-style: italic;
}

.fieldCol {
  min-width: 200px;
}

.diffCol {
  min-width: 400px;
}

.diff {
  font-size: 13.5px;
}
