.root {
  text-align: center;
  margin-bottom: 16px;

  .name {
    font-size: 20px;
    font-weight: 600;
  }

  .birthday {
    font-size: 16px;
  }
}

.skeleton {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  margin-bottom: 16px;
}
