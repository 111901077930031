@import "../../styles/theme.scss";

.root {
  position: relative;
  padding-left: 34px;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  width: fit-content;

  .label {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: $cBlack;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    visibility: hidden;
  }

  .checkMark {
    position: absolute;
    top: 50%;
    left: 0;
    height: 23px;
    width: 23px;
    border-radius: 5px;
    background-color: $cWhite;
    border: 1.5px solid $cGray2;
    transform: translate(0, -50%);
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 13px;
    }
  }

  input:checked ~ .checkMark:not(.invertedCheckMark) {
    background-color: $cPrimaryDim;
    border-color: $cPrimaryDim;
  }
}

.rootSmall {
  padding-left: 26px;

  .label {
    font-size: 14px;
    line-height: 22px;
  }

  .checkMark {
    height: 18px;
    width: 18px;

    img {
      width: 12px;
    }
  }
}

.rootNoLabel {
  padding-left: 23px;
}

.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.intermediateIcon {
  width: 10px;
  height: 2px;
  border-radius: 2px;
  background-color: $cPrimary;
}
