@import "../../styles/theme.scss";

.root {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.empty {
  font-size: 14px;
  color: $cGray1;
}

.option {
  border: none;
  background: none;
  font-size: 15px;
  font-weight: 500;
  color: $cPrimary;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  outline: none;
  padding: 8px 24px;
  background-color: $cPrimaryDim3;
  border-radius: 5px;
  transition: all 0.15s ease;
  white-space: nowrap;
  height: auto;

  &:hover:not(.optionActive) {
    background-color: lighten($cPrimaryDim2, 3%);
  }
}

.optionActive {
  color: $cWhite;
  background-color: $cPrimary;
}

.disabled {
  opacity: 0.6;
  cursor: default;
  user-select: none;
  pointer-events: none;
}

.loader {
  margin-left: 4px;
}
