.clientWrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
  font-family: "Lato", sans-serif;

  .title {
    font-weight: 700;
    text-transform: uppercase;
  }

  .itemText {
    text-transform: uppercase;
    font-size: 12px;
  }
}

.programBlock {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}

.item {
  display: flex;
  gap: 5px;
  align-items: center;

  input {
    margin: 0;
  }
}

@media (max-width: 375px) {
  .programBlock {
    gap: 8px;
  }
}
