@import "../../../../styles/theme.scss";

.root {
  margin: 8px 0 16px 0;
  border-bottom: 1px solid $cGray3;
  flex: 1;
}

.tab {
  font-size: 14px;
  padding: 8px;
}
