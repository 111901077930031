@import "../../../../../styles/theme";

.patientInfoName {
  font-weight: 700;
  word-break: break-all;
  font-size: 20px;

}

.patientNick {
  font-weight: 700;
  word-break: break-all;
  font-size: 14px;
}

.personalDataContainer {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
}

.patientCard {
  width: 100%;
}

.personalInfoItem {
  margin-left: 5px;
  font-size: 14px;
  font-weight: normal;
}

.infoSection {
  display: flex;
  padding: 15px 15px 10px 15px;
  gap: 10px;
  flex-direction: column;
  font-family: "Lato", sans-serif;

  .infoCard {
    display: flex;
    gap: 10px;
  }

  .infoImg {
    max-height: 135px;
    max-width: 144px;
    border: inherit;
    box-shadow: inherit;
    border-radius: 3px;
  }

  .patientPersonalInfo {
    display: flex;
  }

  .patientInfoTitle {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
  }

  .patientActions {
    margin-top: 10px;
    font-weight: 600;
  }

  @media (max-width: 600px) {
    .infoCard {
      flex-direction: column;
    }
  }
}

.allergiesBlock {
  display: flex;
}

.allergiesInfo {
  word-break: break-word;
  font-size: 14px;
}

.patientContactInformationSection {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #ddd;
  gap: 15px;
  padding-bottom: 10px;
}

.patientInfoItem {
  font-size: 12px;
  color: #667680;
  text-transform: uppercase;
  font-weight: 700;
}

.patientItemField {
  font-weight: normal;
  font-size: 14px;
  color: #404040;
}

.btnMenu {
  background: none;
  border: none;
  width: 100%;
  text-align: left;
  padding: 0 20px;
}

.btnLi {
  display: block;
  padding: 3px 0;
  clear: both;
  width: 100%;
  font-weight: normal;
  line-height: 1.42857143;
  color: #7b8a8b;
  white-space: nowrap;
  font-size: 13px;

  &:hover,
  &.active {
    text-decoration: none;
    color: #ffffff;
    background-color: $cPrimary;
  }

  &:focus {
    text-decoration: none;
    color: #ffffff;
    background-color: $cPrimary;
  }
}

