.root {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.providerTitleBox {
  display: flex;
  align-items: center;
  gap: 64px;

  h3 {
    font-size: 20px;
    margin: 0;
  }

  .dateRange {
    margin-left: auto;
  }

  .dashboardTabs {
    display: flex;
    gap: 16px;

    a {
      color: #667680;
      cursor: pointer;
    }

    a.active {
      color: #188bf6;
    }
  }
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 32px;
  row-gap: 16px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.widgetCard {
  background: white;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 2px 0px;
}

.cardContent {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 15px;
}

.cardData {
  display: grid;
  grid-template-columns: 70% 30%;
  font-weight: bold;
  font-size: 13px;
  color: #667680;
}

.dateRange {
  width: auto;

  @media (max-width: 768px) {
    width: auto !important;
  }
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dddddd;
  padding: 15px;
  gap: 8px;

  .label {
    font-weight: bold;
  }
}

.gridSkeleton {
  background: white;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  padding: 16px;
}

.filtersContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.locationsLabel {
  gap: 4px;
}
