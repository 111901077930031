@import "../../../../../styles/theme.scss";
@import "../../SalesCheckoutInvoice.variables.scss";

.titleKlarna {
  height: 38px;
}

.modalKlarna {
  .phoneKlarna {
    input {
      height: auto;
      font-size: 16px;
      color: black;
      font-family: $secondaryFont;
    }
  }
}

.smsLink {
  text-align: center;
  font-size: 12px;
}

.titleConfirmKlarna {
  display: flex;
  gap: 10px;
  justify-content: center;
  line-height: 38px;

  img {
    height: 38px;
  }
}
