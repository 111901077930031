.layoutRoot {
  display: flex;
  flex-direction: column;
}

.root {
  min-height: inherit;
  margin-top: 35px;
  flex: 1;

  .container {
    width: 1133px;
    margin: 0 auto;

    @media screen and (max-width: 1220px) {
      width: 90%;
    }
  }
}
