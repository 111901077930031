@import "../../../../../../styles/theme.scss";

.root {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.options {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;

  button {
    background: none;
    outline: none;
    border: 1px solid $cGray2;
    border-radius: 4px;
    width: 60px;
    height: 60px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;

    &.selected {
      border-color: $cGreen;
      background-color: $cGreenDim;
      color: $cGreen;
    }

    @media screen and (max-width: 850px) {
      width: 40px;
      height: 40px;
      font-size: 13px;
    }
  }
}
