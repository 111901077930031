.frame {
  vertical-align: middle;
}

.frame .frame-title {
  vertical-align: middle;
  color: #000000;
}

.frame .frame-content {
  will-change: transform, opacity, height, display;
  margin-left: 6px;
  padding: 0px 0px 0px 0px;
  border-left: 1px dashed rgba(255, 255, 255, 0.4);
  overflow: hidden;
}

.transform-open {
  transition: opacity 0.3s ease 0.5s;
}
