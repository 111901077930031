@import "../../../../styles/theme.scss";

.root {
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  border-radius: 50%;
  overflow: hidden;
  background-color: $cWhite;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload {
  width: 35px;
  height: 35px;
  border: 1px dashed $cGray0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  border-radius: 50%;
  color: $cGray0;
  cursor: pointer;
  position: relative;

  &:hover {
    border-color: $cPrimary;
    color: $cPrimary;
  }

  input {
    visibility: hidden;
    position: absolute;
    left: -1000px;
  }
}

.imageWrap {
  width: 40px;
  height: 40px;
  position: relative;
  border-radius: 50%;

  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .removeBtn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
    border: none;
    background: none;
    background-color: rgba(255, 255, 255, 0.7);
    color: $cRed;
    border-radius: 50%;
    width: 30px;
    height: 30px;
  }

  &:hover .removeBtn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
