@import "../../../../../styles/theme.scss";

.enrollmentConfigurationContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 0;

  .enrollmentLink {
    width: 50%;
  }

  .clientInvitationSms {
    display: flex;
    gap: 16px;

    .invitationSmsTextarea {
      width: 50%;
      min-width: 50%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      textarea {
        min-height: 150px !important;
      }
    }

    .charsLength {
      font-size: 14px;
      font-weight: 400;
      color: $cGray1;
    }

    .inputErrors {
      width: 100%;
    }

    .snippets {
      width: 50%;
      max-height: 150px;

      .snippetsTitle {
        font-weight: bold;
      }

      .snippetsContent {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
