@import "../../../../styles/theme.scss";

.root {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.cards {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.empty {
  font-size: 13px;
  color: $cGray1;
}
