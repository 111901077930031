@import "../../../../../../styles/theme.scss";

.root {
  max-height: 600px;
  height: 600px;
  flex: 4;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 840px) {
    flex: none;
  }

  br {
    display: none;
  }
}

.header {
  font-size: 20px;
  color: $cBlack;
  margin-bottom: 16px;
  padding: 15px 20px 0 20px;
}

.chat {
  flex: 1;
}
