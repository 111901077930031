@import "../../styles/theme.scss";

.root {
  background: none;
  border: none;
  outline: none;
  min-width: 30px;
  height: 30px;
  width: 30px;
  color: $cGray0;
  background-color: $cGray4;
  border-radius: 50%;
  transition: all 0.1s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  &:active {
    outline: none;
  }
  &:hover {
    background-color: $cPrimary;
    color: $cWhite;
  }
}

.selected {
  background-color: $cPrimary;
  color: $cWhite;
}
