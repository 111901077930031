.root,
.noInteraction {
  position: absolute;

  & > div {
    top: 0 !important;
    left: 0 !important;
    height: 100% !important;
    width: 100% !important;
  }
}

.noInteraction {
  pointer-events: none;
}
