.root {
  display: flex;
  gap: 8px;
  padding: 8px;
  border: 1px dashed #7c7c7c;
  max-width: 300px;
  min-width: 200px;
  background: #f0f9fc;
}

.index {
  color: #7c7c7c;
}
