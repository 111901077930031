.container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 70px;
}

.buttonDefaultExpiration {
    border-radius: 4px;
    padding: 5px 15px;
    height: 40px;
    width: 200px;
}

.spanLabel {
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    font-weight: 300;
}

.modalHeader {
    border-bottom: 0;
    text-align: center;
}

.buttonExpirationStatus {
    background-color: #276EFA;
    cursor: pointer;
}

.selectLabel {
    width: 200px;

}

.selectLabel div[class*="placeholder"] {
    font-size: 16px;
    color: #000;
}

.selectLabel {
    color: red($color: #000000);
}

.datePickerClass {
    margin-top: 15px;
    font-weight: 400;

    input {
        height: 40px;
        border-radius: 4px;
    }
}

.spanUpdatedText {
    font-style: italic;
    padding-top: 15px;
}

.selectCustomLabel {
    [class*="-control"] {
        background-color: #188bf6;
    }

    div div {
        color: white;
    }
}