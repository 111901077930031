.business-hours-outer {
  float: left;
  width: 100%;
  margin-top: -20px;
}
.business-hours-outer .setting-week {
  font-size: 14px;
  color: #667680;
  font-weight: 400;
  margin-bottom: 12px;
  font-family: "Lato", sans-serif;
}
.business-hours-outer .hours-time {
  width: 50px;
  float: left;
  margin-right: 7px;
  margin-bottom: 12px;
}
.business-hours-outer .hours-pm {
  width: 46px;
  float: left;
  padding-right:10px;
}

.disable select, .disable input {
  color: #aaaaaa;
  opacity: 0.5;
}

.hours-time.disable, .hours-pm.disable {
  color: #aaaaaa;
  opacity: 0.5;
}

.multiple_emails-error {
  border-bottom: 1px solid #e52817 !important;
}

.multiple_emails-ul {
  margin-bottom: 0px;
  list-style: none;
}
.multiple_emails-ul span {
  vertical-align: top;
  color: #0472b3;
  line-height: 10px;
  margin-right: 5px;
  word-break: break-all;
}
.notiEmailInput {
    min-height: inherit!important;
    padding: 0px;
    height:auto!important;
    padding-bottom:2px!important;
}
.important-instruction {
  float: left;
  width: 100%;
  font-size: 11px;
  color: #777777;
  margin-top: -20px;
  margin-bottom: 20px;
}
.newInputFileldOuter{
  .important-instruction{margin-top:0px;margin-bottom:0px;}
}
.setting-input-box-invalid{
border-bottom: 1px solid #e74c3c !important;
}


.multiple_emails-ul{margin-bottom:0px;list-style:none;}
.multiple_emails-ul svg{vertical-align: top;color:$blue;line-height:11px;margin-right:5px;margin-top:-2px;}
.multiple_emails-container input{
  font-size: 14px;
    color:$blue;
    background: none;
    width: 100%;
    font-weight: normal;
    padding-bottom: 5px;
    min-height: 26px;
    overflow: hidden;
    outline: none;
    font-family: 'Lato', sans-serif;
}
.div-email-tag-input{
  .react-tagsinput{
    input{width: auto;}
  }
}
.googleLocationInput{
    position:relative;

    input{
    width: 100%;
    float: left;
    color: #404040;
    border: 1px solid #cad2d6;
    border-radius: 3px;
    height: 36px;
    padding: 0px 15px;
    font-size: 14px;
    background: #ffffff;
    }
    .google-places-autocomplete__suggestions-container{
      position:absolute;
      left:0px;
      top:45px;
      z-index:2;
      background:#ffffff;
      border:1px solid #dddddd;
      @include border-radius(3px);

      & > div{
        padding:4px 10px;
        border-bottom:1px solid #dddddd;
        cursor:pointer;

        &:hover{
          background:#f5f5f5;
        }
      }

    }
}
.newInputFileldOuter .googleLocationInput.field_error, .setting-field-outer .googleLocationInput.field_error{
  border:none!important;
  .google-places-autocomplete__input{
  border:1px solid #e74c3c;
}
}
/*# sourceMappingURL=edit-clinic.css.map */
