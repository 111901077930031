.sign-up-main,
.login-policy-wrapper {
  .login-title {
    position: relative;
    margin-bottom: 26px;
    font-size: 22px;
    font-weight: 500;
    margin-top: 12px;
  }

  .login-title::after {
    width: 135px;
    height: 2px;
    position: absolute;
    content: "";
    background: #0075c0;
    left: 0px;
    bottom: -9px;
  }

  .banner-image {
    margin-top: 50px;
    margin-bottom: 0px;
  }

  input,
  select,
  .form-control,
  .form-control.card-details {
    border: 1px solid #aaa;
  }

  .field_error,
  .form-control.field_error,
  .form-control.card-details.field_error {
    border: 1px solid #e74c3c !important;
  }

  .premium-btn {
    width: 170px !important;
    height: 46px !important;
    margin-top: 0px !important;
    float: right;
  }

  .btn-success {
    color: #ffffff;
    background-color: #008bf3 !important;
    border-color: #008bf3 !important;
  }

  button[type="submit"],
  a.back-btn,
  .ar-login-btn {
    background: #0075c0;
    border-radius: 5px;
    border: none;
    width: 293px;
    height: 53px;
    text-align: center;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    margin: auto;
    display: inherit;
    margin-top: 40px;
  }

  .card-block {
    clear: both;
  }

  /*.hippa-block, .bba-block{
		padding: 20px !important;
	}*/
  .login-main-policy {
    display: table;
    width: 100%;
    padding: 20px 0px;
  }

  .member-section {
    border: 1px solid #ddd;
    border-radius: 5px;
    margin: 8px 0px 20px;
    padding: 0px;
    width: 100%;
  }

  .member-section-title {
    border-bottom: 1px solid #ddd;
    font-size: 15px;
    padding: 10px 15px;
    float: left;
    width: 100%;
    margin-bottom: 0px !important;
    background: #f5f5f5;
    margin-bottom: 10px !important;
  }

  .term-policy-div {
    display: block;
    padding: 15px !important;
    clear: both;
    max-height: 400px;
    position: relative;
  }

  .member-section-footer {
    display: block;
    padding: 10px 15px;
    border-top: 1px solid #ddd;
    text-align: right;
  }

  .member-section-footer label {
    float: left;
    font-size: 14px;
    text-align: left;
  }

  .member-section-footer button.agree-btn {
    background: #0075c0;
    border-radius: 3px;
    width: auto;
    height: 30px;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    display: inline-block;
    vertical-align: top;
    padding: 4px 30px;
    color: #ffffff;
    background-color: #5cb85c;
    border: none;
  }

  .member-section-footer button.agree-btn.disable {
    background: #eeeeee;
    color: #444444;
    border: none;
  }

  .selected-flag {
    margin: 6px;
  }

  .terms,
  .member-section-footer {
    input {
      margin-top: 1px;
      vertical-align: top;
      margin-right: 3px;
    }

    a {
      color: $blue;
      text-decoration: inherit;
    }
  }

  .form-group label {
    font-weight: normal;
  }

  select {
    background: url(/../images/arrow.png) no-repeat right 10px center;
  }

  .policy-info .col-sm-12 {
    padding: 0px;
  }
}

.sign-up-account-setup {
  .static-line {
    margin-top: 30px;
  }
}

.signup-sucessful.signup-quick-setup {
  padding: 50px 0px 50px !important;

  h2 {
    font-size: 37px;
    margin-top: 21px;
    margin-bottom: 10.5px;
  }

  h3 {
    font-size: 20px !important;
    margin-top: 10px;
  }

  iframe {
    border: 1px solid #ddd;
    margin: 30px 0px 0px;
    max-width: 100%;
  }

  .signup-sucessful-wrapper {
    padding: 0px 20px;
    width: 860px;
    max-width: 100%;
    margin: auto;
    border-top: 1px solid #ddd;
    margin-top: 40px;
  }

  p {
    font-size: 20px;
    margin: 30px 0px;
  }

  a {
    text-decoration: none;
    cursor: pointer;
    outline: none;
  }

  img {
    max-width: 100%;
    width: 560px;
    border: 4px solid #eee;
  }

  .downloadPDF {
    font-size: 13px;
    text-decoration: underline;
    color: #0075c0;
    margin-top: 15px;
    display: inline-block;
  }

  .signup-btn {
    display: inline-block;
    font-size: 16px;
    color: #999;
    margin: 0px 4px;
    border: 2px solid #999;
    border-radius: 30px;
    padding: 7px 18px;
    background: none;
    width: 300px;
  }
}

.intl-tel-input input.form-control.p-l-55 {
  padding-left: 55px !important;
}

.card-details.StripeElement {
  padding-top: 13px;
}

.member-section b,
.member-section strong {
  font-weight: 700;
}
