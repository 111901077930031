@import "../../SalesCheckoutInvoice.variables.scss";

.root {
  width: 620px;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
}

.titleWrap {
  display: flex;
  align-items: center;
  gap: 20px;
}

.btn {
  padding: 0 66px;

  .btnContent {
    display: flex;
    gap: 4px;
  }
}

.modalNativeFooter {
  padding: 0;
}

// InputWrap

.inputWrap {
  max-width: 300px;
}

.inputWrapFullWidth {
  width: 100%;
}

// InputRow

.inputRow {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

// Content

.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

// Footer

.footer {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 40px;
  flex-wrap: wrap;
  padding: 20px 30px;

  @media screen and (max-width: $mobileBreakpoint) {
    gap: 20px;
    padding: 0 0 20px 0;

    button {
      width: 100%;
    }
  }
}

.footerInModal {
  @media screen and (max-width: $mobileBreakpoint) {
    padding: 0 20px 20px 20px;
  }
}

// Skeleton

.skeletonFooter {
  @media screen and (max-width: $mobileBreakpoint) {
    & span {
      width: 100% !important;
    }
  }
}
