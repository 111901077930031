.root {
  display: flex;
  flex-direction: column;
  gap: 4px;

  img {
    width: 250px;
    border-radius: 4px;
  }
}
