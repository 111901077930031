@import "../variables.scss";

.root {
  margin-top: 30px;
  display: flex;
  align-items: center;
  gap: 20px;

  @media screen and (max-width: $mobileMaxBreakpoint) {
    flex-direction: column;
    gap: 10px;
    & > * {
      width: 100%;
    }
  }
}
