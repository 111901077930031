@import "../../../../styles/theme.scss";

.root {
  border-radius: 8px;
  border: 1px solid $cGray3;
  padding: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 16px;
  font-weight: 600;
  background: $cPrimaryDim3;

  svg {
    color: $cGray1;
  }

  &:hover,
  &:focus {
    color: $cWhite;
    background-color: $cPrimary;
    border: 1px solid $cPrimary;

    svg {
      color: $cWhite;
    }
  }
}

@media screen and (max-width: 620px) {
  .root {
    border-radius: 4px;
    padding: 10px 15px;
    font-size: 14px;
    text-align: left;
  }
}
