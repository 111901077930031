.business-insight-graph {
  padding-bottom: 10px;

  .business-insight-graph-container {
    overflow: inherit !important;

    .highcharts-container {
      margin-left: 0px !important;
    }

    .highcharts-axis-labels text {
      text-decoration: none !important;
      color: #404040 !important;
      fill: #404040 !important;

      tspan {
        color: #404040;
        fill: #404040;
      }
    }

    .highcharts-label text {
      text-decoration: none !important;

      tspan {
        color: #404040;
        fill: #404040;
      }
    }
  }

  .graph-legend-block {
    display: flex;
    align-items: center;
    height: 405px;
    padding-left: 30px;

    .graph-legend-color {
      height: 19px;
      width: 19px;
      display: inline-block;
      margin-right: 10px;
      border-radius: 3px;
      vertical-align: top;
      position: absolute;
      left: 0px;
      top: 0px;
    }

    .graph-legend-label {
      color: #000000;
      font-size: 16px;
      font-weight: normal;
      line-height: 14px;
    }

    .sectionDefine {
      margin-bottom: 20px;
      position: relative;
      padding-left: 30px;
    }
  }
}

.simpleSelect {
  option.option-disable {
    color: #d0d0d0;
  }
}

.businessGraphOuter {
  position: relative;
  top: 0px;
  margin-bottom: -126px !important;

  svg {
    margin-left: 0px;
  }
}

.businessGraphOuter .highcharts-container {
  display: inline-block;
  margin-top: -31px;
}

.businessGraphOuter > .highcharts-container {
  height: auto !important;
  width: 100% !important;
}

.businessReturningLagend {
  margin-bottom: 20px;
  width: 50%;
  float: right;

  .legend-group {
    width: 100%;

    .newCustomer {
      margin-bottom: 10px;
    }

    .legend-dot {
      width: 13px;
      height: 13px;
      top: 1px;
      border-radius: 3px;
    }
  }
}

.graphHeading {
  font-weight: bold;
  padding: 15px;
  border-bottom: 1px solid #dddddd;
  font-size: 13px;
  color: #000000;
}

.new-sale-row {
  margin-left: -8px;
  margin-right: -8px;
  clear: both;

  .new-sale-col {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.totalTransaction {
  padding: 20px 15px 0px;
  color: #000000;
  font-size: 20px;
}

.calender-hide-bottom {
  .rdrDefinedRangesWrapper {
    display: none !important;
  }
}

.vert-middle.vert-middle-report-option {
  padding-bottom: 20px;

  .TP-discount-outer {
    height: 36px;
    margin-bottom: 0px;

    input {
      height: auto;
      font-size: 14px;
      padding: 0 0 0 15px;
      width: 85%;
    }

    .TP-discount-type {
      margin-top: 0px;
    }
  }
}

tbody.ajax_body.tr-bottom-bold {
  tr:last-child {
    td {
      font-weight: 600 !important;
    }
  }
}

.noBorderShadow {
  background: none !important;
  border: none !important;
  box-shadow: inherit !important;
  -webkit-box-shadow: inherit !important;
}

.patient-referral-report-graph {
  min-width: 800px;
}

.downloadDiscountReport .easy-link {
  padding: 0px !important;
}

.clinicDropdown {
  width: 280px;

  ul.actionDropdown {
    width: 100%;
  }
}

.bi_email {
  width: 30% !important;
}

.th-width-80 {
  min-width: 80px !important;
  width: 80px !important;
}

.b-s-sales.sales-filter-cal {
  width: 155px;
}

span.openNewTab {
  color: $blue;
  cursor: pointer;
}

.selectFilter {
  float: right;
  font-size: 13px;
  margin-top: -3px;
  width: 150px;
  margin-left: 18px;

  span {
    display: none;
  }

  svg {
    margin-right: 0px;
    color: $blue;
  }
}

.bi-wallet-outer {
  width: 100% !important;
}

.bookedAppointmentGraph {
  min-width: 700px;
}

.SaveReport {
  height: 30px;
  padding: 3px;
}

.TimeCardMonthWeek {
  width: auto;
  display: inline-block;
  float: left;
  margin-right: 15px;
}

.TimeCardTotalHours {
  float: right;
  width: 250px;
  padding: 10px 20px;

  span {
    font-weight: 600;
    color: #404040;
  }
}

.TimeCardFilter {
  .calendar-btn.today-btn {
    min-width: 90px !important;
  }
}

.mrrCal {
  box-shadow: inherit !important;
  -webkit-box-shadow: inherit !important;
  min-width: 176px !important;
}

.item-sales-item-td {
  word-break: break-word;
}
