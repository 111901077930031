.merge-title{
	width:100%;
	height:auto;
	min-height:inherit;
	margin-bottom:22px;
}
ul.mergeSteps{
	display:flex;
	flex-direction: row;
	flex-flow: row wrap;
	list-style:none;
	clear:both;
    align-items: flex-end;
    margin-bottom:25px;
	
	li{
		flex-grow: 1;
		list-style: none;
		position:relative;
		border-bottom:2px solid #6B7783;
		width:126px;
		padding-bottom:13px;
		
		&:last-child{border:none;}
		
		a{color:#667680;font-size:15px;display:block;}
		
		&:after{
			position:absolute;
			left:-1px;
			bottom:-7px;
			content:'';
			width:13px;
			height:13px;
			background:#6B7783;
			@include border-radius(30px);
	        box-shadow: 0px 0px 0px 5px #e6eef2;
	        -webkit-box-shadow: 0px 0px 0px 5px #e6eef2;
		}
		
		&.active{border-color:$blue;
			a{color:$blue;}
		}
		&.activated{border-color:$blue;
			a{color:#404040;}
		}
		&.active:after, &.activated:after{background:$blue;}
	}
}
.infoSectionTitle{
	float: left;
    width: 100%;
    padding: 15px 18px;
    font-size: 15px;
    color: #404040;
    border-bottom: 1px solid #ddd;
    font-weight:bold;
}
.infoContent{
	float:left;
	width:100%;
	padding:20px;
}
.preview-profile-pic{
	padding-left:108px;
	float:left;
	width:100%;
	position:relative;
	margin-bottom:10px;
}
.preview-picture{
	width:90px;
	height:auto;
	position:absolute;
	left:0px;
	background:#eeeeee;
	@include border-radius(3px);
	overflow:hidden;
	max-height:115px;
	
	img{width:auto;height:86px;}
}
.info-row{
	width:100%;
	display: flex;
	flex-direction: row;
	flex-wrap:nowrap;
	font-size:13px;
	margin-bottom:10px;
	
	label{
		width:90px;
		font-weight:normal;
		color:#404040;
		min-width:90px;
		font-weight:600;
	}
	span{color:#667680;	word-break:break-all;
		p{margin-bottom:5px;}
		} 
}
.merge-main-title{
	width: 100%;
	float: left;
	padding: 10px 20px;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	border-bottom:1px solid #dddddd;
	
	span{
		font-size: 20px;
		color: #404040;
		margin-right:30px;
		white-space: nowrap;
	}
	p{
		margin:5px 0px 5px;
		font-size:13px;
	}
}
.checkOuter{
	position:relative;padding-left:50px!important;
	input[type="checkbox"]{top:12px;left:20px;position:absolute;}
	input[type="radio"]{top:16px;left:20px;position:absolute;}
}
.AddPicturesOuter{
	float:left;
	margin-bottom:20px;
	
	p{
		font-size:14px;
		color:#667680;
		font-weight:normal;
		clear:both;
		margin-bottom:10px;
		
		a{float:right;padding:1px;}
	}
	
	.multiple-pictures{
		position:inherit;
		margin-right:10px;
		float:left;
		border:2px solid transparent;
		@include border-radius(5px);
		cursor:pointer;
		position:relative;
		
		&.active{border:2px solid $blue;}
	}
	
}

.procedure-row{float:left;width:100%;padding:15px;border-bottom:1px solid #dddddd;}
.procedureName{
	float:left;
	width:100%;
	font-size:15px;
	font-weight:bold;
	color:#404040;
	margin-bottom:15px;
}
.procedureInformation{
	display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    width:100%;
    float:left;
    flex-wrap: nowrap;
}
.procedureThreeImages{
	float:left;
	@include border-radius(3px);
	overflow:hidden;
	min-width:500px;
	max-width:500px;
	width:500px;
	
	img{max-width:100%;height:150px;}
}
.procedureDetails{padding-left:20px;
	.info-row label{min-width:150px;}
	.info-row span{word-break: break-word;}
}

.importLog{
	color: #404040;
	font-weight: 600;
	position: relative;
	padding-left: 50px;
	
	.setting-switch{position:absolute;left:0px;top:0px;}
	input{visibility:hidden;}
	
	.radioLabel{margin-top:2px;display:inline-block;}
}
.mergeRightSection{padding-left:5px;height:auto;}
.mergeLeftSection{height:auto!important;min-height:inherit!important;}
.heightAuto{height:auto!important;min-height:inherit!important;}

.medicalHistory{
	.info-row{
		display:block;
		label{width:100%;min-width:100%;}
		span{width:100%;}
	}
}

ul.reviewTabs{
	height:52px;
	border-bottom:1px solid #dddddd;
	list-style:none;
	display: flex;
	flex-direction: row;
	flex-flow: row wrap;
	
	li{			
		flex-grow: 1;
		list-style: none;
		
		a{text-align:center;font-size:15px;color:#667680;display:block;padding:14px 0px;height:52px;}
		
		&.active a, &:hover a{color:$blue;border-bottom:2px solid $blue;}
	}
}
.review-merge-pics{
	.procedureThreeImages{
		min-width: 350px;
		max-width: 350px;
		width: 350px;
	}
}
.review-wallet .info-row label{width:150px;}
.review-treatmentplan .info-row label{width:173px;}
.profileImgThumb{
    background-size: auto 90px;
    height: 90px;
    background-position: center;
    background-repeat: no-repeat;
}
.mergeProfileImg{
	width:100%!important;
	height:auto!important;
}
.profileImgCross{
	position: absolute;
    top: 2px;
    right: 2px;
    background: #ffffff;
    border: 1px solid #cccccc;
    @include border-radius(30px);
    width: 19px;
    height: 19px;
    text-align: center;
    font-size: 13px;
    color:$blue;
    padding-top: 0px;
    line-height: 17px;
	
	img{height:auto;}
}
.font20px{font-size:20px !important}

.info-row-label {
	min-width: 125px !important;
}












