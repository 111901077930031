.efaxOrdersLabel {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .unassignedEfaxes {
    font-size: 12px;
    color: #667680;
  }
}
