@import "../../../../../styles/theme.scss";

.title {
  font-size: 18px;
  color: $cGray0Dark;
  margin-bottom: 24px;
}

.selects {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 1200px;
}
