@import "../../styles/variables.scss";
@import "../../styles/theme.scss";

.root {
  position: relative;
}

.picker {
  position: absolute;
  z-index: $zIndexMax;

  .rdrDefinedRangesWrapper {
    display: none !important;
  }
}

.button {
  background: none;
  outline: none;
  border-radius: 8px;
  border: 1px solid $cGray1;
  height: 50px;
  min-height: 50px;
  background-color: $cWhite;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 13px;
  padding: 0 15px 1px 15px;
  font-size: 16px;
  font-weight: 400;
  color: $cBlack;
}

.buttonSmall {
  height: 36px;
  min-height: 36px;
  border-radius: 4px;
  font-size: 14px;
  padding: 0 10px 1px 10px;
  gap: 8px;
}

.buttonDisabled {
  opacity: 0.6;
  cursor: default;
  user-select: none;
  pointer-events: none;
}

.buttonError {
  border-color: $cRed;
  background-color: $cRedDim2;
}

.placeholder {
  color: $cGray1;
  text-align: start;
  width: 159px;
}

.placeholderError {
  color: $cRed;
}

// Position

.posBottomRight {
  top: calc(100% + 8px);
  right: 0;
}

.posBottomLeft {
  top: calc(100% + 8px);
  left: 0;
}

.posTopRight {
  bottom: calc(100% + 8px);
  right: 0;
}

.posTopLeft {
  bottom: calc(100% + 8px);
  left: 0;
}

.invisibleBtn {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
