@import "../../styles/theme.scss";

.root {
  padding: 10px;
  color: $cGray1;
  cursor: default;
  user-select: none;
}

.rootCenter {
  text-align: center;
}

.noPaddingBottom {
  padding-bottom: 0;
}
