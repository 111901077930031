@import "../../styles/theme.scss";

.root {
  border-radius: 8px;
  border: 1px solid $cGray1;
  height: 50px;
  min-height: 50px;
  background-color: $cWhite;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.input {
  display: flex;
  flex: 1;
  height: 100%;
  border: none;
  outline: none;
  background: transparent;
  padding: 0 15px 1px 15px;
  font-size: 16px;
  font-weight: 400;
  color: $cBlack;
}

.smallInput {
  height: 36px;
  min-height: 36px;
  border-radius: 4px;

  input {
    font-size: 14px;
    padding: 0 10px 1px 10px;
  }
}

.mediumInput {
  height: 44px;
  min-height: 44px;
}

.leftAdornment {
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 15px;
}

.leftAdornmentSmall {
  padding-left: 10px;
}

.rightAdornment {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 15px;
}

.rightAdornmentSmall {
  padding-left: 10px;
}

.error {
  border-color: $cRed;
  background-color: $cRedDim2;

  input::placeholder {
    color: $cRed;
  }
}

.disabled {
  background-color: $cGray4;
  pointer-events: none;
  cursor: default;
  user-select: none;

  input {
    color: $cGray0;
    -webkit-text-fill-color: $cGray0;

    &::placeholder {
      color: $cGray2;
      -webkit-text-fill-color: $cGray2;
    }
  }
}

.readonly {
  input {
    cursor: default;
  }
}

.focused {
  &:not(.error):not(.readonly) {
    border-color: $cPrimary;
  }
}
