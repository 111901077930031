@import "../../../../styles/theme.scss";

.root {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  align-items: flex-end;
}

.signatureBlock {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .signature {
    width: 180px;
    height: 180px;
  }

  span {
    font-size: 15px;
    color: $cGray0;
  }

  i {
    font-size: 14px;
    color: $cGray0;
  }
}
