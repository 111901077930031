@import "../../../../styles/theme.scss";

.root {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.toggleWrap {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-end;
}

.content {
  width: 450px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.selectAreaCode {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 8px;
}
