@import "../../../../../../styles/theme.scss";

.root {
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-bottom: 1px solid $cGray3;
  padding-bottom: 32px;

  &:last-child {
    border-bottom: none;
  }

  &:first-child {
    padding-top: 8px;
  }
}

.question {
  font-size: 22px;
  font-weight: 400;

  span {
    color: $cRed;
  }
}

.description {
  margin: 0;
  color: $cGray0Dark;
  font-size: 14px;
  margin-top: 4px;
}
