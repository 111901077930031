.root {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 16px;

  .nextIcon {
    transform: rotate(180deg);
  }
}

.innerLeft {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}
