@import "../../../../../../styles/theme.scss";

.root {
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
}

.tab {
  border: none;
  background: none;
  padding: 3px 5px;
  border-radius: 4px;
  font-size: 16px;

  &:hover {
    background-color: lighten($cPrimaryDim, 5%);
  }

  &.tabActive {
    color: $cPrimary;
  }
}
