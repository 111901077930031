@import "../../../../../../../../styles/theme.scss";

.root {
  padding: 10px;
  border-radius: 4px;
  background-color: $cGray5;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .header {
    display: flex;
    align-items: center;
    gap: 16px;

    .label {
      font-size: 17px;
      color: lighten($cBlack, 40%);
    }
  }
}

.option {
  display: flex;
  gap: 8px;
  align-items: flex-start;
  background-color: $cPrimaryLight;
  padding: 7px 9px;
  border-radius: 4px;

  & label {
    margin-top: 10px;
  }
}
