.root {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.footer {
  display: flex;
  align-items: center;
  gap: 8px;
}
