$table-border: rgba(0, 0, 0, 0.16);

.new-table {
  display: flex;
  flex-direction: column;
  gap: 10px;

  table {
    margin-bottom: 0px;
  }

  .toolbar-container {
    display: flex;
    gap: 50px;

    padding-top: 12px;
    margin-bottom: 20px;

    height: 44px;
    align-items: center;

    .breadcrums-container {
      flex-grow: 1;
    }

    @media (max-width: 420px) {
      .search {
        padding: 0px !important;
        margin: 10px 0px !important;
        width: 100%;
      }
    }

    .search-bg {
      height: 44px;
      display: flex;
      align-items: center;

      svg {
        position: relative;
        top: 0px;
      }
    }

    .setting-search-input {
      padding-left: 8px;
    }
  }

  .disable-click {
    pointer-events: none;
    cursor: none;
  }

  .table-container {
    min-width: min-content;
    border: 1px solid $table-border;
    border-radius: 8px;
    background: white;

    font-family: $opensan;

    thead {
      background-color: #dae8ee;
      border-radius: 8px;

      th {
        padding: 20px 10px;
        color: black;

        line-height: 24px;
        font-size: 14px;
      }
    }

    tbody {
      border-radius: 8px;
    }

    tr {
      td {
        padding: 16px 10px;
      }
    }

    .loading-div {
      position: relative;

      td {
        position: absolute;
        top: -100px;
      }
    }

    .date-cell {
      display: block;
      max-width: 80px;
    }
  }

  .old-table-header {
    width: 100% !important;
    gap: 20px;

    .sorting {
      position: sticky !important;
      top: 0;

      padding: 1px;
      font-weight: normal;
      vertical-align: middle;
    }

    i {
      color: #667680;
      background-position: right center;
      background-repeat: no-repeat;
      margin-right: 0px;
      top: 0px;
      background-size: 7px 12px;
    }
  }

  .table-sort-icons {
    img {
      width: 16px;
      min-width: 16px;
      height: 16px;
    }
  }

  .table-more-button {
    background-color: #188bf6;
    border-radius: 20px;
    color: white;
    padding-left: 4px;
    padding-right: 4px;
    font-weight: 600;
    margin-left: 6px;
    cursor: pointer;
  }
}

input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.per-unit {
  font-size: 12px;
}
