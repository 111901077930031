@import "./variables.scss";

.CalendarPreviewArea {
  display: block;
  width: 347px;
  position: absolute;
  z-index: 8;
  top: 35px;
  left: 0px;
}
.rdrDefinedRangesWrapper,
.rdrDateRangeWrapper {
  display: block;
  float: left;
  width: 100%;
  -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.4);
}
.rdrDefinedRangesWrapper {
  box-shadow: inherit;
  border: 1px solid #dddddd;
}
.rdrDefinedRangesWrapper {
  position: absolute;
  top: 100%;
}
.rdrMonthAndYearWrapper {
  height: 43px;
  padding: 0px;
}
.rdrInputRanges {
  display: none;
}
.rdrStaticRanges {
  display: block;
  padding: 10px 10px 0px;
}
.rdrStaticRanges button {
  display: inline-block;
  border: 1px solid $blue;
  width: 22%;
  text-align: center;
  margin-right: 2%;
  margin-bottom: 7px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;

  span {
    padding: 4px 0px;
    text-align: center;
    color: $blue;
    font-size: 11px;
  }
}
.rdrStaticRange.rdrStaticRangeSelected {
  background: $blue;
  span {
    color: #ffffff;
    font-weight: normal;
    font-size: 11px;
  }
}
.rdrMonthAndYearWrapper button {
  position: relative;
}
.rdrPprevButton i {
  margin-left: 8px;
}

.top-4 {
  top: 4px !important;
}
