.content-client-documents{
  table td[class*="col-"], table th[class*="col-"]{float:left;}
  .text-ellipsis{float:left!important;max-width:400px;}
  .easy-link{padding: 0px 10px;}
  .setting-table .table-updated-tr .table-updated-td, .juvly-table .table-updated-tr .table-updated-td, .table-updated .table-updated-tr .table-updated-td{    padding: 15px 10px 15px 15px;}
  .manage-document{width: 100%!important;display: block!important;}
  .add-doc-section{
  	padding-top:40px;
  	padding-bottom:40px;
  	border-bottom: 1px solid #ececec;
  	float: left;
  	width: 100%;
    margin:0px;
  }
  .main-profile-picture{left:0px;}
  .add-document-btns{
    float:left;
    width:100%;
    padding-top:40px;

    .new-blue-btn{margin:0px 0px 10px;;}
  }
  .add-doc-serach-dropdown ul{top:26px;}
  .file-upload-img.field_error{
    border: 1px solid #e74c3c;

    .upload{
      border-top: 1px solid #e74c3c;
    }
  }
}
.auto-height{min-height:inherit!important;height:auto!important;}
.new-rest-btn {
  color: #047ec3;
  font-size: 14px;
  font-family: Lato,sans-serif;
  font-weight: 400;
  padding: 5px 17px 4px;
  text-decoration: underline;
}
.documentSearch{padding:15px 20px;
  .new-rest-btn{display:inline-block;}
  form .new-search{margin-right:0px;}
}
.documentActions{padding:10px!important;
  ul{right:inherit!important;left:0px!important;}
}
.documentTable{min-height:300px;}
.docFilter{float:left;position:relative;width:auto!important;
  ul{left:0px!important;right:inherit!important;}
  button{margin-left:0px;}
}
.juvly-agreement-content{
  height:500px!important;
}
.memberAgreemenSign{
	display: block;
	text-align: right;
	
	p{display:block;clear:both;}
}
.agreementContent{min-height:280px;display:block;}

// New Css Add

.line-btn svg{
  right:25px ;
}

@media only screen and (max-width: 1220.98px) {
  .line-btn svg {
    right: 38px;
  }
  .line-btn, .new-line-btn {
    padding: 4px;
  }
}
@media only screen and (max-width: 1080.98px) {
  .line-btn svg {
    right: 25px;
  }
}
@media only screen and (max-width: 767.98px) {
  .line-btn#dropdownMenu1 {
    padding-right: 12px !important;
    font-size: 12px;
  }
  .line-btn, .new-line-btn {
    margin-left: 0px !important;
  }
  .line-btn svg {
    right: 0px;
  }
  .more-info-profile-dropdown {
    width: auto !important;
    margin-left: 0px;
  }
  .documentActions ul {
    left: inherit !important;
    right: 0px !important;
  }
}

.options-button {
  min-width: auto !important; 
  margin: 0 !important;
}

.selectAllTh {
  padding: 1.5px 0 0 15px !important;
  display: flex;
  align-items: center;
}

.selectCell {
  width: 4%;
}

.actionsCell {
  width: 12.65%; 
} 
 
.expiredDocument, .expiredDocument:hover {  
  background: rgba(118, 118, 118, 0.15) !important;
}

.removeDateIcon {
  position: absolute;
  top: 0;
  right: 0;
}