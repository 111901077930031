@import "../../.././../../../styles/theme.scss";

.root {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px 25px;
  border-radius: 4px;
  background-color: $cWhite;

  @media screen and (max-width: 500px) {
    padding: 15px;
  }
}

.rootNoPadding {
  padding: 0;
  background-color: none;
}

.label {
  font-size: 20px;
  color: $cBlack;
}

.labelGray {
  color: $cGray0;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.contentRow {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  & > * {
    flex: 1;
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
}

.contentCompact {
  width: 600px;

  @media screen and (max-width: 740px) {
    width: 100%;
  }
}

.contentBiggerGap {
  gap: 24px;
}
