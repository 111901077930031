@import "../../../../../../../styles/mixins.scss";

.root {
  @include hide-scroll-bar();
  overflow-x: auto;
}

.largestCol {
  max-width: 170px;
  min-width: 170px;
}

.smallestCol {
  max-width: 80px;
  min-width: 80px;
}
