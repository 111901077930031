@import "../../../../../../styles/theme.scss";

.messageCol {
  width: 300px;
}

.author {
  display: flex;
  align-items: center;
  gap: 8px;

  .authorImage {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
}

.unreadRow {
  background-color: lighten($cGreenDim, 2%);

  &:hover {
    background-color: lighten($cGreenDim, 1%);
  }
}
