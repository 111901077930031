@import "../../styles/theme.scss";
@import "../../styles/variables.scss";

.TextEditor-shared {
  .tox-tinymce {
    border-radius: 8px;
    border: 1px solid $cGray1;
    overflow: auto;

    .tox-editor-header {
      box-shadow: none !important;
      border-bottom: 1px solid $cGray1 !important;
    }

    .tox-promotion {
      display: none !important;
    }
  }
}

.TextEditor-shared__disabled {
  cursor: default;
  pointer-events: none;
  opacity: 0.5;

  & * {
    cursor: default !important;
    pointer-events: none !important;
  }
}
