.root {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.row {
  display: flex;
  gap: 3px;
  flex-wrap: wrap;
}
