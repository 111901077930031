.root {
  max-width: 1400px;
  padding: 72px 30px 200px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    padding-top: 127px;
  }

  @media screen and (max-width: 767px) {
    padding-top: 70px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
