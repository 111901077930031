.root {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 420px;
  margin: 0 auto;
  padding-bottom: 128px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.title {
  margin-bottom: 24px;
  text-align: center;
}
