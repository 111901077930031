@import "../../../../../../../styles/theme.scss";

.root {
  position: relative;
  width: max-content;
}

.input {
  border: 1px solid $cGray0Dark;
  border-radius: 8px;
  height: 32px;
  padding: 0 8px;
  max-width: 62px;
  text-align: center;

  &:focus {
    border-color: $cPrimary;
  }

  &.error {
    border-color: $cRed;
    background-color: $cRedDim2;
    color: $cRed;
  }
}

.adornment {
  position: absolute;
  top: 50%;
  right: -20px;
  width: max-content;
  transform: translate(0, -50%);
  color: $cGray0Dark;
  width: 14px;
  text-align: start;
}
