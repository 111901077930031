@import "../../../../../../../../styles/theme.scss";

.root {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  border-radius: 4px;
  background: $cPrimaryDim3;
  min-width: 500px;
}

.title {
  font-size: 17px;
  font-weight: 700;
}
