@import "../../../../../../../../../styles/theme.scss";

.root {
  width: 400px;

  @media screen and (max-width: 630px) {
    width: 100%;
  }
}

.requiredAsterisk {
  color: $cRed;
}
