.root {
  margin-top: 20px;

  @media screen and (max-width: 840px) {
    overflow-x: scroll;
  }
}

.col {
  @media screen and (max-width: 840px) {
    max-width: 200px;
    min-width: 150px;
  }
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.flexCenter {
  display: flex;
  justify-content: center;
}

.flexEnd {
  display: flex;
  justify-content: flex-end;
}

.noMargin {
  margin: 0;
}

.faxNumberName {
  max-width: 130px;
  font-size: 12px;
  font-weight: 600;
  color: #b5b5b5;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}