@import "../../styles/theme.scss";

// Default

.root {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.tab {
  border: none;
  background: none;
  padding: 10px;
  border-bottom: 2px solid transparent;
  color: $cGray0Dark;
  transition: all 0.15s ease;
  font-size: 15px;

  &:hover {
    color: $cPrimary;
  }
}

.tabSelected {
  border-color: $cPrimary;
  color: $cPrimary;
}

.disabled {
  pointer-events: none;
  user-select: none;
  cursor: default;
  opacity: 0.6;
}

// Tiled

.rootTiled {
  display: flex;
  align-items: center;
}

.tabTiled {
  background: none;
  padding: 7px 10px;
  color: $cBlack;
  transition: all 0.15s ease;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid $cGray2;

  &:hover:not(.tabTiledSelected) {
    background-color: $cPrimaryDim3;
    color: $cPrimary;
  }

  &:first-child {
    border-radius: 8px 0px 0px 8px;
    border: 1px solid $cGray2;
    border-right: none;
  }

  &:last-child {
    border-radius: 0px 8px 8px 0px;
    border: 1px solid $cGray2;
    border-left: none;
  }
}

.tabTiledSelected {
  background-color: $cPrimary;
  color: $cWhite;
}
