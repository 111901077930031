@import "../../../../../../styles/theme.scss";

.root {
  span {
    font-style: italic;

    &.delete {
      color: $cRed;
    }

    &.update {
      color: $cOrange;
    }

    &.add {
      color: $cGreen;
    }
  }
}
