.infoWrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
  font-family: "Lato", sans-serif;

  .sectionTitle {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
  }

  .itemTitle {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
  }

  .itemText {
    font-weight: normal;
  }
}
