.root {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.header {
  display: flex;
  align-items: center;
  gap: 8px;

  .title {
    font-size: 20px;
  }

  .left {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
