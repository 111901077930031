@import "../../styles/theme.scss";

.root {
  border-radius: 8px;
  border: 1px solid $cGray1;
}

.title {
  background-color: $cPrimaryDim;
  font-size: 16px;
  color: $cBlack;
  padding: 15px 0;
  text-align: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.nav {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 550px) {
    flex-direction: column;
    gap: 10px;
  }

  span {
    font-size: 16px;
    color: $cBlack;
  }
}

.navBtn {
  color: $cPrimary;
  border: none;
  background: none;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  transition: all 0.3s ease;
  height: 30px;
  border-radius: 6px;
  img {
    margin-right: 10px;
  }
  &:last-child img {
    transform: rotate(-180deg);
    margin-left: 10px;
    margin-top: 4px;
    margin-right: 0;
  }
  &:hover:not(.navBtnDisabled) {
    background-color: $cPrimaryDim;
  }
}

.navBtnDisabled {
  opacity: 0.6;
  cursor: default;
  pointer-events: none;
  user-select: none;
}

.empty {
  text-align: center;
  padding-bottom: 15px;
}

.content {
  max-height: 300px;
  overflow-y: scroll;
  padding: 0 15px 15px 15px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 10px;
}

.timeTile {
  background: none;
  height: 40px;
  border-radius: 5px;
  border: 1px solid $cGray1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: $cBlack;
  transition: all 0.1s ease;
  &:hover:not(.timeTileSelected):not(.timeTileOutOfSchedule) {
    background-color: $cPrimaryDim;
  }
}

.timeTileSelected {
  background-color: $cPrimary !important;
  color: $cWhite !important;
  border-color: $cPrimary !important;
}

.timeTileOutOfSchedule {
  background-color: $cGray4;
  color: $cGray0;
}
