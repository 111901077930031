@import "../../styles/theme.scss";

.notVoidableAlert {
  margin: 70px 15px 25px 15px;
}

.btnDisabled {
  background-color: $cGray1 !important;
}

.productPrice {
  display: flex;
  justify-content: flex-end;
}

.summaryRow {
  display: flex;
  width: 700px;
  @media screen and (max-width: 991px) {
    width: 100%;
  }
}

.summaryData {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  height: 83px;
  padding: 10px;
}
