@import "../../../../../../../../../styles/theme.scss";

.root {
  border: none;
  background: none;
  outline: none;
  border-radius: 4px;
  background-color: transparent;
  border: 1px dashed $cPrimary;
  color: $cPrimary;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 5px 10px;
  width: 342px;

  &:hover {
    background-color: lighten($cPrimaryDim, 4%);
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
