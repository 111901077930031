@import "../../styles/theme.scss";

.root {
  display: flex;
  width: 100%;
  color: $cGray3;
  font-weight: 300;
  cursor: default;
  user-select: none;
}

.pCenter {
  justify-content: center;
}

.pLeft {
  justify-content: start;
}

.sBig {
  font-size: 42px;
  padding: 10px;
}

.sMedium {
  font-size: 24px;
  padding: 6px;
  color: darken($cGray3, 5%);
}

.sSmall {
  font-size: 16px;
  padding: 4px;
  color: $cGray2;
}

.cGray2 {
  color: $cGray2;
}

.cGray1 {
  color: $cGray1;
}
