@mixin trWrap() {
  position: relative;
}

@mixin trInner() {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.loadMoreWrap {
  @include trWrap();
  height: 40px;
}

.loadMoreObs {
  @include trInner();
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.emptyWrap {
  @include trWrap();
  height: 50px;
}

.empty {
  @include trInner();
  display: flex;
  justify-content: center;
  align-items: center;
}

.skeletonWrap {
  @include trWrap();
  height: 773px;
}

.skeleton {
  @include trInner();
}
