@import "../../../../../styles/theme.scss";

.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 15px;
  border-radius: 4px;
  background-color: #f5f9ff;
  border: 1px solid $cPrimaryDim2;

  h3 {
    font-weight: bold;
    font-size: 16px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.footer {
  display: flex;
  align-items: center;
  gap: 16px;
  padding-top: 16px;

  button {
    border: none;
    background: none;
    outline: none;
    padding: 0;
    display: flex;
    align-items: center;
    gap: 2px;
    font-size: 12px;
    color: $cGray0;

    &:hover {
      color: $cPrimary;
    }
  }
}
