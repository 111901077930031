@import "../../../../styles/theme.scss";

.root {
  display: flex;
  padding: 20px;
}

.rootRight {
  justify-content: flex-end;
}

.tile {
  display: flex;
  gap: 8px;

  .image {
    width: 30px;
    height: 30px;
    min-width: 30px;
    background-color: $cGray2;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $cBlack;
    font-size: 12px;
  }

  .info {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .infoAuthor {
      span {
        color: $cBlack;
      }
    }

    .message {
      padding: 10px 20px;
      border-radius: 0px 10px 10px 10px;
      background-color: $cGray3;
      color: $cBlack;
      max-width: 390px;
    }
  }
}

.tileRight {
  flex-direction: row-reverse;
  justify-content: flex-end;

  .image {
    background-color: $cPrimary;
    color: $cWhite;
  }

  .info {
    justify-content: flex-end;
    text-align: right;

    .message {
      border-radius: 10px 0px 10px 10px;
      background-color: $cPrimary;
      color: $cWhite;
      align-self: flex-end;
      text-align: left;
    }
  }
}
