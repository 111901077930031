.root {
  max-height: 600px;
  height: 600px;
  flex: 2;
  display: flex;
  flex-direction: column;

  br {
    display: none;
  }
}

.info {
  overflow-y: scroll;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
  padding-bottom: 20px;
}
