.time-edit-wrapper {
  display: flex;
  align-items: center;

  @media (max-width: 1024px) {
    flex-direction: column;
    justify-content: center;
  }
}

.time-edit-label {
  border: 1px solid transparent;
  padding: 0 2px;
}

.time-edit-datepicker {
  width: 144px;
  border: 1px solid #cad2d6;
  padding: 0 2px;
  border-radius: 2px;
}

.time-edit-button {
  cursor: pointer;
  height: 18px;
  padding: 0;
  font-size: 14px;
  line-height: 18px;
  min-width: 50px;
  margin: 0 0 0 10px;

  @media (max-width: 768px) {
    margin: 5px 0 0 0;
  }
}
