@import "../../../../styles/theme.scss";

.file {
  padding: 5px 6px 5px 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  background-color: $cPrimaryDim;
  @media screen and (max-width: 400px) {
    justify-content: space-between;
  }

  button {
    border: none;
    background-color: $cRedDim;
    display: flex;
    align-items: center;
    border-radius: 3px;
    width: 24px;
    height: 24px;
    justify-content: center;
    margin-left: 10px;
    &:hover {
      background-color: darken($cRedDim, 2%);
    }
  }

  div {
    color: $cPrimary;
    font-size: 14px;
    max-width: 130px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media screen and (max-width: 400px) {
      max-width: 200px;
    }
  }
}

.fileDisabled {
  opacity: 0.5;
  pointer-events: none;
  user-select: none;
  button:hover {
    background-color: $cRedDim;
  }
}
