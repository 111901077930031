.client-filter{
	position: fixed;
	top: 54px;
	right: 0;
	padding:12px 29px 0;
	z-index:1;
	width: 100%;
	max-width: 1400px;
	left:50%;
	transform: translateX(-50%);
	background: #e6eef2;
}
.filter-outer{
	float: left;
	width: 100%;
	@include border-radius(5px);
	margin-bottom: 0px;
	padding-bottom: 0px!important;

	#dropdownMenu1 {
		border: 1px solid #2682c5!important;
		color: #2682c5!important;
		background: none!important;
		height: 30px;
		line-height: 5px;
		padding: 0px 8px;
		font-size: 12px;
		font-weight: normal;
		text-transform: inherit;
	}
}
.btn.btn-default.dropdown-toggle .stack-down-open {
    margin-right: -8px;
    position: relative;
}
.patient-count{
    font-size: 20px;
    font-weight: 400;
    display: inline-block;
    vertical-align: top;
    line-height: 26px;

    b{
		color: #404040;
		font-weight: 600;
    }
}
.total-patients {
    margin-top: 7px;
}
.srch-outer {
    padding: 5px 0px;

    .search {
		padding: 0px 10px;

		.field-outer {
			border: none;
			background: #f7fbfd;
			height: 30px;
			@include border-radius(3px);

			i{
				font-size: 17px;
				color: #aaa;
				margin-left: 5px;
				vertical-align: middle;
			}
			.search-key{
				height: 28px;
				font-size: 13px;
				background:none;
			}
		}
	}
	#get_filters, #reset-filters{
		@include box-shadow(0, 1px, 2px, 0, rgba(0,0,0,0.15));
		border: none;
		padding: 3px 12px 4px;
		height: 30px;
		font-size: 12px;
		margin-right:3px;
	}
	#get_filters:hover, #reset-filters:hover{
		background:$blue-hover;
		color:$white-color;
	}
}
.search-popup{
	background:#ffffff;
	border: 1px solid rgba(0, 0, 0, 0.15);
    @include border-radius(4px);
    @include box-shadow(0, 0, 14px, 0, #eeeeee);
    position:absolute;
    top:37px;
    left:9px;
    right:8px;
    z-index:102;
    width:320px;

    .search-header, .search-result-row{
		float: left;
		width: 100%;
		padding:9px 15px;
		border-bottom:1px solid #ececec;
		position:relative;
		font-size:13px;
	}

	.search-header{
		font-size:13px;
		background:#c2e0f2;
		text-transform:uppercase;
		position:relative;
		text-align:left;
	}
	.search-result-row{
		padding-left:37px;
		min-height:35px;
		color:#77858e;
		text-align:left;

		&:hover{
			background: #f7fbfd;
			cursor:pointer;
		}

		.search-photo{
				width:20px;
				height:20px;
				position:absolute;
				left:10px;
				top:7px;
				background-image:url(/../images/user.png);
				background-position:center;
				background-repeat:no-repeat;
				background-size:cover;
				@include border-radius(30px);}
	}

	> div:last-child{border:none;}
}
.arrow-up {
  width:10px;
  height:10px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #c2e0f2;
  position:absolute;
  top:-10px;
  left:12px;
}
.custom-switch.display-order-switch{
	padding: 13px 0px 0px!important;
    font-size: 12px;
    float:right;
}
.add-memb-outer{
	min-height:64px;
}
.filter-count {
    font-size: 12px;
    margin-right: 5px;
    display: inline-block;
}
.right-create-patient {
    padding-top: 5px;
		.blue-btn{padding:0px 12px;}
		.new-white-btn{height: 30px;}
}
.add-field {
    float: none;
    clear: both;
    display: inline-block;
    position: absolute;
    right: 15px;
    bottom: 0px;
    z-index: 10;

    button {
		font-size: 24px;
		background: #2682c5;
		border: none;
		color: #fff;
		height: 27px;
		line-height: 2px;
		width: 27px;
		padding: 0px 0px 2px;
		@include border-radius(30px);
	}
}
.add-field-list {
    height: 380px;
    overflow: auto;
    position: absolute;
    right: 200px;
    padding: 15px;
    margin-left: -130px!important;
    @include box-shadow(0, 0px, 5px, 0, rgba(0,0,0,0.175));

    label{
		display: inline-block;
		font-size: 13px;
		font-weight: normal;
		vertical-align: top;
		margin-top:0px;
    }
}
ul.patient-alpha {
    list-style: none;
    display: table;
    width:100%;
    z-index: 8;
    text-transform: uppercase;
    padding: 0px;
    text-align: right;

    li {
		display: inline-block;
		vertical-align: top;
		padding: 0px 3px;

		a {
			display: block;
			padding: 5px 5px;
			border: none;
			color: #555555;
			font-family:$opensan!important;

			&.alpha-select {
				color: #2682c5;
				border-bottom: 1px solid #2682c5;
			}
			&.alpha-disable{
				cursor: not-allowed;
				border-bottom: none;
				color: #999999;
			}
			&:hover{
				color: #2682c5;
				border-bottom: 1px solid #2682c5;
			}
			&.alpha-disable:hover{
				border:none;
			}
			&.caps-all {
				text-transform: capitalize;
			}
		}
	}
}
.clients-table th, .clients-table td{
	width:57px;
	max-width:57px;
}

.clients-table th.check, .clients-table td.check {
	max-width: unset;
  width: 4%;
  min-width: 20px;
  padding: 0 !important;
}

.desktop-patient-head {
    margin-top:15px;
}
.patient-responsive {
    background: #ffffff;
    padding-top:84px;
}
.client-treat-cal{
	position:absolute;
	right:0px;
	top:4px;
	color:$blue;
	cursor:pointer;
	z-index:2;
}
.client-treat-reset{
	position:absolute;
	right:25px;
	top:4px;
	color:$blue;
	cursor:pointer;
	z-index:2;
}


a.client-treat-cal{
	position:absolute;
	right:0px;
	top:4px;
	color:$blue;
	cursor:pointer;
	padding:0px 0px 3px 5px;
	z-index:2;
}
a.client-treat-reset{
	position:absolute;
	right:17px;
	top:4px;
	color:$blue;
	cursor:pointer;
	padding:0px 5px 3px 5px;
	z-index:2;
}



a.client-treat-cal svg, a.client-treat-reset svg{
	font-size:12px;
	width:13px;
}

.clientLoader {
	z-index: 10;
	position: fixed;
}
.modal-content{border:none;}
.client-filter-popup{overflow-x:hidden;}
.patient-responsive th, .patient-responsive td{padding-right:10px!important;}
.patient-responsive td{padding: 11px 10px 11px 10px!important;word-break:break-all;}
.keep-all{word-break:keep-all!important;}
.client-norecord {
	margin-top: 15px;
}
.navbar-form.search {
    padding: 0px 10px;
	display:inline-block;
	vertical-align:top;
	margin:0px;

	.dash-clinic-outer {
		background:$white-color!important;
		border: none!important;
		height: 30px;
		@include border-radius(3px);

		.sel-clini {
			border: none;
			font-size: 14px;
			width: 200px;
			padding-left: 5px;
			height: 41px;
			vertical-align: top;
		}
		.new-dash-sel{
			height: 30px;
			font-size: 13px;
		}
	}
}
.btn-default {
    color: #666;
    background-color: #fff;
    border-color: #ddd;
}
.client-filter-popup{width:800px;display:table;margin-top:30px;overflow:inherit;
	.small-popup-content{overflow:inherit;max-height:inherit;}
	.saved-filter-content{overflow-y:auto;max-height: 383px;}
}
.text-loading{padding:20px 0px;float:left;width:100%;}
#showPicker{position:relative;z-index:2;
	position:absolute;z-index:1;
}
.clients-table .table-updated-thead .table-updated-th.user_image{padding-right:5px!important}
.field-mapping-outer{
	.setting-field-outer{float:none;
			.new-field-label{float:none;width:auto;vertical-align:top;
					div{width:200px;word-break: break-word;display:block;padding-right:5px;padding-top:4px;}
			}
			.setting-input-outer{float:none;vertical-align:top;}
	}
}
.delete-file{
      line-height:30px;
        background:$white-color;
      position: absolute!important;
      right: 5px;
      top: 5px;
      z-index: 50;
      @include border-radius(50px);
      width: 30px;
      height: 30px;
      font-weight: bold;
      text-decoration: none;
      text-indent: 100px;
      overflow: hidden;
      font-size: 27px;
      position: relative;
      border: none;
			@include box-shadow(0, 1px, 1px, 1px, rgba(0,0,0,0.15));
  }
  .delete-file:after {
      content: '';
      font-size: 27px!important;
      position: absolute;
      right: 9px;
      top: 9px;
      font-size: 27px;
      width: 11px;
      height: 11px;
      background: url(/../images/close.png) no-repeat center;
  }
.search-header{
	position:relative;
	 .small-cross {
	    color: #2c3e50;
	    font-size: 23px;
	    height: 20px;
	    margin-top: 0px;
	    float: right;
	    left: inherit;
	    right: 15px;
			top:0px;
	    position: absolute;
	    opacity: 0.4;
	    font-weight: 800;
	}
}

.healthtimeline{padding:0px!important;
    .view-number{display:none;}
}
.content-health-timeline-procedure{
	.file-upload-img.field_error{
    border: 1px solid #e74c3c;

    .upload{
      border-top: 1px solid #e74c3c;
    }
  }
	.survey-rate-outer.field_error {
		border: 1px solid #e74c3c;

		.survey-li{
			border-right: 1px solid #e74c3c
		}

		li.survey-li:last-child{
			border: none;
		}
	}
}
.timeline-left h4, .timeline-right h4 {
    margin: 5px 0px;
    color: #2682c5;
}
.health-trac-summry {
    margin-bottom: 10px!important;
    text-transform: uppercase;
    border-radius: 5px;
    overflow: hidden;
}

.font-size-16{
	font-size:16px
}

.color-gray0 {
	color: #a4abb2;
}

.exclamationPatientProfile {
	font-size: 25px;
	margin-right: 5px;
}

.react-contextmenu {
    background: #fff;
    color: #2682c5;
    padding: 5px 10px;
    border-radius: 4px;
    border: 1px solid #2682c5;

		&:hover{text-decoration:underline;cursor:pointer;}
}

.user-profile-img {
		&.male-profile-img{background-image: url(/../images/appmale.png)!important;}
		&.female-profile-img{background-image: url(/../images/appfemale.png)!important;}
}
.monthlyMembershipButton{width:100%;padding:12px 0px!important;}
.inlineField{
	float:left;
	margin:12px 0px 10px;
	.simpleLabel{margin:0px;padding:6px 18px 0px 0px;color:#404040;font-weight:bold;}
	.simpleSelect{float:none;width:140px;margin-right:10px;}
	.new-blue-btn, .new-white-btn{width:auto;margin-left:10px;margin-right:0px;min-width:inherit;padding: 5px 17px 4px;height: 36px;}
}
.membershipTypeTitle .videoPopupClose{
	float: right;
    position: absolute;
    right: 0px;
	top: -10px;

	svg{
		color: #fff;
		font-weight: 100;
		font-size: 21px;
	}
}
.videoOverlay{
	background: rgba(0,0,0,0.7);
}


.procedureTabOuter{margin-bottom:15px;float:left;width:100%;}
.procedureVideo{padding-bottom:15px;border:1px solid #dddddd;margin-bottom:25px;

	.videoThumbOuter{
		width:100%;
		float:left;
		height:166px;
		position:relative;
		overflow:hidden;

		img{height:166px;}

		.video-play-outer{
			position: absolute;
			left: 0px;
			top: 0px;
			background-color: rgba(0,0,0,0.6);
			right:0px;
			bottom:0px;
			cursor: pointer;

			.video-play{
				position: absolute;
				z-index: 1;
				top: 50%;
				left: 50%;
				font-size: 53px;
				color: rgba(255,255,255,0.5);
				margin-top: -26px;
				margin-left: -26px;
				cursor: pointer;

			}
		}
	}
	.videoTitle{
		display: block;
		text-align: center;
		padding: 6px 0px;
		height: 28px;
		margin-top:5px;
		margin-bottom:-5px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size:13px;
		color:#404040;
		float:left;
		width:100%;
	}

}

.mainVideoOuter{
	max-width:100%;
	padding:0px 20px;

	> div {max-width:100%;max-height:100%;width:inherit!important;height:inherit!important;}
	.membershipTypeTitle{width:100%!important;}

	video{
		max-width: 100%;max-height:500px;width:inherit!important;height:inherit!important;
	}
}

.videoPreview{
	max-width:100%;
	max-height:100%;
	margin:auto;
}

.displayTable {
	display: table!important;
}

.createEditFilter {
	overflow: auto;
}

.__react_component_tooltip.client-payment-error-tooltip {
  background: #fff;
  color: red;
  width: auto !important;
}

.__react_component_tooltip.client-payment-error-tooltip.border {
  border: 2px solid red;
}

.__react_component_tooltip.client-payment-error-tooltip.place-right::after {
  border-right-color: red;
}

.cancelled-text {
  color: #de0000;
}

.on-hold-text {
  color: #da833b;
}

.active-text {
	color: #08A621;
}

.inactive {
	color: #929292;
}

.lead-icon {
  color: #E5554F;
  vertical-align: top;
  display: inline-block;
  margin-top: 4px;
  margin-right: 3px;
}

.maxHeight-550 {
	max-height: 550px;
}
