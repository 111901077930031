@import "../../../styles/theme.scss";

.stepperContainer {
  display: flex !important;
  width: 300px;
  font-size: 12px;
  gap: 5%;
  min-height: 75px;

  .stepperLine {
    width: 30%;
    position: relative;
    margin-right: 15px;
  }
  .stepperLineSolid {
    border-right: 3px solid $cPrimary;
  }
  .stepperLastLine {
    border-right: 3px dashed $cPrimary;
  }

  .stepperCircle {
    font-style: italic;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background: $cPrimary;
    color: #fff;
    font-size: 10px;
    right: -11.5px;
    top: 0;
  }

  .stepperContent {
    width: 60%;
    & > div {
      display: flex;
      justify-content: space-between;
    }
  }

  .stepperContentTotal {
    color: $cPrimary;
  }

  .stepperCircleInfo {
    position: static !important;
    width: 34px;  
    margin-right: 5px;
  }
}

.stepperLarge {
  .stepperLine {
    font-weight: 600;
    font-size: 14px;
    max-width: 100px;
  }
  width: 520px !important;
  margin: auto;
  .stepperContent {
    font-size: 14px;
  }
}
