@import "../../SalesCheckoutInvoice.variables.scss";

.amount {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 22px;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 15px;

  .listRow {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 400;

    @media screen and (max-width: $mobileBreakpoint) {
      font-size: 14px;
    }
  }

  .total {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 600;
  }
}
