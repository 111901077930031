@import "../sass/base.scss";

.layout {
  margin-top: 35px;
  background-color: $cPrimaryDim2;
  padding-top: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 20px 0;
  margin-bottom: 40px;

  @media screen and (max-width: $mobileBreakpoint) {
    flex-direction: column;
    gap: 32px;
    align-items: flex-start;
  }

  .search {
    width: 300px;

    @media screen and (max-width: $tabletBreakpoint) {
      width: 210px;
    }

    @media screen and (max-width: $mobileXsBreakpoint) {
      width: 100%;
    }
  }

  .headerTitle {
    font-weight: 600;
    font-size: 25px;
    line-height: 24px;
    white-space: nowrap;
  }

  .headerRight {
    display: flex;
    align-items: center;
    gap: 28px;
    justify-content: flex-end;
    flex-wrap: wrap;

    @media screen and (max-width: $tabletBreakpoint) {
      gap: 16px;
    }

    @media screen and (max-width: $mobileXsBreakpoint) {
      flex-direction: column;
      align-items: stretch;
      width: 100%;
    }
  }
}

.content {
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.alphabetKey {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
}

.workspaces {
  display: flex;
  flex-direction: column;
  gap: 13px;
}

.skeleton {
  margin-bottom: 50px;
}

.empty {
  color: $cGray3;
  font-size: 40px;
  text-align: center;
  font-weight: 300;
  cursor: default;
  user-select: none;
}

.backBtn {
  &:hover {
    background-color: $cWhite !important;
  }
}
