@import "../../styles/variables.scss";
@import "../../styles/theme.scss";

.container {
  font-family: $secondaryFont !important;

  padding: 20px;
  float: left;
  width: 100%;

  .inputLabel {
    font-size: 15px;
    margin-bottom: 8px;
    font-weight: 600;
    color: #666677;
    font-family: "Open Sans", sans-serif !important;
  }

  .deviceDetailsRow {
    max-width: 900px;

    > div {
      margin-bottom: 20px;
    }
  }

  .resourceDetailsRow {
    max-width: 1200px;

    > div {
      margin-bottom: 20px;
    }
  }

  .selectClinicDropdown {
    width: 100%;
    max-width: 300px;
  }

  .newResourceField {
    max-width: 186px;
    margin-right: 12px;
  }

  .addNewResourceField {
    margin-top: 30px;
  }

  .daySchedule {
    min-height: 40px;
    margin: 20px auto;
    min-width: 525px;
  }

  .dayRow {
    max-width: 475px;
  }

  .scheduleWeekday {
    margin-top: 9px;
    gap: 16px;
    cursor: pointer;

    label {
      cursor: pointer;
      margin-bottom: 0px;
    }
  }

  .dayScheduleSlots {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .scheduleRow {
    display: flex;
    gap: 8px;
    margin: 0px auto;
    width: 100%;
  }

  .scheduleTimeFields {
    display: flex;
    gap: 6px;
    align-items: center;
  }

  .newInputFileldOuter {
    display: flex;
    margin: 0px;
  }

  .inputTime {
    color: #404040;
    border: 1px solid #cad2d6;
    border-radius: 3px;
    height: 36px !important;
    padding: 0 6px 0 12px;
    width: 56px !important;
    max-width: 72px;

    border-right: 0px !important;
    margin-right: 0 !important;
  }

  .inputTimeOption {
    width: 56px !important;
    padding: 0px 15px 0px 5px !important;
    margin-right: 0 !important;
    border-left: 0px !important;
    margin-left: -2px !important;
  }

  .timeFieldError,
  .timeOptionFieldError {
    border-color: #e52817 !important;
  }

  .deleteScheduleItem {
    padding: auto 5px;
    cursor: pointer;
    margin: auto;
  }

  .scheduleUnavailable {
    margin-top: 9px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .dayActions {
    margin-left: 16px;
    margin-top: 8px;
    max-height: 28px;
    display: flex;
    gap: 10px;

    > span {
      cursor: pointer;
    }
  }

  .dropdownMenu {
    max-width: 120px;
    min-width: 120px;
  }

  .dropdownContent {
    padding: 8px 10px;
  }

  .menuTitle {
    font-weight: 600;
    font-size: 13px;
    margin-bottom: 14px;
  }

  .dayOption {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    height: 23px;
  }

  .copyDayScheduleButton {
    margin-top: 16px;
  }

  .footer {
    padding: 20px;
    text-align: right;
    border-top: 1px solid #e5e5e5;

    button {
      min-width: 110px;
    }
  }
}
