@import "../../styles/theme.scss";

.button {
  background: $cWhite;
  border: 1px solid $cGray1;
  border-radius: 4px;
  padding: 5px 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.15s ease;
  color: $cGray1;

  &:hover {
    background-color: $cPrimary;
    border-color: $cPrimary;
    color: $cWhite;
  }

  svg {
    width: 18px;
    height: auto;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
