.calender-title {
  width: 100%;
  float: left;
  padding: 15px 20px;
  border-bottom: 1px solid #dddddd;

  .calenderName {
    font-size: 20px;
    color: #404040;
    display: inline-block;
    vertical-align: top;
    min-width: 100px;
  }

  .cal-arrow-outer {
    display: inline-block;
    margin-top: 3px;
    margin-left: 25px;

    .cal-arrow {
      padding: 0px 7px;
      height: 22px;
      line-height: 22px;
    }
  }

  .calName {
    color: #667680;
    display: inline-block;
    vertical-align: top;
    font-size: 18px;
    padding: 2px 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;

    .singleProviderDuration {
      font-size: 13px;
      margin-top: -2px;
      margin-bottom: -5px;

      br:first-child {
        display: none;
      }
    }
  }

  .calWeekdropDown,
  .calClinicdropDown {
    position: relative;

    button {
      font-size: 14px;
      padding: 0px 20px 0px 10px;
      height: 32px;
      min-width: 95px;
      text-align: left;

      &:focus {
        background: none;
        color: #666;
        border-color: #dddddd;
        outline: none;
      }

      svg {
        top: 10px;
        right: 6px;
        font-size: 13px;
        margin: 0 3px;
      }
    }

    ul {
      right: 0px;
      left: inherit !important;
      transform: translate3d(0px, 32px, 0px) !important;
    }
  }

  .cal-dropdown.clinicname-dropdown {
    top: 0px !important;
    max-height: inherit !important;
  }

  ul.cal-dropdown li a label {
    width: 100% !important;
    line-height: 27px !important;
    float: left !important;
  }
}

.calSettingdropDown {
  position: relative;

  button {
    font-size: 14px;
    padding: 0px 15px 0px 15px;
    height: 32px;
    text-align: left;
    margin: 0px;
    background: url(/../images/ic_wrench-gray.svg) no-repeat center;

    &:focus {
      background-color: inherit;
      color: #666;
      border-color: #dddddd;
      outline: none;
    }
  }

  ul {
    right: 0px;
    left: inherit !important;
    transform: translate3d(0px, 32px, 0px) !important;
  }
}

.CalendarOuter {
  .rbc-day-slot .rbc-time-slot {
    border-top: none !important;
  }

  display: block;
  width: 100%;
  overflow-y: auto;

  @media (max-width: 767px) {
    height: 100% !important;
  }

  & .calendarLeftSection,
  & .calendarrightSection {
    display: table-cell;
    vertical-align: top;
  }

  & .calendarLeftSection {
    width: 220px;
    padding: 15px;
    min-width: 220px;
    top: 62px;
    float: left;

    .cal-service-dropdown {
      input[type="checkbox"]:checked::after {
        content: url(/../images/new-check-white.png);
      }
    }

    .CreateAppointmentBtn {
      width: 100%;
      margin: 0px 0px 20px;
      padding: 9px 0px;
      height: auto;
      font-size: 15px;
    }

    .leftCalendar {
      float: left;
      width: 100%;
      min-height: 236px;
      height: 100%;
      margin-bottom: 11px;

      .react-datepicker__triangle {
        background: none;
        margin: 0px;
        border: none;
      }

      .react-datepicker__input-container {
        display: none !important;
      }

      .react-datepicker__header {
        background: none;
        border: none;
      }

      .react-datepicker {
        border: none;
      }

      .react-datepicker__navigation--previous {
        right: 31px !important;
        left: initial !important;
        border-color: #ffffff;
        background: url(/../images/cal-arrow-left.png) no-repeat center;
        width: 20px;
        height: 24px;
        top: 5px;
        background-size: auto 10px;
      }

      .react-datepicker__navigation--next {
        border-color: #ffffff;
        background: url(/../images/cal-arrow-right.png) no-repeat center;
        width: 20px;
        height: 23px;
        top: 5px;
        background-size: auto 10px;
      }

      .react-datepicker__current-month {
        text-align: left;
        padding-left: 10px;
        color: #667680;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 10px;
      }

      .react-datepicker__day-name,
      .react-datepicker__day,
      .react-datepicker__time-name {
        width: 24px !important;
        color: #667680;
      }

      .CalendarOuter
        .calendarLeftSection
        .leftCalendar
        .CalendarOuter
        .calendarLeftSection
        .leftCalendar
        .react-datepicker__day-name,
      .CalendarOuter .calendarLeftSection .leftCalendar .react-datepicker__day {
        width: 24px !important;
        color: #667680;
      }

      .react-datepicker__day.react-datepicker__day--keyboard-selected {
        color: #667680 !important;
      }

      .react-datepicker__day--keyboard-selected,
      .react-datepicker__month-text--keyboard-selected {
        background-color: #ffffff !important;
      }

      .react-datepicker__day.react-datepicker__day--selected {
        color: #ffffff !important;
      }

      .CalendarOuter
        .calendarLeftSection
        .leftCalendar
        .react-datepicker__day--selected {
        color: #ffffff !important;
      }

      .react-datepicker__day:hover {
        color: #ffffff !important;
      }

      .sidebarHeading {
        font-size: 15px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        color: #667680;
        margin-bottom: 9px;
      }

      .sidebarItemName {
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: -0.30000001192092896px;
        color: #667680;
      }

      .sidebarSectionContainer {
        background-color: #f7f8f8;
        padding: 8px;
        margin-bottom: 10px;
        border-radius: 2px;

        ul.cal-dropdown {
          background: transparent;
        }
      }

      .verticalsLegendItem {
        margin-bottom: 13px;
        display: flex;
        align-items: center;
      }

      .verticalsLegendIndicator {
        display: inline-block;
        flex: 0 0 14px;
        height: 14px;
        margin-right: 8px;
      }
    }

    .searchProvider {
      width: 100%;
      border: 1px solid #dddddd;
      @include border-radius(3px);
      height: 25px;
      float: left;
      padding: 3px 8px 5px;
      margin-bottom: 5px;
    }

    ul.cal-dropdown {
      position: initial;
      width: 100%;
      overflow-y: initial;
      max-height: initial;
      -webkit-box-shadow: initial;
      box-shadow: initial;
      margin: 0px;

      li {
        width: 100%;

        a {
          padding-left: 0px;

          label {
            padding-top: 6px;
            min-height: 32px;
            border: none;
            color: #667680;

            input {
              top: 0px;
            }
          }
        }
      }
    }
  }

  .calendarrightSection {
    width: 100%;
    display: block;

    &.calendarrightSectionWithSidebar {
      width: calc(100% - 220px);
      display: inline-block;
    }

    & > .day-view-calendar {
      box-shadow: none;
      min-height: 580px;
    }

    .setting-setion,
    .juvly-section {
      margin-bottom: 0;
    }

    .rbc-time-view {
      border: none;
    }

    .rbc-time-content {
      border-top: none;
    }

    .rbc-time-content > * + * > * {
      border-left: 1px solid #cccccc;
    }

    .rbc-timeslot-group {
      border-bottom: 1px solid #cccccc;
    }

    .rbc-time-header-content {
      .rbc-header {
        border: none;
        background-color: #fff;
      }

      .rbc-row-resource {
        display: none;
      }
    }

    .week-view-calendar {
      box-shadow: none;
      min-height: 590px;
    }

    .rbc-header {
      height: 70px;

      span {
        display: block;
        margin-top: 3px;
        font-weight: normal;
        font-size: 14px;
        color: #666666;
      }

      p {
        font-size: 22px;
        font-weight: normal;
        color: #666666;
      }

      &.rbc-today {
        span {
          color: $blue;
        }

        p {
          display: inline-block;
          background: $blue;
          width: 35px;
          height: 35px;
          text-align: center;
          @include border-radius(50px);
          vertical-align: top;
          line-height: 34px;
          margin-top: -5px;
          color: #ffffff;
        }
      }
    }

    .rbc-current-time-indicator {
      background: $blue;
    }
  }
}

.edit-event-detail-btn {
  margin-left: 20px;
  padding: 9px 0px 0px 0px;
}

.event-detail-outer {
  float: left;
  width: 100%;
}

.event-detail-outer label {
  min-width: 136px;
  width: 136px;
}

.eventDetailBtns a {
  padding: 8px 17px 8px !important;
  margin-left: 0px;
  width: 100%;
  min-width: inherit;
  height: auto;
}

.editEventCalNote {
  position: relative;
  padding-right: 20px;
  width: 100%;

  a {
    position: absolute;
    right: -7px;
    top: 0px;
    color: $blue;
  }
}

.appointNoteBtn a {
  padding: 5px 10px 4px;
  margin-bottom: 5px;
}

.clockIconEvenDetail {
  display: inline-block;
  vertical-align: top;
  color: $blue;
  margin: 2px 4px;
}

.TypeOfClient {
  position: absolute;
  right: 0px;
  top: 0px;

  .membership-bedge-block {
    margin-top: 0px;
  }
}

.eventBookingDate {
  position: relative;
  width: 100%;

  svg {
    cursor: pointer;
  }

  .appoint-pro-edit {
    top: 20px;

    ul li {
      border-bottom: 1px solid #dddddd;

      &:last-child {
        border: none;
      }
    }
  }
}

.selectBookingSlot {
  border: 1px solid #cad2d6;
  @include border-radius(5px);
  display: block;
  float: left;
  width: 100%;
  margin-top: 20px;

  .BookingSlotTitle {
    background: #daedf7;
    padding: 5px 15px;
    font-size: 14px;
    color: #404040;
    @include border-radius(5px 5px 0px 0px);
    float: left;
    width: 100%;
  }

  .BookingSlotContent {
    padding: 15px;
    padding-bottom: 0px;
    float: left;
    width: 100%;
    background: #fafdff;
    @include border-radius(0px 0px 5px 5px);

    .timeSlotDateNextPrev {
      width: 100%;
      float: left;
      text-align: center;
      margin-bottom: 15px;
      font-weight: bold;

      .timeSlotarrow {
        display: inline-block;

        &.inactive {
          cursor: not-allowed;
          opacity: 0.5;
        }

        img {
          display: inline-block;
          vertical-align: top;
          margin-top: 3px;
          font-weight: bold;
        }

        span {
          display: inline-block;
          vertical-align: top;
          color: $blue;
          font-weight: bold;
        }
      }
    }

    .TimeSlotsRow {
      margin-left: -5px;
      margin-right: -5px;
      clear: both;
      max-height: 300px;
      overflow: auto;
    }

    .TimeSlotsCol {
      padding-left: 5px;
      padding-right: 5px;
    }

    .TimeSlots {
      width: 100%;
      float: left;
      color: #404040;
      border: 1px solid #cad2d6;
      border-radius: 3px;
      height: 36px;
      padding: 0px 0px;
      font-size: 13px;
      background: #ffffff;
      margin-bottom: 15px;

      &:hover,
      &.active {
        background: $blue;
        color: #ffffff;
        border: 1px solid $blue;
      }

      &.OutOfSchedule {
        border: 1px solid rgba(202, 210, 214, 0.4);
        color: rgb(163, 163, 163);

        &:hover {
          color: #ffffff;
        }
      }
    }
  }
}

.eventWeekName {
  width: 100%;
  float: left;
  list-style: none;

  li {
    float: left;
    margin-right: 5px;

    a {
      padding: 6px 5px;
      background: #f2f6f7;
      color: #667680;
      font-size: 13px;
      float: left;
      @include border-radius(30px);
      width: 30px;
      height: 30px;
      text-align: center;

      &:hover,
      &.active {
        background: $blue;
        color: #ffffff;
      }
    }
  }
}

.grayField {
  float: left;
  background: #f2f6f7;
  height: 34px;
  position: relative;
  width: 100%;
  @include border-radius(3px);
}

.grayField {
  input,
  select {
    float: left;
    width: 100%;
    background: none;
    height: 34px;
    color: #667680;
    padding: 0px 10px;
  }
}

.grayCalender {
  input,
  select {
    padding: 0px 30px 0px 10px;
  }
}

.grayCalender {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 6px;
  top: 6px;
  background-image: url(/../images/appointment.png);
  z-index: 1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 15px;
}

.eventEndCal {
  width: 150px;
  margin-top: -8px;
}

.eventEndAfter {
  width: 65px;
  margin-top: -8px;
  margin-right: 10px;
}

.eventEndOuter {
  padding-left: 0px !important;
  padding-right: 35px !important;
}

.online-booking-btn {
  min-width: inherit !important;
  width: auto !important;
  padding: 5px 10px !important;
  margin-right: 10px !important;
}

.cal-provider-filter {
  box-shadow: inherit !important;
  -webkit-box-shadow: inherit !important;
  margin-left: 0px !important;
  margin-right: 10px !important;
  position: relative;
  color: #666;
  background-color: #fff;
  border: 1px solid #dddddd !important;
  padding-right: 20px !important;
  padding-left: 10px !important;
  margin-bottom: 0px !important;

  .cal-arrow {
    color: #666;
  }
}

.cal-clinic-filter {
  padding-right: 20px !important;
  padding-left: 10px !important;
}

.ProviderCalendar {
  .calendarrightSection {
    .rbc-time-header-content {
      .rbc-row-resource {
        .resource-name {
          /*line-height: 70px;
                    font-size: 20px;
                    border-left: 1px solid #9ba29b;
                    border-right: 1px solid #9ba29b;
                    */
          font-size: 14px;
          color: #666;
          height: auto !important;
          border: none !important;
          border-bottom: 1px solid #ececec !important;
          background: #fafafa;
        }

        display: flex !important;
      }
    }
  }
}

.ProviderCalendarDay {
  overflow-y: auto;

  .calendarrightSection {
    .rbc-time-header-content {
      border-left: 1px solid #ececec;
      width: 140px;
      margin: 0;
    }
  }
}

.ProviderCalendarVerticals {
  .rbc-time-view-resources {
    .rbc-time-header-content:nth-child(2) {
      position: sticky;
      left: 72px !important;
    }
  }

  .rbc-time-view {
    .rbc-time-gutter {
      border: none;

      .rbc-timeslot-group:before {
        display: none;
      }
    }
  }

  .rbc-time-column:nth-child(2) {
    box-shadow: 4px 0px 5px rgba(0, 0, 0, 0.13);
    background-color: #fff;
    position: sticky;
    left: 72px !important;
    z-index: 6;

    .rbc-timeslot-group {
      border: none;
    }
  }
}

.rbc-events-container {
  .rbc-event {
    // width: 100% !important;
    // left: 0 !important;
  }
}

.calViewCal {
  display: inline-block;
  vertical-align: top;

  .new-calender {
    margin-left: 0px;
    box-shadow: inherit !important;
    border: 1px solid #dddddd;

    img {
      margin: 5px 7px;
    }
  }
}

.outOfficeTag .tag-auto-select > div > div:first-child,
.outOfficeTag .tag-auto-select > div > div:nth-child(2) {
  border: 1px solid #bfc9ce !important;
  border-radius: 3px;
  @include border-radius(3px);
}

.outOfficeTag .tag-auto-select > div > div:last-child {
  margin-top: -2px;
}

.CalendarOuter
  .calendarLeftSection
  .leftCalendar
  .react-datepicker__day.react-datepicker__day--selected {
  color: #fff !important;
}

.CalendarOuter
  .calendarLeftSection
  .leftCalendar
  .react-datepicker__day.react-datepicker__day--today:hover {
  color: #fff !important;
  background: $blue !important;
}

.calClinicFilter {
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.disableCalender {
  position: absolute;
  z-index: 4;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
  bottom: 0px;
  top: 0px;
  left: 0px;
  right: 0px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  cursor: not-allowed;
  text-align: center;

  p {
    font-size: 14px;
    margin: 265px auto 0px;
    display: inline-block;
    background: #daedf7;
    border: 1px solid #daedf7;
    padding: 4px 15px;
    border-radius: 27px;
    -webkit-border-radius: 27px;
  }
}

.editServiceNameCal {
  height: 36px;
  line-height: 34px !important;
}

.editableField {
  position: relative;

  input {
    padding-right: 110px;
  }

  .editBtnOuter {
    position: absolute;
    right: 20px;
    top: 8px;
  }
}

.durationMins {
  width: 317px;

  label {
    display: inline-block;
    float: left;
    min-width: 114px;
    max-width: 114px;
  }

  input {
    width: 172px;
  }

  .editBtnOuter {
    position: absolute;
    right: 20px !important;
    top: 8px;
  }
}

.width-45 {
  width: 45px;
}

.radioTextLabel {
  position: absolute;
  left: 48px;
  top: 2px;
  font-weight: normal;
  font-size: 13px;
}

/*************** new-changes ****************/

.CalendarOuter {
  float: left;
  position: relative;

  .calendarLeftSection {
    top: inherit !important;
  }
}

.CalendarOuter .calendarrightSection .resource_schedule {
  min-height: 104px !important;
  height: auto !important;
}

.providerDuration br:first-child {
  display: none;
}

.iconPointEvents {
  pointer-events: none;
}
