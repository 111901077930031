.card-readers {
    padding: 15px;
    height: auto !important;
}
.cardreader-buttons {
    height: 65px;
    display: flex;
    align-items: flex-end;
    justify-content: space-evenly;
}
.cardreader-label {
        color: #047ec3;
}
.cardreader-form {
    height: 100px;
}
table.cardreader-table {
    table-layout: fixed;
}