.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  top: 0;
  float: left;
  font-size: 13px;
  text-align: center;
  left: 0;
  right: 0;
  z-index: 9999;

  .loaderOuter {
    position: absolute;
    margin-top: 40px;
    margin-left: -20px;
    z-index: 100;
    text-align: center;
    width: 100%;

    img {
      display: inline-block;
      text-align: center;
      margin-bottom: 18px;
      margin-top: 27px;
      height: 62px;
    }
  }
}
