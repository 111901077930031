footer {
  background: #000;
  display: block;
  padding: 20px 0px 15px;
  color: #777;
  font-size: 13px;

  .Address a {
    color: $blue;
  }

  p {
    text-align: center;
    margin-top: 9px;
    font-size: 13px;
    display: inline-block;

    a {
      color: #fff;
    }
  }

  #social {
    color: #777;
    list-style: none;
    display: block;
    text-align: center;
    float: right;
    margin-top: 0px;

    li {
      display: inline-block;

      a {
        display: inline-block;
        color: #777;
        padding: 0px 8px;
        font-size: 20px;
        width: 43px;
        height: auto;
        border-radius: 50%;
        line-height: 32px;
        margin: 0px -3px;
      }

      a:hover {
        border-color: #fff;
        color: #fff;
      }
    }
  }
}

.footer-fixed {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 1;
}
