@import "../../../../../../../styles/theme.scss";

.root {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.label {
  text-transform: uppercase;
  font-size: 15px;
  color: $cGray1;
  display: flex;
  align-items: center;
  gap: 4px;
}

.contentWrap {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.footer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.footerInner {
  display: flex;
  gap: 8px;
}
