@import "../../../styles/theme.scss";

.root {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.questionnairesList {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 313px));
  gap: 16px;

  @media screen and (max-width: 1070px) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }

  @media screen and (max-width: 420px) {
    display: flex;
    flex-direction: column;
  }
}

.questionnaireListEmpty {
  display: flex;
  justify-content: center;
}
