@import "../../../../../../../../../styles/theme.scss";

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 32px;
  padding: 2px 5px 2px 10px;
  border-radius: 4px;
  flex-wrap: wrap;
  gap: 8px;
  border: 1px solid $cGray2;

  .name {
    font-size: 15px;
    font-weight: 600;
  }
}

.actions {
  display: flex;
  gap: 2px;
  align-items: center;

  .action {
    border: none;
    background: none;
    outline: none;
    display: flex;
    align-items: center;
    height: 20px;
    width: 20px;
    border-radius: 2px;
    justify-content: center;
  }

  .editAction {
    color: $cPrimary;

    &:hover {
      background-color: $cPrimaryLight;
    }
  }

  .deleteAction {
    color: $cRed;

    &:hover {
      background-color: $cRedDim;
    }
  }
}
