.root {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.footer {
  display: flex;
  gap: 16px;
}

.additionData {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  justify-content: space-between;

  & > div {
    flex: 1;
    min-width: 205px;
  }
}
