.root {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (max-width: 850px) {
    width: 100%;
  }

  button {
    align-self: flex-start;
  }
}
