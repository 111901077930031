@import "../../../../../../styles/theme.scss";

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  flex-wrap: wrap;
}

.content {
  margin-bottom: 24px;

  a {
    color: $cPrimary;
    text-decoration: underline;
  }

  i {
    margin: 0;
  }
}

.checkbox {
  max-width: 450px;
}

.checkboxLabel {
  font-size: 14px !important;
  font-weight: 300 !important;
  line-height: 18px !important;

  i {
    margin: 0;
  }

  b {
    font-weight: 400;
  }
}
