@import "../../../../styles/theme.scss";

.mt {
  margin-top: 50px;
}

.welcomeSection {
  .row {
    display: flex;
    align-items: center;
  }
}

.textSection {
  padding: 0 25px;
  flex: 1;

  .context {
    color: #3c3c3cb2;
    font-size: 16px;
    font-weight: 400;
    min-width: 50%;
    width: 365px;
  }
}

.planTitle {
  padding-bottom: 15px;
  color: #3c3c3c;
  font-size: 20px;
  font-weight: 500;
}

.boxFeature {
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.08);

  .headBox {
    border-bottom: 2px solid #eaecf0;
    display: flex;
    justify-content: space-between;

    .enable {
      font-size: 15px;
      font-weight: 500;
      color: #b1b1b1;
      align-items: center;
      display: flex;
    }

    .toogle {
      padding-right: 25px;
    }

    .planTitle {
      align-items: center;
      display: flex;
      padding: 25px 25px;
    }
  }

  .bodyBox {
    padding: 0 25px;
    margin: 25px 0;
    display: flex;
    flex-direction: column;
  }

  .bodyTitle {
    color: #2d3748;
    font-size: 20px;
    font-weight: 500;
  }

  .subTitle {
    color: #3c3c3cb2;
    font-size: 16px;
    font-weight: 700;
    padding: 10px 0;
  }

  .contentText {
    padding-top: 10px;
    color: #3c3c3cb2;
    font-size: 14px;
    font-weight: 400;

    ul {
      list-style-position: inside;

      li {
        margin-bottom: 10px;
      }
    }
  }

  .more {
    font-size: 16px;
    color: #000000;
    font-weight: 500;
    text-decoration: underline;
  }
}

.footerBox {
  display: flex;
  padding: 25px 25px;
  justify-content: space-between;

  .liveDay {
    font-size: 18px;
    font-weight: 500;
    color: #3c3c3c66;
  }
}

@media screen and (max-width: 991px) {
  .textSection {
    .context {
      min-width: 100%;
      width: 100%;
    }
  }
}

.toggleBox {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 25px;
}

.modalFooter {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.charsLength {
  font-size: 14px;
  font-weight: 400;
  color: $cGray1;
}