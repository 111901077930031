@import "../../../../../../styles/theme.scss";

.root {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.question {
  font-size: 20px;
  font-weight: 300;

  span {
    color: $cRed;
  }
}

.description {
  margin: 0;
  color: $cGray0Dark;
  font-size: 14px;
  margin-top: 4px;
}
