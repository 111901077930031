@import "../../../../../../styles/theme.scss";

.root {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;

  @media screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;
  }
}

.block {
  padding: 10px;
  border-radius: 4px;
  background-color: $cGray5;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .label {
    font-size: 17px;
    color: lighten($cBlack, 40%);
  }
}

.content {
  display: flex;
  align-items: flex-start;

  & label {
    margin-top: 10px;
  }
}

.empty {
  pointer-events: none;
  user-select: none;
  color: $cGray2;
}
