@import "../../../../styles/theme.scss";

.root {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.label {
  margin-bottom: 5px;
  color: $cGray0;
}
