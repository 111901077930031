.root {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 16px;

  @media screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;
  }
}
