.mainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f9f9f9;
  border-radius: 5px;
  margin-bottom: 20px;
  max-width: 800px;
  width: 100%;
  height: auto;
}
.declinesmsEmailTitleSection {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}

.emailSmsTabs {
  li {
    span {
      font-size: 16px;
      padding: 15px 20px;
      border-bottom: none;
      color: #000;

      &.active {
        border-bottom: 2px solid #188bf6;
        color: #188bf6;
      }
    }
  }
}

.instructions {
  float: left;
  font-size: 13px;
  color: #188bf6;
  background: #fff;
  position: absolute;
  margin-left: 10px;
  top: -11px;
  min-height: 18px;
  z-index: 1;
}

.newtextareaField {
  font-size: 14px;
  font-weight: normal;
  border: 1px solid #cad2d6;
  border-radius: 3px;
  padding: 10px;
  width: 100%;
  min-height: 250px;
  box-sizing: border-box;
  z-index: 0;
  position: relative;
  resize: none;
}

.filterCount {
  font-size: 12px;
  margin-right: 5px;
  display: inline-block;
}

.previewButtonOpen {
  height: 150px;
  visibility: hidden;
}
