@import "../../../../../../styles/theme.scss";

.root {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.footer {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}

.singleProp {
  b {
    margin-right: 4px;
  }
}

.propCol {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.empty {
  pointer-events: none;
  user-select: none;
  color: $cGray2;
}
