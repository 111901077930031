@import "../../styles/theme.scss";

.root {
  border-radius: 8px;
  background-color: $cWhite;
  padding: 15px 20px;
}

.rootSmall {
  border-radius: 4px;
  padding: 10px 15px;

  &.rootElevation {
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.08);
  }
}

.rootNoPadding {
  padding: 0;
}

.rootNoOverflow {
  overflow: hidden;
}

.rootElevation {
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.08);
}

.rootOverflowXScroll {
  overflow-x: scroll;
}
