.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  @media screen and (max-width: 550px) {
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;

    & > * {
      width: 100% !important;
    }
  }
}

.search {
  width: 230px;
}

.filter {
  width: 175px;
}
