.root {
  width: 700px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;

  @media screen and (max-width: 992px) {
    width: 100%;
  }
}
