.root {
  @media screen and (max-width: 780px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.right {
  display: flex;
  align-items: center;
  gap: 8px;

  @media screen and (max-width: 430px) {
    flex-direction: column;
    align-items: flex-start;
  }
}