@import "../../pages/Event//sass/variables.scss";

.maintenanceOverlay {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #818183;
  filter: grayscale(100%);
  text-align: center;

  h1 {
    font-size: 56px;

    @media screen and (max-width: $mobileMaxBreakpoint) {
      font-size: 24px;
    }
  }

  p {
    font-size: 32px;

    @media screen and (max-width: $mobileMaxBreakpoint) {
      font-size: 16px;
    }
  }

  .maintenanceContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    .maintenanceImage {
      width: 500px;
      height: 350px;
      background-size: contain;
      background-repeat: no-repeat;

      @media screen and (max-width: $mobileMaxBreakpoint) {
        width: 300px;
        height: 200px;
      }

      .maintenanceAnimationContainer {
        width: 90%;
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: $mobileMaxBreakpoint) {
          height: 175px;
        }

        .animationImage {
          width: 150px;
          height: 150px;

          @media screen and (max-width: $mobileMaxBreakpoint) {
            width: 100px;
            height: 100px;
          }
        }
      }
    }
  }
}
