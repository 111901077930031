@import "../../styles/theme.scss";

.eyeButton {
  background: none;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;

  svg {
    width: 16px;
    color: $cGray1;

    &:hover {
      color: $cGray0;
    }
  }
}
