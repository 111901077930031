@import "../../styles/theme.scss";

.root {
  width: 16px;
  min-width: 16px;
  height: 7px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: grab;

  span {
    height: 2px;
    background-color: $cGray2;
  }
}
