.appointmentConfirmIconWrapper {
  .confirmedIcon {
    color: rgb(0, 212, 0);
    font-size: 14px;

    & svg {
      border: 1px solid white;
      border-radius: 50%;
      background: white;
    }
  }
}
