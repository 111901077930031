@import "../../../../styles/variables.scss";
@import "../../../../styles/theme.scss";

.modalRoot {
  font-family: $secondaryFont !important;

  .modalDatepickerOpen {
    height: 320px;
  }

  .deleteModalDatepickerOpen {
    height: 560px;
  }

  .weekDaysSchedule {
    overflow-x: scroll;
    white-space: nowrap;

    > div {
      width: 625px;
    }

    .dayActions {
      margin-left: 16px;
      margin-top: 8px;
      max-height: 28px;

      span {
        cursor: pointer;
        padding: auto 5px;
      }

      display: flex;
      gap: 10px;

      .dropdownMenu {
        max-width: 120px;
        min-width: 120px;
      }

      .dropdownContent {
        padding: 8px 10px;
      }

      .menuTitle {
        font-weight: 600;
        font-size: 13px;
        margin-bottom: 14px;
      }

      .dayOption {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
        height: 23px;
      }

      .copyDayScheduleButton {
        margin-top: 16px;
      }
    }

    .dayScheduleSlots {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    .scheduleWeekday {
      margin: 9px auto auto 2px;
      gap: 16px;
      cursor: pointer;

      label {
        cursor: pointer;
        margin-bottom: 0px;
      }
    }

    .scheduleUnavailable {
      margin-top: 9px;
    }

    .deleteScheduleItem {
      padding: auto 5px;
      cursor: pointer;
      margin: auto;
    }

    .daySchedule {
      min-height: 40px;
      margin: 20px auto 20px 0px;
    }

    .scheduleRow {
      display: flex;
      gap: 8px;
      margin: 0px auto;
      width: 100%;
    }

    .appointmentTypeField {
      flex-grow: 1;
      max-width: 184px;
      min-width: 160px;
    }

    .appointmentTypeSelect {
      min-width: 100% !important;
      padding: 0 10px !important;

      color: #404040;
      border: 1px solid #cad2d6;
      border-radius: 3px;
      height: 36px !important;
      width: 80px !important;
      margin-right: 10px !important;
    }

    .scheduleTimeFields {
      display: flex;
      gap: 6px;
      align-items: center;
    }
  }

  .deleteDaySchedule {
    width: 100%;
    overflow: unset;
  }

  .settingInputBox,
  .newInputField {
    color: #404040;
    border: 1px solid #cad2d6;
    border-radius: 3px;
    height: 36px !important;
    padding: 0px 6px 0px 12px;
    width: 56px !important;
  }

  .newInputLabel {
    margin: 0px 3px;
    padding: 0px 2px;
    position: absolute;
    top: -9px;
  }

  .newSelectField {
    width: 56px !important;
    padding-left: 5px !important;
    margin-right: 0 !important;
    border-left: 0 !important;
    margin-left: -2px !important;
    border: 1px solid #cad2d6;
  }

  .timeFieldError,
  .timeOptionFieldError {
    border: 1px solid #e52817 !important;
  }

  .timeFieldError {
    border-right: 0px;
  }

  .timeOptionFieldError {
    border-left: 0px;
  }

  .changeScheduleTypeContainer {
    display: flex;
    margin-top: 8px;
    gap: 16px;
  }

  .newInputFileldOuter {
    display: flex;
    margin: 0px;
    float: left;
    width: 100%;
    position: relative;
  }

  .inputTime {
    color: #404040;
    border: 1px solid #cad2d6;
    border-radius: 3px;
    height: 36px !important;
    padding: 0 6px 0 12px;
    width: 56px !important;
    max-width: 72px;
    border-right: 0px !important;
    margin-right: 0px !important;
  }

  .inputTimeOption {
    width: 56px !important;
    padding-left: 5px !important;
    margin-right: 0 !important;
    border-left: 0 !important;
    margin-left: -2px !important;
  }

  .dropdownMenu {
    left: -88px;
  }

  .selectTitle {
    font-size: 15px;
    margin-bottom: 8px;
    font-weight: 600;
    color: #666677;
    font-family: $secondaryFont !important;
  }

  .datePicker {
    max-width: 180px;
  }

  .selectDates {
    margin: 0px auto 42px;
    display: flex;
    gap: 60px;
  }

  .selectClinicDropdown {
    max-width: 535px;
    margin-bottom: 32px;
  }

  .calendarIcon {
    position: absolute;
    right: 0px;
    cursor: pointer;
    padding: 0px 0px 3px 5px;
    color: $cPrimary;
  }

  .dateCalendarIcon {
    top: 0px;
    z-index: 0;

    img {
      max-width: 18px;
    }
  }

  .datepickerAction {
    z-index: 1;
    top: 1px;
    margin-left: 4px;

    img,
    svg {
      max-width: 18px;
      height: 18px;
      width: 18px !important;
    }
  }

  .deleteRepeatDateIcon {
    top: 2px;
    right: 17px;
    margin-left: 4px;
    padding: 0 5px 3px;
  }

  .footerWrapper {
    @media (max-width: 700px) {
      position: relative;
    }

    @media (min-width: 821px) {
      margin-left: auto;
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    margin-left: auto;

    @media (max-width: 700px) {
      margin-left: 0px;
    }
  }

  .editScheduleModalFooter {
    display: flex;
    justify-content: space-between;
    width: 100%;

    > button {
      width: 100px;
      font-family: $secondaryFont !important;
    }
  }

  .deleteDaysOptions {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 16px;
    max-width: 100%;
  }

  .deleteDayLabel {
    margin-bottom: 3px;
  }

  .selectDeleteScheduleDates {
    margin: 0px auto 42px;
    display: flex;
    gap: 60px;
  }

  .repeatIndefinitely {
    margin-bottom: 12px;
    gap: 16px;
  }
}

.updateProviderSchedule {
  width: 1100px !important;
  min-width: 1100px !important;
  margin-left: auto !important;
  margin-right: auto !important;

  @media (max-width: 821px) {
    width: 700px !important;
    min-width: 700px !important;
  }

  @media (max-width: 500px) {
    margin-top: 6px !important;
    max-height: 87vh !important;
  }

  .templatesList {
    @media (min-width: 821px) {
      display: flex !important;
    }

    gap: 8px;
    overflow-x: auto;
    padding: 10px 0px;

    .templateDetails {
      padding: 8px 14px;
      border: 1px solid $cGray1;
      border-radius: 20px;
      font-family: $latoFont;
      font-weight: 500;
      text-transform: capitalize;
      align-items: center;
      display: flex;
      cursor: pointer;
      min-width: fit-content;
    }

    .selectedTemplateDetail {
      background-color: $cPrimary;
      color: white;
    }
  }

  .templateSettingsButton {
    text-align: right;
    margin-right: 14px;

    > div {
      top: 16px !important;

      .dropdownMenu {
        left: -156px !important;
      }
    }

    .settingsMenuOption {
      padding: 12px 14px;
      border-bottom: 1px solid $cGray1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 600;
      font-family: $latoFont;
      cursor: pointer;
    }

    .settingsMenuOption:last-child {
      border-bottom: none;
    }
  }
}

.updateManualSchedule {
  @media (max-width: 821px) {
    max-width: 560px !important;
    min-width: 560px !important;
  }
}

.selectedClinicsDropdown,
.selectedTemplatesDropdown {
  font-family: $secondaryFont !important;

  .dropdownMenu {
    max-width: 200px;
    min-width: 200px;
    margin-top: 30px;
  }

  .dropdownContent {
    padding: 0px;
    overflow-x: auto;
    max-height: 50vh;

    > div {
      height: 40px;
      padding: 8px 10px;
      margin-bottom: 0px;
    }

    > div:hover {
      background-color: $cPrimaryLight;
    }

    .copyDayScheduleButton,
    .addTemplateButton {
      font-family: $secondaryFont !important;
      margin: 8px 10px;
      max-width: -webkit-fill-available;
    }
  }

  .menuTitle {
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    margin-bottom: 16px;
  }

  .clinicOption {
    display: flex;
    gap: 8px;
    align-items: center;
    margin-bottom: 8px;
    height: 23px;
    cursor: pointer;
  }

  .templateName {
    padding: 0px 4px;
    font-size: 16px;
    height: 28px;
    cursor: pointer;
  }

  .addNewTemplateButton {
    width: 100%;
    margin: 4px 0px 0px 0px;
  }
}

.selectedTemplatesDropdown {
  .dropdownContent {
    padding: 0px;
    overflow-x: auto;
    max-height: 50vh;

    .templateName {
      padding: 0px 20px;
      border-bottom: 1px solid $cGray1;
      height: 40px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .addTemplateButton {
      margin: 10px;
    }
  }
}

.clinicDropdown {
  left: -83px !important;
}

.stopProviderSchedule {
  font-family: $secondaryFont !important;
  margin-top: 18px;

  .dropdownMenu {
    max-width: 200px;
    min-width: 200px;
  }

  .dropdownContent {
    padding: 8px 10px;
    overflow-x: auto;
    max-height: 50vh;
  }

  .menuTitle {
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    margin-bottom: 16px;
  }

  .clinicOption {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    height: 23px;
  }

  .copyDayScheduleButton {
    margin: 10px 10px 0px 10px;
    margin-top: 16px;
  }

  .datepicker {
    border: 1px solid $cGray1;
    border-radius: 8px;
    padding: 0px 6px;
    margin-bottom: 16px;
    color: #000;
  }

  .calendarIcon {
    position: absolute;
    right: 0px;
    cursor: pointer;
    padding: 0px 6px 3px 5px;
    color: $cPrimary;
  }

  .dateCalendarIcon {
    top: 0px;
    z-index: 0;

    img {
      max-width: 18px;
    }
  }
}

.editScheduleModal {
  width: 600px !important;
  max-width: 600px !important;

  .weekDaysSchedule {
    overflow-x: scroll;
    white-space: nowrap;

    > div {
      width: 524px;
    }
  }

  .deleteScheduleIcon {
    margin: 8px;
    cursor: pointer;
  }

  .scheduleDetails {
    font-weight: 500;
    font-size: 16px;
  }
}

.viewScheduleDetailsModal {
  width: 520px !important;
  max-width: 520px !important;

  .weekDaysSchedule {
    overflow-x: scroll;
    white-space: nowrap;

    > div {
      width: 450px;
    }
  }

  .deleteScheduleIcon {
    margin: 8px;
    cursor: pointer;
  }

  .scheduleDetails {
    font-weight: 500;
    font-size: 16px;
  }
}

.unSelectedDay {
  pointer-events: none;
  filter: opacity(0.5);
}

.viewAllClinicsField {
  select {
    border: 1px solid #cad2d6 !important;
    max-width: 38px !important;
  }
}

.clinicNameField {
  border: 1px solid #cad2d6 !important;

  input {
    max-width: 140px;
  }
}

.verticalBorder {
  display: none;

  @media (min-width: 821px) {
    display: block;
    border: 1px solid $cGray1;
    height: 100%;
    width: 0px;
  }
}

.setCustomHours {
  display: flex;
  flex-direction: column;
  height: 100%;

  @media (min-width: 821px) {
    margin-left: 20px;
  }

  .selectTitle {
    font-size: 15px;
    margin-bottom: 8px;
    color: #666677;
    font-family: $secondaryFont !important;
  }

  .availabilityTitle {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 10px;
  }

  .setCustomHoursButton {
    padding: 10px 14px;
    border: 1px solid #d0d5dd;
    height: 44px;
    background: #fff;
    border-radius: 20px;
  }

  .scheduleOverridesList {
    overflow-y: auto;

    @media (min-width: 821px) {
      flex: 1 1 0;
      flex-basis: 0 !important;
    }
  }

  .dateOverrideSchedule {
    border-bottom: 1px solid #e5e7eb;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-weight: 500;
    font-family: $latoFont;
    font-size: 16px;
  }
}

.overrideScheduleModal {
  max-width: 580px !important;
  width: 580px !important;

  @media (max-width: 500px) {
    overflow: auto;
  }

  .modalContent {
    padding: 0px;
  }

  .titleAndDate {
    padding: 0px 30px;
  }

  .contentTitle {
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 16px;
  }

  .scheduleRow {
    display: flex;
    gap: 8px;
    margin: 0px auto;
    width: 100%;
  }

  .newInputFileldOuter {
    display: flex;
    margin: 0px;
    float: left;
    width: 100%;
    position: relative;
  }

  .inputTimeOption {
    width: 56px !important;
    padding: 0px 15px 0px 5px !important;
    margin-right: 0 !important;
    border-left: 0px !important;
    margin-left: -2px !important;
  }

  .newSelectField {
    width: 56px !important;
    padding-left: 5px !important;
    margin-right: 0 !important;
    border-left: 0 !important;
    margin-left: -2px !important;
    border: 1px solid #cad2d6;
  }

  select {
    border-color: #cad2d6;
  }

  .footer {
    padding-bottom: 20px;
    display: flex;
    justify-content: space-around;

    button {
      height: 38px;
      width: 180px;
    }
  }

  .scheduleTimeFields {
    display: flex;
    gap: 6px;
    align-items: center;
  }

  .scheduleDetails {
    background-color: #fafafa;
    padding: 24px 30px 0px 30px;
    border: 1px solid rgba(26, 26, 26, 0.1);

    @media (max-width: 500px) {
      overflow: auto;
    }

    border-left: 0px;
    border-right: 0px;
    margin: 20px 0px;
  }
}

@media (min-width: 821px) and (max-width: 1660px) {
  .legendContainer {
    position: relative;
  }

  .scheduleLegend {
    position: absolute;
    bottom: 0px;
  }
}

.scheduleLegend {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 2px;

  .dateOverride,
  .regularSchedule {
    display: flex;
    align-items: center;
  }

  .dateOverride {
    font-size: 12px;

    img {
      width: 18px;
    }
  }

  .regularSchedule {
    font-size: 12px;

    img {
      width: 15px;
      margin-top: -4px;
      margin-right: 3px;
    }
  }
}

.noRecordFoundToDelete {
  float: left;
  width: 100%;
  font-size: 13px;
  text-align: center;
  margin-top: 0px;
  padding: 0px;
}

.deleteDayScheduleModal {
  max-width: 750px;
  width: 750px;
}

.stopSchedulesError {
  .tableHeading,
  .tableContent {
    padding: 4px 4px 4px 0px;
  }
}

.mobileViewTabs {
  @media (max-width: 821px) {
    display: block;
  }

  display: none;

  margin-bottom: 12px;
}

.manualSchedulesSection {
  @media (max-width: 821px) {
    display: none;
  }

  display: flex;
}

.displayManualSchedule {
  display: block !important;
}

.templateForm {
  @media (max-width: 821px) {
    display: none !important;
  }
}

.displaytemplateForm {
  display: block !important;
}

.responsiveFooter {
  position: relative;
  min-height: 68px;

  @media (max-width: 821px) {
    .footer {
      position: fixed;
    }
  }
}

.saveButton {
  @media (max-width: 821px) {
    display: none;
  }
}

.saveButtonResponsive {
  display: flex !important;
}

.displayFlex {
  display: flex !important;
}

.templateNameField {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-top: 16px;

  img {
    width: 20px;
  }
}
