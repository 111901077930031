@import "../../styles/theme.scss";

.root {
  border-bottom: 1px solid $cGray3;
  padding-bottom: 14px;
  display: flex;
  flex-direction: column;
  gap: 4px;

  @media screen and (max-width: 480px) {
    gap: 8px;
  }
}

.rootNoPadding {
  padding-bottom: 0;
}

.inner {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
}

.rootNoBorderBottom {
  border-bottom: none;
}

.left {
  display: flex;
  align-items: center;
  gap: 8px;

  @media screen and (max-width: 480px) {
    gap: 4px;
  }
}

.title {
  font-size: 22px;

  @media screen and (max-width: 480px) {
    font-size: 20px;
  }
}
