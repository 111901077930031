.payment-wallet {
  width: 100% !important;
  display: table;

  .take-payment-left {
    width: 70%;
    display: table-cell;
    vertical-align: top;
  }

  .take-payment-right {
    width: 30%;
    background: #f7fbfd;
    display: table-cell;
    border-left: 1px solid #ececec;
    @include border-radius(0px 5px 5px 0px);
    vertical-align: top;
    position: relative;
    padding: 35px 20px;

    .table-updated .table-updated-tr .table-updated-td {
      padding: 5px 10px 5px 15px;
      font-size: 13px;
    }
  }
}

.checkout-total {
  width: 100%;
  text-align: center;
  float: left;
  margin-bottom: 30px;

  .checkout-amount {
    font-size: 30px;
    color: #404040;
    font-weight: bold;
    display: block;
  }

  .amount-tip {
    display: block;
    font-size: 18px;
    font-weight: 300;
    color: 667680;
  }
}

.choose-tip-main {
  width: 330px;
  text-align: center;
  margin: auto;
  display: table;
  margin-bottom: 40px;

  &.tip-ul ul {
    width: auto;
  }

  ul {
    list-style: none;
    display: inline-block;
    @include border-radius(3px);
    border: 1px solid $blue;
    margin: 0px;
    float: left;
    width: 100%;

    li {
      display: inline-block;
      border-right: 1px solid $blue;
      float: left;

      a {
        padding: 10px 15px;
        font-size: 13px;
        display: inline-block;
        color: $blue;

        &.active {
          background: $blue;
          color: #ffffff;
        }
      }

      a:hover {
        color: #ffffff;
      }

      &:last-child {
        border: none;
      }
    }

    li:last-child {
      float: right;
    }

    li:hover {
      background: $blue;
      color: #ffffff !important;
    }
  }

  .customPayment {
    float: right;
    color: $blue;
    font-size: 13px;
    text-decoration: underline;
  }
}

.choose-payment-outer {
  width: 100%;
  margin: auto;
  display: table;
  margin-bottom: 30px;

  .payment-methods {
    width: 110px;
    height: 120px;
    display: inline-block;
    border: 1px solid #ececec;
    cursor: pointer;
    @include border-radius(5px);
    color: #404040;

    p {
      margin-top: 90px;
      font-size: 12px;
      text-transform: uppercase;
      text-align: center;
      display: block;
    }
  }
}

.cash-method {
  background-image: url(/../images/payment-cash.png);
  background-size: auto 36px;
  background-repeat: no-repeat;
  background-position: center 33px;
}

.cash-method.active {
  background-image: url(/../images/payment-cash-hover.png);
  background-color: $blue;
  color: #ffffff;
}

.greensky {
  background-image: url(/../images/greenskyUnselected.png);
  background-size: auto 27px;
  background-repeat: no-repeat;
  background-position: center 33px;
}

.greensky.active {
  background-image: url(/../images/greenskySelected.png);
  background-color: $blue;
  color: #ffffff;
}

.cherry {
  background-image: url(/../images/cherryUnselected.png);
  background-size: auto 27px;
  background-repeat: no-repeat;
  background-position: center 33px;
}

.cherry.active {
  background-image: url(/../images/cherrySelected.png);
  background-color: $blue;
  color: #ffffff;
}

.card-method {
  background-image: url(/../images/payment-card.png);
  background-size: auto 36px;
  background-repeat: no-repeat;
  background-position: center 33px;
}

.card-method.active {
  background-image: url(/../images/payment-card-hover.png);
  background-color: $blue;
  color: #ffffff;
}

.care-credit {
  background-image: url(/../images/payment-careCredit.png);
  background-size: auto 36px;
  background-repeat: no-repeat;
  background-position: center 33px;
}

.care-credit.active {
  background-image: url(/../images/payment-careCredit-hover.png);
  background-color: $blue;
  color: #ffffff;
}

.cheque {
  background-image: url(/../images/bank-check2.png);
  background-size: auto 36px;
  background-repeat: no-repeat;
  background-position: center 33px;
}

.cheque.active {
  background-image: url(/../images/bank-check2-hover.png);
  background-color: $blue;
  color: #ffffff;
}

.wallet-method {
  background-image: url(/../images/payment-wallet.png);
  background-size: auto 36px;
  background-repeat: no-repeat;
  background-position: center 33px;
}

.wallet-method.active {
  background-image: url(/../images/payment-wallet-hover.png);
  background-color: $blue;
  color: #ffffff;
}

.klarna.active {
  background: $blue;
  color: #ffffff;
}

.klarna-modal {
  h4 {
    text-align: center;

    img {
      height: 41px;
      margin: -3px 8px 0 0;
    }
  }

  .setting-input-outer {
    color: #5a5a5a;
  }

  small {
    position: absolute;
    top: 100%;
    color: #e52817;
  }

  .new-blue-btn {
    background-color: #5a5a5a;
    transition: background-color 0.3s ease-in-out;

    &:hover {
      background-color: #6a6a6a;
    }

    &.disabled {
      opacity: 0.2;
      color: white;
    }
  }

  .spinner-load {
    display: block;
    height: 40px;
    margin: 0 auto;
  }

  .small-cross {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    line-height: 10px;
    color: #5a5a5a;
  }

  .custom-klarna-btn {
    background: white;
    border: 4px solid #ddd;
    cursor: pointer;
    padding: 12px 25px 8px;
    display: flex;
    font-size: 14px;
    line-height: 26px;
    margin: 0 auto;
    transition: border-color 0.3s ease-in-out;

    &:hover {
      border-color: #ffc0cb;
    }

    img {
      height: 20px;
      margin: 0 10px 0 0;
    }
  }

  .setting-field-outer {
    margin: 0 auto;
    float: none;
  }

  .setting-input-box {
    border-radius: 8px;
    border: 1px solid #ddd;
    padding: 10px;
    color: #667680;
  }

  .new-field-label {
    font-size: 16px;
  }
}

.payment-methods {
  &.klarna {
    align-content: center;
    align-items: center;

    svg {
      position: absolute;
      top: 30px;
      left: 0;
      right: 0;
      margin: auto;
      fill: #0472b3;
      height: 40px;
      width: 80px;

      rect.cls-1 {
        fill: white;
      }
    }
  }
}

.bottom-text {
  text-align: center;
  margin: 0;

  a {
    color: #ffc0cb;
    text-decoration: underline;
  }

  span {
    color: #5a5a5a;
  }
}

.card-type {
  width: 300px;

  ul {
    li {
      width: 50%;
      float: left;

      a {
        display: block;
        padding: 7px 15px;
      }
    }
  }
}

ul.cardPaymentTypeList {
  li {
    width: 33.33%;
  }

  a {
    white-space: nowrap;
  }
}

.expiry-date input {
  width: 40%;
  display: inline-block;
  outline: none;
  text-align: center;
  color: $blue;

  span {
    width: 5px;
    display: inline-block;
  }
}

.save-card label {
  font-weight: normal;
}

.dollar {
  position: relative;
}

.dollar input {
  padding-left: 12px;
  position: relative;
}

.dollar:after {
  position: absolute;
  width: 22px;
  height: 26px;
  content: "$";
  left: 0px;
  color: #000;
  top: 0px;
  z-index: 10;
}

.percent {
  position: relative;
}

.percent input {
  padding-left: 12px;
  position: relative;
}

.percent:after {
  position: absolute;
  width: 22px;
  height: 26px;
  content: "%";
  left: 0px;
  color: #000;
  top: 0px;
  z-index: 10;
}

.payment-wallet .crossIcon {
  top: 6px;
  right: 12px;
  position: absolute;
  z-index: 1;
}

.invoiceTotal {
  font-size: 15px;
  color: $blue;
}

.current-amount-wallet {
  width: 100%;
  float: left;
  font-size: 12px;
  margin-top: 5px;
}

.couponCodeOuter {
  float: left;
  width: 100%;
  margin-bottom: 15px;
  max-width: 300px;
}

.couponLabel {
  float: left;
  padding: 5px 10px 5px 0px;
  font-size: 13px;
  color: #404040;
}

.couponInput {
  width: 43%;
  border: 1px solid #dddddd;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  float: left;
  margin-right: 0px;
  height: 32px;
  padding: 0px 10px;
}

.couponCodeOuter .new-blue-btn {
  width: auto;
  margin: 0px;
  min-width: auto;
  float: right;
}

.card-type ul li.disable:hover {
  background: none;

  a {
    color: #047ec3;
    cursor: not-allowed;
  }
}

.checkoutInvoice {
  font-size: 18px !important;
}

.Codecoupon {
  .couponLabel {
    display: block;
  }

  .coupon-outer {
    float: left;
    width: 100%;
    padding-right: 100px;
    position: relative;

    input.couponInput {
      color: $blue;
    }

    div.couponInput {
      color: #404040;
      padding-top: 4px;
    }

    .couponInput {
      width: 100%;
      padding: 0px;
      border: none;
      border-bottom: 1px solid #bfc9ce;
      padding-top: 0px;
      color: #404040;
      font-size: 14px;
    }

    .new-blue-btn {
      width: 82px;
      position: absolute;
      right: 0px;
      top: 0px;
    }
  }
}

.paymeny-type-outer {
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  display: flex;
  flex-wrap: inherit;
}

.payment-col {
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}

.giftCardDiscount td {
  padding: 0px 15px 0px 0px !important;
}

.haveGiftCard {
  display: inline-block;
  margin-top: 6px;
  vertical-align: top;
}

.layout-fixed {
  table-layout: fixed;
  width: 500px !important;
}

.charge-disabled {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

.card-readers-modal {
  overflow: auto;
}

.merged-tip-row {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.subtitle-no-float {
  float: none !important;
}

.width-fitcontent {
  width: fit-content;
}

.klarna-modal .selected-flag {
  height: 100% !important;
  margin-top: unset;
  border: 0;
  padding-left: 8px !important;
}

.klarna-modal .form-control {
  width: 100%;
  border-radius: 8px;
  height: 42px;
}

.overflow-visible {
  overflow: visible !important;
}
