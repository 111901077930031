@import "../../../../SalesCheckoutInvoice.variables.scss";

.root {
  font-size: 16px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: $mobileBreakpoint) {
    font-size: 14px;
  }
}

.left {
  display: flex;
  align-items: center;
  gap: 4px;
}
