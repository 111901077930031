@import "../../../styles/theme.scss";

.date {
  color: $cGray1;
  font-size: 13px;
}

.head {
  display: flex;
  gap: 4px;
  align-items: center;
}
