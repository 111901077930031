@import "../../../../styles/theme.scss";

.label {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

.block {
  display: flex;
  gap: 10px;
  margin-bottom: 5px;
  @media screen and (max-width: 445px) {
    flex-direction: column;
  }
}

.confirmBtn {
  height: 48px;
}

.resendBtn {
  color: $cPrimary;
  border: none;
  background: none;
  display: flex;
  align-items: center;
  &:hover:not(.resendBtnDisabled) {
    color: $cPrimaryDark;
    text-decoration: underline;
  }
}

.resendBtnDisabled {
  opacity: 0.7;
  cursor: default;
  user-select: none;
  pointer-events: none;
}

.resendBtnLoader {
  margin-right: 5px;
}
