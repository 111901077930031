.root {
  display: grid;
  grid-template-columns: 1fr 525px;
  gap: 26px;

  @media screen and (max-width: 1200px) {
    display: flex;
    flex-direction: column;
  }
}
