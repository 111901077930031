@import "../../../../styles/theme.scss";

.root {
  display: flex;
  gap: 8px;
  width: 100%;
  flex-wrap: wrap;

  button {
    background: none;
    outline: none;
    border: 1px solid $cGray2;
    border-radius: 8px;
    padding: 20px 25px;
    text-align: start;
    font-weight: bold;

    &.selected {
      border-color: $cGreen;
      background-color: $cGreenDim;
      color: $cGreen;
    }
  }
}
