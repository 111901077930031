.settingsBlock {
  padding-top: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 15px;
}

.clientSetting {
  display: block;
  clear: both;
  margin-bottom: 8px;

  > span {
    display: inline-block;
    padding: 3px 0;
  }
}

.title {
  text-transform: uppercase;
  font-weight: 700;
}

.patientSettingSection {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
  font-family: "Lato", sans-serif;
}
