// File for Theme and constants

// types : color

$color-primary: #188bf6;
$color-black: #000000;
$color-border-gray: #b5b5b5;
$color-secondary-btn: #afb8c2;
$color-editable-bg: #ededed;

$color-primary-button: rgba(24, 139, 246, 0.15);
