.root {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 500px;

  @media screen and (max-width: 640px) {
    width: 100%;
  }
}

.inner {
  display: flex;
  gap: 4px;
  align-items: center;
  flex-wrap: wrap;
}

.range {
  width: 60px;

  @media screen and (max-width: 640px) {
    flex: 1;
  }
}

.name {
  flex: 1;
}

.actions {
  display: flex;
  gap: 4px;

  & > * {
    width: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 540px) {
    flex: 1;

    & > * {
      flex: 1;
    }
  }
}
