.onHoldContainer {
  position: relative;
  height: min-content;
}

.obliqueLineWrap {
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border-radius: 50%;
  border: 2px solid grey;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: content-box;
  overflow: hidden;
}

.obliqueLine {
  position: absolute;
  width: 2px;
  background-color: grey;
  transform: rotate(45deg);
  top: 0;
  bottom: 0;
}
