.root {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 0 10px 0;
}

.empty {
  padding: 20px 30px;

  @media screen and (max-width: 520px) {
    padding: 20px;
  }
}
