.card-exp-date{
  width: 118px;
  float: left;
}
.text-left.data-usage{
  .dash-box{
    box-shadow: none;
  }
  .sales-goal-legend{
    height: auto;
    position: absolute;
    right: -19px;
    top: 70px;
    width:174px;


    .legend-group{width:100%;
      .legend-dot {
          width: 12px;
          height: 12px;
          position: absolute;
          left:0px;
          top: 2px;
          border-radius: 20px;
          background: #047ec3;
      }
      .legend-price{
        font-size: 18px;
        color: #404040;
        font-family: 'Lato', sans-serif;
        font-weight: normal;
        line-height: 14px;
      }
      .legend-clinic{
          font-size: 14px;
          color: #667680;
          display: block;
          margin-top: 10px;
          font-weight: normal;
          font-family: 'Lato', sans-serif;
          margin-bottom: 20px;
      }
    }
  }
}
.billing-stats {
    text-align: center;
}
.billing-stats .portal-numbers {
    margin-top: 0px;
    font-size: 30px;
}
.data-usage .dash-box{padding-left:0px!important;margin-left:-30px!important;}
.data-usage .dash-box-content{margin:0px!important;}
.data-usage .sales-date-price{left:62px!important;top: 100px;}
.data-usage .sale-goal-date{display: block;font-size: 18px;color:#667680;}
.data-usage .sale-goal-price{display: block;margin-top: 0px;font-size: 14px;color:#667680;}
.accordian-section svg{color:$blue!important;margin-right:5px;}
.data-usage .highcharts-container{margin-left: -14px!important;}
.popup-accordian-section-head {
    padding: 13px;
    background: #f0f1f2;
    font-weight: 600;
    color: #404040;
    font-size: 14px;
    width:100%;
    display:table;
}
.subscription-popup{width:740px;}
.accordion-table .plan-estimate-block{display: table-row-group;}
.autoRefill{
  .graphHeading{font-weight:normal;}
  .table-responsive td{color:#000000;padding:13px 10px 13px 15px;
      select{
          background:none;
          border:none;
          color:$blue;
          background-image:url(/../images/new-down-arrow.png);
          background-repeat:no-repeat;
          background-position:right 9px;
          padding-right:12px;
          &:disabled{color:#667680;}
      }
      .setting-switch{float:right;}
  }
}
.clinicHeading .text-ellipsis{margin:0px;}
.resourceGraphTitle{position:relative;z-index:1;}
.table-head-color{background-color:#daedf7;}
.new-loader{
  .loader-outer.m-t-10-percent{margin-top: 10% !important;}
}
.newInputField.setting-input-box-disabled{opacity: 0.8 !important}
.feature-not-available{
    font-size: 17px;
    border: none;
    padding: 20px;
    background: #e6f2f8;
    @include border-radius(5px);
    margin-bottom:15px;
    border-left:5px solid #047ec3;

    p{margin:0px;}
  }
.popp-up-upgrade-block{float:left;width:100%;}
.ContactYourAdmin{float:left;width:100%;text-align:right;font-size:17px;font-weight:bold;}

.planPriceSection{
  min-height:343px;
  display:block;
  text-align:center;
  padding:40px 20px 20px;
  overflow:hidden;

  &:after{
    height:5px;
    widtH:100%;
    position:absolute;
    left:0px;
    top:0px;
    content:'';
    background-color:$blue;
    background-image: -webkit-linear-gradient(to left, #047ec3, #32a8eb);
    background-image:    -moz-linear-gradient(to left, #047ec3, #32a8eb);
    background-image:     -ms-linear-gradient(to left, #047ec3, #32a8eb);
    background-image:      -o-linear-gradient(to left, #047ec3, #32a8eb);
    background-image:         linear-gradient(to left, #047ec3, #32a8eb);
    @include border-radius(5px 5px 0px 0px);
  }

  h2{
    color: #000000;
    display: block;
    font-weight: bold;
    font-size: 22px;
    margin-bottom:15px;
  }
  p{
    font-size:14px;
    line-height:17px;
    display:block;
    color: #667680;
    min-height:68px;
    float:left;
  }

  .priceLabel{
    display: block;
    margin-top: 51px;
    min-height: 58px;
    margin-bottom:37px;
    float:left;
    width:100%;

    h2{margin:0px; font-size:27px;
      span{font-size:18px;}
    }
    /*&.priceLabel-lowMargin{
      margin-top: 25px;
      margin-bottom: 0px;
    }*/
  }

  a{
    padding:8px 20px;
    height: 40px;
    min-width:170px;

    svg{margin-right:5px;position:static;}
  }
}
.featuresSection{
  padding:20px;

  h4{
    color: #000000;
    display: block;
    font-size:18px;
    margin-bottom:20px;
  }
  .planFeatures{
    list-style:none;
    li{
      display:block;
      position:relative;
      padding:6px 20px 6px 30px;
      margin-bottom:5px;
      @include border-radius(3px);

      svg.fa-caret-right{position:absolute;right:9px;top:9px;color:$blue;}
      svg.fa-caret-down{position:absolute;right:9px;top:9px;color:$blue;}
      svg.fa-check{position:absolute;left:9px;top:9px;color:#34AD51;}
      svg.fa-times{position:absolute;left:9px;top:7px;color:#D02A33;font-size:17px;}
      &:nth-child(odd){background:#EDF8FF;}
      &:nth-child(even){background:#f9fcfe;}

      ul{margin-top:10px; li{padding:0px;padding:0px 10px 0px;}}
    }
  }
}

.cursor-default{cursor: default !important;}
.cursor-auto{cursor: auto !important;}
.featureNote p{font-size:14px;}
.featureNoteTitle{font-size:16px;font-weight:bold;}
.Accelerator{background:#EDF8FF;
  .planFeatures{
      min-height: 624px;
      li:nth-child(even){background:#ffffff;cursor:pointer;}
      li:nth-child(odd){background:#ffffff;cursor:pointer;}
    }
}
.monthlyYearlySubscription{width:100%;float:left;}
.planTypeOuter{border:1px solid $blue;display:block;padding:8px;@include border-radius(3px);cursor:pointer;min-height: 142px;
  h6{display:block;margin-bottom:10px;color:#000000;margin-bottom:6px;}
  .dollarSubscription{display:block;text-align:left;width:100%;padding-left:33px;position:relative;
    .radio-outer{position: absolute;left: 4px;top: 0px;
      input{border-color:$blue;margin-top:8px;}
    }
    .yearPrice{display:block;font-size:21px;font-weight:bold;color:#000000;
      span{font-size:18px;}
      label{font-size:14px;font-weight:normal;display: block;margin-top: -5px;margin-bottom:0px;}
    }
    .monthPrice{
      color:$blue;font-size:16px;
    }
    img{position:absolute;right:-6px;top:9px;height:44px;}
  }
}
.upgradePlanBtn{background:#48b564;border-color:#48b564;color:#ffffff;}
.discountBadget{
  background-image: url(/../images/discount_badget.png);
background-size: cover;
background-repeat: no-repeat;
background-position: center;
position: absolute;
right: -6px;
top: 10px;
height: 44px;
width: 44px;
text-align: center;
color: #fff;
line-height: 14px;
font-size: 12px;
padding-top: 8px;
}
.paidAnnually{
  font-weight: normal;
  font-size: 13px;
  font-style: italic;
  display: block;
}
.singleSubscription .dollarSubscription{
  text-align:center;
  padding-left:22px;
}
.singleSubscription .planTypeOuter{
  min-height: 122px;
}

.limits {
  color: #dddddd;
  user-select: none;
}

.dataPackageSelect:disabled {
  color: #047ec3 !important;
  background-image: none !important;
  opacity: 1;
}