.root {
  background: none;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  gap: 6px;

  &:active {
    outline: none;
  }
}

.icon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 3px;
}

.up {
  opacity: 0.5;
}

.down {
  opacity: 0.5;
  transform: rotate(180deg);
}

.active {
  opacity: 1;
}
