@import "../../styles/theme.scss";

.root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.messages {
  flex: 1;
  overflow-y: scroll;
}

.dateDivider {
  padding: 10px;
  text-align: center;
  color: $cGray2;
}
