ul.appointment-config-link {
  background-image: url(/../images/step-line.png);
  background-repeat: repeat-y;
  background-position: left 10px top 10px;
  background-size: 5px auto;
  margin-top: 22px;

  li {
    a {
      position: relative;
      text-transform: uppercase;
      font-size: 12px;
      padding: 22px 0px 22px 36px;
      color: #404040;

      &.unselect:after {
        content: "";
        font-size: 27px !important;
        position: absolute;
        left: 0px;
        top: 16px;
        width: 25px;
        height: 25px;
        cursor: pointer;
        background: #ffffff;
        border: 2px solid #707070;
        @include border-radius(50px);
      }

      &.select {
        color: $blue;

        &:after {
          content: "";
          font-size: 27px !important;
          position: absolute;
          left: 0px;
          top: 16px;
          width: 25px;
          height: 25px;
          cursor: pointer;
          background: $blue;
          border: 2px solid $blue;
          @include border-radius(50px);
        }
      }

      &.complete {
        color: #bfc9ce;

        &:after {
          content: "";
          font-size: 27px !important;
          position: absolute;
          left: 0px;
          top: 16px;
          width: 25px;
          height: 25px;
          cursor: pointer;
          border: 2px solid #bfc9ce;
          @include border-radius(50px);
          background: url(/../images/step-check.png) no-repeat center #bfc9ce;
          background-size: 17px auto;
        }
      }
    }

    &:first-child a {
      padding-top: 0px;

      &:after {
        top: -8px !important;
      }
    }

    &:last-child a {
      padding-bottom: 0px;

      &:after {
        top: 16px !important;
      }
    }
  }
}

.appointment-container {
  padding: 20px;
  float: left;
  width: 100%;
}

.appointmenTable {
  th {
    padding: 10px 10px 10px 15px !important;
  }

  th,
  td {
    border-left: none !important;

    i {
      position: initial;
      margin-top: 0px;
      margin-top: 0px !important;
      display: inline-block;
      vertical-align: top;
    }
  }
}

ul.appointment-tabs {
  list-style: none;
  display: block;
  float: left;
  margin: 10px 0px 20px;
  width: 100%;

  li {
    display: inline-block;

    a {
      font-size: 15px;
      color: #667680;
      display: block;
      padding: 0px 20px 15px;
      border-bottom: 2px solid #ffffff;

      &.active {
        border-bottom: 2px solid $blue;
        color: $blue;
      }
    }
  }
}

.table-tabs {
  padding-left: 15px;
  padding-right: 15px;
}

ul.tabWithSwitch {
  li {
    width: auto;
    display: inline-block;

    a {
      padding: 0px 0px 15px 9px;
      font-size: 15px;

      .setting-switch {
        visibility: hidden;
        margin-left: 10px;
      }

      &.active {
        .setting-switch {
          visibility: visible;
        }
      }
    }
  }
}

.newInputFileldOuter {
  float: left;
  width: 100%;
  position: relative;
  margin-bottom: 10px;
  margin-top: 15px;

  .newInputLabel {
    float: left;
    font-size: 12px;
    color: $blue;
    background: #ffffff;
    margin: 0px 11px -8px;
    position: absolute;
    padding: 0px 4px;
    top: -9px;

    .fieldRequired {
      color: $blue;
    }
  }

  .newInputField {
    width: 100%;
    float: left;
    color: #404040;
    border: 1px solid #cad2d6;
    @include border-radius(3px);
    height: 33px;
    padding: 0px 15px;
  }

  .newSelectField {
    width: 100%;
    float: left;
    color: #404040;
    border: 1px solid #cad2d6;
    @include border-radius(3px);
    background-image: url(/../images/select-arrow2.png);
    background-repeat: no-repeat;
    background-position: right 8px center;
    background-size: 10px auto;
    background-color: #ffffff;
    height: 33px;
    padding: 0px 15px;
  }

  .newtextareaField {
    width: 100%;
    float: left;
    color: #404040;
    border: 1px solid #cad2d6;
    @include border-radius(3px);
    height: 150px;
    padding: 10px 15px;
  }
}

.AppointmentSubtitle {
  font-size: 15px;
  float: left;
  width: 100%;
  margin: 15px 0px 30px;

  .setting-switch {
    float: none;
    display: inline-block;
    vertical-align: top;
    margin: 0px 5px;
  }
}

.snippets {
  float: left;
  width: 100%;

  p {
    float: left;
    width: 100%;
    margin-bottom: 5px;
  }

  .addAppointTime {
    color: #404040;
  }
}

.appointment-content {
  float: left;
  width: 100%;
  min-height: 255px;
}

.sm-switch {
  height: 19px;
  width: 39px;
  margin-top: 2px !important;

  .setting-slider:before {
    height: 14px;
    width: 14px;
  }
}

.editField {
  position: absolute;
  right: -31px;
  top: 6px;

  img {
    height: 23px;
  }
}

.appTimeSetting {
  position: relative;
  display: inline-block;
  clear: both;
  margin-bottom: 30px;

  .editField {
    top: 0px;
  }
}

.easy-link.select {
  position: absolute;
  top: 9px;
  right: 10px;
  padding: 0px;
  background: #ffffff;
  padding-left: 10px;
}

.business-hours-outer.equipment-hours {
  margin-top: 0px !important;

  .datetimeparent {
    border-bottom: 1px solid #cad2d6;
    float: left;
    width: 100%;
    padding-top: 23px;
  }

  .setting-input-box {
    color: #404040;
    border: 1px solid #cad2d6;
    @include border-radius(3px);
    height: 33px !important;
    padding: 0px 15px;
    width: 88px !important;
    margin-right: 10px !important;
    margin-bottom: 10px !important;
  }

  .newSelectField {
    height: 33px !important;
    margin-bottom: 10px !important;
  }

  .hours-pm {
    width: 56px !important;
    padding-left: 5px;
    margin-bottom: 10px !important;
  }
}

.equip-field-outer {
  width: auto;
  margin: -10px 0px 0px;
  padding-left: 0px;
}

.edit-list-category {
  padding-bottom: 20px;
  border-bottom: 1px solid #ddd !important;
  margin-bottom: 20px;
}

.appointHelp {
  float: left;
}

.appointEnableDisable {
  float: right;
}

.appointment-container .row-service-type-virtual {
  .tag-auto-select {
    padding-top: 0px !important;
    min-height: 29px !important;
  }

  .field_error {
    margin-top: 0px !important;
  }
}

.appointmentIndex {
  color: #404040;
}

.prevEmailTemplate {
  .confirmation-modal-wrapper {
    clear: both;
    width: 100%;
  }

  .mce-tinymce iframe {
    height: 500px !important;
  }
}

.covidCommunication .covidColOuter {
  float: left;
  width: 100%;

  .easy-link {
    padding: 1px 10px;
  }
}

.previewEditPopup {
  .es-wrapper-color > table {
    width: 100% !important;
  }
}

.editor-with-preview.editor_textarea.field_error {
  border: none !important;

  .mce-tinymce {
    border: 1px solid #e74c3c !important;
  }
}

.custom-flag {
  display: flex !important;
}

.custom-button-style {
  background-color: #ffffff !important;
  border-style: none !important;
}
