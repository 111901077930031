@import "../../../../../../../../styles/theme.scss";

.root {
  padding: 0 20px;
}

.title {
  font-size: 16px;
  color: $cBlack;
  margin-bottom: 2px;
}
