.header {
  padding: 40px 0px 0px;
  display: table;
  width: 100%;

  .logo {
    float: left;

    img {
      margin-top: 0px;
      margin-bottom: 0px;
      height: 50px;
    }
  }
}

.header-right {
  float: right;
}

.nav {
  display: inline-block;
  float: right;
  list-style: none;
  margin-top: 10px;

  li {
    display: inline-block;
    margin-left: 6px;

    a {
      display: block;
      font-size: 16px;
      color: #999;
      margin: 0px 4px;
      padding-bottom: 4px;
    }

    a:hover {
      border-bottom: 2px solid #fff;
      color: #0075c0;
      background: none;
    }

    a.login {
      border: 2px solid #999;
      border-radius: 30px;
      padding: 7px 18px;
      background: none;
    }

    a.login:hover {
      color: #0075c0;
      border-color: #0075c0;
    }
  }
}
