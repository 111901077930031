@import "../../styles/theme.scss";

.root {
  position: relative;
}

.list {
  position: absolute;
  left: 0;
  right: 0;
  max-height: 300px;
  overflow-y: scroll;
  background-color: $cWhite;
  z-index: 10;
  border-radius: 8px;
  border: 1px solid $cGray2;

  button {
    border: none;
    background: none;
    text-align: left;
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;

    &:hover {
      background-color: $cPrimaryLight;
    }
  }
}
