.row {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  width: 100%;

  & > div {
    flex: 1;
    min-width: 230px;
  }
}
