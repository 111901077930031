.root {
  overflow-x: scroll;
}

.colMin {
  min-width: 135px;

  @media screen and (max-width: 991px) {
    width: 146px;
    min-width: 146px;
  }
}
