@import "../../../../../../../styles/variables.scss";
@import "../../../../../../../styles/theme.scss";

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: grab;
  padding: 10px 15px;
  border-radius: 8px;
  border: 1px solid $cGray3;
  background-color: $cPrimaryDim3;
  gap: 16px;
  z-index: $zIndexMax;

  & * {
    user-select: none;
  }
}

.dragIcon {
  margin-right: 6px;
}

.left {
  display: flex;
  gap: 8px;
  align-items: center;
  flex: 1;
}

.input {
  width: 100%;
  border: none;
  background-color: transparent;
}
