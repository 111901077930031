@import "../../../../styles/theme.scss";
@import "./variables.scss";

.root {
  background-color: $cPrimaryDim;
  color: $cPrimary;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 16px;
  border-radius: 4px;
}

.location {
  width: $locationWidth;
  @media screen and (max-width: $tabletBreakpoint) {
    width: $locationWidthTablet;
  }
  @media screen and (max-width: $mobileBreakpoint) {
    width: auto;
  }
}
