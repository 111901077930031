@import "../../styles/theme.scss";

$gray: lighten($cGray0Dark, 10%);

.root {
  border-radius: 4px;
  border: none;
  background: none;
  outline: none;
  margin: 0;
  padding: 0;
  transition: all 0.15s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;

  &:focus {
    outline: none;
  }
}

.content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Variant */

.vText {
  &.cPrimary {
    .content,
    .leftAdornment,
    .rightAdornment {
      color: $cPrimary !important;
    }

    &:hover,
    &.hovered {
      background-color: $cPrimaryDim !important;
    }
  }

  &.cError {
    .content,
    .leftAdornment,
    .rightAdornment {
      color: $cRed !important;
    }

    &:hover,
    &.hovered {
      background-color: $cRedDim !important;
    }
  }

  &.cWarn {
    .content {
      color: $cOrange !important;
    }

    &:hover,
    &.hovered {
      background-color: $cOrangeDim !important;
    }
  }
  &.cSuccess {
    .content,
    .leftAdornment,
    .rightAdornment {
      color: $cGreen !important;
    }

    &:hover,
    &.hovered {
      background-color: $cGreenDark !important;
    }
  }

  &.cWarn {
    .content,
    .leftAdornment,
    .rightAdornment {
      color: $cOrange !important;
    }

    &:hover,
    &.hovered {
      background-color: $cOrangeDim !important;
    }
  }

  &.cGray {
    .content,
    .leftAdornment,
    .rightAdornment {
      color: $gray !important;
    }

    &:hover,
    &.hovered {
      background-color: $cGray0Dark !important;

      & * {
        color: $cWhite !important;
      }
    }
  }

  &.cOrangeDark {
    .content,
    .leftAdornment,
    .rightAdornment {
      color: $cOrangeDark !important;
    }

    &:hover,
    &.hovered {
      background-color: lighten($cOrangeDark, 2%) !important;
    }
  }
}

.vOutlined {
  &.cPrimary {
    .content,
    .leftAdornment,
    .rightAdornment {
      color: $cPrimary !important;
    }

    border: 1px solid $cPrimary !important;

    &:hover,
    &.hovered {
      background-color: lighten($cPrimaryDim, 4%) !important;
    }
  }

  &.cError {
    .content,
    .leftAdornment,
    .rightAdornment {
      color: $cRed !important;
    }

    border: 1px solid $cRed !important;

    &:hover,
    &.hovered {
      background-color: lighten($cRedDim, 2%) !important;
    }
  }

  &.cSuccess {
    .content,
    .leftAdornment,
    .rightAdornment {
      color: $cGreen !important;
    }

    border: 1px solid $cGreen !important;

    &:hover,
    &.hovered {
      background-color: lighten($cGreenDim, 2%) !important;
    }
  }

  &.cWarn {
    .content,
    .leftAdornment,
    .rightAdornment {
      color: $cOrange !important;
    }

    border: 1px solid $cOrange !important;

    &:hover,
    &.hovered {
      background-color: lighten($cOrangeDim, 2%) !important;
    }
  }

  &.cGray {
    .content,
    .leftAdornment,
    .rightAdornment {
      color: $gray !important;
    }

    border: 1px solid $gray !important;

    &:hover,
    &.hovered {
      background-color: $cGray4 !important;
    }
  }

  &.cWarn {
    .content {
      color: $cOrange !important;
    }

    border: 1px solid $cOrange !important;

    &:hover,
    &.hovered {
      .content {
        color: $cOrangeDark !important;
      }

      border-color: $cOrangeDark !important;
    }
  }
  &.cOrangeDark {
    .content {
      color: $cOrangeDark !important;
    }

    border: 1px solid $cOrangeDark !important;

    &:hover,
    &.hovered {
      .content {
        color: lighten($cOrangeDark, 2%) !important;
      }

      border-color: lighten($cOrangeDark, 2%) !important;
    }
  }
}

.vContained {
  .content,
  .leftAdornment,
  .rightAdornment {
    color: $cWhite !important;
  }

  &.cPrimary {
    background-color: $cPrimary !important;

    &:hover,
    &.hovered {
      background-color: $cPrimaryDark !important;
    }
  }

  &.cError {
    background-color: $cRed !important;

    &:hover,
    &.hovered {
      background-color: $cRedDark !important;
    }
  }

  &.cWarn {
    background-color: $cOrange !important;

    &:hover,
    &.hovered {
      background-color: $cOrangeDark !important;
    }
  }
  &.cSuccess {
    background-color: $cGreen !important;

    &:hover,
    &.hovered {
      background-color: $cGreenDark !important;
    }
  }

  &.cWarn {
    background-color: $cOrange !important;

    &:hover,
    &.hovered {
      background-color: $cOrangeDark !important;
    }
  }

  &.cGray {
    background-color: $gray !important;

    &:hover,
    &.hovered {
      background-color: $cGray0Dark !important;
    }
  }

  &.cOrangeDark {
    background-color: $cOrangeDark !important;

    &:hover,
    &.hovered {
      background-color: lighten($cOrangeDark, 2%) !important;
    }
  }
}

/* Size */

.sTiny {
  font-size: 13px;
  font-weight: 300;
  height: 30px;
  padding: 0 5px;

  .leftAdornment {
    margin-right: 4px;
  }

  .rightAdornment {
    margin-left: 4px;
  }
}

.sSmall {
  font-size: 15px;
  font-weight: 300;
  height: 36px;
  padding: 0 10px;

  .leftAdornment {
    margin-right: 5px;
  }

  .rightAdornment {
    margin-left: 5px;
  }
}

.sDefault {
  font-size: 16px;
  font-weight: 500;
  height: 44px;
  padding: 0 30px;

  .leftAdornment {
    margin-right: 10px;
  }

  .rightAdornment {
    margin-left: 10px;
  }
}

.sBig {
  font-size: 17px;
  font-weight: 500;
  height: 50px;
  padding: 0 40px;
  border-radius: 6px;

  .leftAdornment {
    margin-right: 10px;
  }

  .rightAdornment {
    margin-left: 10px;
  }
}

/* Font */

.fontPrimary {
  font-family: $primaryFont;
}

.fontSecondary {
  font-family: $secondaryFont;
}

/* Misc */

.disabled {
  opacity: 0.6;
  cursor: default;
  pointer-events: none;
  user-select: none;
}

.leftAdornmentBase {
  height: 100%;
  display: flex;
  align-items: center;
}

.rightAdornmentBase {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.fullWidth {
  width: 100%;
}

.widthFit {
  width: fit-content;
}
