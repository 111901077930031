@import "../../../../../../../styles/theme.scss";

.root {
  width: 100%;
  border-collapse: separate;
  border-spacing: 5px;

  thead {
    height: 35px;

    th {
      font-size: 16px;
      font-weight: 600;
      color: $cGray1;
    }
  }
}

.topSpace {
  margin-top: 10px;
}

.main {
  display: grid;
  gap: 10px;
}

.option {
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 16px;
  font-weight: 400;
}

.imgWrap {
  width: 56px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.amount {
  font-size: 16px;
  font-weight: 400;
}
