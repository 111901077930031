.rdp {
  --rdp-accent-color: #216ba5 !important;
}

.rdp-day:hover:not([disabled]):not(.rdp-day_selected) {
  background-color: #188bf6 !important;
}

.rdp-caption {
  position: relative;
}

.rdp-caption_label {
  position: absolute !important;
  left: 50%;
  transform: translate(-50%);
}

.rdp-nav {
  width: 280px;
  display: flex;
  justify-content: space-between;
}

.rdp-months {
  justify-content: center;
}
