@import "../../../../styles/theme.scss";

.root {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  float: left;
  padding: 15px 20px;
  margin-bottom: 10px;
  background-color: #fff;
  width: 100%;
  border-radius: 5px;
}

.header {
  font-size: 16px;
}

.reports {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  gap: 8px;
}

.reportsNoPadding {
  padding-top: 0;
}

.report {
  border: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 10px;
  border-bottom: 1px solid $cGray2;

  .link {
    display: none;
    font-size: 12px;
  }

  &:hover {
    background-color: $cPrimaryDim3;
    border-bottom: 1px solid $cPrimary;
    color: $cPrimary;

    .link {
      display: block;
    }
  }
}
