@import "../../../../../styles/theme.scss";

.root {
  border: none;
  background: none;
  border: 1px dashed $cGray0;
  border-radius: 8px;
  display: flex;
  gap: 8px;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 15px;
  color: $cGray0;
  font-weight: 300;
  width: 100%;

  &:hover {
    border-color: $cPrimary;
    color: $cPrimary;
  }
}

.rootSmall {
  padding: 10px;
  border-radius: 4px;
}
