.right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
}

.collapseArrow {
  transform: rotate(-90deg);
}

.collapseArrowActive {
  transform: rotate(90deg);
}

.title {
  display: flex;
  align-items: center;
  gap: 4px;
}

.collapseButton {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
