.root {
  width: unset !important;
  float: unset !important;
  margin-top: 20px;
  display: flex;
  margin-bottom: 0 !important;
}

.titleWrap {
  padding: 0 0 15px 0 !important;
}

.numberToBuy {
  padding: 0;
  border-bottom: none;
}

.numberToBuySubtitle {
  padding: 0;
}

.numberToBuyFooter {
  padding: 0;
  border-top: none;
  button {
    margin: 0 !important;
  }
}
