@import "../../../../styles/theme.scss";
@import "./variables.scss";

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.label {
  @media screen and (max-width: $mobileXsBreakpoint) {
    font-size: 12px !important;
  }
}

.location {
  color: $cGray1;
  font-size: 14px;
  width: $locationWidth;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  @media screen and (max-width: $tabletBreakpoint) {
    width: $locationWidthTablet;
  }
  @media screen and (max-width: $mobileBreakpoint) {
    width: auto;
  }
  @media screen and (max-width: $mobileXsBreakpoint) {
    font-size: 11px;
  }
}
