@import "../../../styles/variables.scss";
@import "../../../styles/theme.scss";

.root {
  background-color: $cWhite;
  border-radius: 8px;
  top: 5%;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 400px;
}

.rootSmall {
  max-width: 400px;
  width: 400px;

  @media screen and (max-width: 450px) {
    max-width: 90%;
    width: 90%;
  }
}

.rootDefault {
  max-width: 700px;
  width: 700px;

  @media screen and (max-width: 800px) {
    max-width: 90%;
    width: 90%;
  }
}

.rootLarge {
  max-width: 1000px;
  width: 1000px;

  @media screen and (max-width: 1024px) {
    max-width: 90%;
    width: 90%;
  }
}

.overlay {
  z-index: $zIndexModal;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: self-start;
}

.closeBtn {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  background: none;
  z-index: 10;
  background-color: $cWhite;
  border-radius: 8px;
  transition: all 0.2s ease;
  padding: 10px 15px;

  img {
    opacity: 0.6;
  }

  &:hover {
    img {
      opacity: 1;
    }
  }
}

.header,
.content,
.footer {
  padding: 20px 30px;

  @media screen and (max-width: 520px) {
    padding: 20px;
  }
}

.content {
  flex: 1;
}

.header {
  border-bottom: 1px solid $cGray2;
}

.footer {
  border-top: 1px solid $cGray2;
}

.contentNoTopPadding {
  padding-top: 0;
}

.contentNoBottomPadding {
  padding-bottom: 0;
}

.headerNoBorder {
  border-bottom: none;
}

.footerNoBorder {
  border-top: none;
}
