@import "../../../../../../styles/theme.scss";

.root {
  font-size: 20px;
  color: $cBlack;
  display: flex;
  align-items: center;
  gap: 8px;
  width: fit-content;

  svg {
    width: 20px;
  }

  &:hover {
    color: $cPrimary;
  }
}
