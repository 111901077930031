.discountTableUpdatedThead {
  background: #d8d8d8 !important;

  .discountTableUpdatedTh {
    border-left: none !important;
    color: #000000 !important;
    font-weight: 500 !important;
  }

  .classNotWorked {
    color: #3b3633;
  }
}
