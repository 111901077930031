@import "../../../styles/theme.scss";

.root {
  padding: 3px;
  border-radius: 50%;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.rootSuccess {
  &:hover {
    background-color: $cGreenDim;
  }
}

.rootFailure {
  &:hover {
    background-color: $cRedDim;
  }
}

.isDisabled {
  cursor: not-allowed;
  pointer-events: none;
}
