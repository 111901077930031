@import "../../../../styles/theme.scss";

.form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.signatureButtonsRight {
  gap: 24px !important;
}
