@import "../../styles/theme.scss";

.root {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.footer {
  display: flex;
  gap: 16px;
}

.block {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 15px;
  border-radius: 8px;
  background-color: $cGray5;
}

.name {
  b {
    margin-right: 4px;
  }
}
