.root {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.boxTitle {
  font-size: 19px;
}

.box {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
}

.toggleWrap {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-end;
}
