.memberWalletOuter {
  height: auto;
  min-height: inherit;
  width: 81%;
  float: right;

  .setting-setion {
    width: 100%;
    min-height: inherit;
  }
}
.membership-title {
  font-size: 20px;
  color: #404040;
  width: 100%;
  float: left;
  padding: 15px 20px;
}
.membershipSectionTitle {
  float: left;
  width: 100%;
  padding: 15px;
  font-size: 13px;
  color: #404040;
  border-bottom: 1px solid #dddddd;
}
.background-white-outer {
  background: #fff;
  border-radius: 5px;
}
.membershipSectionContent {
  float: left;
  width: 100%;
  padding: 15px;
  font-size: 13px;
  color: #404040;
}
.memberPoint {
  width: auto;
  float: left;
  position: relative;
  padding-left: 20px;

  svg {
    color: $blue;
    position: absolute;
    left: 0px;
    top: 3px;
  }
}
.memberProgram-right {
  padding-left: 0px;
}
.membership-type-table {
  width: 100%;
  td {
    padding: 5px 0px;
  }
}
.noShadow {
  box-shadow: inherit !important;
}
.memberDiscount {
  input {
    width: 70% !important;
  }
}
.memberprice {
  .TP-discount-outer {
    position: relative;
    max-width: inherit;
  }
  input {
    width: 100% !important;
  }
  .TP-discount-type {
    position: absolute;
    right: 3px;
    top: 3px;
  }
}
.membershipTypeTitle {
  font-weight: normal !important;
  width: 100%;
  float: left;
  color: #444444;
  font-size: 20px;
  margin-bottom: 25px;
  position: relative;
  padding-right: 20px;

  .popupClose {
    float: right;
    position: absolute;
    right: 0px;
    top: 0px;
    background: none;
    border: none;
  }
  .popupClose2 {
    float: right;
    position: absolute;
    right: 0px;
    top: -13px;
  }
}
.units {
  float: left;
  height: 30px;
  border: 1px solid #dddddd;
  @include border-radius(3px);
  width: 60px;
  padding: 0px 10px;
}
.search_product_container {
  position: relative;
}

.appointmenTable {
  th.p-l-25 {
    padding-left: 25px !important;
  }
  .td-membership-product-delete {
    padding-right: 14px !important;
    text-align: right;
  }
}
.p-r-24 {
  padding-right: 24px !important;
}

.memberRightActions {
  float: right;
}
.memberRightActions .easy-link {
  padding: 8px 9px 5px !important;
}
.not-create-report {
  float: left;
  width: 100%;
  text-align: center;
  margin-top: 20px;
  h3 {
    font-size: 20px;
  }
}
.question-name-report {
  font-size: 14px;
  font-weight: bold;
  float: left;
  width: 100%;
  margin-bottom: 15px;
}
.report-ans-outer {
  position: relative;
  padding-left: 25px;
  float: left;
  width: 100%;
  margin-bottom: 10px;
  .basic-form-checkbox {
    position: absolute;
    left: 0px;
    top: 0px;
  }
}
.search-icon {
  font-size: 16px !important;
  color: #707070;
}
.inventry-title {
  font-size: 20px;
  color: #404040;
  float: left;
  width: 100%;
  font-weight: bold;
}
.business-stats {
  min-height: 77px;
  position: relative;
}
.business-graph {
  min-height: 164px;
  position: relative;
}
.business-row {
  margin-left: -5px;
  margin-right: -5px;
  clear: both;
}
.membership-title svg {
  font-size: 13px;
  margin: 0px 3px;
}
.breadCrumb-text {
  color: #6f7c88;
}
.searchForm {
  display: inline-block;
  margin: 0px 0px 0px 10px;
  width: 200px;
  .searchOuter {
    width: 100%;
    display: inline-block;
    background: #f2f6f7;
    @include border-radius(3px);
    position: relative;
    font-size: 14px;
    height: 32px;
    vertical-align: top;
    padding-left: 35px;
    svg {
      display: table-cell;
      font-size: 14px !important;
      color: #aaa;
      left: 10px;
      vertical-align: middle;
      position: absolute;
      top: 9px;
      margin: 0px;
    }
    input {
      width: 100%;
      height: 29px;
      background: none;
    }
  }
}

.text-inline-input {
  height: 24px;
  width: 50px;
  font-size: 13px;
  padding: 0px 5px;
}
.large-popup .sms-btn {
  margin-top: 17px !important;
  padding: 6px 17px 6px;
  height: 36px;
}
.walletSettingMinHeight {
  min-height: 278px;
}
.discountMonthSelect {
  width: auto !important;
  select {
    width: 80px !important;
    margin-top: -7px;
  }
}
.freeMonthlyProduct a {
  margin-top: 16px;
  padding: 6px 17px !important;
  height: 35px !important;
}
.singleCheckBox {
  position: relative;
  float: left;
  width: 100%;
  padding-left: 24px;
  color: #667680;
  margin: 10px 0px;
  input {
    position: absolute;
    left: 0px;
    top: 0px;
    margin: 0px;
  }
}
.membership-bedge-block {
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: top;
  .membership-bedge-circle {
    border: 1px solid #f5cb42;
    padding: 2px;
    border-radius: 50%;
    height: 17px;
    width: 17px;
    display: inline-block;
    .membership-bedge-icon {
      font-size: 10px;
      display: block;
    }
  }

  &.bedge-client-profile {
    .membership-bedge-circle {
      height: 29px;
      width: 29px;
      padding: 4px;
      .membership-bedge-icon {
        font-size: 17px;
      }
    }
  }
}

.editMembershipType .TP-discount-outer {
  height: 36px;
  margin-bottom: 10px;
  input {
    margin-top: 2px;
  }
  .TP-discount-type {
    margin-top: 0px;
  }
}
.primary-color {
  color: #188bf6;
}
.coupon-button {
  height: 28px;
  font-size: 14px;
}
.freeMonthlyProductBtn a {
  margin-top: 11px;
  padding: 6px 17px 4px;
  height: 36px;
}
.clientMembershipColor {
  float: none !important;
  margin-left: 10px;
  margin-top: -3px !important;
  width: 26px !important;
  height: 23px !important;
}
#membershipAgreementSelect.simpleSelect {
  border: none !important;
  margin-top: 2px;
  height: 30px;
}

.change-button {
  display: flex;
  justify-content: right;
  width: 100% !important;
}

.change-color-btn{
  float: right;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  font-size: 12px;
  min-width: inherit;
  height: 30px;
  display: flex;
  vertical-align: top;
  margin-top: -3px;
}

.date-input {
  border-radius: 8px;
  height: 50px;
  min-height: 50px;
  border: 1px solid #b5b5b5;
}

.calender-icon-discount {
  top: 27px !important;
}

.limited-select {
  width: 100px !important;
}

.membership-type-modal {
  width: 750px !important;
}
