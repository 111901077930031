.vert-middle{
	align-items: center;
    display: -webkit-flex;
    display: flex;
    min-height:650px;
    height: 100%;
}
.loyaltyMembership{
	width:480px;
	height:auto;
	margin:auto;
	display:table;
	background:#ffffff;
	@include border-radius(5px);
	padding:25px;
}
.newPopupTitle{
	width:100%;
	float:left;
	color:#444444;
	font-size:22px;
	font-weight:bold;
	margin-bottom:25px;
	position:relative;
	padding-right:20px;

	.popupClose{float:right;position:absolute;right:0px;top:0px;border: none;background: none;}
	.popupClose2{float:right;position:absolute;right:0px;top:-13px;}
}

.membershipDetail{
	float:left;
	width:100%;
	font-size:15px;
	color:#444444;

	.row{margin-bottom:10px;}
}
.newPopupSubTitle{
	width:100%;
	float:left;
	color:#444444;
	font-size:17px;
	font-weight:600;
	margin-bottom:10px;
	text-transform:uppercase;
}
.waive-setup{
	display:block;
	color:#667680;
	font-size:15px;
	line-height:17px;
}
.width130 {
	width: 130px;
}
/*.membershipLabel{
	color:#444444;
	font-size:15px;
}
.simpleLabel{
	float:left;
	color:#444444;
	font-size:14px;
	width:100%;
	margin-bottom:7px;
}
.simpleField{
	width:100%;
	float:left;
	border:1px solid #cccccc;
	height:42px;
	@include border-radius(3px);
	box-shadow:0px 1px 1px 0 rgba(0,0,0,0.15) inset;
	margin-bottom:20px;

	.simpleInput, .simpleSelect{
		padding:0px 10px;
		color:#444444;
		font-size:15px;
		background:none;
		height:42px;
		float:left;
		width:100%;
	}
}
.field-icon{
	padding-right:30px;
	position:relative;

	a{position:absolute;right:7px;top:7px;}
}
*/
.simple-btn{
	display:inline-block;
	color:#ffffff;
	background:$blue;
	height:42px;
	padding:12px 30px;
	@include border-radius(3px);
	text-align:center;
}
.payment-field{
	iframe{height:36px;margin-top:10px;position: relative;top: -4px;
		input{height:36px;}
	}
}
.start-date-picker .react-datepicker-popper{margin-top:50px!important;}
