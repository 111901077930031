.priceListTable {
  padding-top: 20px !important;
  background: #fff !important;
  border-radius: 5px;
  width: 100%;

  .toolbarContainer {
    flex-wrap: wrap;
    height: auto !important;
    padding: 0px 10px !important;
    gap: 0px !important;
    grid-gap: 0px !important;
  }

  @media (max-width: 1200px) {
    .toolbarButtons {
      order: 4;
    }
  }

  .tableContainer {
    border: 0px !important;

    thead {
      background-color: #daedf7 !important;

      th {
        padding: 5px 10px !important;

        div {
          font-size: 12px;
          font-weight: 400;
          line-height: 15px;
          letter-spacing: -0.23999999463558197px;
          text-align: left;
        }
      }
    }

    tbody {
      tr {
        td {
          input {
            box-sizing: border-box;
            border: 1px solid #d0d5dd;
            width: 93px;
            max-height: 36px;
            padding: 10px;
          }
        }
      }
    }
  }
}

.bgLightBlue {
  background: #dceefe !important;
}

.headerButtons {
  display: flex;
  gap: 10px;
}
