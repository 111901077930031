.create-appoint-popup {
  background: #e6eef2;
  position: fixed;
  top: 55px;
  bottom: 0px;
  width: 100%;
  z-index: 1;
  overflow-y: scroll;
  padding-top: 50px;
}

.create-appoint-title {
  padding: 14px;
  top: 56px;
  border: none;
  background: #e6eef2;
  z-index: 1000;
  text-align: center;
  color: #3d454d;
  min-height: 46px;
  margin-bottom: 18px;
  position: fixed;
  width: 100%;

  a {
    color: $blue;
    float: left;

    img {
      display: inline-block;
      vertical-align: top;
      margin-top: 2px;
      margin-right: 5px;
    }
  }
}

.popup-new-container {
  width: 100%;
  margin-top: 0px;
  height: 100%;
  padding: 0px 14px;
}

.popup-left-section {
  background: #c2e0f2;
  width: 336px;
  padding: 20px 30px 70px !important;
  vertical-align: top;
  border-radius: 10px 0px 0px 10px;
  overflow: hidden;
  border: none;
  font-weight: normal;
  color: #404040;
}

.popup-left-title {
  color: #667680;
  font-weight: normal;
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  padding-bottom: 20px;
  border-bottom: 1px solid #999;
  margin-bottom: 30px;
}

.left-field-outer {
  display: block;
  margin-bottom: 20px;

  .popup-input-label {
    font-size: 11px !important;
    color: #667680 !important;
    display: block;
    text-transform: uppercase;
    font-weight: 300;
  }

  .popup-input-box {
    font-size: 14px;
    color: #404040;
    display: block;
    border-bottom: 1px dotted #a3bccc;
    background: none;
    width: 100%;
    font-weight: normal;
    padding-bottom: 5px;
    min-height: 26px;
  }
}

.popup-right-section {
  border-radius: 0px 10px 10px 0px;
  overflow: hidden;
  background: #ffffff;

  .popup-right-form-outer {
    width: 70%;
    margin: auto;
    display: table;
    padding: 25px 0px 100px;

    .popup-right-section-title {
      font-size: 22px;
      color: #404040;
      font-weight: normal;
      margin-bottom: 40px;
    }

    .popup-form .form-group {
      margin-bottom: 18px;
    }
  }
}

.allow-double-booking {
  width: 100%;
  float: left;

  label {
    font-weight: normal;
    display: contents;
  }
}

.save-appoint-btn {
  margin: 10px 0px 0px;
}

.appoint-note {
  height: 26px;
  min-height: inherit;
  top: -25px;
}

.add-another-service #add-more-service {
  background: #ffffff;
  color: $blue;
}

.appointment-detail-main {
  width: 673px;
  margin-top: 20px;

  .small-popup-content {
    max-height: 460px;
  }

  textarea {
    min-height: 20px;
  }
}

.last-cancel-appint {
  font-size: 11px;
  text-align: center;
}

#previous_appointments ul {
  list-style: none;
  display: block;
}

#previous_appointments ul li {
  display: block;
  padding: 5px;
}

#previous_appointments {
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
  display: block;
}

.app_status_span {
  display: inline-block;
  padding: 1px 5px;
  border-radius: 3px;
  background: #999;
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
}

.upcoming {
  background: #38a100 none repeat scroll 0 0;
  color: #fff;
}

.canceled {
  background: #ea4335;
  color: #fff;
}

.add-services {
  margin-bottom: 20px;
  overflow: inherit;
  float: left;
  width: 100%;

  select {
    width: 60%;
    background: url(/../images/new-down-arrow.png) no-repeat right 9px #ffffff;
    border: none;
    display: inline-block;
  }

  input {
    width: 19%;
    border-left: 1px solid #ececec;
    padding-left: 10px;

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
      background: #ffffff;
    }
  }

  .add-round-btn {
    top: 0px;
    right: -13px;
  }
}

.reschedule-msg {
  font-size: 14px;
  font-family: "Lato", sans-serif;
  color: #951b1b;
  display: block;
}

.appointment-service-name-outer {
  margin-bottom: 20px;
  float: left;
  width: 100%;

  .appointment-service-name {
    position: relative;
    padding-right: 25px;
    margin-bottom: 15px;

    .add-round-btn {
      top: 0px;
    }
  }
}

.add-service-btn {
  width: 113px;
  text-align: center;
  position: absolute;
  top: -4px;
  right: 0px;
}

.add-service-outer {
  float: left;
  width: 100%;
  position: relative;
  padding-right: 130px;
}

.create-appointment-container .new-loader {
  z-index: 1001;
}

/*********** new-create-event **************/
.create-appoint-popup.createEventPopup {
  padding-top: 35px !important;
}

.createEventOuter {
  float: left;
  width: 100%;

  .breadcrumbs {
    margin-bottom: 18px;
  }

  hr.divider {
    margin-bottom: 28px;
  }
}

.eventCalIcon.field-icon {
  input {
    padding-right: 30px;
  }

  a {
    top: 8px !important;
    right: 24px !important;
  }
}

.eventFromTo {
  .simpleInput,
  .simpleSelect {
    width: 125px;
    float: left;
  }

  .eventTo {
    float: left;
    width: auto;
    padding: 10px;
  }
}

.event-Title {
  float: left;
}

.eventCountryFlag {
  .simpleInput {
    padding-left: 50px !important;
  }

  .selected-flag {
    margin-top: 1px;
    padding-left: 0px !important;
    border-right: none;
    height: 32px !important;
    outline: none;
    margin-left: 10px;
    width: 36px !important;
  }
}

.eventCardDetail {
  .simpleInput {
    padding-top: 9px;
  }
}

.eventExpiryCVV {
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 1;
  width: 148px;

  .StripeElement {
    float: left;
    width: 75px;
    display: inline-block;
    padding: 0px 0px 0px 10px;
    text-align: center;
    margin-top: 10px;
  }

  & > div:first-child {
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
  }

  & > div:last-child {
    width: 54px;
  }
}

.createEventHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.createEventContainerPadding {
  @media (max-width: 768px) {
    padding: 15px !important;
  }
}

.createEventTitle {
  font-size: 22px;
  color: #677680;

  @media (max-width: 768px) {
    font-size: 19px;
  }

  @media (max-width: 450px) {
    font-size: 17px;
  }
}

.createEventBtn {
  min-width: 88px;

  @media (max-width: 450px) {
    min-width: 70px;
    margin-left: 5px;
  }
}

.clientInputs {
  @media (min-width: 768px) {
    display: flex;
  }
}

.timePickerContainer {
  display: flex;
  flex-wrap: wrap-reverse;
}

.appointment-add-to-waitlist-link {
  color: $color-primary;
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin-top: 16px;
  text-decoration: underline;
  cursor: pointer;
}

.field-display {
  font-family: $opensan;
  display: flex !important;
  align-items: center;
  gap: 4px;
  font-size: 16px;
  line-height: 25px;
  color: #000000;

  .field-value {
    color: $color-primary;
  }
}

.waitlist-user-details {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin: 18px auto 0px;

  hr {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .clinic-provider-container {
    background-color: $color-editable-bg;
    padding: 12px;
    margin-right: 12px;
    margin-bottom: 8px;
    border-radius: 8px;
  }

  .formLabel {
    max-width: 65px !important;
    width: 65px !important;
    min-width: 65px !important;
  }
}

.add-edit-waitlist-modal {
  .user-cards {
    .existingCard {
      display: flex !important;

      .btn-primary {
        height: 36px;
        margin-left: 12px;
        padding: 8px 12px;
      }
    }
  }

  .client-details-with-membership-badge {
    display: flex;
    align-items: center;

    .membership-bedge-block {
      margin: 0px 0px 0px 8px;

      .membership-bedge-circle {
        border: none;

        .membership-bedge-icon {
          font-size: 16px;
        }
      }
    }
  }
}

.waitlist-time-schedule-container {
  display: flex;
  flex-direction: column;

  .schedule-waitlist {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .schedule-waitlist > .schedule-title {
    font-family: $opensan;
    font-size: 16px;
    color: $color-black;
    margin-bottom: 4px;

    .error-message {
      vertical-align: middle;
      font-size: 13px;
      margin-left: 8px;
    }
  }

  .schedule-week-day,
  .schedule-month,
  .schedule-time-slot,
  .notes,
  .pos-fields {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .schedule-slots {
      margin-left: 0px !important;
      margin-right: 0px !important;

      .form-row {
        margin-bottom: 0px !important;
      }

      .simpleTextarea {
        border-radius: 16px;
        height: 122px;
      }

      .time-slot-option {
        margin-bottom: 12px;
      }
    }

    .schedule-slots > ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-left: 2px;
    }

    .schedule-slots > input,
    label {
      margin-top: 0px;
      margin-bottom: 0px;
    }

    .gap-3 {
      gap: 12px;
    }
  }

  .notes {
    margin-top: 0px;
  }

  .pos-fields {
    span {
      margin-left: 2px;
    }

    margin-bottom: 24px;
  }

  .clearent-fields {
    margin-left: 6px;
  }

  .schedule-slots {
    .day-preference-slots {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;

      span {
        min-width: 60px;
      }
    }
  }

  .schedule-month {
    .schedule-slots > div {
      padding-bottom: 12px;
    }
  }

  .cc-details {
    margin-right: 20px;
  }
}

.schedule-week-day {
  .schedule-slots {
    padding-right: 16px;
  }
}

.notes {
  .schedule-title {
    margin-bottom: 0px;
  }
}

.schedule-time-slot > .filled-checkbox {
  margin-top: 15px;
  margin-bottom: 15px;
}

.btn-primary {
  background-color: $color-primary;
  margin: auto;
  border: none;
}

.btn-primary:hover {
  opacity: 0.8;
  border: none !important;
}

.waitlist-toolbar-buttons {
  display: flex;
  gap: 16px;

  .reset-button {
    background-color: $color-secondary-btn;
    margin: auto;
    border: none;
    color: white;
  }

  .reset-button:hover {
    opacity: 0.8;
    border: none !important;
  }
}

.appointment-waitlist-preference-container {
  margin-bottom: 40px;

  .schedule-slots > ul {
    display: flex;
    flex-wrap: wrap;

    @media (min-width: 768px) {
      justify-content: space-between;
    }
  }

  .week-day-preference-option,
  .time-preference-option {
    cursor: pointer !important;
    gap: 12px;
    display: flex;
    align-items: center;

    padding-top: 6px;
    padding-bottom: 6px;

    @media (max-width: 768px) {
      flex-basis: 25%;
    }

    label {
      cursor: pointer !important;
      font-weight: 400;
      font-size: 14px;

      margin-bottom: 0px;
    }
  }

  .week-day-preference-option:last-child {
    margin-right: 16px;
  }

  .month-preference-select-container {
    div:first-child {
      padding-bottom: 6px;
    }

    div {
      padding: 0px;

      @media (min-width: 768px) {
        padding-right: 16px;
      }
    }
  }

  .form-row {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .formLabel {
      width: 250px;
      max-width: 250px;

      font-size: 16px;
      color: black;
      font-family: $opensan;
    }

    .day-list-container,
    .month-preference-select-container {
      max-width: 600px;
    }

    .time-slot-select-container {
      max-width: 450px;
    }
  }
}

.waitlist-clinic-provider-item {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  div {
    .col-xs-12 {
      min-width: 100%;
    }
  }

  .duration-field {
    @media (max-width: 770px) {
      padding-left: 15px !important;
    }
  }
}

.waitlist-clinic-provider-item:last-child {
  margin-bottom: 0px;
}

.schedule-slots {
  label {
    cursor: pointer !important;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 0px;
  }
}

.waitlist-modal-actions {
  margin-top: 8px;
  margin-bottom: 4px;

  button {
    width: 250px;
  }

  @media (max-width: 768px) {
    & > div:first-child {
      margin-bottom: 12px;
    }
  }
}

.waitlist-search-modal {
  .search-field-placeholder-icon {
    margin-right: 10px;
  }

  .save-search-filter-buttons {
    width: 100%;
  }
}

.create-appointment-form {
  .form-button {
    .easy-link {
      background-color: $color-primary-button;
      border-radius: 8px;
      padding: 8px 14px;
    }
  }
}

.clinic-provider-edit-form {
  margin-right: 20px;

  .easy-link {
    background-color: $color-primary-button;
    border-radius: 8px;
    padding: 8px 14px;
  }
}
