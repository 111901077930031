.container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 36px;
}

.headerRow {
  display: flex;
  justify-content: space-between;
}

.closeButton {
  background: none;
  border: none;
}

input[type="file"].uploadInput {
  display: none;
}

.fileContainer {
  display: flex;
  width: 125px;
  flex-direction: column;
  gap: 8px;
  border: 1px solid #dddddd;
  border-radius: 5px;
  text-align: center;
}

.selectFileText {
  border-top: 1px solid #dddddd;
  font-size: 12px;
  font-weight: 600;
  overflow: hidden;
}

.selectFileText,
.selectFileImage {
  padding: 5px 15px;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  font-family: "Lato", sans-serif;

  label {
    font-size: 12px;
    color: #667680;
    font-weight: 600;
  }

  input,
  select {
    font-size: 14px;
    color: #188bf6;
    border: none;
    border-bottom: 1px solid #bfc9ce;
  }

  select {
    background: url(/../images/new-down-arrow.png) no-repeat right 9px;
  }
}

.formContainer {
  width: 100%;
  display: flex;
  gap: 120px;
  padding-bottom: 20px;
}

.fullWidth {
  width: 100%;
  display: flex;
}

.addDocumentForm {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 32px;
}

.addMoreContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 40px;
  border-bottom: 1px solid #dddddd;
  font-family: "Lato", sans-serif;

  span {
    font-size: 14px;
    color: #667680;
  }

  button {
    font-size: 14px;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.uploadFormContainer {
  display: flex;
  border-bottom: 1px solid #dddddd;

  button {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    background: none;
    color: #188bf6;
    border: 1px solid #188bf6;
    border-radius: 50%;
  }
}
