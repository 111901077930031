@import "../../../../../styles/theme.scss";
@import "../../SalesCheckoutInvoice.variables.scss";

.root {
  display: flex;
  gap: 50px;
  flex-wrap: wrap;

  @media screen and (max-width: $mobileBreakpoint) {
    gap: 25px;
  }
}

.col {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 15px;
  font-weight: 400;
}

.label {
  font-size: 15px;
  font-weight: 600;
  color: $cGray1;
}
