@media screen and (max-width: 1280px) {
  .wrapper {
    width: 95%;
  }

  .touchMDHeader .mdWrapper {
    width: 450px !important;
  }
}

@media screen and (max-width: 1170px) {
  .wrapper {
    width: 90% !important;
  }

  footer {
    padding: 10px 0px;

    p {
      display: block;
    }

    .Address {
      margin-left: 0px;
      display: block;
      text-align: center;
      padding: 10px 0px;
    }

    #social {
      float: none;
      margin-top: 0px;
      margin-bottom: 0px;

      a {
        height: auto;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .half {
    width: 100% !important;
    display: block;
  }

  input.half {
    margin-top: 20px;
  }

  .login-left {
    display: none;
  }

  .login-right {
    width: 100%;
  }

  .login-outer {
    float: none;
    display: table;
    margin: 150px auto 100px !important;
  }

  .payment {
    margin-top: 70px !important;
  }

  .login-left.subscibe {
    display: block;
    width: 100%;
    text-align: center;
  }

  .yearly-plan {
    width: 100%;
  }

  .billing,
  .subs-box {
    margin-bottom: 15px;
  }

  .dash-content.payment {
    margin-top: 0px !important;
  }

  .plan {
    padding: 10px 24px;
  }

  .plan h2 {
    font-size: 27px;
  }

  .plan h2 span {
    font-size: 20px;
  }

  .annual-plan h2 {
    font-size: 24px;
  }

  .plan-option {
    width: 37% !important;
  }

  .banner-image {
    width: 90%;
  }
}

@media screen and (max-width: 767px) {
  .login-main {
    width: 400px;
    margin: 0px auto 10px !important;

    h1 {
      font-size: 30px;
    }

    .first-name {
      padding-right: 15px;
    }

    .last-name {
      padding-left: 15px;
    }
  }

  .login-title {
    width: 100%;
    float: left;
  }

  .plan-info {
    width: 100%;
  }

  .plan {
    width: 48%;
    margin-bottom: 20px;
  }

  .free-trail .form-group {
    margin: 0px;
    margin-bottom: 20px;
  }

  .free-trail .form-group .col-sm-6,
  .free-trail .form-group .col-sm-12 {
    padding: 0px;
  }

  .member-section {
    width: 100%;
    float: left;
  }

  .signup {
    width: 100%;
  }

  .state-business {
    margin-bottom: 15px;
  }

  .touchMDHeader .mdWrapper .logo img {
    height: 36px;
  }

  .touchMDHeader a.touchMDlogo {
    padding: 8px 10px;
  }

  .touchMDHeader .touchMDlogo img {
    height: 16px;
  }

  .touchMDHeader .candelaLogo img {
    height: 37px;
  }

  .touchMDHeader .header-right {
    margin-top: 0px !important;
  }
}

@media screen and (max-width: 450px) {
  .input-row input[type="text"],
  .input-row input[type="password"],
  .input-row select {
    width: 80%;
  }

  .nav li a {
    margin: 0px;
  }

  .nav li a.login {
    padding: 7px 11px;
  }

  .small-input {
    width: 100%;
    float: none !important;
  }

  .header {
    padding-top: 20px;
  }

  .Phone-no {
    margin-top: -55px;
  }

  .login-main {
    display: table;
    width: 100%;
  }

  .login-main input[type="submit"],
  .login-main button[type="submit"],
  .login-main a.back-btn {
    width: 100% !important;
    margin-top: 20px;
    font-size: 17px;
  }

  .wrapper .sign-up-main .billingBtn {
    width: 100% !important;
    height: 53px !important;
    font-size: 20px;
  }

  .backtologin,
  .resetpswd {
    width: 100%;
  }

  .plan-info,
  .plan {
    width: 100%;
    display: table;
  }

  .sucess-btn,
  .danger-btn {
    margin-bottom: 20px !important;
    width: 100% !important;
  }

  .sign-up-main .banner-image,
  .login-policy-wrapper .banner-image {
    margin-top: 10px !important;
  }

  .login-main-policy.row {
    margin: 0px;
  }

  .login-main-policy.row > div {
    padding: 0px;
  }

  .ar-login {
    padding: 20px 15px;
  }

  .touchMDHeader .mdWrapper,
  .touchMDHeader .header-right {
    width: 100% !important;
  }
}

/********************** AR-Next-start ************************/
@media screen and (max-width: 1200px) {
  .arNextLogin .login-main {
    width: 90% !important;
  }
}

@media screen and (max-width: 1023px) {
  .arNextLogin,
  .ar-next {
    width: 100%;
    position: initial;
  }

  .aestheticNextMain {
    position: initial;
    margin: 0px auto 20px;
    padding: 30px 50px 50px;
    background: #000000;
  }

  .aestheticNextMain img {
    max-width: 100%;
    height: auto;
  }

  .arNextLogin .login-main {
    height: auto !important;
    margin: 75px auto 0px !important;
    width: 450px !important;
  }

  .arNextLoginguest .ar-next {
    width: 100%;
    position: inherit;
    height: auto;
    bottom: 0px;
    top: inherit;
  }

  .sponsership {
    position: inherit;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 20px;
  }
}

@media screen and (max-width: 767px) {
  .aestheticNextMain a {
    width: 100%;
    display: block;
    margin: 0px 0px 20px !important;
  }

  .arNextLogin .login-main {
    width: 90% !important;
  }

  .ar-next {
    width: 100%;
    position: initial;
    height: auto;
  }

  .modal-dialog {
    margin: 60px 20px !important;
  }

  .switchTo.creditCardSections {
    padding-top: 20px;
  }

  .creditCardSections {
    min-height: inherit;
    margin-bottom: 20px;
  }
}

/********************** AR-Next-end ************************/
