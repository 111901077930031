.setting-verticals-outer {
  display: flex;
  align-items: center;
  float: none;
  padding: 15px 20px;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }

  .searchPageName {
    margin: 0 26px 0 0;
    font-size: 22px;
    line-height: 27px;
    letter-spacing: -0.30000001192092896px;

    &.searchPageName-list {
      @media (max-width: 768px) {
        margin: 0 0 12px 0;
        width: 100%;
      }
    }
  }

  .checkbox-input {
    flex: 0 0 17px;
    width: 17px;
    height: 17px;
    margin: 0;
  }

  .checkbox-label {
    display: flex;
    align-items: center;
    margin: 0;
  }

  .checkbox-text {
    margin: 0 0 0 14px;
    padding: 0;
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
  }

  .link {
    margin-left: auto;
  }
}

.vertical-form-wrapper {
  min-height: 100%;

  .vertical-form {
    padding: 20px 0 30px;
    border-bottom: 1px solid #ececec;
    margin: 0 20px 0;
  }

  .footer-static {
    border: none;
    padding: 14px 20px 16px;
  }
}

#table-verticals {
  .table-updated-th,
  .table-updated-td {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0;
  }

  .table-updated-thead {
    .table-updated-th {
      color: #667680;
      padding: 7px 10px 7px 20px;
    }
  }

  .table-updated-tr {
    .table-updated-td {
      color: #000000;
      padding: 20px;
    }
  }
}

.deleteVertical {
  background: url(/../images/delete-icon.png) no-repeat;
  background-size: contain;
  background-position: center;
  width: 0 auto;
  height: 18px;
}
