@import "../../../../../../styles/theme.scss";

.root {
  padding-bottom: 16px;
  border-bottom: 1px solid $cGray3;

  &:last-child {
    border-bottom: none;
  }
}

.header {
  margin-bottom: 8px;
  font-size: 14px;
  color: $cGray1;
}
