@import "../../../../styles/theme.scss";

.col {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background: none;
  border: none;
  outline: none;
  cursor: default;

  & * {
    width: 100%;
  }

  &.clickable {
    cursor: pointer;

    &:hover {
      background-color: $cPrimaryDim3;
    }

    &.active {
      background-color: $cPrimaryLight2;
    }
  }

  &.stretch {
    flex: 1;
  }
}

.cell {
  min-height: 55px;
  max-height: 55px;
  height: 55px;
  font-size: 16px;
  display: flex;
  border-bottom: 1px solid $cGray2;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: $cBlack;
  padding: 0 16px;
  white-space: nowrap;

  &.firstCellAccent {
    border-bottom: none;
  }
}
