@import "../../styles/theme.scss";

.root {
  background-color: $cPrimaryDim;
  padding: 10px;
  border-radius: 4px;
  color: $cPrimary;
  font-size: 14px;
  height: 40px;
}

.error {
  background-color: $cRedDim !important;
  color: $cRed !important;
  &::placeholder {
    color: $cRed !important;
  }
}

.disabled {
  background-color: $cGray4 !important;
  pointer-events: none;
  cursor: default;
  user-select: none;
  color: $cGray0 !important;
  &::placeholder {
    color: $cGray2 !important;
  }
}
