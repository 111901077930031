@import "../../../../../../../styles/theme.scss";

.createBtn {
  border: none;
  background: none;
  outline: none;
  color: $cPrimary;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  &:hover {
    text-decoration: underline;
  }
}
