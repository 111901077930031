.right {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;
  justify-content: flex-end;

  @media screen and (max-width: 650px) {
    justify-content: space-between;
    gap: 8px;
  }
}
