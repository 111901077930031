.headerContainer {
  display: flex;
  align-items: center;
  gap: 64px;

  .addWidgetButton {
    margin-left: auto;
  }

  .dashboardTabs {
    display: flex;
    gap: 16px;

    a {
      font-size: 15px;
      color: #667680;
      cursor: pointer;
    }

    a.active {
      color: #188bf6;
    }
  }
}
