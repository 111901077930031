@import "../../styles/theme.scss";

.content {
  margin-top: 8px;
}

.placeholder {
  display: flex;
  align-items: center;
  color: $cGray2;
  gap: 8px;
}

.placeholderRecording {
  display: flex;
  align-items: center;
  color: $cRed;
  gap: 8px;
}

.dot {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: $cRed;
}

.valueWrap {
  display: flex;
  gap: 8px;
  font-weight: bold;
}

.mt5 {
  margin-top: 5px;
}
