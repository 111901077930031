@import "../../../../styles/theme.scss";

.root {
  position: relative;
  overflow: scroll;
}

.inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.table {
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
  width: 100%;
}

.head {
  display: flex;
  flex-wrap: nowrap;

  .headCol {
    background-color: $cPrimaryDim;
  }
}

.row {
  display: flex;
  flex-wrap: nowrap;

  &:last-child {
    border-bottom: none;
  }
}

.col {
  display: flex;
  align-items: center;
  user-select: none;
}

.nonDraggable {
  opacity: 0.5;

  & * {
    cursor: not-allowed !important;
  }
}
