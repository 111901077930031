@import "../../styles/variables.scss";

.root {
  position: fixed;
  bottom: 30px;
  z-index: $zIndexMax;
}

.left {
  left: 30px;
}

.right {
  right: 30px;
}
