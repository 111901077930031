$primaryFont: "Poppins";
$secondaryFont: "Open Sans";
$latoFont: "Lato";

$cWhite: #fff;
$cBlack: #000;

$cPrimary: #188bf6;
$cPrimaryDim: #dbeefd;
$primaryDim1: "#7ebaf2";
$cPrimaryDim2: #e5eef2;
$cPrimaryDim3: #f9fafb;
$cPrimaryDark: #217acd;
$cPrimaryLight: #daedf7;
$cPrimaryLight2: #f2f4f7;

$cRed: #bf2828;
$cRedFade: #d55d5d;
$cRedDim: #f5e7e7;
$cRedDim2: #fff8f8;
$cRedDim3: #ffeded;
$cRedDark: #972b2b;
$cRedLighten: #ffc9c9;

$cOrange: #ee971f;
$cOrangeDim: #fff2e0;
$cOrangeDark: #b2540f;

$cGreen: #23b023;
$cGreenDim: #e2fde2;
$cGreenDark: #1a821a;

$cBlue: #0085ff;

$cYellowDark: #938e1a;
$cYellowDim: #f9f7c7;

$cGray0Dark: #7e7e7e;
$cGray0: #a4abb2;
$cGray1: #b5b5b5;
$cGray2: #c3c9ce;
$cGray3: #ededed;
$cGray4: #f3f3f3;
$cGray5: #f9f9f9;
$cGray6: #d8d8d8;
