.procedureName {
  width: 180px;
  min-width: 180px;
}

.colMin {
  min-width: 135px;

  @media screen and (max-width: 991px) {
    width: 146px;
    min-width: 146px;
  }
}

.colCheckbox {
  width: 50px;
}
