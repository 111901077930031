header,
#content,
footer {
  font-size: 13px;
  color: #5a5a5a;
}

.dashboard-wrapper {
  max-width: 1200px;
}

.dashboard-title {
  margin: 0px -23px;
  padding: 0px 0px;
  float: none;
  width: auto;

  .dash-filter-outer {
    padding: 0px 15px;
  }

  .this-month {
    font-size: 18px;
    float: right;
    vertical-align: top;
    margin-bottom: 10px;
  }
}

.patient-count,
.patient-count b {
  font-size: 20px;
  font-weight: 400;
  display: inline-block;
  vertical-align: top;
  line-height: 26px;

  b {
    font-weight: 600;
  }
}

.blue-btn .dashboard-filter-icon {
  line-height: inherit;
  margin-right: 6px;
  font-size: 15px;
}

#mainContent.main-content {
  margin-top: 0px;
}

.main-content {
  margin-top: 20px;
  float: left;
  width: 100%;
  display: table;
}

.goals-outer {
  padding-left: 5px;
  padding-right: 5px;
}

.sales-date-price {
  position: absolute;
  left: 138px;
  top: 112px;
  text-align: center;
  width: 129px;
}

.sale-goal-date {
  font-size: 13px;
  display: block;
  text-align: center;
  color: #444444;
}

.sale-goal-price {
  font-size: 16px;
  display: block;
  text-align: center;
  color: #444444;
  font-weight: bold;
}

.new-stats-outer {
  padding-left: 5px;
  padding-right: 5px;
}

.new-stats {
  min-height: 100px;
  position: relative;
}

.view-sign {
  float: left;
  bottom: 15px;
  left: 15px;
  position: absolute;
  background: none;
  color: $blue;
  padding: 4px 15px 4px 0px !important;
  border: none;
  text-transform: inherit;
}

.new-dash-stats {
  font-size: 22px;
  color: #404040;
  position: absolute;
  bottom: 12px;
  padding: 0px;
  right: 15px;
}

#office-sales-goals {
  min-height: inherit !important;
  height: 216px !important;
  margin-bottom: 25px;
  margin-left: 20px;
}

.legend-group {
  width: 47%;
  margin-right: 2%;
  display: inline-block;
  vertical-align: top;
  position: relative;
  padding-left: 15px;
  margin-bottom: 2px;

  .legend-dot {
    width: 10px;
    height: 10px;
    position: absolute;
    left: 0px;
    top: 4px;
    @include border-radius(20px);
    background: $blue;
  }

  .legend-price {
    display: block;
    color: #222222;
    font-size: 13px;
    font-weight: bold;
  }

  .legend-clinic {
    display: block;
    color: #555555;
    font-size: 13px;
    font-weight: normal;
  }
}

.dashboard-set-chart-value {
  font-weight: 100;
}

.sales-date-price {
  position: absolute;
  left: 138px;
  top: 112px;
  text-align: center;
  width: 129px;
}

.Returning-date-price {
  left: 221px;
  top: 70px;
}

.position-left {
  right: inherit;
  left: 15px;
  padding: 0px;
  bottom: 12px;
}

#returning-customers {
  margin: -1px 0 -125px;
  position: relative;
  top: -21px;
}

#returning-customers .highcharts-container {
  display: inline-block;
  margin-top: -37px;
}

.graph-toggle {
  float: right;
  margin-left: 10px;
  margin-top: -3px;
  margin-bottom: 5px;
  background: #e6eef2;
  @include border-radius(3px);
}

.graph-toggle button.select {
  color: $white-color;
  background: $blue;
}

.graph-toggle button {
  font-size: 12px;
  padding: 3px 7px;
  background: none;
  border: none !important;
}

.top-ten-items {
  margin-top: 10px;
  float: left;
  width: 100%;
  min-height: 476px;
}

.table-dashboard {
  width: 100%;
  background: #ffffff;
  border: none;
}

.table-dashboard {
  width: 100%;
  background: #ffffff;
  border: none;
}

.table-dashboard-th,
.table-dashboard-td {
  padding: 10px;
  font-weight: normal;
  border-bottom: 1px dotted #e6e9eb;
  vertical-align: middle;
}

.table-dashboard-th {
  border-top: none;
  font-size: 10px;
  border-bottom: 1px solid #eeeeee;
  color: #667680;
}

.table-dashboard tr th:first-child,
.table-dashboard tr td:first-child {
  border-left: none !important;
}

.table-dashboard-thead {
  background: #ffffff;
  text-transform: uppercase;
  color: #77858e;
}

.table-fixed thead {
  position: sticky;
}

.table-fixed thead tr {
  width: 97%;
}

.table-fixed tbody {
  height: 230px;
  overflow-y: auto;
  width: 100%;
  float: left;
}

//enable to copy text from drag and drop react component

.no-hover .table-updated-tr {
  user-select: auto !important;
}

.no-hover .table-updated-tr button {
  border: none;
  background: none;
}

.no-hover .table-updated-tr button:hover {
  color: black;
}

td.col-xs-6.table-updated-td.Questionnaire-name {
  width: 69%;
}

td.col-xs-6.table-updated-td.Questionnaire-name {
  width: 69%;
}

.no-hover tbody tr:hover {
  background: #ffffff;
  cursor: default;
}

.table-fixed tbody td,
.table-fixed thead > tr > th {
  float: left;
  border-bottom-width: 0;
  position: static;
  display: table-cell;
}

.table-fixed tbody td {
  color: #667680;
  text-decoration: none;
  font-weight: 400;
  font-size: 13px;
  min-height: 478px;
}

.table-fixed tr {
  float: left;
  width: 100%;
}

.top-ten-items {
  margin-top: 10px;
  float: left;
  width: 100%;
  min-height: 476px;
}

.survey-scrore h2 {
  font-size: 30px;
  color: #404040;
  margin: 0px;
  font-family: $opensan;
}

.survey-scrore p {
  font-size: 13px;
  color: #667680;
  margin: 0px;
}

.survey-scrore {
  float: left;
  margin-top: 5px;
  width: 35%;
}

.score-map {
  background: rgb(230, 238, 242);
  float: right;
  width: 63%;
  height: 45px;
  margin-top: 7px;
  position: relative;
  overflow: hidden;
}

.score-map-fill {
  background: rgb(4, 126, 195);
  float: left;
  width: 63%;
  height: 45px;
  margin-top: 0px;
}

.map-overly {
  width: 100%;
  height: 45px;
  position: absolute;
  left: 0px;
  top: 0px;
  background: url("/../images/map-overly.png");
  background-size: 100% 45px;
}

.highcharts-credits {
  display: none;
}

.content {
  padding: 0px 30px 40px;
}

.sales-goal-legend {
  height: 175px;
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}

.Returning-legend {
  height: 98px;
}

.Returning-legend .legend-group {
  padding-left: 27px;
}

.Returning-legend .legend-clinic {
  margin-top: -4px;
  margin-bottom: 5px;
}

.dashboard-set-chart-name {
  font-size: 14px;
}

#returning-customers .highcharts-legend {
  margin-left: 12px;
}

.this-month {
  float: right !important;
  padding: 2px 0px !important;
}

.office-goal .highcharts-container {
  margin-left: -6px;
  margin-top: -35px;
}

p.dashboard-no-data {
  text-align: center;
  color: #dddddd;
  float: left;
  height: 162px;
  line-height: 129px;
  width: 100%;
}

.chat-loader {
  position: fixed;
  left: 0px;
  right: 0px;

  .loader-outer {
    top: 50%;
    margin-top: -40px;
  }
}

//-------css by tushar
.title {
  margin-bottom: 15px;
  display: block;
  font-size: 24px;
  padding: 5px 0px 5px;
  font-weight: 400;
  width: 100%;
  float: left;
}

.sales-goal-legend {
  height: 370px;
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
}

#top-sales-outer {
  height: 337px;
}

.all-clinic-form {
  vertical-align: top !important;
}

.dashboard-title .search-bg.new-calender {
  background: #ffffff;
  height: 30px;

  input {
    padding-top: 0px;
    vertical-align: top;
  }

  div.CalendarPreviewArea {
    right: 0px !important;
    left: inherit !important;
  }
}

.dashboard-title {
  margin: 0px -23px !important;
  padding: 0px 0px !important;
  float: none !important;
  width: auto !important;
}

.custom-progress-outer {
  display: block;
  padding: 10px;
  width: 100%;
  float: left;
  position: relative;
  border-bottom: 1px dotted #e6e9eb;
}

.progress-label-clinic {
  vertical-align: top;
  min-width: 133px;
  font-size: 12px;
  color: #5a5a5a;
  font-weight: 600;
  float: left;
  width: 100%;
  padding-right: 138px;
}

.total-prog-bar {
  float: right;
  position: absolute;
  right: 23px;
}

.custom-progress {
  width: 100%;
  height: 10px;
  background: #f5f5f5;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  margin: 4px 0px;
  float: left;
  position: relative;
}

.custom-progress-growth {
  display: block;
  float: left;
  height: 10px;
  background: #00ab66;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  position: relative;
}

.custom-progress-growth span:after,
.gray-progress-bar span:after {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #7cb5ec;
  position: absolute;
  left: 40%;
  bottom: -5px;
  content: "";
}

.gray-progress-bar {
  float: left;
  height: 10px;
  position: relative;
}

.green-progress,
.gray-progress {
  position: absolute;
  top: -1px;
  font-size: 11px;
  width: 100%;
  text-align: center;
  color: #ffffff;
}

.gray-progress {
  color: #667680;
}

.sale-pending {
  font-size: 12px;
  color: #5a5a5a;
  float: left;
  width: 100%;
}

.sales-need {
  width: 100%;
  float: left;
  margin-top: 10px;
  text-align: center;
  font-size: 12px;
}

.total-prog-bar b {
  font-weight: 600;
}

.sales-goal-juvlycc {
  float: left;
  width: 100%;
  height: 595px;
}

.sales-goal-juvlycc.custom-progress-outer .mCSB_scrollTools {
  right: -3px;
}

.mCSB_container > div.custom-progress-outer:nth-child(even) {
  background: #fafafa;
}

.Month,
.Week {
  float: left;
  width: 100%;

  svg {
    height: 224px;
  }
}

.dashboard-wrapper rect.highcharts-point {
  display: none;
}

.custome-scroll {
  > div:last-child {
    width: 4px !important;
    right: 0px;
  }

  .top-ten-items {
    min-height: inherit;
  }

  #table-body-provider table th:last-child,
  #table-body-provider table td:last-child {
    padding-right: 7px !important;
  }

  #table-body-sales table th:last-child,
  #table-body-sales table td:last-child {
    padding-right: 7px !important;
  }
}

.dashboard-wrapper {
  .dash-box {
    overflow: hidden;
  }

  .custome-scroll {
    width: 103% !important;

    .dash-box-content {
      padding-right: 3% !important;

      .table-responsive {
        overflow-x: hidden !important;
      }
    }
  }
}

.no-sales-goal {
  display: block;
  text-align: center;
  margin-top: 40px;
  float: left;
  width: 100%;
  height: 556px;

  .add-goal {
    margin-top: 10px;
  }
}

.Returning-legend .legend-group {
  width: 49%;
  padding-left: 0px;
  margin-right: 0px;
}

.Returning-outer {
  position: relative;
  padding-left: 28px;
}

.newCustomer .legend-dot {
  background: #bec3c8;
}

.dashboardTypeTitle {
  font-size: 14px;
  color: #000000;
  font-weight: bold;
  margin-bottom: 20px;
}

.dashboardPopupContainer {
  width: 100%;
  float: left;
  overflow-x: hidden;
  overflow-y: auto;
}

.dashSection {
  width: 100%;
  float: left;
  height: 196px;
  @include border-radius(3px);
  overflow: hidden;
  border: 1px solid #dddddd;
  position: relative;
  @include box-shadow(0, 1px, 2px, 0, rgba(0, 0, 0, 0.15));
  margin-bottom: 20px;
  cursor: pointer;

  img {
    width: 100%;
    margin-top: 5px;
  }

  &.active {
    border: 2px solid $blue;
  }

  .dashFooter {
    border-top: 1px solid #dddddd;
    border-bottom: 2px solid #ffffff;
    width: 100%;
    float: left;
    text-align: center;
    font-size: 13px;
    color: #404040;
    padding: 10px;
    background: #ffffff;
    position: absolute;
    bottom: 0px;
  }
}

.dashboardTypeRow {
  margin-left: -10px;
  margin-right: -10px;
  width: 100%;

  .dashboardTypeCol {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.dashboardSectionTitle {
  float: left;
  width: 100%;
  padding: 15px;
  font-size: 15px;
  color: #000;
  border-bottom: 1px solid #dddddd;
  position: relative;
  height: 52px;

  .memberRightActions {
    margin: -6px;
  }

  .dash-filter-outer {
    float: right;
    margin: -5px -3px -6px;
  }
}

.dashboardSectionContent {
  height: 315px;

  td {
    padding: 6px 15px;
  }
}

.dashboardSectionFooter {
  float: left;
  width: 100%;
  padding: 15px;
  color: #000;
  border-top: 1px solid #dddddd;
  height: 50px;
}

.businessGraphOuter.dashBarGraph {
  margin: 0px !important;
  clear: both;
  width: 100%;
}

.dashSection svg {
  display: none;
}

.dashboardTypeCol.active {
  .dashCheckOuter {
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 1;
    border: 2px solid $blue;
  }

  svg {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -25px;
    margin-left: -25px;
    color: $blue;
    display: block;
    z-index: 1;
  }

  .dashFooter {
    border: 2px solid #047ec3;
    border-top: 1px solid #dddddd;
    z-index: 1;
  }
}

.lockDashboard {
  .dashFooter {
    border: 2px solid #047ec3;
    border-top: 1px solid #dddddd;
    z-index: 1;
  }

  .dashCheckOuter {
    background: rgba(255, 255, 255, 0.9);
    cursor: not-allowed;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 1;
    border: 2px solid #047ec3;

    p {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 0%;
      margin-top: 10px;
      margin-left: 0px;
      text-align: center;
      color: #000000;
      text-shadow: 0px 0px 3px #ffffff;
      -webkit-text-shadow: 0px 0px 3px #ffffff;
      font-weight: bold;
    }

    svg {
      display: block !important;
      width: 26px;
      height: 30px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -25px;
      margin-left: -13px;
      color: $blue;
      z-index: 1;
    }
  }
}

.page-numbers {
  list-style: none;
  display: block;
  text-align: right;
  margin: 0px;

  li {
    display: inline-block;
    width: 22px;
    height: 22px;
    margin: 0px 4px;
    text-align: center;
    cursor: pointer;
    @include border-radius(3px);
    line-height: 22px;

    &:hover,
    &.active {
      background: $blue;
      color: #ffffff;
    }
  }
}

.newAppintmentGraph {
  min-height: 542px;
}

.newDashSalesGoal {
  height: 274px;
}

.officeSaleNewDashboard {
  float: left;
  width: 100%;
  padding: 15px;

  .sales-goal-juvlycc {
    height: auto;
  }
}

.dashboardPagination {
  list-style: none;
  display: block;
  text-align: right;

  .pagination {
    margin: -4px 0 0px;
  }

  li {
    display: inline-block;

    a {
      font-size: 15px;
      color: #000000;
      padding: 3px 7px;
      margin: 0px 1px;
      @include border-radius(3px);
      background: none;

      svg {
        font-size: 12px;
        margin: 0px 2px;
      }
    }

    &:hover a,
    &.active a {
      background: $blue;
      color: #ffffff;
    }

    &:first-child:hover a,
    &:last-child:hover a {
      background: #ffffff;
      color: #000000;
    }

    &:first-child a.disabled,
    &:last-child a.disabled {
      background: #ffffff;
      color: #666666;
      cursor: not-allowed;
    }

    &.disabled a,
    &.disabled a:hover {
      background: none;
      color: #777777;
    }
  }
}

.new-dashboard-container {
  #procedureGoals,
  #salesGoals,
  #salesGoalsWeek,
  #procedureGoalsWeek {
    overflow: auto !important;
    height: 360px;
  }

  .loader-outer {
    margin-left: 0px !important;
  }

  .row .setting-setion {
    height: 450px;

    .new-loader {
      z-index: 1;
    }
  }
}

.zIndex1 {
  z-index: 1 !important;
}

#mostUsedDiscount {
  min-height: inherit;
  overflow: auto !important;
  width: 100%;

  > div {
    min-width: 540px;
  }
}
