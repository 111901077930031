@import "../../../../../../../styles/theme.scss";

.root {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.item {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  border-bottom: 1px solid $cGray3;
  padding-bottom: 10px;

  &:last-child {
    border-bottom: none;
  }
}

.label {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  gap: 8px;
}

.amount {
  font-weight: 600;
  color: $cPrimary;
}
