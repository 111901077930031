@import "../../../../../styles/theme.scss";

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 660px) {
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
  }
}

.title {
  font-size: 20px;
  color: $cBlack;
}

.side {
  display: flex;
  align-items: center;
  gap: 8px;
}
