@import "../../../styles/theme.scss";

.pos-stripe-outer{
  position: relative;
  margin-top:40px;
  margin-bottom:40px;
  padding-left:30px;
}
.pos-stripe-input{
  float: left;
  position: absolute;
  left: 0px;
  top: 0px;
}
.pos-stripe-title{
  display: block;
  font-size: 22px;
  font-weight:normal;
  font-style:normal;
  font-family: 'Lato', sans-serif;
  color:#404040;
  margin-bottom:15px;
}
.pos-stripe-discrip{
  display: block;
  font-size:13px;
  font-weight:normal;
  font-weight:400;
  font-family: 'Lato', sans-serif;
  color:#667680;
  line-height:22px;
}
.pos-stripe-subtitle{
  font-size: 17px;
  font-weight: 300;
  color: #252525;
  margin:0px 0px 15px;
}
.pos-stripe-option{
  display: inline-block;
  vertical-align: top;
  top: 0px;
  position: absolute!important;
  left: 0px;
  border: 1px solid black !important;
}
.stripe-link {
  color: $cPrimary;
  text-decoration: none!important;
  &:hover {
    color: $cPrimaryDark;
    text-decoration: underline !important;
  }
}


.small-stripe-btn {
  margin-left: 0px;
  margin-right: 20px;
  height: 29px;
  padding-top: 4px;
  line-height: 20px;
}
.new-white-btn.small-stripe-btn {
  width: 100%;
}
.response, .yellow {
  color: #f2b675!important;
}
.payment-response {
  background: #f2b675;
  display: inline-block;
  vertical-align: top;
  padding: 3px 10px;
  border-radius: 30px;
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
  margin-top: 2px;
  min-width: 80px;
  text-align: center;
  color: #ffffff;
  font-size: 14px;
}
.small-popup-content-pos-disputes-settings{height: 180px}

.show-payout-details, .show-dispute-details {
  cursor: pointer !important;
}
.payout-payment-suceed {
  margin-top: -4px;
  margin-right: 10px;
}

.content-pos-dashboard, .content-pos-payment-settings {
  .verification-field-warning {
    background-color: #fff8f5;
    height: 50px;
    vertical-align: middle;
    font-weight: 700;
    font-size: 14px;
    color: #2b2d50;
    border-radius: 4px 4px 0px 0px;
    -webkit-border-radius: 4px 4px 0px 0px;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
  }
  .alert {
    padding: 15px;
    margin-bottom: 21px;
    border: 1px solid transparent;
    border-radius: 4px;
  }
  .field-warning-color {
    color: #ed5f74 !important;
  }
  #pos-dashboard-payments {
    float: left;
    width: 100%;
    margin-top: 60px;
    margin-bottom: 20px;
  }
}
.pos-content {
  .loading-please-wait{
    margin-top: 0px !important;
  }
}
.error-color{
  color : #e74c3c;
}
.pos-settings{
  .global-connect {
    padding: 19px 10px;
    background: #f5f5f5;
    margin-top: 24px;
    border: 1px solid #ececec;
    border-radius: 5px;
  }
}
.cursor-pointer{
  cursor: pointer !important;
}

div.setting-input-box.pos-dispute-file{color:#047ec3;}
.newPOStripe{
  padding:0px 20px;

  .pos-stripe-outer{margin:20px 0px;

    .pos-stripe-title{margin-bottom:5px;font-size:20px;}
  }
}
.bankOuter{
  display:block;
  margin-bottom: 20px;
}
.bankName{color:$blue;font-size:16px;display:block;margin-bottom:10px;
  svg{font-size:19px;margin-right:5px;}
}
.bankRow{display:flex; flex-wrap: nowrap;}
.bankLabel{width:120px;color:#000000;font-weight:normal;}
.bankDetails{color:#667680;}
.bankSection{margin-bottom:30px;min-height:187px;}
.bankSection170{min-height:170px;margin-bottom:30px;}
.addBank{margin-top:-10px;}
.clinic-account .bankOuter{border-top:1px solid #dddddd;padding-top:32px;
  &:first-child{border:none;padding-top:0px;}
}

.font-size-12{font-size: 12px !important}
.mg-top-17 {margin-top: 17px;}
.content-pos-dashboard{
  .CalendarPreviewArea {
    left: -134px;
  }
}

/*  POS Clearance   */
.form-step-hide{
  display: none;
}

.cardPresent h3{
  font-size:14px;
  margin-bottom:5px;
  color:#404040;
}
.cardPresent p{
  font-size:13px;
}
.card-progress-outer{
  /*
	width:100%;
	float:left;
	height:5px;
	border-radius:5px;
	-moz-border-radius:5px;
	-webkit-border-radius:5px;
    background:$blue; */
  margin-top:15px;
  position:relative;
  .StepRangeSlider__track{
    background-color: #047EC3;
    border:1px solid #047EC3;
  }
}
.card-progress-outer span{
  position:absolute;
  width:38px;
  height:38px;
  /*left:50%;
  margin-left:-15px;
  margin-top:-15px;*/
  background:#ffffff;
  border:1px solid #dddddd;
  border-radius:50px;
  -moz-border-radius:50px;
  -webkit-border-radius:50px;
  z-index:1;
  font-size:12px;
  padding-top:7px;
  text-align:center;
  cursor: pointer;
}
.otherLocations{position:relative;padding-left:30px;}
.otherLocations > input{position:absolute;left:0px;top:0px;}
.otherLocations .newInputFileldOuter{margin-top:0px;top:-7px;position:relative;}
.ownershipField{margin-top:-10px!important;}


.contactRow{
  background: #ffffff;
  border: 1px solid #dddddd;
  float: left;
  padding:10px 15px;
  width: 100%;
  padding-right:165px;
  position:relative;
  margin-bottom:10px;
  min-height: 42px;
}
.contactRow h3{
  width:100%;
  float:left;
  color:#000000;
  font-size:13px;
  margin-bottom:5px;
  font-weight:bold;
}
.contactRow p{
  width:100%;
  float:left;
  color:#667680;
  font-size:13px;
  margin:0px;
}
.contactRow .contactActionBtn{
  position:absolute;
  right:5px;
  top:4px;
}

.signatureAccordion{
  background: #fafafa;
  border: 1px solid #dddddd;
  float: left;
  padding:0px;
  width: 100%;
  position:relative;
  margin-bottom:10px;
}
.signatureAccordion .signatureHeading{
  width:100%;
  float:left;
  color:#000000;
  font-size:13px;
  font-weight:bold;
  padding:15px;
}
.signatureAccordion .signatureBody{
  width:100%;
  float:left;
  color:#000000;
  font-size:13px;
  color:#667680;
  padding:5px 15px 10px;
}
.signatureAccordion.active{
  background:#ffffff;
}
.termSigned .signatureHeading{
  position: relative;
  padding-right: 145px;
}
.termSigned .signatureHeading .termSignedRight{
  position:absolute;
  right:10px;
  top:5px;
}
.termSigned .signatureHeading a{
  float:right;
  margin-left:5px;
  padding: 7px 16px 4px;
  font-size: 12px;
  margin-top:4px !important;
  height: 27px;
  line-height: 11px;
}
.termSigned .signatureHeading span{
  float: right;
  padding: 9px;
  color: #5cb85c;
  font-weight: normal;
}
.new-blue-btn.disabled{
  background-color:gray;
  color:#000;

}
.clearent-error-message {
  margin-left: 0px;
  margin-top: -3px;
  color: red;
}
.newPopupContent{
  .p-text{
    word-break: break-word;
    h2{
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 12px;
    }
    a{color:$blue;word-break: break-word;}
    .cla-section-info{
      margin-bottom: 20px;
      display: block;
    }
    ul, ol{
      padding-left: 16px;
      margin-bottom:18px;

      li{margin-bottom:5px;}
    }
  }
  #W-9{
    p{font-size:13px!important;}
    span{font-size:13px!important;}
  }
}
.acceptTermBtn{
  padding: 9px 25px 9px!important;
  height: auto!important;
  font-size: 15px!important;
}
.merchantSteps{
  color:#404040;
  font-weight:bold;
  font-size:16px;
}

.merchant-subtitle{
  color: #404040;
  font-size: 14px;
  float: left;
  width: 100%;
  font-family: Lato,sans-serif;
  font-weight: bold;
}

.merchant-title{
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 6px;
  display: block;
  width: 100%;
}
.businessNote p{
  position: absolute;
  bottom: -16px;
  margin: 0px;
  font-size: 12px;
}
.customRadioOuter label{
  font-weight:normal;
  margin-left:7px;
  color:#404040;
}
.mechantCheckboxOuter .basic-checkbox-outer label{font-weight:normal;}
.merchantFooter{
  display: flex;
  float: right;
  background-color: #f5f5f5;

  .footerProgressOuter{
    flex: 1;
    text-align: right;
    font-size: 11px;

    p{
      margin: 0px;
      line-height: 13px;
      letter-spacing: 0.4px;

      span{font-weight: bold;}
    }

    p.agree-check {
      text-align: left;
      margin: 0px;
      line-height: 13px;
      letter-spacing: 0.4px;

      span{font-weight: bold;}
    }

    .footerProgressBg{
      width: 100%;
      display: block;
      height: 3px;
      background: #dddddd;
      margin-top: 8px;

      .footerProgressBar{
        width: 26%;
        height: 3px;
        display: flex;
        background: #7bd2a8;
      }
    }
  }
}

.filename {
  width: 300px;
  height: 137px;
  border: 1px solid #dddddd;
  float: left;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding:0px 20px;

  a{
    line-height: 30px;
    background: #fff;
    position: absolute !important;
    right: 10px;
    top: 10px;
    z-index: 50;
    border-radius: 50px;
    width: 30px;
    height: 30px;
    font-weight: bold;
    text-decoration: none;
    text-indent: 100px;
    overflow: hidden;
    font-size: 27px;
    position: relative;
    border: none;

    &:after{
      content: '';
      font-size: 27px !important;
      position: absolute;
      right: 9px;
      top: 9px;
      font-size: 27px;
      width: 11px;
      height: 11px;
      background: url(/../images/close.png) no-repeat center;
    }

  }
}
.titleWithDownload{
  padding-right:144px!important;

  .popupDownloadBtn{
    position: absolute;
    right: 30px;
    top: 2px;
    min-width: inherit;

  }
}
.wide-popup-wrapper{
  .setting-setion {
    width: 100%;
    box-shadow: inherit;
    margin-bottom: 0px;
  }
}
.tracking-number {
  display: block;
  width: 100%;
  white-space: nowrap;
}

.success-msg p .easy-link {padding: 0px;}

.average-ticket-info {
  display: flex;
  width: 20px;
  height: 20px;
  border-radius: 40px;
  border: 1px solid #047ec3;
  color: #047ec3;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  position: absolute;
  right: -11px;
  top: 23px;
}

.check-text {
  font-size: 16px;
}

.memberWalletOuter .new-blue-btn.AddBtn {
  margin-top: 18.5px;
  margin-left: 0;
  right: 0;
  top: 0;
  min-width: auto;
  width: 100%;
}

.link-color {
  color: #188bf6;
}

.font-size-21 {
  font-size: 21px !important;
}