@import "../../styles/theme.scss";

.root {
  width: 100%;

  .btn {
    display: flex;
    align-items: center;
    border: none;
    background: none;
    padding: 5px 10px;
    border-radius: 4px;
    background-color: $cRedDim3;
    color: $cRed;
    font-weight: 600;
    width: 100%;
    justify-content: space-between;

    .triangle {
      margin-top: 2px;
      margin-left: 5px;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid $cRed;
    }

    .triangleUp {
      border-bottom: 5px solid $cRed;
      border-top: transparent;
    }
  }

  .btnOpen {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.logs {
  background-color: $cRedDim3;
  padding: 0 10px 10px 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.nestedLog {
  border-left: 1px solid $cRedLighten;
  padding-left: 6px;

  .nestedLogLabel {
    font-size: 14px;
    color: $cRedFade;
  }

  .nestedLogErrors {
    padding-left: 6px;
  }
}

.error {
  color: $cRedDark;
  font-size: 13px;
}
