.memberTypeOuter {
    display: flex;
    margin-top: 5px;
    align-items: center;
    flex-wrap: wrap;
    gap: 4px;

    img {
        float: none;
        display: inline-block;
        margin-right: 4px;
        margin-bottom: 0;
        position: static;
    }
}

.infoImg {
    max-height: 135px;
    max-width: 144px;
    border: inherit;
    box-shadow: inherit;
    border-radius: 3px;
}

.react_component_tooltip.client-payment-error-tooltip {
    background: #fff;
    color: red;
    width: auto !important;
}

.react_component_tooltip.client-payment-error-tooltip.border {
    border: 2px solid red;
}

.react_component_tooltip.client-payment-error-tooltip.place-right::after {
    border-right-color: red;
}