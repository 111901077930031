.form {
  padding-top: 24px;
  width: 370px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (max-width: 560px) {
    width: 100%;
  }
}

.row {
  display: flex;
  gap: 8px;

  & > * {
    flex: 1;
  }

  @media screen and (max-width: 450px) {
    flex-direction: column;

    & > * {
      flex: unset;
    }
  }
}
