@import "../../../../../../styles/theme.scss";

.footer {
  display: flex;
  gap: 16px;
}

.header {
  display: flex;
  gap: 16px;

  .cardNumber {
    font-size: 16px;
    color: $cGray1;
  }
}

.clearentForm {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
