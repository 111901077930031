.leads-action-button {
  background: transparent;
  border: none;
  margin-left: 6px;
  margin-right: 6px;
  font-size: 20px;
  color: #047ec3;
}

.lead-table-action-column {
  text-align: center;
}

.lead-table-created-column {
  width: 155px;
}

.lead-table-action-column {
  min-width: 110px;
  max-width: 110px;
}

.leads-filters-container {
  padding: 0 29px 0;
  z-index: 1;
}

.leads-filters {
  background: #e6eef2;
  padding-top: 12px;
  padding-bottom: 15px;
}

.leads-filter-item {
  display: inline-block;
}

.leads-filter-icon {
  display: inline-block;
  height: 32px;
  background: #f2f6f7;
  margin-left: 7px;
  padding: 5px 10px;
  color: #667680;
  font-size: 14px;
  border-radius: 3px;
  vertical-align: top;
}

.table-hidden-rows {
  margin-top: 0 !important;
  background: transparent;

  .hidden-rows {
    visibility: collapse;
  }

  .table-updated-th,
  .table-updated-td {
    border-color: transparent;
  }
}

.opt-in-column {
  min-width: 72px;
}

.lead-created-at-tooltip {
  width: auto !important;
}

.lead-details-button {
  margin-right: 8px;
  padding: 0 4px;
  background: white;
  border: none;
}

.overflow-x-scroll {
  overflow: scroll;
  max-height: 600px;
  position: relative;
}

.w-220 {
  min-width: 220px !important;
  width: 220px !important;
}

.th-sticky {
  background: #daedf7;
  position: sticky !important;
  top: 0;
  z-index: 10;
}
