.root {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
}

.root .flagButton {
  width: 100% !important;
}

.phoneInputBox {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
  width: 100%;

  .copyIcon {
    display: flex;
    cursor: pointer;
    border: none;
    background: none;
  }
}

.packageBox {
  width: 100%;
}

.desc {
  i {
    display: block;
    font-size: 13px;
    margin-bottom: 4px;
  }
}

.phoneBox {
  margin-bottom: 16px;
}
