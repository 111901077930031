.root {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.newExpiryDate {
  width: 300px;

  @media screen and (max-width: 450px) {
    width: 100%;
  }
}

.footer {
  display: flex;
  gap: 8px;
}
