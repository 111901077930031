.tableWrap {
  width: 100%;
}

.actionsCol {
  width: 200px;

  @media screen and (max-width: 500px) {
    width: unset;
  }
}
