.calendar-provider-schedule {
  .rbc-calendar {
    min-height: 600px;

    .rbc-toolbar {
      .rbc-btn-group {
        display: block;
      }
    }
  }
}

.cal-month-week-day-outer {
  text-align: center;
  padding: 0px;

  .btn-month,
  .btn-week,
  .btn-day {
    min-width: 70px;
    border-radius: 3px 0px 0px 3px;
    margin-right: -2px;
    display: inline-block;
    border-right: 1px solid #ececec;
    text-align: center;
  }

  .btn-month.selected,
  .btn-week.selected,
  .btn-day.selected {
    background: #d2e2ea;
    color: #404040;
  }
}

.toolbar-container {
  display: flex;

  @media (max-width: 1660px) {
    flex-wrap: wrap;

    .provider-cal-filter {
      margin-top: 12px;
    }
  }
}

.provider-cal-filter {
  float: right;
  margin-right: 15px;
  margin-left: auto;

  @media (min-width: 900px) {
    min-width: 732px;
  }

  min-width: fit-content;
  display: flex;
  justify-content: flex-end;
  gap: 8px;

  .new-blue-btn {
    margin-left: 5px;
  }

  flex-wrap: wrap;
}

.provider-m-d-w {
  max-width: 250px;
  width: 250px;
}

.provider-schedule-view .rbc-day-slot .rbc-event {
  border: none !important;
}

.provider-schedule-popup-height {
  height: 4000px;
  max-height: 400px;
}

.conflict-date {
  color: #404040;
  font-size: 14px;
}

.conflict-date-footer {
  padding: 10px 0px;
}

.editSchedulePop {
  min-height: 326px !important;
  max-height: 326px !important;
}

.back-txt {
  color: #047ec3;
  float: left;

  img {
    display: inline-block;
    vertical-align: top;
    margin-top: 2px;
    margin-right: 5px;
  }
}

#business_time.providerName {
  margin-bottom: 17px;
}

.privider-calender-popup {
  width: 80%;

  .small-popup-header .popup-name {
    font-size: 18px;
    text-transform: inherit;
  }

  .privider-calender-content {
    padding: 30px;
    background: #e6eef2;
    float: left;
    width: 100%;
  }
}

// add style for multi select component

.setting-table {
  td {
    p {
      margin-bottom: 0;
    }
  }
}

.custom-dropdown-checkbox {
  list-style: none;

  .setting-submenu {
    list-style: none;
    margin-left: 20px;
  }

  .new-setting-tabs-a {
    color: #667680;
    display: block;
    margin-bottom: 10px;
    text-decoration: none;
  }

  li {
    margin-bottom: 10px;
  }
}

.table-responsive {
  .services-name-checkbox,
  .services-location {
    vertical-align: top;
    width: 50%;
  }

  .services-location {
    .rct-checkbox,
    .rct-icon {
      display: none;
    }

    ol ol {
      padding-left: 0;
    }
  }
}

.react-checkbox-tree {
  overflow: hidden;
  text-overflow: ellipsis;

  label {
    font-weight: normal;

    &:hover {
      background: none;
    }
  }

  .rct-node-icon {
    display: none;
  }

  .rct-text {
    position: relative;
    width: max-content;
    padding-right: 30px;
  }

  .rct-collapse-btn {
    position: absolute;
    right: 0;
  }

  .rct-title {
    font-size: 14px;
  }

  .rct-node-leaf {
    .rct-collapse {
      display: none;
    }
  }

  .rct-checkbox {
    position: relative;
    border: 1px solid #dcdcdc;
    border-radius: 1px;
    background: white;
    outline: none;
    box-sizing: border-box;
    padding: 0;
    width: 18px;
    height: 18px;
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    margin-right: 4px;
    margin-top: 3px;

    .rct-icon-half-check {
      &:before {
        content: none;
      }
    }

    .rct-icon-uncheck {
      &:before {
        content: none;
      }
    }

    .rct-icon-check {
      background: url(/../images/new-check.png) no-repeat 4px 6px;
      width: 18px;
      height: 18px;
      position: absolute;
      top: -5px;
      left: -2px;

      &:before {
        content: none;
      }
    }
  }

  .rct-icon-expand-open {
    background: url(/../images/new-down-arrow.svg) no-repeat 4px 6px;
    width: 24px;
    height: 24px;
    transform: rotate(90deg);

    &:before {
      content: none;
    }
  }

  .rct-icon-expand-close {
    background: url(/../images/new-down-arrow.svg) no-repeat 0 3px;
    width: 24px;
    height: 24px;
    transform: rotate(0);

    &:before {
      content: none;
    }
  }
}

.bulk-delete-button {
  margin-right: 12px;
}

.time-conflict-modal {
  display: flex;
  flex-direction: column;
  max-height: 90vh;

  .modal-body {
    overflow: auto;
  }

  .modal-header {
    min-height: 58px;
  }
}
