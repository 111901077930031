@import "../../SalesCheckoutInvoice.variables.scss";

.completeBtn {
  margin: 0 auto;
  padding: 0 110px;
  margin-top: 16px;

  @media screen and (max-width: $mobileBreakpoint) {
    padding: 0;
    width: 100%;
  }
}
