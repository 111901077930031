@import "../../../../styles/theme.scss";
@import "./variables.scss";

.root {
  @media screen and (max-width: $mobileBreakpoint) {
    padding: 0 0 16px 0;
    border-bottom: 1px solid $cGray3;
  }
}

.label {
  font-weight: 600;
  @media screen and (max-width: $mobileBreakpoint) {
    flex: 1;
    justify-content: space-between;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-left: 8px !important;
  @media screen and (max-width: $mobileBreakpoint) {
    border-bottom: none !important;
    padding: 10px 0 0 8px !important;
  }
  @media screen and (max-width: $mobileXsBreakpoint) {
    padding: 10px 0 0 4px !important;
  }
}

.header {
  @media screen and (max-width: $mobileBreakpoint) {
    flex: 1;
  }
}

.location {
  color: $cGray1;
  font-size: 16px;
  width: $locationWidth;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  @media screen and (max-width: $tabletBreakpoint) {
    width: $locationWidthTablet;
  }
  @media screen and (max-width: $mobileBreakpoint) {
    width: auto;
  }
}
