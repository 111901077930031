.head {
  font-size: 13px;

  * {
    text-align: left;
  }
}

.col {
  font-size: 13px;
  width: 130px;
}
