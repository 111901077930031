.root {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.ranges {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
  gap: 16px;
  width: 700px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 550px) {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}
