.multiPostFromFilesRoot {
  min-height: unset !important;
}

.multiPostFromFilesFooter {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
}

.multiPostFromFilesRow {
  padding: 0 10px;
}

.multiPostFromFilesEditorContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
}

.singlePostRichEditor {
  iframe {
    height: 420px !important;
  }
}

.singlePostTextarea {
  height: 420px !important;
}

.multiPostFromFilesTextarea {
  height: 100%;
}
