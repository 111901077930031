@import "../sass/base.scss";

.header {
  padding-bottom: 13px;
  padding-left: 5px;

  @media screen and (max-width: $tabletBreakpoint) {
    padding: 15px 20px;
  }
}

.box {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.05);

  hr {
    margin: 0;
    border-color: $cGray3;
  }
}

.node {
  border-bottom: 1px solid $cGray3;

  &:last-child {
    border-bottom: none;
  }
}
