@import "../../../styles/theme.scss";
@import "./variables.scss";

.mb10 {
  margin-bottom: 10px;
}

/* Common */

.pageTitle {
  font-size: 25px;
  font-family: $primaryFont;
  margin-bottom: 40px;
}

.appointmentContainer {
  font-family: $secondaryFont;
}

/* Types */

.types {
  display: flex;
  @media screen and (max-width: $mobileMaxBreakpoint) {
    flex-direction: column;
  }

  .typeLabel {
    margin-right: 50px;
  }

  .typeGutter {
    margin-right: 135px;
  }
}

/* Services */

.services {
  .servicesWrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 20px;
    row-gap: 10px;
    @media screen and (max-width: $desktopMaxBreakpoint) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .servicesInner {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 10px;
    @media screen and (max-width: $desktopMaxBreakpoint) {
      width: $entityRowContentMaxWidth;
      column-gap: 10px;
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: $tabletMaxBreakpoint) {
      width: 100%;
    }
    @media screen and (max-width: $mobileMaxBreakpoint) {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      & > * {
        width: 100%;
      }
    }
  }

  .serviceSelect {
    width: $entityRowContentMaxWidth;
    min-width: $entityRowContentMaxWidth;
    @media screen and (max-width: $tabletMaxBreakpoint) {
      width: 100%;
      min-width: unset;
    }
  }

  .servicesDurationWrap {
    display: flex;
  }

  .servicesDurationLabel {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: $cGray0;
    margin-right: 20px;
    @media screen and (max-width: $desktopMaxBreakpoint) {
      display: none;
    }
  }

  .serviceDurationRightAdornment {
    padding-right: 8px;
  }

  .serviceDurationInput {
    width: 140px;
    @media screen and (max-width: $desktopMaxBreakpoint) {
      width: inherit;
    }
  }

  .serviceDuration {
    @media screen and (max-width: $tabletMaxBreakpoint) {
      width: 100%;
    }
  }

  .servicesDivider {
    height: 1px;
    width: $entityRowContentMaxWidth;
    border: none;
    background-color: $cGray2;
    margin: 5px 0;
    display: none;
    @media screen and (max-width: $desktopMaxBreakpoint) {
      display: block;
    }
    @media screen and (max-width: $tabletMaxBreakpoint) {
      width: 100%;
    }
  }
}

/* WaitList date & times */

.waitListDateTime {
  display: flex;
  padding-top: 10px;
  margin-bottom: 50px;
  @media screen and (max-width: $desktopMaxBreakpoint) {
    flex-direction: column;
    margin-bottom: 30px;
  }

  .waitListDateTimeLabel {
    font-size: 20px;
    margin-bottom: 20px;
    color: $cBlack;
  }

  .waitListDateTimeDays {
    display: flex;
    align-items: center;
    column-gap: 20px;
    @media screen and (max-width: $tabletMaxBreakpoint) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
    }
  }

  .waitListDateTimeRow {
    margin-bottom: 40px;
  }

  .waitListDateTimeMonthYear {
    display: grid;
    grid-template-columns: 303px 303px;
    gap: 20px;
    @media screen and (max-width: $desktopMaxBreakpoint) {
      grid-template-columns: 1fr 1fr;
      width: $entityRowMaxWidth;
    }
    @media screen and (max-width: $tabletMaxBreakpoint) {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;
    }
  }

  .selectYearDropdown {
    height: fit-content;
  }

  .waitListDateTimeTimes {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 19px;
    @media screen and (max-width: $desktopMaxBreakpoint) {
      width: $entityRowMaxWidth;
    }
    @media screen and (max-width: $tabletMaxBreakpoint) {
      width: 100%;
    }
  }

  .waitListDateTimeTimesWrap {
    padding-left: 90px;
    margin-bottom: 40px;
    @media screen and (max-width: $desktopMaxBreakpoint) {
      padding-left: 0;
    }
  }
}

/* CC Details */

.ccDetails {
  .ccDetailsCard {
    display: grid;
    grid-template-columns: 10fr 5fr;
    gap: 10px;
    @media screen and (max-width: 700px) {
      display: flex;
      flex-direction: column;
    }
  }
}

/* Out of office Ends */

.ends {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .endsRow {
    display: grid;
    grid-template-columns: 1fr 1fr;
    @media screen and (max-width: 430px) {
      grid-template-columns: 1fr 2fr;
    }
  }

  .endsOccurrenceLabel {
    font-size: 14px;
    margin-top: 5px;
  }

  .endsInput {
    width: 100%;
    @media screen and (max-width: 430px) {
      font-size: 13px;
      input {
        font-size: 13px;
      }
    }
  }

  .endsPickerInputError {
    background-color: $cRedDim !important;
    color: $cRed !important;
  }
}

/* Out of office Repeat every */

.repeatEvery {
  .repeatEveryWeekWrap {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
  }

  .repeatEveryRow {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    @media screen and (max-width: $mobileMaxBreakpoint) {
      display: flex;
      flex-direction: column;
    }
  }
}

/* Date Time */

.dateTime {
  display: flex;
  gap: 20px;
  @media screen and (max-width: 1270px) {
    flex-direction: column;
  }

  .dateTimePicker {
    width: $entityRowContentMaxWidth;
    @media screen and (max-width: $tabletMaxBreakpoint) {
      width: 100%;
    }
  }

  .dateTimeInner {
    display: flex;
    align-items: center;
    gap: 20px;
    flex: 1;
    @media screen and (max-width: $mobileMaxBreakpoint) {
      gap: 10px;
      flex-direction: column;
    }
  }

  .dateTimeSelect {
    width: 180px;
    @media screen and (max-width: $mobileMaxBreakpoint) {
      width: 100%;
    }
  }
}

.dateTimeCheckboxes {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Appointment Date Time */

.appointmentDateTime {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.appointmentDateTimePicker {
  width: 750px;
  @media screen and (max-width: 1060px) {
    width: 100%;
  }
}

/* Alert */

.alert {
  width: $entityRowMaxWidth;
  @media screen and (max-width: $tabletMaxBreakpoint) {
    width: 100%;
  }
}

/* Confirm Waitlist Services Modal */

.waitlistServicesConfirmModal {
  .modalHeading {
    font-size: 18px;
    font-family: 600px;
    margin-bottom: 12px;
  }

  table {
    width: 100% !important;

    tr {
      display: flex;
      align-items: center;

      td {
        padding: 8px 4px;
      }

      .priceColumn {
        padding: 0 10px !important;
      }

      button {
        margin: auto 6px;
      }
    }
  }
}

.waitlistServicesConfirmModalFooter {
  display: flex;
  gap: 10px;

  margin-top: 32px;
}

.convertWaitlistModal {
  .clinicProviderFields {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
  }

  .footerButtons {
    display: flex;
    gap: 15px;
    @media screen and (max-width: 430px) {
      flex-direction: column;
    }
  }
}
