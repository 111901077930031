.root {
  display: flex;
  gap: 16px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
}
