@import "../../../../../styles/theme.scss";

.root {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.authorizationContainer {
  display: flex;

  .authorizationBox {
    flex: 4;
  }

  .integrationType {
    flex: 1;
  }
}

.clinicsBox {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.saveBtn {
  display: flex;
  justify-content: flex-end;
  padding: 16px;
}

.clinicRow {
  display: flex;
  padding: 15px;
  border-top: 1px solid $cGray3;

  .clinicName {
    display: inline-block;
    width: 50%;
  }

  .locationSelect {
    width: 50%;
  }
}

.autorizationText {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 600;

  svg {
    color: #228c22;
  }
}

.changeSettingsText {
  font-size: 14px;
  font-family: "Lato", sans-serif;
  color: #667680;
}

.updateButton {
  max-width: 150px;
}

.apiKeyInput {
  max-width: 50%;
}

.updateApiKeyFooter {
  display: flex;
  border-top: 1px solid $cGray3;
  gap: 8px;
  padding-top: 15px;
}

.expandButtonContainer {
  border-bottom: 1px solid $cGray3;

  &.active {
    padding-bottom: 16px;
  }
}

.expandListButton {
  display: flex;
  width: 70%;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  color: #667680;
  font-size: 16px;
  background: transparent;
  border: none;
  padding: 16px 0;

  .expandIcon {
    display: inline-block;
    margin-top: 2px;

    &.expanded {
      transform: rotate(180deg);
      margin-top: 0;
    }
  }
}
