$mobileBreakpoint: 650px;

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  @media screen and (max-width: $mobileBreakpoint) {
    flex-direction: column;
    gap: 8px;

    .left,
    .right,
    .search,
    .right > * {
      width: 100%;
    }
  }

  .left {
    display: flex;
    align-items: center;
    gap: 16px;

    .titleWrap {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .title {
      font-size: 20px;
      font-weight: 600;
    }

    @media screen and (max-width: $mobileBreakpoint) {
      flex-direction: column;
      gap: 8px;
    }
  }

  .right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
  }
}
