@import "../../../../../../styles/theme.scss";

.root {
  position: relative;
}

.mb {
  margin-bottom: 20px;
}

.mt {
  margin-top: 10px;
}

.dropdown {
  position: absolute;
  right: 0px;
  left: 0px;
  top: 55px;
  border-radius: 8px;
  z-index: 9;
  max-height: 306px;
  overflow-y: auto;
  width: 100% !important;
  background: $cWhite;
  border: 1px solid $cGray2;

  button {
    cursor: pointer;
    display: block;
    user-select: none;
    list-style: none;
    padding: 15px;
    font-size: 15px;
    color: $cBlack;
    border: none;
    background: none;
    text-align: start;
    width: 100%;

    &:hover {
      background-color: $cPrimaryDim;
    }
  }

  .clientInfo {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    font-weight: 400;
    color: $cGray0;
    margin-top: 8px;
    flex-wrap: wrap;

    .clientPhone {
      margin-right: 27px;
    }
  }

  .createBtn {
    display: flex;
    gap: 14px;
    color: $cPrimary;
    border-bottom: 1px solid $cGray2;
    align-items: center;
  }

  .status {
    padding: 15px;
    text-align: center;
  }

  .patient {
    border-bottom: 1px solid $cGray2;

    &:last-child {
      border-bottom: none;
    }
  }
}

.favoritesHandlerContainer {
  position: relative;
}

.favoritesInput {
  margin-bottom: 8px;
}

.favoritesOptionsList {
  background: white;
  max-height: 150px;
  display: flex;
  flex-direction: column;
  border: 1px solid #c3c9ce;
  border-radius: 8px;
  overflow: auto;
  position: absolute;
  top: 55px;
  left: 0;
  right: 0;
  z-index: 1;

  .favoritesOption {
    display: flex;
    flex-direction: column;
    background: white;
    text-align: left;
    padding: 10px;
    border: none;
    border-bottom: 1px solid #c3c9ce;

    .optionFavoriteName {
      font-size: 12px;
      font-weight: 600;
      color: #b5b5b5;
    }
  }

  .createFavorite {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    color: #188bf6;
  }

  .favoritesOption:hover {
    background: #f5f5f5;
  }
}

.efaxTests {
  display: flex;
  flex-direction: column;
  gap: 8px;

  button {
    background: none;
    border: none;
  }

  .testsRow {
    display: flex;
    align-items: center;
    gap: 8px;

    input {
      flex: 0 0 auto;
      margin: 0;
    }

    input[type="checkbox"]:checked.squareCheck::after {
      content: "\25A0";
      font-size: 30px;
      top: -15px !important;
      left: -1px;
      color: rgb(13, 130, 198);
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 8px;
    list-style-type: none;

    li {
      margin-left: 32px;
    }
  }
}