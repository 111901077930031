@import "../../styles/theme.scss";

.root {
  border: 1px solid $cGray1;
  border-radius: 8px;
  height: 50px;
  padding: 0 15px;
  color: $cBlack;
  font-size: 16px;
  font-weight: 400;
  width: 100%;
  background-color: $cWhite;
  background-image: linear-gradient(45deg, transparent 50%, $cBlack 50%),
    linear-gradient(135deg, $cBlack 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 6px),
    calc(100% - 15px) calc(1em + 6px);
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  padding-right: 32px;
}

.sSmall {
  height: 36px;
  padding: 0 10px;
  font-size: 14px;
  background-position: calc(100% - 15px) calc(1em + 2px),
    calc(100% - 10px) calc(1em + 2px);
  border-radius: 4px;
}

.error {
  background-color: $cRedDim2 !important;
  color: $cRed !important;
  background-image: linear-gradient(45deg, transparent 50%, $cRed 50%),
    linear-gradient(135deg, $cRed 50%, transparent 50%);

  &::placeholder {
    color: $cRed !important;
  }
}

.disabled {
  background-color: $cGray4 !important;
  pointer-events: none;
  cursor: default;
  user-select: none;
  color: $cGray0 !important;
  background-image: linear-gradient(45deg, transparent 50%, $cGray0 50%),
    linear-gradient(135deg, $cGray0 50%, transparent 50%);

  &::placeholder {
    color: $cGray2 !important;
  }
}
