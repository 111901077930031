@import "../../styles/theme.scss";

.root {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.title {
  margin-bottom: 16px;
}

.question {
  font-size: 18px;
  font-weight: bold;
}

.answer {
  font-size: 16px;
  color: $cGray0Dark;
  word-break: break-word;
}

.noAnswer {
  font-size: 16px;
  color: $cGray0;
}

.imageAnswer {
  padding-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.answers {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.questionWrap {
  border-bottom: 1px solid $cGray3;
  background-color: $cGray5;
  padding: 16px;
}
