@import "../../styles/theme.scss";

.root {
  border-radius: 8px;
  border: 1px solid $cGray1;
  height: 50px;
  min-height: 50px;
  background-color: $cWhite;
  display: flex;
  padding: 0 15px 1px 15px;
  font-size: 16px;
  font-weight: 400;
  color: $cBlack;
  width: 100%;

  &:focus:not(.rootError) {
    border-color: $cPrimary;
  }
}

.rootMedium {
  height: 44px;
  min-height: 44px;
}

.rootSmall {
  height: 36px;
  min-height: 36px;
  border-radius: 4px;
  font-size: 14px;
  padding: 0 10px 1px 10px;
}

.rootError {
  border-color: $cRed;
  background-color: $cRedDim2;

  &::placeholder {
    color: $cRed;
  }
}

.rootDisabled {
  background-color: $cGray4;
  pointer-events: none;
  cursor: default;
  user-select: none;
  color: $cGray0;

  &::placeholder {
    color: $cGray0;
  }
}
