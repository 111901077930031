@import "../../../../../styles/theme.scss";

.root {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.infoBtn {
  padding-top: 3px;
}

.formBox {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.formFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    color: $cGray1;
  }
}
