@import "../../styles/variables.scss";

.root {
  display: flex;
  margin: 0 auto;
  max-width: 1400px;
  gap: 40px;
  padding: $headerHeight + 16px 30px 40px;

  @media screen and (max-width: 1200px) {
    padding-top: $headerHeight + 16px + 55px;
  }

  @media screen and (max-width: 1050px) {
    padding-top: 16px + 55px;
  }

  @media screen and (max-width: 991px) {
    flex-direction: column;
    gap: 0;
  }

  @media screen and (max-width: 767px) {
    padding-top: 12px;
  }

  @media screen and (max-width: 600px) {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 20px;
  }
}

.content {
  flex: 1;
}
