@import "../../../../styles/theme.scss";

.manualChip {
  font-size: 12px;
  border-radius: 4px;
  font-style: italic;
  color: $cPrimary;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  cursor: default;
}

.dayTd {
  display: flex;
  align-items: center;
}

.dayName {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;

  .overtime {
    font-style: italic;
    font-size: 13px;
    display: flex;
    align-items: center;
    gap: 4px;

    svg {
      color: $cRed;
    }
  }
}
