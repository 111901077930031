@import "../../../../../../styles/theme.scss";

.root {
  display: flex;
  flex-direction: column;
  gap: 8px;

  h4 {
    margin-bottom: 4px;
    color: $cGray1;
  }
}
