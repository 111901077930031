.table-responsive {
  border-radius: 0px 0px 5px 5px;
  overflow: auto;
}
.td-break-content td{
  word-break: break-all !important;
}
/*# sourceMappingURL=clinics.css.map */
.clinicHeading{
  font-size: 20px;
  color: #000000;
  padding: 18px;
  border-bottom: 1px solid #ddd;
  margin-bottom:10px;
  position: relative;
  padding-right:70px;

  .easy-link{position:absolute;right:15px;top:18px;}
}
.businessWeekName{width:40px;display:inline-block;}
.businessWeekrow{margin-bottom:10px;}
.clinicAddressSection{min-height:375px;border:none;}
.bold-black { color: #000000;}

.clinic-card {
  height: 470px;
}