.root {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
}

.skeleton {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;

  br {
    display: none;
  }
}

.node {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.nodeCount {
  font-size: 25px;
  font-weight: 600;
}

.nodeName {
  font-size: 14px;
  margin-top: 8px;
}
