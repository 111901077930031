@import "../../../../../styles/theme.scss";

.amountRow {
  display: flex;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;

  .amount {
    font-size: 25px;
    font-weight: 600;
  }

  .tip {
    font-size: 16px;
    font-weight: 400;
    color: $cGray1;
  }
}

.tipHeader {
  font-size: 16px;
  font-weight: 400;
}

.tipsWrap {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
