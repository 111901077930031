.root {
  font-size: 20px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 550px) {
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
  }
}
