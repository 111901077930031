ul.appointment-config-link {
  background-image: url(/../images/step-line.png);
  background-repeat: repeat-y;
  background-position: left 10px top 10px;
  background-size: 5px auto;
  margin-top: 22px;

  li {
    a {
      position: relative;
      text-transform: uppercase;
      font-size: 12px;
      padding: 22px 0px 22px 36px;
      color: #404040;

      &.unselect:after {
        content: "";
        font-size: 27px !important;
        position: absolute;
        left: 0px;
        top: 16px;
        width: 25px;
        height: 25px;
        cursor: pointer;
        background: #ffffff;
        border: 2px solid #707070;
        @include border-radius(50px);
      }

      &.select {
        color: $blue;

        &:after {
          content: "";
          font-size: 27px !important;
          position: absolute;
          left: 0px;
          top: 16px;
          width: 25px;
          height: 25px;
          cursor: pointer;
          background: $blue;
          border: 2px solid $blue;
          @include border-radius(50px);
        }
      }

      &.complete {
        color: #bfc9ce;

        &:after {
          content: "";
          font-size: 27px !important;
          position: absolute;
          left: 0px;
          top: 16px;
          width: 25px;
          height: 25px;
          cursor: pointer;
          border: 2px solid #bfc9ce;
          @include border-radius(50px);
          background: url(/../images/step-check.png) no-repeat center #bfc9ce;
          background-size: 17px auto;
        }
      }
    }

    &:first-child a {
      padding-top: 0px;

      &:after {
        top: -8px !important;
      }
    }

    &:last-child a {
      padding-bottom: 0px;

      &:after {
        top: 16px !important;
      }
    }
  }
}

.appointment-container {
  padding: 20px;
  float: left;
  width: 100%;
}

.appointmenTable {
  th {
    padding: 10px 10px 10px 15px !important;
  }

  th,
  td {
    border-left: none !important;

    i {
      position: initial;
      margin-top: 0px;
      margin-top: 0px !important;
      display: inline-block;
      vertical-align: top;
    }
  }
}

ul.appointment-tabs {
  list-style: none;
  display: block;
  float: left;
  margin: 0px 0px 34px;
  width: 100%;

  li {
    display: inline-block;

    a {
      font-size: 16px;
      color: #667680;
      display: block;
      padding: 15px 30px 15px;
      border-bottom: 2px solid #ffffff;

      &.active {
        border-bottom: 2px solid $blue;
        color: $blue;
      }
    }
  }
}

ul.tabWithSwitch {
  li {
    width: auto;
    display: inline-block;

    a {
      padding: 0px 0px 15px 9px;
      font-size: 15px;

      .setting-switch {
        visibility: hidden;
        margin-left: 10px;
      }

      &.active {
        .setting-switch {
          visibility: visible;
        }
      }
    }
  }
}

.newInputFileldOuter {
  float: left;
  width: 100%;
  position: relative;
  margin-bottom: 10px;
  margin-top: 7px;

  .newInputLabel {
    float: left;
    font-size: 13px;
    color: $blue;
    background: #ffffff;
    position: relative;
    padding: 0px 4px;
    top: 1px;
    min-height: 18px;
    z-index: 1;

    .fieldRequired {
      color: $blue;
    }
  }

  .newInputField {
    width: 100%;
    float: left;
    color: #404040;
    border: 1px solid #cad2d6;
    @include border-radius(3px);
    height: 36px;
    padding: 0px 15px;
    font-size: 14px;
    background: #ffffff;
  }

  .newInputFileldOuter .selected-flag {
    width: 34px !important;
    border: none !important;
    margin-left: 8px;
    margin-top: 0px;
  }

  div.newInputField {
    padding: 9px 15px;
  }

  a.newInputField {
    padding: 8px 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #0472b3;
    }
  }

  .newSelectField {
    width: 100%;
    float: left;
    color: #404040;
    border: 1px solid #cad2d6;
    @include border-radius(3px);
    background-image: url(/../images/select-arrow2.png);
    background-repeat: no-repeat;
    background-position: right 8px center;
    background-size: 10px auto;
    background-color: #ffffff;
    height: 36px;
    padding: 0px 22px 0px 15px;
    font-size: 14px;
  }

  .newtextareaField {
    width: 100%;
    float: left;
    color: #404040;
    border: 1px solid #cad2d6;
    @include border-radius(3px);
    height: 150px;
    padding: 10px 15px;
    font-size: 14px;
  }
}

.AppointmentSubtitle {
  font-size: 17px;
  float: left;
  width: 100%;
  margin: 15px 0px 30px;

  .setting-switch {
    float: none;
    display: inline-block;
    vertical-align: top;
    margin: 0px 5px;
  }
}

.snippets {
  float: left;
  width: 100%;
  font-size: 14px;

  .easy-link {
    display: none;
    margin-left: 10px;
  }

  p {
    float: left;
    width: 100%;
    margin-bottom: 5px;

    &:hover {
      color: #404040;
      cursor: pointer;

      .easy-link {
        display: inline-block;
      }
    }
  }
}

.sm-switch {
  height: 19px;
  width: 39px;
  margin-top: 2px !important;

  .setting-slider:before {
    height: 14px;
    width: 14px;
  }
}

.appointment-content {
  float: left;
  width: 100%;
  min-height: 255px;

  .custom-edit {
    #pportal_subdomain {
      padding: 8px 15px;
    }
  }
}

.editField {
  position: absolute;
  right: -31px;
  top: 15px;

  img {
    height: 23px;
  }
}

.appTimeSetting {
  position: relative;
  display: inline-block;
  clear: both;
  margin-bottom: 30px;

  .editField {
    top: 0px;
  }
}

.easy-link.select {
  position: absolute;
  top: 9px;
  right: 10px;
  padding: 0px;
  background: #ffffff;
  padding-left: 10px;
}

.business-hours-outer.equipment-hours {
  margin-top: 0px !important;

  .datetimeparent {
    border-bottom: 1px solid #cad2d6;
    float: left;
    width: 100%;
    padding-top: 23px;
  }

  .setting-input-box,
  .newInputField {
    color: #404040;
    border: 1px solid #cad2d6;
    @include border-radius(3px);
    height: 36px !important;
    padding: 0px 15px;
    width: 80px !important;
    margin-right: 10px !important;
    margin-bottom: 10px !important;
  }

  .newInputLabel {
    margin: 0px 3px -8px;
    padding: 0px 2px;
    position: absolute;
    top: -9px;
  }

  .newSelectField {
    color: #404040;
    border: 1px solid #cad2d6;
    @include border-radius(3px);
    height: 36px !important;
    padding: 0px 15px;
    width: 80px !important;
    margin-right: 10px !important;
    margin-bottom: 10px !important;
  }

  .hours-pm {
    width: 56px !important;
    padding-left: 5px;
    margin-bottom: 10px !important;
    margin-right: 0px !important;
  }
}

.equip-field-outer {
  width: auto;
  margin: -10px 0px 0px;
  padding-left: 0px;
}

.equipment-hours .add-round-btn {
  right: -30px;
}

.email-msgs .setting-slider {
  background: $blue;
  border: 1px solid $blue;
}

.msg-active {
  color: #222222 !important;
}

ul.smallTab li a {
  font-size: 14px;
  padding: 0px 13px 15px;
}

.url-first {
  position: absolute;
  top: 19px;
  left: 10px;
  padding: 0px;
  background: #ffffff;
  padding-left: 10px;
  color: #404040;
  font-weight: bold;
}

.url-last {
  position: absolute;
  top: 19px;
  right: 10px;
  padding: 0px;
  background: #ffffff;
  padding-left: 10px;
  color: #404040;
  font-weight: bold;
}

#pportal_subdomain {
  padding: 0px 70px;
}

.newInputFileldOuter .tag-auto-select {
  border: 1px solid #cad2d6 !important;
  @include border-radius(3px);
  min-height: 39px !important;
  display: flex;
  display: -webkit-flex;
  padding-top: 4px;
  width: 100%;
}

.newInputFileldOuter .setting-input-outer {
  padding-top: 0px;
}

.newInputFileldOuter .flag-input {
  height: 34px;
}

.newInputFileldOuter .tag-auto-select svg {
  width: 15px;
  margin-top: -2px;
  margin-right: 2px;
}

.newInputFileldOuter .tag-auto-select > div {
  width: 100%;
}

.newInputFileldOuter .tag-auto-select > div > div:first-child {
  border: none !important;
  color: #404040;
  background: none !important;
  min-height: inherit !important;
}

.newInputFileldOuter .tag-auto-select > div > div:nth-child(2) {
  border: none !important;
  color: #404040;
  background: none !important;
  min-height: inherit !important;
}

.newInputFileldOuter .tag-auto-select > div > div:nth-child(3) {
  z-index: 2;
}

.resourceSurveyRow {
  position: relative;
  float: left;
  width: 100%;
  position: relative;

  .add-round-btn {
    top: 24px !important;
  }

  .newSelectField {
    margin-bottom: 0px !important;
  }
}

.appointment-container .communication-tabs li a {
  padding: 15px 13px 15px;
}

.newInputFileldOuter a.newInputField {
  color: $blue;
}

.providerCal {
  .privider-calender-popup {
    margin-top: 5px;
  }
}

.calendar-provider-schedule .rbc-today {
  background-color: #c2e0f2 !important;
}

.calendar-provider-schedule .rbc-event {
  width: 100%;
  text-align: center;
}

.appointmentTimeSettingsEdit {
  top: 0px !important;
}

.smart-configuration-title {
  font-size: 20px !important;
}

.booking-url-title {
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: -0.30000001192092896px;
  color: #667680;
  margin-bottom: 4px;
}

.booking-url-wrapper {
  max-width: 660px;
}

.booking-setting-section {
  background: rgba(102, 118, 128, 0.05);
  border-radius: 2px;
  padding: 22px 25px;
  margin-bottom: 22px;

  @media (max-width: 767px) {
    padding: 22px 5px;
  }

  booking-setting-subcaption,
  .booking-setting-caption,
  .booking-setting-title {
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: -0.30000001192092896px;
    color: #667680;
    margin-bottom: 24px;

    @media (max-width: 767px) {
      line-height: 16px;
    }
  }

  .booking-setting-caption {
    font-size: 15px;
    margin: 0;
  }

  .booking-setting-subcaption {
    font-size: 13px;
    font-weight: 400;
    margin: 0;
  }

  .booking-editField {
    right: 0;

    .booking-editField-img {
      height: 18px;
      margin-bottom: 1px;
    }
  }

  .booking-setting-switch-wrapper {
    @media (max-width: 767px) {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .setting-switch {
        flex: 0 0 39px;
        margin-left: 5px;
      }
    }
  }
}

.list-booking-contact {
  li {
    a {
      color: $blue;
    }
  }
}

.booking-theme-tab {
  .m-t--10 {
    margin-top: -10px;
  }

  .new-check {
    display: inline-block;
    height: 20px;
    width: 20px;
    margin-bottom: 11px;

    &:checked {
      &:after {
        top: 5px;
        left: 5px;
        width: 7px;
        height: 7px;
      }
    }

    @media (max-width: 767px) {
      height: 13px;
      width: 13px;
      margin-bottom: 3px;

      &:checked {
        &:after {
          top: 4px;
          left: 4px;
          width: 4px;
          height: 4px;
        }
      }
    }
  }

  label {
    cursor: pointer;
    height: 30px;

    svg {
      //height: 30px;

      @media (max-width: 767px) {
        height: 20px !important;
        width: 90px !important;
      }
    }
  }
}

.booking-upload-img {
  .simpleInput {
    position: relative;
    height: 150px;

    .icon-upload,
    .image_questionnaire {
      cursor: pointer;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      width: 200px;
      height: 60px;

      i {
        margin: 0;
      }

      span {
        display: block;
      }
    }
  }

  .file-upload-name {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    max-width: 280px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .image-upload {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 100px;
    z-index: 2;

    button {
      position: absolute;
      top: -10px;
      right: -10px;
      border-radius: 50%;
      min-width: 18px;
      height: 18px;
      margin: 0;
      padding: 4px;
      cursor: pointer;
      line-height: 10px;

      svg {
        width: 10px;
        height: 10px;
      }
    }

    img {
      height: 100px;
    }
  }
}

.business-setion .customTaxRule .switch-accordian-row {
  padding-left: 50px;
}

.business-setion .customTaxRule .switch-accordian-row .setting-switch {
  left: 0;
}
