.infoContainer {
  display: flex;
  gap: 5px;
  justify-content: space-between;
  align-items: center;

  .infoBlock {
    display: flex;
  }

  .idSection {
    display: flex;
    align-items: center;
    font-size: 16px;
  }

  .idTitle {
    font-weight: 700;
    text-transform: uppercase;
  }

  .userId {
    font-weight: 700;
    margin-left: 5px;
  }
}

.portalData {
  padding: 0 15px;
}

.sectionTitle {
  padding: 10px 15px;
}

.allergyIcon {
  display: inline-block;
  background: url("/../images/alarm-icon.svg") no-repeat;
  background-size: cover;
  height: 16px;
  width: 16px;
  margin: 0 0 0 6px;
}

.btnRedirectToEdit {
  background: none;
  border: none;
  font-weight: 700;
}
