@import "../../styles/theme.scss";

.root {
  display: flex;
  gap: 8px;
  align-items: center;

  button {
    border: none;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 35px;
    min-height: 35px;
    border-radius: 50%;
    transition: all 0.15s ease;

    img {
      transform: rotate(90deg);
      margin-right: 1px;
    }

    &:last-child img {
      transform: rotate(-90deg);
      margin-left: 2px;
    }

    &:hover {
      background-color: $cPrimaryDim;
    }
  }

  .range {
    font-size: 15px;
  }
}

.disabled {
  user-select: none;
  cursor: default;
  pointer-events: none;
  opacity: 0.6;
}
