.downloadOptions {
  position: absolute;
  background: white;
  font-size: 13px;
  border: 1px solid #dddddd;
  border-radius: 5px;
  margin-top: 5px;
  z-index: 1;

  ul {
    margin: 0;
    width: 100px;
  }

  li {
    list-style: none;
    border-bottom: 1px solid #dddddd;

    button {
      width: 100%;
      background: none;
      border: none;
      color: black;
      padding: 10px 15px;
      text-align: left;
    }
  }

  li:hover,
  li.active {
    font-weight: bold;
  }
}
