@import "../../../../../../styles/theme.scss";

.root {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 13px;
}

.approved {
  color: $cGreen;
}

.rejected {
  color: $cRed;
}

.expired {
  color: $cOrangeDark;
}

.label {
  color: lighten($cBlack, 40%);
}
