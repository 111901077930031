.root {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.templateOptions {
  ul {
    list-style: none;
    border: 1px solid #ddd;
    max-height: 306px;
    overflow-y: auto;

    li {
      font-size: 13px;
      border-bottom: 1px solid #ddd;
      cursor: pointer;

      &:hover {
        background-color: #f5f5f5;
      }
    }
  }
}

.textarea {
  max-height: 600px;
  font-size: 14px;
}

.red {
  color: #bf2828;
}

.small {
  font-size: 12px;
}

.mt {
  margin-top: 5px;
}

.templateOptionsButton {
  width: 100%;
  text-align: left;
  background: white;
  border: none;
  padding: 8px;
}

.templateOptionsButton:hover {
  background: #f5f5f5;
}

.testsContainer {
  max-height: 300px;
  overflow: auto;
}

.favoritesLabel {
  color: #7e7e7e;
  font-weight: bold;
  font-size: 14px;
}