@import "../../../../../../../../../styles/theme.scss";

.root {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 16px;
  max-width: 1200px;

  @media screen and (max-width: 430px) {
    display: flex;
    flex-direction: column;
  }
}

.requiredAsterisk {
  color: $cRed;
}
