.note-timeline {
  text-align: left;
  display: table;
  border-left: 1px solid #dddddd;
  padding-left: 30px;
  float: left;
  width: 100%;
  margin: 40px 0 0px;
  clear: both;
  position: relative;
  padding: 0px;

  > div {
    position: relative;
    margin-bottom: 20px;

    .note-content {
      word-wrap: break-word;
      color: #667680;
      padding: 10px 15px;
      @include border-radius(3px);
      border: 1px solid #ececec;
      background: #fcfcfc;
      float: left;
      width: 100%;

      .hashTag {
        display: inline-block;
        vertical-align: top;
        font-weight: normal;
        padding: 3px 7px;
        border: 1px solid #cccccc;
        border-radius: 50px;
        margin: 10px 4px 2px 0px;
        float: left;
      }
    }

    .circle {
      width: 35px;
      height: 35px;
      display: inline-block;
      border-radius: 30px;
      border: 1px solid #ddd;
      left: -4px;
      position: absolute;
      background: #fff;
    }
    .note-subject {
      padding-left: 45px;
      .converted-history {
        p,
        h4 {
          font-size: 12px !important;
        }
      }
    }
  }
  > div:nth-child(odd) .note-content {
    background: #f5f5f5;
    color: #667680;
  }
  > div:nth-child(odd) .textarea {
    background: #f5f5f5;
    color: #667680;
  }
  .circle {
    width: 35px;
    height: 35px;
    display: inline-block;
    border-radius: 30px;
    border: 1px solid #ddd;
    left: -33px;
    position: absolute;
    background: #fff;
  }
  h4 {
    margin: 8px 0px 6px;
    font-size: 16px;
    color: #2682c5;
  }
  p {
    font-size: 13px;
    color: #667680;
  }
}

.consent-wide-popup {
  text-align: justify;
  margin-top: 70px;
  .div-consent-signature {
    .consent-signature {
      margin-top: 0px;
    }
    .signed-label {
      display: block;
      font-size: 13px;
      font-weight: normal;
    }
    .signed-user {
      display: block;
      padding-top: 5px;
      font-weight: 500;
      font-size: 13px;
      color: #777777;
      clear: both;
    }
    .signed-date {
      color: #404040;
      font-size: 15px;
      font-style: inherit;
      font-weight: 500;
      clear: both;
      margin-top: 7px;
      margin-bottom: 1px;
      display: block;
    }
  }
  .label-page-navigation {
    padding-top: 7px;
    margin-left: 10px;
  }
  .span {
    width: auto !important;
    min-width: inherit !important;
    .signed-date {
      font-size: 27px;
      display: block;
      vertical-align: top;
      height: 20px;
      line-height: 18px;
      margin: 0px 1px;
    }
  }
}
.consent-next-prev {
  min-width: 80px;
  padding: 4px 10px 4px;

  span {
    font-size: 26px;
    line-height: 19px;
    display: unset;
    width: 10px !important;
    height: 11px;
    margin-top: 7px;
    vertical-align: top;
    margin: 0px !important;
  }
}
