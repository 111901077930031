.root {
  display: flex;
  flex-direction: row;
  gap: 16px;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
}

.placeholdersHeader {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 16px;
}

.editorStyle {
  height: auto;
  border-radius: 8px;
  padding: 15px;
  border: 1px solid #b5b5b5;
}

.placeholdersBox {
  background-color: unset;
  color: black;
}
