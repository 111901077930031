@import "../../../../../../../styles/theme.scss";

.expandBtn {
  border: none;
  background: none;
  font-size: 13px;
  color: $cPrimary;
  padding: 0 3px;
  border-radius: 2px;
  margin-left: 3px;

  &:hover {
    background-color: $cPrimaryDim2;
  }
}
