.more-info-profile-dropdown ul {
  padding: 0px !important;
  right: 0px !important;
  left: inherit !important;
  @include box-shadow(0, 0px, 5px, 0, rgba(0, 0, 0, 0.175));

  li a {
    font-size: 13px !important;
    text-transform: inherit;
    border-bottom: 1px solid #dddddd;
    display: block;
    font-weight: normal;
    white-space: nowrap;
    height: 33px;
    line-height: 33px;
    padding: 0px 20px;
  }
}

.pro-pic-outer {
  float: left;
  width: 100%;
  border-radius: 3px;
  @include border-radius(3px);
  text-align: center;
  padding: 24px 24px 0px 188px;
  text-align: left;
  border: none;
  position: relative;
  min-height: 146px;

  img {
    max-height: 119px;
    max-width: 144px;
    border: inherit;
    box-shadow: inherit;
    border-radius: 3px;
    display: inline-block;
    margin-bottom: 12px;
    vertical-align: middle;
    position: absolute;
    left: 24px;
    top: 24px;
  }

  .profile-img-info {
    display: block;
    width: 100%;

    h3 {
      margin-bottom: 11px;
    }
  }

  h3 {
    font-size: 16px;
    font-weight: 500;
    color: #444444;
    margin-bottom: 5px;
    margin-top: 0px;
    display: block;
  }
}

.member-type-outer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;

  img {
    float: none;
    display: inline-block;
    margin-right: 4px;
    margin-bottom: 0px;
    position: static;
  }
}

.profile-actions {
  border-color: $blue;
  color: $blue;
  background: none;
  padding: 5px 7px 5px 16px;
  font-size: 13px !important;
  height: 30px;
}

.client-setting {
  display: block;
  clear: both;
  margin-bottom: 8px;

  >span {
    display: inline-block;
    padding: 3px 0px;
  }
}

.client-setting-dnd {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 110px;
  margin: 10px 0px;
}

.about-form {
  padding: 24px;
  width: 100%;
  float: left;

  a.popup-field-box {
    color: $blue;
  }
}

.access-portal-allrow {
  border-bottom: 1px dotted #bfc9ce;
  padding-bottom: 11px;
  margin-bottom: 23px;

  .access-portal-row {
    display: table;
    width: 100%;

    .setting-switch {
      margin-top: 6px;
    }

    .access-portal-label {
      width: 100px;
      display: table-cell;
      vertical-align: top;
      font-size: 13px;
      color: #5a5a5a;
      padding: 7px 0px;
    }

    .access-portal-content {
      display: table-cell;
      vertical-align: top;
      font-size: 13px;
      color: #5a5a5a;
      padding: 7px 0px;
      word-break: break-all;

      #resend_portal_mail {
        margin-top: -7px;
      }

      .line-btn {
        font-size: 13px;
        padding: 4px 10px;
      }
    }
  }
}

.patient-right {
  display: block;
  padding-left: 15px;
}

.section-tab-outer {
  padding: 0px 15px;
  height: 49px;
  border-bottom: 1px solid #dddddd;
  display: block;
  list-style: none;
  margin-bottom: 0px;

  li {
    display: inline-block;

    a,
    button {
      border: none;
      background: none;
      color: #444444;
      display: inline-block;
      width: auto;
      padding: 15px 10px;
      font-size: 13px;

      &.sel-merge-tab {
        color: $blue;
        border-bottom: 1px solid $blue;
      }
    }
  }
}


.time-line {
  display: block;
  // height: auto;
  overflow-y: auto;
  // overflow-x: hidden;
  float: left;
  width: 100%;
  padding: 24px;

  .timeline-outer {
    background: url(/../images/divider.png) no-repeat center bottom 32px;
    display: block;

    &:last-child {
      background: none;
    }

    >div {
      padding: 20px;
    }

    .procedure-name-time-top {
      margin-bottom: -22px;
      margin-top: -29px;
      position: relative;
      padding: 20px;

      .procedure-name-time {
        display: table;
        left: 50%;
        margin-left: -18px;
        padding-left: 44px;
        position: absolute;
        min-width: 280px;
        background: url(/../images/dot2.png) no-repeat left 9px;
        padding-top: 3px;

        .procedure-number {
          font-family: 'Roboto Condensed', sans-serif;
          font-weight: 600;
          font-size: 37px;
          color: #444;
          display: inline-block;
          vertical-align: top;
          line-height: 43px;
        }

        .pro-name-section {
          display: inline-block;
          vertical-align: top;
          position: relative;
          width: 230px;
          margin-top: 4px;

          .pro-name {
            color: #444;
            display: block;
            font-size: 18px;
            font-weight: 600;
            text-transform: uppercase;

            h4 {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              font-size: 15px;
              font-weight: 600;
            }
          }

          .pro-time {
            font-size: 12px;
            color: #888;
          }
        }
      }
    }

    &:first-child {
      .procedure-name-time {
        background: url(/../images/dot1.png) no-repeat left 9px;
      }
    }
  }
}

.invoice-btn {
  background: #c2e0f2;
  color: #404040;
  height: 28px;
  padding: 0 13px;
  border: none;
  text-transform: inherit;
  font-weight: 400;
  position: relative;
  line-height: 26px;
  margin: 5px 5px 8px 10px;
  @include border-radius(3px);
  font-size: 13px;
  display: inline-block;
}

.proc-cost {
  left: 50%;
  margin-left: -309px;
  position: absolute;
  text-align: right;
  top: 34px;
  width: 279px;
  font-weight: 500;
  font-size: 17px;
}

.timeline-left {
  padding-bottom: 50px !important;
  border-right: 2px solid $blue;
  text-align: right;

  center img {
    cursor: pointer;
  }
}

.timeline-right {
  padding-bottom: 50px !important;
  border-left: 2px solid $blue;
  left: -2px;
  position: relative;
  text-align: left;
}

.left-big-img img {
  margin-bottom: 0px;
  height: 280px;
}

.time-line img {
  max-width: 100%;
}

.timeline-detail {
  text-align: left;
  display: table;
  margin: 20px 0;
  width: 100%;

  h4 {
    margin: 0px 0px 25px;
    font-size: 16px;
    text-transform: uppercase;
    display: block;
    color: $blue;
  }
}

.profile-treat-sumry {
  width: 100%;
  border: 1px solid #ddd;
  margin: 0px;
  @include border-radius(5px);
  list-style: none;
  float: left;

  .product-name {
    padding-bottom: 0px !important;
    margin-left: 8px;
    word-wrap: break-word;
    width: calc(100% - 22px);
  }

  .color-box {
    width: 22px;
    height: 21px;
    border-radius: 4px;
  }

  li {
    padding: 0px;
    background: none;
    border-bottom: 1px solid #ddd;
    float: left;
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;

    label {
      width: 73%;
      padding: 8px 10px;
      background: #F5F5F5;
      border-right: 1px solid #ddd;
      font-weight: normal;
      margin-bottom: 0px;
      display: flex;
      flex-direction: row;
      vertical-align: top;
      float: left;
    }

    span {
      width: 27%;
      text-align: center;
      padding: 8px 0px;
      display: inline-block;
      vertical-align: top;
    }

    label.sorry-no-record {
      width: 100%;
      text-align: center;
    }

    span {
      width: 27%;
      text-align: center;
      padding: 8px 0px;
    }

    &:last-child {
      border: none;
    }
  }
}


.tracbi-outer {
  float: left;
  margin-top: 20px;
  width: 100%;

  .provder-md {
    width: 100%;
    margin-bottom: 15px;
    float: left;

    h5 {
      font-weight: bold;
    }

    label {
      color: #667680;
      font-weight: 400;
    }

    span {
      color: #404040;
      margin-bottom: 13px;
      font-weight: normal;
    }
  }
}

.time-line .line-btn.sign-fill {
  padding: 0px 0px 0px 7px !important;
  height: 29px;
  line-height: 26px;
  font-weight: normal;
  position: relative;
  font-size: 13px;
  color: #666666;
  overflow: hidden;
  margin-bottom: 10px;

  &:hover {
    background: #ffffff;
  }

  img {
    margin-left: 5px;
    height: 29px;
    float: right;
  }
}

.trac-right-btn-outer {
  margin-bottom: 20px;
  display: table;
  margin: auto auto 20px;
  max-width: 385px;
  width: 100%;

  div {
    float: left;
    width: 100%;

    a.line-btn {
      padding: 4px 9px 4px;
    }
  }
}

.right-images {
  display: table;
  margin-bottom: 14px;
  float: none;
  margin: 0px auto 15px;
  height: auto;
  max-width: 385px;

  img {
    cursor: pointer;
  }

  .images-half {
    width: 49%;
    float: left;

    img {
      max-width: 100%;
      width: 100%;
    }
  }

  .images-full {
    width: 100%;
    margin-top: 3px;
    display: block;
    float: left;

    img {
      margin-top: 5px;
    }
  }
}

.marketing-photo {
  position: relative;
  top: 10px;
  float: right;
}

.after-photos-btn {
  position: relative;
  top: 10px;
  float: right;
}

.trac-info {
  margin-top: -5px;
  text-transform: capitalize;
  font-size: 13px;
  line-height: 20px !important;
}

.view-invoice {
  margin-top: -4px;
}

.health-name-time {
  left: 0px;
  margin-left: -19px;
  height: 50px;
  top: -9px;
  margin-left: -33px !important;
  left: 0px !important;
}

.health-sumry {
  max-width: 380px;
  margin-bottom: 20px;
}

.health-links a {
  margin-right: 10px;
  margin-bottom: 10px;
}

#Health_Timeline {
  padding-left: 68px;
  padding-top: 48px;

  div.timeline-outer {
    background: url(/../images/divider1.png) no-repeat left 22px bottom -80px;

    &:last-child {
      background: none;

      .timeline-right {
        padding-bottom: 20px !important;
      }
    }
  }

  .timeline-right {
    padding-bottom: 24px !important;
  }
}

.title-health {
  visibility: hidden;
  height: 37px;
}

.health-treat-sumry {
  border: 1px solid #ddd;
  margin: 0px;
  display: block;
  padding: 0px;
  margin-bottom: 10px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-webkit-border-radius: 5px;
  overflow: hidden;

  li {
    display: block;
    width: 100%;
    padding: 0px;
    background: none;
    border-bottom: 1px solid #ddd;

    &:last-child {
      border: none;
    }
  }
}

.health-treat-sumry-label {
  width: 100%;
  padding: 8px 10px;
  background: #ffffff;
  border-right: none;
  font-weight: normal;
  color: #252525;
  display: inline-block;
  vertical-align: top;
  margin: 0px;
  display: block;
}

.health-treat-sumry-span {
  width: 27%;
  text-align: center;
  padding: 8px 0px;
  color: #666;
  display: inline-block;
}

.health-links {
  padding: 10px 0px;

  .line-btn {
    width: auto !important;
    padding: 4px 10px;
    margin-left: 0px;
    margin-right: 7px;
    ;
  }
}

.health-btn {
  margin: 0px 5px 0px 0px;
}

.health-trac-summry {
  margin-bottom: 10px !important;
  text-transform: uppercase;
  border-radius: 5px;
  overflow: hidden;
}

.health-md {
  margin-top: 20px;
  margin-bottom: -10px;
}

.pro-name .health-name {
  font-size: 15px;
  font-weight: 600;
  margin: 5px 0px;
  color: #2682c5;
  display: block;
  color: #5a5a5a;
  text-transform: uppercase;
}

.credit-edit-btn {
  float: right;
  margin-top: -7px;
}

.add-mon-mem-btn.new-blue-btn {
  margin-top: -8px;
  margin-bottom: 3px;
}

.profile-header-red {
  color: #444444;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  font-size: 12px;
  text-align: center;
  padding: 5px 0px;
}

.profile-header-red a,
.profile-header-red a:hover {
  color: #444444;
  text-decoration: underline;
}

.h-240 {
  height: 240px;
  overflow-y: auto;
}

.upperTable {
  height: 246px;
}

.add-edit-procedure .react-datepicker {
  width: 344px;

  .react-datepicker__time-container {
    width: 100px;

    .react-datepicker__time-box {
      width: 98px;
    }
  }
}

.left-big-image {
  margin-bottom: 0px;
  height: 280px;
}

.under-dev {
  padding: 0px !important;
}

.procedure-edit-btn {
  height: 28px;
  width: auto;
  float: right;
  min-width: 50px;
  margin: 5px 5px 8px 10px;
}

.pass-hidden {
  width: 15px;
  height: 15px;
  background: url(/../images/eye-icon.png) no-repeat center;
}

.pass-show {
  width: 15px;
  height: 15px;
  background: url(/../images/eye-slash-icon.png) no-repeat center;
}

.fitzpatrick-block {
  .skin-descp {
    position: absolute;
    top: 0px;
    right: 15px;
  }
}

.prescription-row {
  position: relative;
}

.health-smry-outer {
  min-height: 160px;
  float: left;
  width: 100%;
}

.healthPrescriptionLoader {
  z-index: 9999 !important
}

.font-weight-600 {
  font-weight: 600
}

.simpleField {
  .simpleInput.simpleInput-div-span {
    padding-top: 7px
  }
}

.clear-both {
  clear: both;
}

.cursor-pointer {
  cursor: pointer !important;
}

.invoiceTotalDue {
  font-size: 14px !important;
  text-transform: capitalize !important;

  button {
    margin-top: -6px;
  }

  .invoiceTotalDueRight {
    float: right;
  }
}

.clientMembershipPopup {
  .simpleLabel {
    top: 7px;
  }

  .membershipDetail .row {
    margin-bottom: 5px;
  }

  .newPopupSubTitle {
    font-size: 14px;
    margin-bottom: 5px;
  }
}

.field-btn {
  padding: 8px 20px !important;
  height: 36px !important;
  margin-top: 5px;
}

.ProductMembershipContent {
  height: auto;
  margin-bottom: 15px;
}

.top-5 {
  top: 5px !important;
}

.changeClinic {
  padding: 0px;
  display: inline-block;
  font-size: 15px;
  margin-left: 5px;
}

.membershipAccordion {
  float: left;
  border: 1px solid #dfedf2;
  width: 100%;
  @include border-radius(3px);
  margin-bottom: 3px;

  .membershipAccordionHead {
    button {
      color: #404040;
      font-weight: normal;
      font-size: 13px;
      width: 100%;
      text-align: left;
      padding: 10px 15px;
      background: #dfedf2;
      outline: none;
      text-decoration: none;
      display: block;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .membershipAccordionContent {
    padding: 15px;
  }
}

.no-memberShip-Available {
  float: left;
  width: 100%;
  margin: 90px 0px 100px;
}

.juvly-agreement-popup {
  width: 685px;
  right: 8px;
}

.agreementClose {
  color: #404040;
  margin-top: 2px;
}

.juvly-Agreement-content a {
  color: $blue !important;
}

.handIcon {
  cursor: pointer
}

.profileLeftSelect {
  border: none;
  background: none;
  width: 65%;
  height: 20px;
  background-image: url(/../images/select-arrow2.png);
  background-repeat: no-repeat;
  background-position: right 8px center;
  background-size: 10px auto;
  background-color: #ffffff;
}

.profileLeftClinic {
  border: none;
  background: none;
  width: 65%;
  height: 20px;
  float: left;
}

.profileLeftbtns {
  width: 34%;
  float: right;
}

.xs-blue-btn {
  @include box-shadow(0, 1px, 2px, 0, rgba(0, 0, 0, 0.15));
  border: none;
  padding: 0px 7px 0px;
  height: auto;
  font-size: 13px;
  color: $white-color;
  background-color: $blue;
  @include border-radius(3px);
  display: inline-block;
  vertical-align: top;
  text-align: center;
  margin-left: 5px;
  text-decoration: none !important;
  font-family: $lato;
  line-height: 23px;
  margin-top: -2px;
}

.xs-white-btn {
  @include box-shadow(0, 1px, 2px, 0, rgba(0, 0, 0, 0.15));
  border: none;
  padding: 0px 7px 0px;
  height: auto;
  font-size: 13px;
  color: $blue;
  background-color: #fff;
  @include border-radius(3px);
  display: inline-block;
  vertical-align: top;
  text-align: center;
  text-decoration: none !important;
  font-family: $lato;
  line-height: 23px;
  margin-top: -2px;
}

.timelineProcedureBtn {
  line-height: 20px;
  padding: 6px 10px !important;
  margin-top: 9px;
  margin-left: 7px;
}

.healthTimelineTop {
  padding-bottom: 0px !important;
  margin-bottom: 15px;

  .pro-name h4 {
    color: $blue;
    font-size: 13px;
    display: block;
    margin-bottom: 5px;
    padding-bottom: 0px !important;
    font-weight: bold;
  }

  .healthTimelineDetail {
    float: left;
    width: 100%;
    margin-bottom: 15px;
    color: #404040;
  }
}

.lockSensitiveProcedureImages {
  filter: blur(8px);
}

.lockSensitiveProcedureImages:hover {
  cursor: default;
}

.healthTreatSmry {
  font-size: 15px;
  font-weight: normal;
  color: #047ec3;
  display: block;
}

.healthTimelineBtn {
  margin-top: 0px;
  padding-top: 0px;
}

.pharmacyAddNew {
  margin: 0px;
  width: auto;
  min-width: inherit;
  padding: 6px 15px;
  margin-top: 17px;
  height: 35px;
}

.prescriptionTitile {
  float: left;
  width: 100%;
  background: #047ec3;
  color: #ffffff;
  font-size: 14px;
  padding: 4px 10px;
  margin-bottom: 15px;
  margin-top: 15px;
  @include border-radius(3px);
}

.Therapies {
  float: left;
  width: 100%;
  position: relative;
  padding-left: 0px;
}

.TherapiesInput {
  float: left;
  width: 100%;
  margin-top: 10px;
}

.TherapiesCheck {
  position: absolute;
  left: 0px;
  top: -3px;
  z-index: 1;
}

.prescriberFooter {
  background: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px;
  border-top: 1px solid #dddddd;
  z-index: 2;
}

.new-prescription-section {
  padding-bottom: 70px !important;
}

.membershipSign {
  .juvly-container {
    padding: 0px 0px 35px !important;
    float: none;
  }

  .signature-subtitle {
    margin-top: 0px !important;
  }

  .change-sig {
    width: 100%;
  }

  .signature-not-found {
    border: 1px solid #e74c3c;
  }

  .signature-box,
  .newPopupSubTitle,
  .input-outer {
    float: none;
  }
}

.QuestConsentCompleted {
  float: left;
  width: 100%;
  margin-top: 0px;

  .QuestConsentTitle {
    float: left;
    width: 100%;
    font-size: 14px;
    color: #404040;
    font-weight: 500;
    margin-bottom: 5px;
  }

  .QuestConsentLink {
    width: 100%;
    float: left;
    color: #047ec3;
    font-size: 13px;
    border-bottom: 1px dotted #bfc9ce;
    padding: 5px 0px 6px;
    line-height: 17px;
  }

  .noneConsentLink {
    width: 100%;
    float: left;
    color: black;
    font-size: 13px;
    border-bottom: 1px dotted #bfc9ce;
    padding: 5px 0px 6px;
    line-height: 17px;
  }
}

.DiagnosisDetailstextbox {
  height: auto !important;
  max-height: 183px !important;
}

.health-trac-summry.healthTreatSmry.DiagnosisDetailTitle {
  margin-bottom: 0px !important;
}

.healtimeline-Paperwok {
  float: left;
  width: 100%;
  margin-bottom: 37px;
  padding: 0px 20px !important;


  .healthpaperworkTitle {
    float: left;
    width: 100%;
    font-size: 14px;
    color: #667680;
    margin-bottom: 9px;
  }

  .healthpaperworkul {
    float: left;
    width: 100%;
    list-style: none;
    margin: 0px;

    li {
      float: left;
      width: 100%;
      background: none;
      border: 1px solid $blue;
      color: $blue;
      font-size: 13px;
      font-family: "Lato", sans-serif;
      font-weight: normal;
      height: 27px;
      line-height: inherit;
      padding: 3px 19px 0px 9px;
      text-transform: inherit;
      border-radius: 3px;
      min-width: 103px;
      display: inline-block;
      vertical-align: top;
      text-decoration: none !important;
      overflow: hidden;
      position: relative;
      margin-bottom: 5px;


      svg,
      i {
        top: 6px;
        right: 6px;
        position: absolute;
        font-size: 12px;
      }

      &:hover {
        background: $blue;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }

  .healthpaperworkSection {
    border-right: 1px dashed #dddddd;
    flex: 1;
  }


  .healtimeline-Paperwok-inner {
    float: left;
    width: 100%;
    background: #f1f9fd;
    padding: 20px 10px !important;
    display: flex;
    flex-direction: row;
    align-items: inherit;
    @include border-radius(5px);

    &>div.healthpaperworkSection:last-child {
      border: none;
    }
  }
}

.CreateInvoiceTotalAmount {
  float: right;
  font-size: 20px;
  color: #404040;
  padding: 3px 22px 3px 0px;
}

.createInviceDetail {
  float: left;
  width: 100%;
  border-bottom: 1px solid #dddddd;
  padding-bottom: 5px;
  margin-bottom: 15px;
}

.inline-block {
  display: inline-block;
}

.single-timeline-wrapper {
  padding-bottom: 40px;
  display: block;
  border-left: 2px solid $blue !important;
  margin-left: 0px;
  padding-left: 10px;
  position: relative;
  display: flex;

  &:after {
    left: -12px;
    top: -3px;
    content: '';
    background: #047ec3;
    width: 22px;
    height: 22px;
    border: 5px solid #ffffff;
    position: absolute;
    border-radius: 40px;

  }

  &:last-child {
    background: none;
  }

  .QuestConsentLink {
    &:last-child {
      margin-bottom: 20px;
    }
  }

  .noneConsentLink {
    margin-bottom: 20px;
  }

  .procedure-name {
    color: #047ec3;
    font-size: 13px;
    display: block;
    margin-bottom: 5px;
    padding-bottom: 0px !important;
    font-weight: bold;
  }

  .title-1 {
    margin-bottom: 0px;
    text-transform: uppercase;
    border-radius: 5px;
    font-size: 15px;
    font-weight: normal;
    color: #047ec3;
    display: block;

    a.morePhotos {
      padding: 4px 10px 4px;
      font-size: 13px;
      text-transform: initial;
      margin-top: -9px;
    }
  }

  .action-button-wrapper {
    width: 100%;
    margin-bottom: 20px;

    .action-button-inner {
      width: 100%;
      background: #f1f9fd;
      padding: 20px 10px !important;
      display: flex;
      flex-direction: row;
      align-items: inherit;
      @include border-radius(5px);
    }

    .action-button-section {
      border-right: 1px dashed #dddddd;
      flex: 1;

      &:last-child {
        border: none;
      }
    }

    .action-button-title {
      float: left;
      width: 100%;
      font-size: 14px;
      color: #667680;
      margin-bottom: 9px;
    }

    .action-button-ul {
      float: left;
      width: 100%;
      list-style: none;
      margin: 0px;

      .action-button-li {
        width: 100%;
        background: none;
        border: 1px solid $blue;
        color: $blue;
        font-size: 12px;
        font-family: "Lato", sans-serif;
        font-weight: normal;
        min-height: 27px;
        line-height: inherit;
        padding: 3px 9px;
        text-transform: inherit;
        border-radius: 3px;
        min-width: 103px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 5px;
        gap: 4px;
        flex-wrap: wrap;

        .action-button-li__text {
          max-width: 85%;
        }

        .disable {
          background-color: #f4f6f6;
          pointer-events: none;
          cursor: not-allowed;
        }

        &:hover {
          background: $blue;
          color: #ffffff;
          cursor: pointer;

          svg {
            color: #ffffff;
          }
        }
      }

      .pending-payment {
        min-height: 27px;
        height: fit-content;
      }
    }
  }

}

.create-procedure-dropdown {
  margin-top: 9px;

  button {
    padding: 4px 22px 4px 10px;
    font-size: 13px;

    svg {
      top: 7px;
    }
  }

  ul {
    left: inherit !important;
    right: 5px !important;
    min-width: 136px !important;

    li {
      display: block;

      a {
        font-size: 13px !important;
        text-transform: inherit;
        border-bottom: 1px solid #dddddd;
        display: block;
        font-weight: normal;
        white-space: nowrap;
        height: 33px;
        line-height: 33px;
        padding: 0px 10px;
        color: #7b8a8b;

        &:hover {
          text-decoration: none;
          color: #ffffff;
          background-color: $blue;
        }
      }
    }
  }
}

.single-timeline-wrapper>div {
  border-bottom: 1px dotted #bfc9ce;
  padding-bottom: 20px;
}

.cosmeticImages {
  padding: 0px 15px;

  img {
    max-width: 175px !important;
    max-height: 175px !important;
    display: inline-block;
    vertical-align: top !important;
    cursor: pointer;
  }
}

#Single_Timeline>div:last-child>div {
  border: none;
}

.loading-data {
  position: relative;
  min-height: 500px;
  float: left;
  width: 100%;
  z-index: 1
}

.allergyIcon {
  display: inline-block;
  background: url("/../images/alarm-icon.svg") no-repeat;
  background-size: cover;
  height: 12px;
  width: 12px;
  margin: 3px 0 0 3px;
}

.font-w-300 {
  font-weight: 300;
}

.font-w-400 {
  font-weight: 400;
}

.font-size-23 {
  font-size: 23px
}

.centerTableHeading {
  display: table-cell;
  align-items: center;
  vertical-align: middle;
}

.alertIcon {
  display: inline-block;
  background: url("/../images/alarm-icon.svg") no-repeat;
  background-size: cover;
  height: 15px;
  width: 15px;
  margin: 3px 0 0 3px;
  top: 4px !important;
}

.expiryDocumentTimer {
  display: inline-block;
  width: 30px;
  height: 30px;
}
