@import "../../../../../../../styles/theme.scss";

.root {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: $cWhite;
  padding: 20px 30px;

  @media screen and (max-width: 520px) {
    padding: 20px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;

  h3 {
    font-size: 18px;
    flex: 1;
  }

  @media screen and (max-width: 430px) {
    flex-direction: column;

    h3 {
      text-align: center;
      width: 100%;
      font-size: 16px;
    }
  }
}

.options {
  display: flex;
  gap: 4px;
  justify-content: flex-end;

  button {
    width: 30px;
    height: 30px;
  }
}
