@import "../../styles/theme.scss";

.root {
  border-radius: 8px;
  border: 1px solid $cGray1;
  background-color: $cWhite;
  padding: 15px;
  min-width: 100%;
  max-width: 100%;
  min-height: 80px;
  font-size: 16px;
  font-weight: 400;
  resize: vertical;
  color: $cBlack;

  &:focus:not(.error):not(.readonly) {
    border-color: $cPrimary;
  }
}

.error {
  border-color: $cRed;
  background-color: $cRedDim2;

  &::placeholder {
    color: $cRed;
  }
}

.disabled {
  background-color: $cGray4;
  pointer-events: none;
  cursor: default;
  user-select: none;
  color: $cGray0;

  &::placeholder {
    color: $cGray0;
  }
}

.readonly {
  cursor: default;
}
