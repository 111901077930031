@import "../../../../../styles/theme.scss";

.root {
  &:hover {
    background-color: transparent;
  }
}

.cell {
  justify-content: flex-start;
  font-weight: 400;
  color: $cGray0Dark;
  padding: 0 32px 0 0;
}

.firstCellAccent {
  border-bottom: none;
  color: $cBlack;
}
