@import "../../../../../../../styles/theme.scss";

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: grab;
  padding: 10px 15px;
  border-radius: 8px;
  border: 1px solid $cGray3;
  background-color: $cPrimaryDim3;
  gap: 16px;

  @media screen and (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
  }

  & * {
    user-select: none;
  }
}

.rootError {
  border-color: lighten($cRed, 25%);
  background-color: lighten($cRedDim2, 0.4%);
}

.dragIcon {
  margin-right: 6px;
  margin-top: 7px;
}

.left {
  display: flex;
  gap: 8px;
}

.actions {
  display: flex;
  align-items: center;
  gap: 8px;
}

.questionWrap {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.questionInner {
  display: flex;
  gap: 4px;

  .requiredSymbol {
    color: $cRed;
  }
}

.desc {
  font-size: 15px;
  color: $cGray1;
}

.typeWrap {
  display: flex;
  align-items: center;
  gap: 4px;
  color: $cGray1;

  .adornment {
    font-size: 13px;
  }
}
