.col {
  width: 170px;
  max-width: 170px;
  min-width: 170px;
  word-break: break-all;
}

.checkboxCol {
  width: 40px;
}

.emailCol {
  word-break: break-all;
  width: 300px;
  max-width: 300px;
  min-width: 300px;
}

.actionCol {
  display: flex;
  justify-content: flex-end;
}
