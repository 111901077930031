.root {
  display: flex;
  flex-direction: column;
  gap: 10px
}

.actionButtons {
  display: flex;
  gap: 8px;
}

.modal {
  max-width: 500px;
}

.modalFooter {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.deleteModalContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.deleteModalFooter {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}
