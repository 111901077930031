@import "../../../../../styles/theme.scss";

.root {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.title {
  font-size: 22px;
  color: #404040;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border-radius: 4px;
  background-color: $cPrimaryLight2;

  .checkboxes {
    display: flex;
    gap: 32px;
    flex-wrap: wrap;
  }

  .submitBtn {
    align-self: flex-start;
    padding-left: 32px;
    padding-right: 32px;
  }
}

.otpButtons {
  display: flex;
  gap: 16px;
  justify-content: flex-start;
}

.otpInput {
  width: 234px;
}
