@import "../../../../../styles/theme.scss";

.toggle {
  border: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: $cPrimaryLight;
  padding: 10px 15px;
  border-radius: 4px;
  color: $cPrimary;
}

.toggleOpen {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.chevron {
  transform: rotate(-90deg);
}

.left {
  display: flex;
  align-items: center;
  gap: 8px;
}

.chevronOpen {
  transform: rotate(90deg);
}

.dropdown {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 10px 15px;
  background-color: $cWhite;
  border: 1px solid $cPrimaryLight;
  border-top: none;
  display: flex;
  flex-direction: column;
  gap: 8px;

  h3 {
    font-size: 17px;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 8px;
  }
}

.nodes {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
