@import "../../../../../../../../../../../../styles/theme.scss";

.root {
  border-radius: 4px;
  padding: 8px;
  background: $cPrimaryDim2;
}

.type {
  font-size: 13px;
  color: $cGray0;
  font-style: italic;
}

.choices {
  display: flex;
  gap: 4px;
  padding-top: 4px;
  flex-wrap: wrap;
}
