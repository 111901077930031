@import "../../../../../../../styles/theme.scss";

.row {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  flex-wrap: wrap;

  & > * {
    flex: 1;
    min-width: 210px;
  }
}

.radioRow {
  display: flex;
  gap: 16px;
  align-items: center;
}
