.root {
  position: relative;

  .loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.6);
    display: flex;
    flex-direction: column;
    padding-top: 3%;
    align-items: center;
    z-index: 10;
  }
}

.disabled {
  & * {
    pointer-events: none;
    user-select: none;
    cursor: default;
  }
}
