.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.number {
  font-size: 13px;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  gap: 4px;
}
