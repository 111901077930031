.root {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.buttons {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;

  @media screen and (max-width: 445px) {
    button {
      width: 100%;
    }
  }
}

.email {
  max-width: 450px;
}
