@import "../../../../../../styles/theme.scss";

.root {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.label {
  color: $cGray0;
  font-size: 15px;
}

.divider {
  height: 1px;
  background-color: $cGray3;
  margin: 5px 0;
}
