@import "../../../styles/theme.scss";

@mixin button-style($color, $height, $margin, $background: null) {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: $height;
  border-radius: 4px;
  border: 1px solid $color;
  color: $color;
  font-weight: 500;
  font-size: 16px;
  background: $background;

  &:hover {
    border-color: #188bf6;
    box-shadow: 0 0 2px #188bf6;
  }

  @media (max-width: 1235px) {
    font-size: 12.5px;
    width: auto;
    padding:0px 16px 0px 16px;
  }
}


.coupnCodeDiv {
  max-width: 50%;
}

.root {
  font-family: $primaryFont;
  .cardTitle {
    font-size: 18px;
    font-family: $primaryFont;
  }

  .table {
    width: 100%;
    color: #404040;

    th,
    td {
      padding: 4px 8px;
    }

    .membershipName {
      min-width: 100px;
    }

    @media (min-width: 992px) {
      .membershipName {
        min-width: 180px;
      }
    }

    .paymentFrequency {
      color: #404040;
      text-transform: capitalize;
    }

    .showMembershipDetailsButton {
      color: $cPrimary;
      cursor: pointer;
      max-width: 110px;
    }
  }

  .tableHeadingRow {
    background-color: $cPrimaryLight;
    vertical-align: baseline;

    th {
      height: 47px;
      font-weight: 500;
      font-size: 14px;
      padding-top: 6px;
    }

    .thpaymentFrequency {
      max-width: 158px !important;
    }

    .thactions {
      max-width: 115px;
    }

    @media (min-width: 992px) {
      .thpaymentFrequency {
        width: 158px;
      }

      .thactions {
        min-width: 115px;
      }
      .thstartDate,
      .thvalidUpto {
        min-width: 110px;
        width: 110px;
      }
      .thmembershipFee {
        max-width: 135px;
        width: 135px;
      }
    }
  }

  .membershipMetaData {
    border-bottom: 1px solid #e4e7e9;
    font-size: 15px;
    height: 70px;
  }

  .selectTitle {
    font-size: 14px;
    margin-bottom: 8px;
    font-weight: 500;
    color: #666677;
    font-family: $secondaryFont !important;
  }
}
.membershipDetails {
  .membershipCard {
    font-family: $primaryFont;
    background: #ffffff;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.05);
    border-radius: 8px;

    .cardHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 0px 12px 0px;
    }

    .cardTitle {
      font-size: 16px;
      font-family: $primaryFont;
    }
  }

  .tableRow {
    margin-top: 20px;
    font-size: 15px;
    font-weight: 400;
  }

  .membershipSection {
    float: left;
    width: 100%;
    font-size: 16px;
    color: #404040;
  }

  .membershipContainer {
    padding: 8px 24px 8px 24px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  
  @media (min-width: 768px) {
    .membershipContainer {
      flex-direction: row;
    }
  }
  

  .membershipTitle {
    height: 70px;
    padding: 14px 12px 0px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 400;
    font-size: 18px;
    color: #404040;
  }

  .horizontal {
    margin-top: 8px;
    margin-bottom: 8px;
    border: 1px solid #e4e7e9;
  }

  .membershipKey {
    font-weight: 600;
    color: #929292;
    font-size: 16px;
  }

  .buttonsContainer {
    padding: 8px 24px 24px 24px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  @media (min-width: 768px) {
    .buttonsContainer {
      flex-direction: row;
      justify-content: space-between;
      column-gap: 40px;
    }
  }
  
  @media (min-width: 1024px) {
    .buttonsContainer {
      justify-content: flex-start;
    }
  }
  

  .downloadAgreement {
    @include button-style(#ffffff, 40px, 40px, #188bf6);
  }

  .cancelMembership {
    @include button-style(#188bf6, 40px, 40px);
  }

  .holdMembership {
    @include button-style(#188bf6, 40px, 0px);
  }

  .payButton {
    padding: 8px 32px 8px 32px;
    background: rgba(24, 139, 246, 0.15);
    border-radius: 4px;
    color: #188bf6;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
  }

  .amountTitle {
    font-size: 16px;
    font-weight: 600;
    font-family: $primaryFont;
    color: #404040;
  }
}

.card {
  background-color: white;
  margin-bottom: 30px;

  .cardHeader {
    display: flex;
    justify-content: space-between;
    padding: 20px 16px;
    align-items: center;
  }
}

.fieldTitle {
  font-size: 15px;
  margin-bottom: 8px;
  font-weight: 600;
  color: #666677;
  font-family: $secondaryFont !important;
}

.formField {
  width: 100%;
  margin-bottom: 24px;
}

.coupnCodeField {
  display: flex;
  gap: 12px;
}

.waiveSetupFeeCheckbox {
  display: flex;
  align-items: center;
  margin: 0px 0px 12px 4px;

  span {
    margin: 0px 0px 0px 10px;
  }
}

.showExpiredMemberships {
  font-weight: 400;
  font-size: 14px;
  padding: 0px 16px 12px;

  display: flex;
  align-items: center;

  span {
    margin: 0px 0px 0px 10px;
  }
}

.freeProductsDisplayBox {
  width: 100%;
  float: left;
  color: #404040;
  border: 1px solid #cad2d6;
  border-radius: 3px;
  height: 75px;
  padding: 10px 15px;
  font-size: 14px;
  margin-bottom: 8px;
}

.startDate {
  width: 100%;
  height: 34px;
  border-radius: 4px;
  border: 1px solid $cGray1;
  padding: 0 35px 0 15px;
  background-color: #fff;
  color: #000;
  font-size: 14px;
}

.footer {
  display: flex;
  justify-content: flex-end;
}

.button {
  min-width: 80px;
}

@media (max-width: 600px) {
  .footer {
    margin-bottom: 15px;
    justify-content: center;
  }
}


.payment {
  .paymentTitle {
    position: absolute !important;
    z-index: 0;
  }
  .formField {
    top: 4px;

    > div {
      padding-top: 14px;
    }
  }
  .clearentForm {
    margin: 16px;
  }
}

.flexBox {
  display: flex;
  align-items: center;
  gap: 8px;
}

@media (max-width: 1280px) {
  .cancelButton,
  .saveButton {
    font-size: 12px;
    padding: 6px 10px;
  }
}

.cancelButton {
  cursor: pointer;
  color: #188bf6;
  border: 1px solid #188bf6;
  border-radius: 4px;
  padding: 6px 12px;
}

.saveButton {
  cursor: pointer;
  background: #188bf6;
  color: white;
  border: 1px solid #188bf6;
  border-radius: 4px;
  padding: 6px 20px;
}

.selectField {
  margin-right: 0px !important;
  width: 92px !important;
  border: 1px solid #b5b5b5;
  border-radius: 8px;
}

.selectMonth {
  margin-right: 22px !important;
  float: left;
  font-weight: 600;
  font-size: 16px;
  color: #929292;
  mix-blend-mode: normal;
  opacity: 0.8;
}

.appliedCouponCode {
  display: flex;
  .couponCode {
    min-width: 110px;
    font-size: 15px;
    color: $cPrimary;
    font-weight: 600;
  }
  padding: 2px;

  .clearCounponButton {
    width: 16px;
    cursor: pointer;
    padding: 2px;
  }
}

.couponAppliedText {
  font-size: 12px;
}

.noMembershipsAvailable {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  border-top: 1px solid $cGray6;
}

.feeScheduleContainer {
  margin-bottom: 12px;

  .feeScheduleTitle {
    margin-bottom: 12px;
  }
}

.addMembershipForm {
  font-family: $primaryFont;
  padding: 0px 10px 0px 10px;
}

.hide {
  display: none;
}

.agreementContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.patientSignatureContainer {
  background-color: white;
}

.fieldsReponsiveContainer {
  padding: 0px;
}

.fieldsContainer {
  display: flex;
  flex-wrap: wrap;

  .field {
    padding: 0px 15px;
    flex-basis: 1/2;
    min-width: 337px;

    @media (min-width: 767px) {
      max-width: 50%;
    }

    @media (max-width: 767px) {
      flex-basis: 1/1;
      min-width: 100%;
    }
  }
}

.agreementModal {
  .footer {
    margin-left: auto;
    padding: 18px 0px;
  }
}

.tableContainer {
  overflow-x: auto;
}

@media (max-width: 768px) {
  .tableContainer {
    overflow-x: scroll;
  }
}