@import "../../styles/theme.scss";

.root {
  display: flex;
  width: fit-content;
  min-width: 100%;
  flex: 1;
  background-color: $cWhite;
  border-radius: 4px;
  position: relative;
}
