@import "../../styles/theme.scss";

.root {
  display: flex;
  flex-direction: column;
  width: fit-content;
}

.rootFullWidth {
  width: 100%;
}

.label {
  display: flex;
  align-items: center;
  gap: 10px;
  background: none;
  border: none;
  font-size: 16px;
  outline: none;
  text-align: left;
}

.chevronOpen {
  transform: rotate(180deg);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  .headerInner {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.content {
  padding: 10px 0;
  border-bottom: 1px solid $cGray3;
}
