footer.main-footer {
  border-top: 1px solid #ddd;
  padding: 7px 0px;
  font-size: 13px;
  background: none;
  color: #444444;

  label {
    font-weight: normal;
  }
}

.footer-fixed {
  position: fixed;
  bottom: 0px;
  width: 100%;
}
