$out-of-range-color: lighten(#333, 40%);
$out-of-range-bg-color: lighten(#333, 70%);

$calendar-border: #ddd;
$cell-border: #ddd;

$border-color: #ccc;

$segment-width: percentage(1 / 7);

$time-selection-color: white;
$time-selection-bg-color: rgba(0, 0, 0, 0.5);
$date-selection-bg-color: rgba(0, 0, 0, 0.1);

$event-bg: #3174ad;
$event-border: darken(#3174ad, 10%);
$event-outline: #3b99fc;
$event-color: #fff;
$event-border-radius: 5px;
$event-padding: 2px 5px;
$event-zindex: 4;

$btn-color: #373a3c;
$btn-bg: #fff;
$btn-border: #ccc;

$current-time-color: #74ad31;

$rbc-css-prefix: rbc-i;

$today-highlight-bg: #eaf6ff;
