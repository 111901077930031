@import "../../../../../styles/theme.scss";

.root {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

@mixin section-title() {
  font-size: 18px;
  color: $cGray0Dark;
  margin-bottom: 24px;
}

@mixin images-row() {
  display: flex;
  max-width: 1200px;
  gap: 64px;
  flex-wrap: wrap;

  @media screen and (max-width: 370px) {
    gap: 24px;
  }
}
