@import "../../../../styles/theme.scss";

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rootMediaDefault {
  @media screen and (max-width: 1200px) {
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
  }
}

.rootMediaSmall {
  @media screen and (max-width: 550px) {
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
  }
}

.right {
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
}

.title {
  font-size: 20px;
  color: $cBlack;
  display: flex;
  align-items: center;
  gap: 5px;

  @media screen and (max-width: 550px) {
    font-size: 18px;
  }

  i {
    margin-right: 0;
  }
}

.titleWrap {
  display: flex;
  gap: 8px;
  align-items: center;

  @media screen and (max-width: 550px) {
    gap: 4px;
  }
}
