@import "../../../../../styles/theme.scss";

.modalBtn {
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: center;
}

.file {
  margin-top: 8px;
  display: flex;
  align-items: center;
  gap: 8px;

  a {
    color: $cPrimary;

    &:hover {
      text-decoration: underline;
    }
  }

  button {
    width: 30px;
    height: 30px;
  }
}
