@import "../../styles/theme.scss";

.collapse {
  display: flex;
  flex-direction: column;
  padding: 15px 0;
  width: 100%;

  .leftAdornment {
    display: flex;
    justify-content: flex-start;
  }

  .rightAdornmentBlock {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: none;
    border: none;
  }

  .chevron {
    margin-left: 10px;
  }

  .chevronUp {
    transform: rotate(180deg);
  }

  .title {
    display: flex;
    flex-grow: 1;
  }
}

.collapseHeader {
  display: flex;
  align-items: center;
  font-family: $latoFont;
  font-size: 15px;
  background: none;
  outline: none;
  border: none;
  width: 250px;
}

.collapseBody {
  padding-top: 40px;
}
