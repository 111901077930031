@import "../../../../../styles/theme.scss";
@import "../../SalesCheckoutInvoice.variables.scss";

.modal {
  width: 586px;
}

.root {
  display: flex;
  flex-direction: column;
}

.option {
  min-height: 65px;
  height: 65px;
  background: none;
  border: none;
  outline: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease;
  gap: 30px;

  &:hover .optionInfo {
    .optionLabel {
      color: $cPrimary;
    }
  }

  &:last-child {
    .optionInfo {
      border-bottom: none;
    }
  }

  @media screen and (max-width: $mobileBreakpoint) {
    gap: 8px;
  }
}

.imgBox {
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: $mobileBreakpoint) {
    width: 34px;
    height: 34px;
  }
}

.optionInfo {
  display: flex;
  align-items: center;
  height: 100%;
  border-bottom: 1px solid lighten($cGray2, 9%);
  flex: 1;
  justify-content: space-between;

  .optionLabel {
    font-size: 15px;
    font-weight: 400;
    transition: all 0.15s ease;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  @media screen and (max-width: $mobileBreakpoint) {
    flex-wrap: wrap;
  }
}

.optionDisabled {
  filter: grayscale(1);
  cursor: default;
  pointer-events: none;
  user-select: none;

  .imgBox {
    opacity: 0.7;
  }

  .optionInfo {
    color: $cGray1;
  }
}

.optionRight {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;

  @media screen and (max-width: $mobileBreakpoint) {
    gap: 8px;
    font-size: 12px;
  }
}
