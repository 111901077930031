@import "../../../../../styles/theme.scss";
@import "../../SalesCheckoutInvoice.variables.scss";

.root {
  height: max-content;
}

.meta {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;

  @media screen and (max-width: 560px) {
    display: flex;
    flex-direction: column;
  }
}

.metaLabel {
  font-size: 16px;
  font-weight: 600;
  color: $cGray1;
  margin-bottom: 4px;
}

.metaValue {
  font-size: 16px;
  font-weight: 400;
}

.productsTable {
  width: 100%;
  margin-top: -13px;

  tr {
    height: 40px;
  }

  tbody tr {
    font-size: 16px;
    font-weight: 400;
  }

  .productsTableHead {
    width: 220px;
  }

  .center {
    text-align: center;
  }

  .price {
    font-weight: 600;
    text-align: right;
  }
}

.total {
  font-size: 20px;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.totalWrap {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.productsMobile {
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 16px;

  i {
    margin-right: 0 !important;
  }
}
