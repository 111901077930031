@import "../../../styles/theme.scss";

.root {
  padding-bottom: 100px;
}

.header {
  font-size: 25px;
  padding: 16px 0 30px 0;
  border-bottom: 1px solid $cGray2;
  font-weight: 600;
  margin-bottom: 26px;
}
