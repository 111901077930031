.root {
  padding-left: 20px;
  padding-right: 20px;
}

.rightAdornment {
  display: flex;
  align-items: center;
  gap: 4px;
}
