@import "../../../../../../../styles/theme.scss";

.root {
  border-radius: 4px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: $cGray5;
}

.row {
  display: grid;
  grid-template-columns: 1fr 3fr;
  align-items: center;
  gap: 8px;

  @media screen and (max-width: 570px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    & > div {
      width: 100%;
    }
  }

  .label {
    color: $cGray0;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
}
