.container {
  display: flex;
  flex: 1 1 20%;
  flex-direction: column;
}

.insightsList {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 10px;

  a {
    font-size: 15px;
    display: block;
    color: #667680;
    padding: 6px 15px 6px;
  }

  a.active {
    color: #047ec3;
    background: #dae7ee;
    border-radius: 3px;
  }
}
