.infoWrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
  font-family: "Lato", sans-serif;
}

.itemTitle {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
}

.itemText {
  font-weight: normal;
}
