.hashTag {
  display: inline-block;
  vertical-align: top;
  font-weight: normal;
  padding: 3px 7px;
  border: 1px solid #cccccc;
  @include border-radius(50px);
  margin: 10px 4px 2px 0px;
  float: left;
}

.export-procedure {
  input {
    margin-top: 55px;
    position: absolute;
    left: 0px;
    top: 50%;
    margin-top: -15px;
  }

  .exportProcedureCheck input {
    top: 114px;
  }

  .exportProcedureCheck input.without-consult-fees {
    top: 88px;
  }

  .juvly-subtitle {
    background: #ffffff;
    position: relative;
  }

  .table-cell {
    vertical-align: top;
    border-bottom: 1px solid #ececec;
    padding: 16px 0px;
    position: relative;
  }

  .export-pro-image {
    width: 300px;
    margin-right: 15px;
    border: 1px solid #ececec;
    text-align: center;
    background: #f5f5f5;
    @include border-radius(3px);
    overflow: hidden;

    img {
      max-height: 200px;
    }
  }

  .export-pro-time {
    font-size: 13px;
    color: #667680;
    margin-bottom: 10px;
  }

  .export-pro-note {
    float: left;
    width: 100%;
    padding: 10px 0px;
    border-top: 1px dotted #ececec;
    border-radius: 3px;
    margin-top: -1px;
  }

  & > div:last-child .table-cell {
    border: none;
  }
}

.export-input {
  width: 30px;
}

.pro-to-export {
  background: #f5f5f5;
  padding: 5px 8px;
  border: 1px solid #eeee;
  display: block;
  margin-bottom: 10px;
  @include border-radius(3px);
}
