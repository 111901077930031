@import "../../styles/theme.scss";

.dropZone {
  border: 2px dotted $cGray1;
  border-radius: 8px;
  cursor: pointer;
  .addFileActive {
    display: none;
  }
  &:hover:not(.disabled) {
    border-color: $cPrimary;
    .addFileActive {
      display: block;
    }
    .addFile {
      display: none;
    }
  }
}

.disabled {
  cursor: default;
  pointer-events: none;
  background-color: $cGray5;
  opacity: 0.8;
}

.vDefault {
  text-align: center;
  padding: 20px;
  color: $cGray1;
  &:hover {
    color: $cPrimary;
  }
}

.vCircle {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  img {
    width: 16px;
  }
}

.dragActive {
  border-color: $cPrimary;
  color: $cPrimary;
}

.selectedFiles {
  padding-top: 10px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  @media screen and (max-width: 400px) {
    flex-direction: column;
  }
}

.defaultTextWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;

  .loaderDefault {
    margin-right: 10px;
  }
}
