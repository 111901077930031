.root {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.examsWrap {
  background-color: #f1f9fd;
  padding: 10px 14px 20px 14px;
  border-radius: 5px;
  margin: 0 15px;
  display: flex;
  gap: 16px;

  @media screen and (max-width: 1148px) {
    flex-wrap: wrap;
  }

  table {
    width: 100%;
  }

  thead {
    height: 30px;
  }

  thead th {
    font-size: 14px;
    color: #667680;
    font-weight: 400;
  }

  tbody tr {
    height: 35px;
  }
}

.examsPaperworkHeader {
  max-width: 289px;
  width: 289px;

  @media screen and (max-width: 560px) {
    max-width: 180px;
    width: 180px;
  }
}

.fileBtnCol {
  padding-right: 10px;
}

.actions {
  padding-top: 5px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}
