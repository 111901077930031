@import "./variables.scss";
@import "../../../../styles/theme.scss";

.content {
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media screen and (max-width: $mobileBreakpoint) {
    padding: 0;
    gap: 16px;
  }

  br {
    display: none;
  }
}

.empty {
  color: $cGray2;
  font-size: 20px;
  text-align: center;
  font-weight: 300;
  cursor: default;
  user-select: none;
}
