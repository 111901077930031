@import "../../../../../../../src/styles/theme.scss";

.modalView {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 8px;
}

.field {
  display: flex;
  flex-direction: column;
  color: $cGray0Dark;

  .label {
    font-size: 20px;
    font-weight: 600;

    span {
      font-size: 16px;
      font-weight: 400;
    }
  }

  .sum {
    font-size: 25px;
    font-weight: bold;
    color: black;
  }

  .info {
    color: black;
    font-weight: 600;
  }
}

.tip {
  color: $cGray0Dark;

  .amount {
    color: black;
    font-weight: 600;
  }
}

.infoBox {
  display: flex;
  align-items: center;
  gap: 8px;
}

.infoIcon {
  display: inline-block;
  height: 25px;
}
