@import "../../../../../../../../../styles/theme.scss";

.root {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
}

.product {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  padding: 16px;
  border: 1px solid $cGray2;
  border-radius: 8px;
  width: 203px;
  background-color: $cPrimaryDim3;

  @media screen and (max-width: 800px) {
    width: 100%;
  }

  .inner {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  img {
    width: 100%;
    height: auto;
    border-radius: 4px;
  }

  .name {
    font-size: 15px;
    font-weight: 600;
  }

  .price {
    font-size: 22px;
    font-weight: 600;
    color: $cPrimary;
  }

  .quantity {
    font-size: 13px;
    font-style: italic;
  }
}
