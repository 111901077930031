.content {
  height: 300px;
  padding-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sendOtp {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}
