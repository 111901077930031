@import "../../styles/theme.scss";

.container {
  position: relative !important;
  width: fit-content !important;

  &.fullWidth {
    width: 100% !important;
  }

  .input {
    border: 1px solid $cGray1 !important;
    height: 50px !important;
    border-radius: 8px !important;
    padding-left: 50px !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    width: 100% !important;

    &::placeholder {
      color: $cGray0 !important;
      font-weight: normal !important;
    }

    &:focus:not(.legacy):not(.legacyOutlined) {
      border-color: $cPrimary !important;
    }

    &.disabled:not(.legacy) {
      background-color: $cGray4 !important;
      pointer-events: none !important;
      cursor: default !important;
      user-select: none !important;
      color: $cGray0 !important;

      &::placeholder {
        color: $cGray2 !important;
      }

      & * {
        cursor: default !important;
        pointer-events: none !important;
      }
    }

    &.error:not(.legacy):not(.legacyOutlined) {
      border: 1px solid $cRed !important;
      background-color: $cRedDim2 !important;

      &::placeholder {
        color: $cRed !important;
      }
    }

    &.legacy {
      font-size: 14px !important;
      color: $cPrimary !important;
      display: block !important;
      border: none !important;
      border-bottom: 1px solid #bfc9ce !important;
      background: none !important;
      font-weight: normal !important;
      padding: 0 !important;
      padding-left: 36px !important;
      height: auto !important;
      overflow: hidden !important;
      outline: none !important;
      border-radius: 0px !important;

      &.error {
        border-bottom: 1px solid $cRed !important;
        color: $cRed !important;
      }

      &.disabled {
        pointer-events: none !important;
        cursor: default !important;
        user-select: none !important;
        color: $cGray0 !important;

        &::placeholder {
          color: $cGray2 !important;
        }

        & * {
          cursor: default !important;
          pointer-events: none !important;
        }
      }
    }

    &.legacyOutlined {
      border-radius: 3px !important;
      height: 36px !important;
      padding: 0px 15px 0px 45px !important;
      font-size: 14px !important;
      border: 1px solid #cad2d6 !important;

      &.error {
        border: 1px solid $cRed !important;
        color: $cRed !important;
      }
    }
  }

  .flagButton {
    background: none !important;
    border: none !important;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    bottom: 0 !important;
    width: 50px !important;
    display: flex !important;
    align-items: center !important;

    &.open {
      background: none !important;
    }

    & > div {
      margin-top: 0 !important;
      border: none !important;
      padding-left: 15px !important;
      height: 100% !important;
      width: 100% !important;

      &:hover,
      &:focus,
      &.open {
        background: none !important;
      }
    }

    &.legacy {
      & > div {
        padding-left: 0 !important;
      }
    }

    &.legacyOutlined {
      & > div {
        padding-left: 10px !important;
      }
    }
  }

  .dropdown {
    border-radius: 4px !important;
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.15) !important;
  }
}
