@import "../../../../../styles/theme.scss";

.root {
  position: relative;
}

.dropdown {
  position: absolute;
  right: 0px;
  left: 0px;
  top: 55px;
  border-radius: 8px;
  z-index: 9;
  max-height: 306px;
  overflow-y: auto;
  width: 100% !important;
  background: $cWhite;
  border: 1px solid $cGray2;

  button {
    cursor: pointer;
    display: block;
    user-select: none;
    list-style: none;
    padding: 15px;
    font-size: 15px;
    color: $cBlack;
    border: none;
    background: none;
    text-align: start;
    width: 100%;

    &:hover {
      background-color: $cPrimaryDim;
    }
  }

  .clientInfo {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    font-weight: 400;
    color: $cGray0;
    margin-top: 8px;
    flex-wrap: wrap;

    .clientPhone {
      margin-right: 27px;
    }
  }

  .createBtn {
    display: flex;
    gap: 14px;
    color: $cPrimary;
    border-bottom: 1px solid $cGray2;
    align-items: center;
  }

  .status {
    padding: 15px;
    text-align: center;
  }

  .patient {
    border-bottom: 1px solid $cGray2;

    &:last-child {
      border-bottom: none;
    }
  }
}
