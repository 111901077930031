@import "../../../../../../../styles/theme.scss";

.root {
  display: flex;
  align-items: center;
  color: $cGray0Dark;
  gap: 4px;
  font-size: 14px;
}

.valueCheck {
  color: $cGreen;
  display: flex;
  align-items: center;
}

.valueCross {
  color: $cRed;
  display: flex;
  align-items: center;
}
