.simpleLabel::after {
  content: "*";
  color: red;
}

.choiceBoxes {
  display: flex;
  gap: 24px;
  max-width: 500px;
}

.simpleField {
  max-width: 240px;
  min-width: 200px;
  flex-grow: 1;
}

@media (max-width: 420px) {
  .choiceBoxes {
    flex-wrap: wrap;
    margin-top: 10px;
    gap: 0px;
  }

  .simpleField {
    max-width: 100%;
  }
}
