.procedur-div {
  background-color: #cee1eabf;
  border-radius: 5px;
}

.chart-audit-title {
  font-size: 20px;
  color: #404040;
  width: 100%;
  float: left;
  padding: 15px 10px;
}

.chart-audit-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
  border-radius: 5px;
  gap: 10px;

  .box {
    height: 100px;
    width: 261px;
    padding-right: 20px;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    background-color: #fff;
    padding: 7px;
    align-items: center;
    align-content: center;

    p,
    h4,
    span {
      margin: 0;
      font-family: Lato;
      font-size: 22px;
      font-weight: 400;
      line-height: 27px;
      letter-spacing: 0em;
      color: #000000;
    }

    .text-wrapper {
      display: flex;
      flex-direction: column;
    }

    .text-wrapper-fixed {
      height: 90px;
      width: 181px;
      box-sizing: border-box;
      align-items: center;
      justify-content: center;
    }

    .span-fixed {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      width: 90px;
    }

    span {
      font-size: 30px;
    }

    @media (min-width: 767px) and (max-width: 1371px) {
      .text-wrapper-fixed,
      .span-fixed {
        height: auto;
        width: auto;
        display: block;
        padding: 0;
      }
    }

    @media (max-width: 768px) {
      width: 100%;
      margin-bottom: 5px;

      p,
      h4,
      span {
        font-size: 18px;
      }

      .text-wrapper-fixed,
      .span-fixed {
        height: auto;
        width: auto;
        display: block;
      }
    }
  }

  .chart-audit-tableHead {
    border-bottom: 5px solid #d0d5dd !important;
    background-color: #ffffff !important;
  }

  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    padding: 5px;
    margin-bottom: 5px;

    .box {
      width: auto;
      padding-right: 10px;
    }
  }
}
