@import "../../../../../styles/theme.scss";

.footer {
  display: flex;
  gap: 8px;
}

.legacyBtn {
  display: block;
  color: $cPrimary;
  font-size: 13px;
  padding: 8px 0;

  &:hover {
    text-decoration: underline;
    color: $cPrimary !important;
  }
}
