@import "../../styles/theme.scss";

.root {
  border: none;
  background: none;
  height: 50px;
  border-radius: 8px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.15s ease;
  padding: 0 20px;
  font-family: $secondaryFont;
  font-weight: 600;
}

.rootFontPrimary {
  font-family: $primaryFont;
}

.rootFontSecondary {
  font-family: $secondaryFont;
}

.smallButton {
  height: 36px;
  padding: 0 12px;
  font-size: 15px;
  font-weight: 300;
  border-radius: 4px;
}

.cPrimary {
  color: $cPrimary;
  background-color: $cPrimaryDim;

  &:hover {
    background-color: darken($cPrimaryDim, 3%);
  }
}

.cError {
  color: $cRed;
  background-color: $cRedDim;

  &:hover {
    background-color: darken($cRedDim, 3%);
  }
}

.adornment {
  height: 100%;
  display: flex;
  align-items: center;
}

.leftAdornment {
  margin-right: 10px;
}

.rightAdornment {
  margin-left: 10px;
}

.disabled {
  opacity: 0.6;
  cursor: default;
  pointer-events: none;
  user-select: none;
}
