@import "../../../../../../../styles/theme.scss";

.btn {
  color: $cPrimary;
  border: none;
  background: none;
  outline: none;

  &:hover:not(.disabled) {
    text-decoration: underline;
  }
}

.modalContent {
  padding: 0;
}

.notes {
  padding: 10px 30px 0 30px;

  @media screen and (max-width: 860px) {
    padding: 0 20px 20px 20px;
  }
}

.form {
  padding: 20px 30px;

  @media screen and (max-width: 860px) {
    padding: 20px;
  }
}

.loader {
  padding: 20px 30px;

  @media screen and (max-width: 860px) {
    padding: 20px;
  }
}
