.root {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.footer {
  display: flex;
  gap: 8px;
}
