@import "../../../../../../styles/theme.scss";

.root {
  background: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  border-radius: 3px;
  border: 1px solid $cPrimary;
  color: $cPrimary;
  font-size: 13px;
  text-align: left;
  padding: 5px 9px;

  &:hover:not(.disabled) {
    background-color: $cPrimary;
    color: $cWhite;
  }
}

.warn {
  border: 1px solid $cOrange;
  color: $cOrange;

  &:hover:not(.disabled) {
    background-color: $cOrange;
    color: $cWhite;
  }
}

.disabled {
  pointer-events: none;
}

.fade {
  border: 1px solid $cOrangeDark;
  color: $cOrangeDark;

  &:hover:not(.disabled) {
    background-color: $cOrangeDark;
    color: $cWhite;
  }
}
