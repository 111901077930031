@import "../../../../../../styles/theme.scss";

.root {
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
}

.title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;
}

.links {
  padding: 10px 0 10px 25px;
  font-weight: 400;

  a {
    color: $cPrimary;

    &:hover {
      text-decoration: underline;
    }
  }

  .link {
    display: list-item;
  }
}

.logo {
  width: 250px;
}
