.root {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.tabsBox {
  display: flex;
  align-items: center;
  gap: 16px;
  .createButton {
    margin-left: auto;
  }
}
