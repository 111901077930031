.previlage-ouer-section {
    float: left;
    width: 100%;
    margin-bottom: 10px;
}
.privileges-submenu{
	display:block;
	float:left;
	width:100%;
	margin-left:0px;
  margin:0px;
}
.privileges-subtabs-li{
	display:block;
}
.privileges-submenu > li:last-child{
	margin:0px;
}
.privileges-subtabs-a{
	display:block;
	padding:15px;
	color:#000000;
	font-size: 13px;
	text-align:left;
	font-family: 'Lato', sans-serif;
  font-weight:normal;
  border-bottom:1px solid #dddddd;
}
.privileges-subtabs-a.sel-submenu, .privileges-subtabs-a:hover{
	background:none;
	border-radius:3px;
	color:$blue;
}
.privileges-submenu li:last-child a{border:none;}
.privileges-row{
	display:block;
	position:relative;
	padding:0px 0px 13px 28px;
	float:left;
	width:100%;
}
.privileges-row *{
	cursor:pointer;
}
.privileges-row .setting-text {
  text-transform: capitalize;
}
.privileges-row .new-check{
	position:absolute!important;
	left:0px;
	top:2px;
}
.privilegeOuter{
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  display: flex;
  flex-wrap: wrap;

  &:before{display:inherit!important;}
}
.previliageSectionOuter{
  padding: 20px;
  float: left;
  width: 100%;
  border-bottom: 1px solid #ddd;
  padding-bottom: 0px;
}

.preferred-color{
  float: left;
  font-size: 13px;
  margin-top: -27px;
  margin-bottom: 20px;

  .current-color{
    width: 30px;
    height: 28px;
    border-radius: 3px;
    display: inline-block;
    float: right;
    background-color:$blue;
    color: #ffffff;
    text-align: center;
    font-size: 11px;
    vertical-align: top;
    line-height: 19px;
    border:1px solid $blue;
    margin-top:-2px;
    position:relative;
    @include box-shadow(0, 1px, 2px, 0, rgba(0,0,0,0.15));
  }
    ul{
      width:136px !important;
      min-width:136px !important;
      padding:3px;
      border:1px solid #dddddd;
      position:absolute;
      left:-75px!important;
      top:22px;
      background:#ffffff;
      @include border-radius(3px);
      @include box-shadow(0, 1px, 2px, 0, rgba(0,0,0,0.15));

      li{display:inline-block;margin:2px;list-style:none;
        .color-palette{width:28px;height:28px;background:#dddddd;display:inline-block;@include border-radius(3px);cursor:pointer; font-size: 11px;text-align: center;
        font-size: 11px;
        vertical-align: top;
        line-height: 19px; padding-top: 2px;
        .color-palette-checked{
          vertical-align: middle;
        }
      }
      }
    }


  label{display:inline-block;margin-top:2px;color:#667680;}
  .changeColor{
    float: right;
    margin-left: 8px;
    padding: 4px 11px 4px;
    font-size: 12px;
    min-width: inherit;
    margin-top: 0px;
    height: 29px;
    display: inline-block;
    vertical-align: top;
    margin-top: -2px;
  }
}
