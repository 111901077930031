@import "../../../../styles/variables.scss";
@import "../../../../styles/theme.scss";

.root {
  display: flex;
  gap: 13px;
  cursor: grab;
  padding: 10px 15px;
  border-radius: 4px;
  border: 1px solid $cGray3;
  background-color: $cPrimaryDim3;
  z-index: $zIndexMax;
}

.dragIcon {
  margin-top: 7px;
}

.disabled {
  cursor: default;

  & * {
    cursor: default;
  }
}
