.clinicSelect {
  display: flex;
  position: relative;
  max-width: 250px;
  white-space: nowrap;

  button {
    border: 0;
    background: white;
    color: #188bf6;
    font-size: 13px;
    text-align: left;
  }
}

.clinicIcon {
  color: #188bf6;
  font-size: 11px;
}

.clinicOptions {
  background: white;
  font-size: 13px;
  border: 1px solid #ddd;
  border-radius: 5px;
  position: absolute;
  top: 30px;
  width: 280px;
  z-index: 1;
  margin-top: 10px;

  ul {
    max-height: 200px;
    list-style-type: none;
    overflow: auto;
    margin: 0;

    li {
      border-bottom: 1px solid #dddddd;
      color: black;
      text-align: left;

      button {
        width: 100%;
        background: none;
        border: none;
        color: black;
        padding: 10px 15px;
        text-align: left;
      }
    }

    li:hover,
    li.active {
      font-weight: bold;
    }
  }
}

.clinicOptions::after {
  width: 15px;
  height: 15px;
  content: url(/../images/arrow-top.png);
  position: absolute;
  top: -13px;
  left: 15px;
}
