.prod-price-clinic-name {
  margin: 15px 0px;
  font-size: 13px;
}

.custom-txt-rule-left-padding {
  padding-left: 20px;
}

.product-list-outer {
  border-left: 1px solid #e6e9eb;
  border-right: 1px solid #e6e9eb;
}

.product-list-outer td {
  padding: 7px 10px 7px 15px !important;
}

.textarea-height-50 {
  height: 50px;
  min-height: inherit;
}

.new-gift-card {
  float: left;
  width: 100%;
  height: auto;
  max-width: 320px;
  background-size: 100%;
  background-position: left top;
  position: relative;
  @include border-radius(5px);
  border: 1px solid #ececec;
  margin-bottom: 10px;

  .gift-card {
    width: 100%;
    float: left;
  }

  .gift-card-logo {
    position: absolute;
    top: 102px;
    text-align: center;
    width: 100%;
    left: 0px;

    img {
      max-height: 40px;
    }
  }

  .gift-amount {
    position: absolute;
    right: 0px;
    top: 14px;
    width: 89px;
    font-family: "lato", Open Sans;

    .gift-amount-span {
      float: left;
      font-size: 15px;
      font-weight: bold;
      color: #0080c9;
    }

    .gift-amount-label {
      float: left;
      font-size: 30px;
      margin: 0px;
      line-height: 37px;
      font-weight: bold;
      color: #0080c9;
    }
  }
}

.inventory-product-img {
  max-width: 50px;
  max-height: 70px;
  margin-right: 20px;
}

.inentory-product-name {
  max-width: 250px;
}

.cursor-pointer {
  cursor: pointer !important;
}

.limited-time-in-month {
  float: left;
  width: 110px;
  margin-top: 4px;

  select {
    float: left;
    width: 50px;
    margin-right: 10px;
  }

  span {
    float: left;
    margin-top: 2px;
  }
}

.spinOuter {
  position: absolute;
  right: 0px;
  top: 2px;
}

.edit-product-upload {
  position: static;
  float: right;
}

.newInputFileldOuter .newInputFileldOuter {
  margin-top: -2px;
}

.editProTable th {
  padding: 4px 10px 4px 10px !important;
}

.weeklyInventory {
  .tdtextarea {
    padding: 10px 25px;

    textarea {
      height: 40px;
      resize: none;
      padding: 8px;
    }
  }

  /*td{border-color:#cad2d6;}*/
}

.weeklyInventoryTable {
  tbody tr:first-child .weeklyInventory td {
    border: none;
  }
}

.large-popup {
  width: 650px;
  max-width: 100%;
}

.extra-large-popup {
  width: 1000px;
}

.ReconciliationReason {
  padding: 5px 0px 10px;
  word-break: break-word;
}

.weeklyInventory .childTable {
  padding: 15px 25px !important;
  float: none;
  border: none;
}

.saveDraft {
  float: right;
}

.selectedtr {
  background: #f2f4f5;
}

.ReconciliationOuter {
  width: 100%;
  float: left;
  margin-bottom: 15px;
}

.inventryCategoryList {
  position: absolute;
  left: 30px;
  top: 0px;
  right: 30px;
}

.inventryCategoryOuter {
  position: relative;
  min-height: 460px;
}

.manageCategoryShowActive {
  line-height: 25px;

  .newInputFileldOuter {
    margin-top: 5px;
  }
}

.manageCategoryProductActive {
  line-height: 25px;
}

.new-rest-btn {
  color: #047ec3;
  font-size: 14px;
  font-family: "Lato", sans-serif;
  font-weight: normal;
  padding: 5px 17px 4px;
  text-decoration: underline;
}

.option-dropDown {
  .btn-default {
    border-color: $blue;
    color: $blue;
    padding: 5px 19px 5px 10px;
    @include border-radius(3px);
    height: 33px;
    font-size: 14px;

    &:focus {
      background: none;
      border-color: 1px solid $blue;
      outline: initial;
    }

    svg {
      top: 10px;
      right: 6px;
    }
  }
}

.ratioOfProduct {
  width: 80%;
}

.new-repeat-price input {
  position: absolute;
  left: 0px;
}

.productDirectoryCheck {
  display: inline-block;
  vertical-align: top;
  margin-right: 5px;
  margin-top: 0px;
}

.customSelectbox {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 7px !important;
  cursor: pointer;
}

.customSelectOption {
  position: absolute;
  top: 45px;
  left: 0px;
  width: 100%;
  border: 1px solid #dddddd;
  height: auto;
  z-index: 1;
  @include border-radius(3px);
  @include box-shadow(0, 1px, 2px, 0, rgba(0, 0, 0, 0.15));

  ul {
    list-style: none;
    display: block;
    background: #ffffff;
    margin: 0px;

    li {
      display: block;
      padding: 10px;
      font-size: 13px;
      color: #404040;
      border-bottom: 1px solid #dddddd;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover {
        background: #f5f5f5;
        cursor: pointer;
      }
    }
  }
}

.manageCategoryPopupHeight {
  min-height: 650px;
}

.backManageCategory {
  height: 32px;
}

.reconciledProducts {
  float: right;
  margin-top: 6px;
  font-size: 14px;
}

.breadcrumbArrow {
  display: inline-block;
  vertical-align: top;
  margin-top: 10px !important;
}

.createStockInventory {
  overflow: inherit !important;
  display: table;
}

.supplierStatus {
  float: right;
  font-size: 15px;
  padding: 5px 0px 0px;
}

.easy-link .fa-info-circle {
  margin-top: 2px;
}

.supplierInformation {
  width: 100%;
  padding: 20px;
  margin-bottom: 10px;
  min-height: inherit;
}

/*.supplierDetailsLeft{border-right:1px solid #dddddd;}*/
.purchaseOrderInfo {
  padding: 10px 15px 0px;

  .detail-row {
    label {
      margin-bottom: 0px;
    }

    span {
      color: #404040;
      font-weight: 600;
      width: 100%;
      text-align: right;
    }
  }
}

.orderInfoTotal {
  padding-top: 17px !important;
}

.productTableDropdown {
  top: 35px;
}

.productTableDropdownnorecord li {
  padding: 8px;
}

.orderInfoTable {
  overflow-x: inherit !important;
  overflow-y: inherit !important;
}

.proNameTitle {
  background: #daedf7;
  padding: 10px;
  font-size: 14px;
  @include border-radius(3px);
}

.editSupplieInfoBtn {
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 1;
}

.remainUnits {
  margin-right: 50px;
}

.shippingInfo .detail-row label {
  width: 130px;
}

.stockDetail-btn {
  padding: 4px 10px;
}

.maxCharacterInstruction {
  font-size: 12px;
  display: block;
  float: left;
  width: 100%;
}

.stockTransferProductDetail td {
  vertical-align: top;
}

.protected .table-responsive.stockTransferProductDetail {
  overflow: visible !important;
}

.confirmAcceptPopup {
  padding-bottom: 0px;
  display: block !important;
}

.supplierInformation {
  .detail-row label {
    width: 160px;
    min-width: 160px;
  }
}

.stockTransferTitle {
  font-size: 15px;
  font-family: "Lato", sans-serif;
  color: #404040;
  width: 100%;
  float: left;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
  padding-bottom: 13px;
}

.stockTransfersubTitle {
  font-size: 14px;
  font-family: "Lato", sans-serif;
  color: #404040;
  width: 100%;
  float: left;
}

.recieveProductPopup {
  overflow: visible;
  display: table;

  .small-popup-content {
    overflow: visible;
    max-height: inherit;

    .react-datepicker-popper {
      margin-top: 10px !important;
    }
  }
}

.supplierAccordion label {
  font-size: 15px;
  display: block;
  color: #404040;
  text-decoration: none;
  padding: 17px 20px 0px 20px;
  position: relative;
  margin: 0px;
  float: left;
  width: 100%;
  font-weight: normal;
  cursor: pointer;

  &.active:after {
    content: "_";
    position: absolute;
    left: 0px;
    top: -4px;
    font-size: 27px;
  }

  &:after {
    content: "+";
    position: absolute;
    left: 0px;
    top: 7px;
    font-size: 27px;
  }
}

.edit-supplier {
  display: block !important;
}

.tableColorPrefer {
  position: relative;
  margin: 5px 0px 0px;

  ul {
    top: 26px !important;
    left: 0px !important;
    transform: initial !important;

    li {
      float: left;
    }
  }
}

.standardPackageTable {
  overflow: visible;
}

.purchaseOrderSuntotal {
  .detail-row label {
    width: 155px;
    min-width: 155px;
  }

  .detail-row span {
    text-align: left !important;
  }
}

.purchaseOrderExpiry {
  width: 87px;
}

.purchaseOrderInvoice {
  width: 140px;
}

/**************************** inventory variation ******************************/

.bucketOuter {
  display: block;
  border: 1px solid #dddddd;
  @include border-radius(5px);
  float: left;
  width: 100%;
  position: relative;
}

.bucketBtn button.btn {
  background: $blue !important;
  color: #ffffff !important;
}

.trashBucket {
  background: #eeeeee;
  color: $blue;
  padding: 4px 8px;
  position: absolute;
  right: 3px;
  top: 3px;
  border-radius: 3px;
  z-index: 1;
}

.bucketNumber {
  background: #047ec3;
  padding: 3px 0px;
  position: absolute;
  color: #fff;
  @include border-radius(20px);
  font-size: 12px;
  left: 5px;
  top: 4px;
  width: 24px;
  height: 24px;
  text-align: center;
}

.bucketProductOuter {
  padding: 0px;

  a {
    font-weight: normal;
  }
}

.simpleSelectProduct {
  width: 100%;
  float: left;
  color: #404040;
  border: 1px solid #cad2d6;
  @include border-radius(3px);
  min-height: 80px;
  padding: 0px 15px;
  font-size: 14px;
  background: #ffffff;
}

.customTagOuter {
  width: 100%;
  float: left;
  border-bottom: 1px dashed #dddddd;
  padding: 10px 0px 5px;

  .customTag {
    background-color: $blue;
    border: 1px solid $blue;
    color: #ffffff;
    padding: 2px 5px;
    margin-bottom: 2px;
    @include border-radius(2px);
    display: inline-block;
    font-family: sans-serif;
    font-size: 13px;
    font-weight: 400;
    margin-right: 5px;
    position: relative;
    padding-right: 18px;

    i,
    svg {
      position: absolute;
      right: 5px;
      top: 6px;
      font-size: 10px;
      color: #ffffff;
    }
  }
}

.bucket-tabs {
  list-style: none;
  display: block;
  text-align: center;

  li {
    display: inline-block;
    vertical-align: top;
    margin-right: 6px;
    margin-bottom: 6px;

    a {
      padding: 15px 40px;
      color: #404040;
      border: 1px solid #dddddd;
      @include border-radius(4px);
      font-size: 13px;
      display: inline-block;
      min-width: 150px;
      position: relative;

      i,
      svg {
        display: inline-block;
        position: absolute;
        left: 15px;
        top: 28px;
      }

      p {
        margin: 0px;
      }
    }

    a.active {
      border-color: $blue;
      background: $blue;
      color: $blue;
      color: #ffffff;

      i,
      svg {
        display: inline-block;
        position: absolute;
        left: 15px;
        top: 28px;
      }
    }
  }
}

.seviceBucketTable {
  overflow: inherit !important;
}
