@import "../../styles/theme.scss";

.root {
  display: flex;
  align-items: center;
  border: none;
  background: none;
  gap: 10px;
}

.rootAlignStart {
  align-items: flex-start;
}

.rootAlignEnd {
  align-items: flex-end;
}

.rootDisabled {
  opacity: 0.6;
  user-select: none;
  pointer-events: none;
}

.rootFitContent {
  width: fit-content;
}

.label {
  font-size: 13px;
}

.toggle {
  width: 42px;
  height: 22px;
  background-color: $cGray1;
  position: relative;
  border-radius: 30px;
  display: flex;
  align-items: center;
  transition: all 0.2s ease;
  span {
    width: 16px;
    height: 16px;
    margin-left: 3px;
    background-color: $cWhite;
    border-radius: 50%;
    transition: all 0.2s ease;
  }
}

.toggleActive {
  background-color: $cPrimary;
  span {
    margin-left: 23px;
  }
}
