@import "../../styles/theme.scss";

.root {
  border: none;
  background: none;
  border-radius: 8px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $cPrimaryLight;
  color: $cPrimary;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
}
