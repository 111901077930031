@import "../../styles/theme.scss";

.root {
  position: relative;
  padding-left: 27px;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  width: fit-content;

  .label {
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    color: $cBlack;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkMark {
    position: absolute;
    top: 50%;
    left: 0;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background-color: transparent;
    border: 1px solid $cGray2;
    transform: translate(0, -50%);
  }

  .checkMark:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 10px;
    height: 10px;
    background-color: $cPrimary;
    transform: translate(-50%, -50%);
    display: none;
    border-radius: 50%;
  }

  input:checked ~ .checkMark:after {
    display: block;
  }
}

.disabled {
  opacity: 0.4;
  cursor: not-allowed;
}
