@import "../../../../../styles/theme.scss";

.file {
  display: flex;
  justify-content: space-between;
  border: 1px solid $cGray2;
  border-radius: 8px;
  align-items: center;
  padding-right: 8px;
  transition: all 0.15s ease;
  &:hover {
    border-color: transparent;
    background-color: $cPrimaryDim;
    .fileBtn {
      color: $cPrimary;
    }
    .closeBtn {
      background-color: $cPrimaryDim;
    }
  }

  .fileBtn {
    border: none;
    background: none;
    font-size: 16px;
    padding: 10px;
    flex: 1;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all 0.15s ease;
  }

  .closeBtn {
    border: none;
    background-color: $cRedDim;
    display: flex;
    align-items: center;
    border-radius: 3px;
    width: 24px;
    height: 24px;
    justify-content: center;
    margin-left: 10px;
    transition: all 0.15s ease;
    &:hover {
      background-color: darken($cRedDim, 2%);
    }
  }
}

.fileSelected {
  border-color: transparent;
  background-color: $cPrimaryDim;
  .fileBtn {
    color: $cPrimary;
  }
  .closeBtn {
    background-color: $cPrimaryDim;
  }
}

.disabled {
  user-select: none;
  cursor: default;
}
