@import "../../../../../styles/mixins.scss";
@import "../../../../../styles/theme.scss";

.root {
  width: 100%;
  overflow-x: scroll;
  padding-left: 20px;

  @include hide-scroll-bar();
}

.inputWrap {
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: center;
}

.col {
  border-left: 1px solid $cGray3;
  border-radius: 0;

  &:first-child {
    border-left: none;
  }
}
