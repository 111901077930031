@import "../../styles/mixins.scss";

.footer {
  display: flex;
  gap: 8px;
}

.subheader {
  margin: 0 0 16px 0;
}

.listWrap {
  max-height: 332px;
  overflow-y: scroll;
  @include hide-scroll-bar();
}
