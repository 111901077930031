.tableHead {
  font-size: 13px;
}

.bigColWidth {
  width: 180px;
}

.biggerColWidth {
  width: 230px;
}

.row {
  font-size: 13px;
}

.col {
  word-break: break-word;
}
