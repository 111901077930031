@import "../../../../styles/theme.scss";
@import "../variables.scss";

.root {
  display: flex;
  margin-bottom: 30px;
  flex-wrap: wrap;
  @media screen and (max-width: $tabletMaxBreakpoint) {
    flex-direction: column;
  }
}

.rootSmall {
  margin-bottom: 24px;
}

.rootNoMarginBottom {
  margin-bottom: 0;
}

.rootWNone {
  width: auto;
}

.rootWMax {
  width: $entityRowMaxWidth;
  max-width: 100%;
  @media screen and (max-width: $tabletMaxBreakpoint) {
    width: 100%;
  }
}

.label {
  width: 130px;
  max-width: 130px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: $cGray0;
  margin-right: 10px;
  margin-bottom: 10px;
}

.smallLabel {
  width: 65px;
  max-width: 65px;
  min-width: fit-content;
}

.content {
  flex: 1;
  width: 100%;
  & > * {
    width: 100%;
  }
}
