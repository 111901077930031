.clinicContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.togglesContainer {
    margin-top: auto;
    display: flex;
    justify-content: flex-end;
}

.toggleField {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
}

.settingsClinicsPage {
    display: flex;
    flex-direction: column;
}

.clinicsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
}

.clinicCard {
    width: 100%;
    background: white;
    border-radius: 5px;
    font-size: 13px;

    .cardTitle {
        display: flex;
        justify-content: space-between;
        padding: 18px;
        border-bottom: 1px solid #ddd;

        h3.title {
            color: black;
            font-size: 20px;
            font-weight: 400;
            max-width: 200px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        button {
            white-space: nowrap;
        }
    }

    .cardBody {
        display: flex;
        flex-direction: column;
        gap: 24px;
        color: black;
        padding: 15px;
        min-height: 470px;


        .businessHours {
            display: flex;
            flex-direction: column;
            gap: 14px;

            .businessHoursTitle {
                font-weight: bold;
            }

            .hoursContainer {
                display: flex;
                flex-direction: column;
                gap: 10px;

                .day {
                    display: inline-block;
                    width: 40px;
                }
            }
        }

        .togglesInnerContainer {
            width: fit-content;
            display: grid;
            grid-template-columns: max-content max-content;
            gap: 8px;
            white-space: nowrap;
        }

        .toggle {
            font-size: 13px;
        }
    }
}

.disabledClinic {
    background: #F2F6F7;
}