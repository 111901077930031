.dropdownMenu {
    padding: 5px 10px;
}

.header {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    margin-bottom: 16px;
}

.dayOption {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;

    input {
        display: flex;
    }
}

.copyIcon {
    display: inline-block;
    margin: 5px;
    cursor: pointer;
}

.closeBtn {
    display: flex;
    justify-content: flex-end;

    button {
        background-color: transparent;
        border: none;
        cursor: pointer;
    }
}