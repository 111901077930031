.root {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 24px;

  @media screen and (max-width: 750px) {
    flex-direction: column;
    align-items: flex-end;
    gap: 16px;
  }
}

.right {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 4px;

  .buttons {
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: flex-end;
    flex-wrap: wrap;
  }
}
