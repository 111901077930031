.root {
  display: flex;
  flex-direction: column;
  gap: 2px;

  img {
    width: 200px;
    height: auto;
  }
}
